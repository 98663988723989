﻿export class SvgTooltipConfig {
    graphHeight: number;
    graphWidth: number;
    tooltipWidth: number;
    tooltipTextHeight: number;
    fontSize: string;
    fontWeight: string;
    bgClassName: string;
    textClassName: string;
    circleClassName: string;
}