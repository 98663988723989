import { Unit } from './unit';

export class UnitConversion {
    unitList = new Array<Unit>();
    baseUnit: Unit;
    defaultUnit: Unit;
    currentUnit: Unit;
    convertOnChange = true;

    constructor(baseUnit: Unit, defaultUnit: Unit = null, unitList: Unit[] = null) {
        this.baseUnit = baseUnit;
        this.defaultUnit = defaultUnit;
        if (this.defaultUnit == null) {
            this.defaultUnit = this.baseUnit;
        }
        this.currentUnit = this.defaultUnit;
        this.unitList = unitList;
    }

    getBaseUnit(): Unit {
        return this.baseUnit;
    }

    getDefaultUnit(): Unit {
        return this.defaultUnit;
    }

    convert(value: number, fromUnit: Unit, toUnit: Unit): number {
        if (this.convertOnChange) {
            // convert to base before converting
            value = value / fromUnit.multiplier;
            value = value * toUnit.multiplier;
            this.currentUnit = toUnit;
        }
        return value;
    }

    convertToBase(value: number, fromUnit: Unit) {
        value = value / fromUnit.multiplier;
        value = value * this.baseUnit.multiplier;
        return value;
    }

    convertToDefault(value: number, fromUnit: Unit) {
        value = value / fromUnit.multiplier;
        value = value * this.defaultUnit.multiplier;
        return value;
    }
}
