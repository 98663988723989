import {
  OnInit, Component, Input, ElementRef, ViewChild, Inject, InjectionToken, ViewContainerRef,
  ChangeDetectorRef, ComponentFactoryResolver
} from '@angular/core';
import { UserService } from 'app/services/user-service';
import { AppUtilities } from 'app/services/app-utilities';
import { DataViewDemoService } from 'app/services/data-view-demo-service';
import { EntityType } from 'app/enums/entity-type';
import { EntityOperation } from 'app/enums/entity-operation';
import { MonitorGroup } from 'app/models/monitor-group';
import { resetCompiledComponents } from '@angular/core/src/render3/jit/module';
import { AddUpdateMonitorGroupDialogComponent } from '../add-update-monitor-group-dialog/add-update-monitor-group-dialog.component';
import { ServiceResponse } from 'app/models';
import { ConfirmationDialogComponent } from 'app/components/confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'monitor-groups-dialog',
  templateUrl: './monitor-groups-dialog.component.html',
  styleUrls: ['./monitor-groups-dialog.component.css']
})

export class MonitorGroupsDialogComponent {
  availableMonitorGroups = new Array<MonitorGroup>();
  selectedMonitorGroup: MonitorGroup;
  @ViewChild('okButton') public okButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;
  loading = true;

  constructor(private dataService: DataViewDemoService, private viewContainer: ViewContainerRef, 
    private componentFactoryResolver: ComponentFactoryResolver, private utilityService: AppUtilities) {
    this.getMonitorGroups();
  }

  open(): Promise<MonitorGroup> {
    return new Promise<MonitorGroup>(resolve => {
      if (this.cancelButton != null) {
        this.cancelButton.nativeElement.onclick = ((e: any) => {
          e.preventDefault();
          resolve(null);
        });
      }
      if (this.okButton != null) {
        this.okButton.nativeElement.onclick = ((e: any) => {
          this.loading = true;
          let data = {};
          (data as any).monitorGroupId = this.selectedMonitorGroup.monitor_group_id;
          this.dataService.performEntityOperation(EntityType.MonitorGroup, EntityOperation.Get, data).subscribe((results) => {
            if (results.success) {
              resolve(JSON.parse(results.data));
            } else {
              this.utilityService.showError('Error Retrieving Selected Monitor Group', results.responseMsg);
            }
            this.loading = false;
          }, (error) => {
            this.loading = false;
            this.utilityService.showError('Error Retrieving Selected Monitor Group', error.statusText);
          });
        });
      }
      
    });
  }

  getMonitorGroups() {
    this.dataService.performEntitiesOperation(EntityType.MonitorGroup, EntityOperation.GetList).subscribe((results) => {
      if (results.success) {
        this.availableMonitorGroups = JSON.parse(results.data);
        if(this.availableMonitorGroups.length > 0)
          this.selectedMonitorGroup = this.availableMonitorGroups[0];
      } else {
        this.utilityService.showError('Error Retrieving Monitor Groups', results.responseMsg);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.utilityService.showError('Error Retrieving Monitor Groups', error.statusText);
    });
  }

  openUpdate() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(AddUpdateMonitorGroupDialogComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    componentRef.instance.open(this.selectedMonitorGroup).then((result: MonitorGroup) => {
      componentRef.destroy();
    });
  }

  openAddNew() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(AddUpdateMonitorGroupDialogComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    componentRef.instance.open().then((result: MonitorGroup) => {
      if (result != null) {
        this.loading = true;
        this.getMonitorGroups();
      }
      componentRef.destroy();
    });
  }

  delete() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ConfirmationDialogComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    componentRef.instance.open('Monitor Group Deletion Confirmation', 'Are you sure you want to delete this monitor group? This action cannot be undone.').then((result: boolean) => {
      // result will be null if they cancelled
      if (result) {
        this.loading = true;
        let data = {};
        (data as any).monitorGroupId = this.selectedMonitorGroup.monitor_group_id;
        this.dataService.performEntityOperation(EntityType.MonitorGroup, EntityOperation.Delete, data).subscribe((response: ServiceResponse) => {
          this.loading = false;
          if (response.success) {
            this.utilityService.showSuccess('Delete Success', 'Monitor Group was deleted successfully');
            this.getMonitorGroups();
          } else {
            this.utilityService.showError('Request Failed', 'Unable to delete Monitor Group');
          }
        });

      }
      componentRef.destroy();
    });
  }
}
