import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { PFNavbarModule } from '@kratos/pf-ui/navbar';
import { PFThemeChooserModule } from '@kratos/pf-ui/theme-chooser';
import { AngularDraggableModule } from 'angular2-draggable';
import { ConfirmationDialogComponent } from 'app/components/confirmation-dialog/confirmation-dialog.component';
//import { RangingDataComponent } from 'app/components/telemetry-view/telemetry-view.component';
import { SpecanViewComponent } from 'app/components/specan-view/specan-view.component';
import { AddUserComponent } from 'app/components/user-management/add-user/add-user.component';
import { UserManagementComponent } from 'app/components/user-management/user-management.component';
import { UserProfileComponent } from 'app/components/user-profile/user-profile.component';
import { UserService } from 'app/services/user-service';
import { D3Service } from 'd3-ng2-service';
import { GridStackModule } from 'ng4-gridstack';
import { NgxMaskModule } from 'ngx-mask';
import { ButtonModule, CalendarModule, CheckboxModule, ColorPickerModule, ContextMenuModule, AutoCompleteModule, DropdownModule, GrowlModule, ListboxModule, OverlayPanelModule, SliderModule, TooltipModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { AppComponent } from './app.component';
import { AnalysisSelectionBarComponent } from './components';
import { ContextMenuService } from './components/context-menu/context-menu-service';
import { PFSpecanModule } from './components/graphs/specan';
import { PFUnitModule } from './components/graphs/unit';
import { PFWaterfallModule } from './components/graphs/waterfall';
import { ContextMenuComponent, ContextMenuDirective, DataViewConfigComponent, DataViewDemoComponent, HomeComponent, LoadingDialog, LoginComponent, UserSettingsComponent } from './components/index';
import { MultiSpecanViewFullscreenComponent } from './components/multi-specan-view-fullscreen/multi-specan-view-fullscreen.component';
import { FittextDirective } from './directives/fit-text.directive';
import { AppUtilities } from './services/app-utilities';
import { ConfigurationService } from './services/config-service';
import { CookieModule } from 'ngx-cookie';
import { DataViewDemoService } from './services/data-view-demo-service';
import { CanActivateViaAuthGuard } from './tokens/authguard';
import { MultiSpecanViewComponent } from './components/multi-specan-view/multi-specan-view.component';
import { DatabaseComponent, DatabaseModule } from './components/database';
import { CacheInterceptor } from './services/http-interceptor';
import { AddUpdateMonitorGroupDialogComponent } from './components/multi-specan-view/add-update-monitor-group-dialog/add-update-monitor-group-dialog.component';
import { MonitorGroupsDialogComponent } from './components/multi-specan-view/monitor-groups-dialog/monitor-groups-dialog.component';
import { CookieManager } from './models/cookie-manager';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'dataview', component: DataViewDemoComponent, canActivate: [CanActivateViaAuthGuard] },
  { path: 'specan', component: SpecanViewComponent, canActivate: [CanActivateViaAuthGuard] },
  { path: 'multi-specan', component: MultiSpecanViewComponent, canActivate: [CanActivateViaAuthGuard] }, 
  { path: 'multi-specan-fullscreen', component: MultiSpecanViewFullscreenComponent, canActivate: [CanActivateViaAuthGuard] }, 
//  { path: 'ranging', component: RangingDataComponent, canActivate: [CanActivateViaAuthGuard] },
  { path: 'user-management', component: UserManagementComponent, canActivate: [CanActivateViaAuthGuard] },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [CanActivateViaAuthGuard] },
  { path: 'dataview-config', component: DataViewConfigComponent, canActivate: [CanActivateViaAuthGuard], outlet: 'slideout' },
  { path: 'database', component: DatabaseComponent, canActivate: [CanActivateViaAuthGuard] },
  { path: 'user-settings', component: UserSettingsComponent, outlet: 'slideout' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    MultiSpecanViewComponent,
    HomeComponent,
    UserSettingsComponent,
    MonitorGroupsDialogComponent,
    UserProfileComponent,
    AddUpdateMonitorGroupDialogComponent,
    MultiSpecanViewFullscreenComponent,
    LoginComponent,
    AnalysisSelectionBarComponent,
    FittextDirective,
    AddUserComponent,
    LoadingDialog,
    //RangingDataComponent,
    SpecanViewComponent,
    ConfirmationDialogComponent,
    ContextMenuDirective,
    UserManagementComponent,
    DataViewDemoComponent,
    DataViewConfigComponent
  ],
  imports: [
    ButtonModule, ContextMenuModule, GrowlModule, CalendarModule,
    BrowserModule,
    OverlayPanelModule,
    ColorPickerModule,
    DropdownModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    TooltipModule,
    PFThemeChooserModule,
    AutoCompleteModule,
    PFUnitModule,
    CookieModule.forRoot(),
    DatabaseModule,
    HttpClientModule,
    SliderModule,
    CheckboxModule,
    GridStackModule,
    ListboxModule,
    AngularDraggableModule,
    TableModule,
    PFNavbarModule,
    PFWaterfallModule,
    PFSpecanModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
  ],
  entryComponents: [AddUserComponent, ConfirmationDialogComponent, AddUpdateMonitorGroupDialogComponent, MonitorGroupsDialogComponent],
  providers: [DataViewDemoService, UserService, ContextMenuService, D3Service, AppUtilities, ConfigurationService, CanActivateViaAuthGuard, CookieManager,
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
