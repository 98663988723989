﻿import { OnInit, Component, Input, ElementRef, ViewChild, Inject, InjectionToken, ViewContainerRef,
    ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
@Component({
    selector: 'loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.css']
})

export class LoadingDialog {
    @ViewChild('okButton') public okButton: ElementRef;
    @ViewChild('cancelButton') public cancelButton: ElementRef;
    @Input('title') title: string;
    elementRef: ElementRef;

    open(): Promise<boolean> {
         //focus on the first input on the dialog when it opens for convenience
        
        return new Promise<boolean>(resolve => {
            if (this.cancelButton != null) {
                this.cancelButton.nativeElement.onclick = ((e: any) => {
                    e.preventDefault();
                    resolve(false);
                });
            }
            if (this.okButton != null) {
                this.okButton.nativeElement.onclick = ((e: any) => {
                    e.preventDefault();
                    resolve(true);
                });
            }
            
        });
    }

}