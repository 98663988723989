
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DropdownModule, CalendarModule, AutoCompleteModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppUtilities } from './services/app-utilities';

import { ActivationPopupComponent } from './popups/activation/activation-popup.component';
import { FormComponent } from './form.component';
import { KeysPipe } from './models/pipe';
import { FittextDirective } from './directives/fit-text.directive';
import { RequestBody } from './models/request-body';

export { ActivationPopupComponent } from './popups/activation/activation-popup.component';
export { RequestBody } from './models/request-body';
export { FormComponent } from './form.component';
export { UserMessage } from './models/user-message';
export {  ServiceResponse } from './models/service-response';
export { AppUtilities } from './services/app-utilities';
export { AuthResponse, AuthToken } from './models/auth-response';
@NgModule({
  imports: [
    DropdownModule,
    CalendarModule,
    CommonModule,
    FormsModule,
    AutoCompleteModule
  ],
  declarations: [
    ActivationPopupComponent,
    FittextDirective,
    KeysPipe,
  ],
  exports: [
    ActivationPopupComponent,
    KeysPipe
  ],
  providers: [AppUtilities],

})
export class SharedModule {
}
