import { Component, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { UnitConversion, UnitOptions } from "../unit";
import { Subject } from "rxjs";
import { Transponder } from "../models/transponder";
import { Satellite } from "../models/satellite";
import { DataViewDemoService } from "../../../services/data-view-demo-service";
import { EntityType } from '../shared/enums/entity-type';
import { EntityOperation } from '../shared/enums/entity-operation';
import { EntityAlias } from "../models/entity-alias";
import { AppUtilities } from 'app/services/app-utilities';

@Component({
  selector: 'transponders-update',
  templateUrl: './transponders-update.component.html',
  styleUrls: ['./transponders-update.component.css']
})
export class TransponderFormComponent {
  newType: string;
  selectedItem: Transponder;
  selectedSatellite: Satellite;
  inputsThatHaveChanged = new Array<HTMLSpanElement>();
  @ViewChild('addButton') public addButton: ElementRef;
  @ViewChild('closeButton') public closeButton: ElementRef;
  @ViewChild('updateButton') public updateButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;
  @Output() updateGrid = new Subject();
  aliasEntity: string;
  aliasName: string;
  altitudeOptions = ['LEO', 'MEO', 'GEO', 'HEO'];
  processing: boolean;
  hasError: boolean;
  updating: boolean;
  addingAlias: boolean;
  addingTypes: boolean;
  mhzUnits: UnitConversion;
  satellites: Satellite[];
  constructor(private dataService: DataViewDemoService, private utilities: AppUtilities) {
    this.mhzUnits = new UnitConversion(UnitOptions.Hz, UnitOptions.MHz,
      [UnitOptions.kHz, UnitOptions.MHz, UnitOptions.GHz]);
  }
  inputChanged(label: HTMLSpanElement) {
    label.classList.add('text-yellow');
    this.inputsThatHaveChanged.push(label);
  }
  open(satellites, currentSatellite: Satellite, existingItem: Transponder = null): Promise<boolean> {
    if (existingItem != null) {
      this.selectedItem = Transponder.copy(existingItem);
      this.updating = true;
    } else {
      this.selectedItem = new Transponder();
    }
    this.selectedSatellite = currentSatellite;
    this.satellites = satellites;
    return new Promise<boolean>((resolve, reject) => {
      this.cancelButton.nativeElement.onclick = ((e: any) => {
        e.preventDefault();
        resolve(false);
      });
      setTimeout(() => {
        this.closeButton.nativeElement.onclick = ((e: any) => {
          e.preventDefault();
          resolve(true);
        });
        if (this.addButton != null)
          this.addButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.addOrUpdate(EntityOperation.Create, resolve);
          });
        else
          this.updateButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.addOrUpdate(EntityOperation.Update, resolve);
          });
      });
    });
  }

  addOrUpdate(entityOp, resolve) {
    this.selectedItem.satellite_id = this.selectedSatellite.satellite_id;
    if (this.validate()) {
      this.processing = true;
      let data = {} as any;
      data.satelliteId = this.selectedSatellite.satellite_id;
      data.data = this.selectedItem;

      if (this.selectedItem.transponder_id != null) {
        data.transponderId = this.selectedItem.transponder_id;
      }
      this.dataService.performEntityOperation(EntityType.Transponder, entityOp, data).subscribe(
        (result) => {
          this.processing = false;
          if (result.success) {
            this.updateGrid.next();
            if (this.selectedItem.transponder_id != null) {
              this.utilities.showSuccess('Transponder Updated', 'Transponder was updated successfully');
            } else {
              this.utilities.showSuccess('Transponder Created', 'Transponder was created successfully');
              let newtrans = JSON.parse(result.data);
              this.selectedItem.transponder_id = newtrans.transponder_id;
              resolve(true);
            }
          } else {
            this.utilities.showError('Error Saving Transponder', result.responseMsg);
          }
        },
        (err) => {
          this.processing = false;
          this.utilities.showError('Error Saving Transponder', err);
        }
      );
    }
  }
  onSatelliteChange(newval, input) {
    this.inputChanged(input);
  }
  update() {
    this.addOrUpdate(EntityOperation.Update, null);
  }
  addAlias(aliasInput: HTMLSpanElement) {
    this.inputChanged(aliasInput);
    let newAlias = new EntityAlias();
    newAlias.name = this.aliasName;
    newAlias.entity = this.aliasEntity;
    this.selectedItem.aliases.push(newAlias);
    this.aliasEntity = '';
    this.aliasName = '';
  }
  validate() {
    if (this.selectedItem.name == '' || this.selectedItem.name == null) {
      this.hasError = true;
      return false;
    } else if (this.selectedItem.translation == null) {
      this.hasError = true;
      return false;
    } else if (this.selectedItem.satellite_id == null) {
      this.hasError = true;
      return false;
    } else if (this.selectedItem.uguard_band == null) {
      this.hasError = true;
      return false;
    } else if (this.selectedItem.lguard_band == null) {
      this.hasError = true;
      return false;
    }
    return true;
  }
}
