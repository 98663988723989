import {
  Component, ElementRef, HostListener, EventEmitter, Output, Input, OnDestroy,
  AfterViewInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { D3, D3Service, Selection, ZoomBehavior } from 'd3-ng2-service';
import { Observable, Subscription } from 'rxjs/Rx';

import {
  CarrierRegion
} from './data-models/pf-specan-carrier-region';
import { SpectrumAnalyzerSeries } from './data-models/pf-specan-series';
import { SpectrumAnalyzerConfiguration } from './configuration/pf-specan-config';
import { SpectrumAnalyzerDataParameters } from './configuration/pf-specan-data-params';
import { SpectrumAnalyzerRenderer } from './render/pf-specan-renderer';
import { SpectrumAnalyzerUnits } from './configuration/pf-specan-units';
import { SpectrumAnalyzerTemplateVariables } from './pf-specan-html-template-vars';
import { SpectrumAnalyzer } from './pf-specan';
import { MenuItem, OverlayPanel } from 'primeng/primeng';
import { ChartScale, SvgZoom, ZoomEventInfo, CanvasGraphElementModel, SvgMarker } from '../../chart/index';
import { MarkerShape } from '../../chart/pf-chart/marker/pf-svg-marker-shape';


@Component({
  selector: 'pf-spectrum-analyzer',
  templateUrl: './pf-specan.component.html'
})
export class SpectrumAnalyzerComponent implements AfterViewInit, OnDestroy {

  @Input() config: SpectrumAnalyzerConfiguration = new SpectrumAnalyzerConfiguration();
  @Input() dataParams: SpectrumAnalyzerDataParameters = new SpectrumAnalyzerDataParameters();
  // by default this is initialized to the most commonly used units. Does not have to be provided but can be overridden
  @Input() units: SpectrumAnalyzerUnits = new SpectrumAnalyzerUnits();
  @Input() carrierRegions: CarrierRegion[] = new Array<CarrierRegion>();
  @Input() contextMenuItems: MenuItem[];
  @Input() dataModels: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();
  @Input() graphElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();
  @ViewChild('configMenu') configMenu: OverlayPanel;


  // this is the connection between the private specan object and the html template for this component.
  // rather than directly exposing elements of the renderer and axes to the html template,
  // we will use this object to set the variables accordingly and use them in this component and in the html
  htmlTemplateVars: SpectrumAnalyzerTemplateVariables = new SpectrumAnalyzerTemplateVariables();

  mouseCoords: [number, number] = [0, 0];

  // this is the current sample frequency value which can be captured by the enclosing component
  // when the user drags it to a new location
  @Output() sampleFreqValueChange: EventEmitter<number> = new EventEmitter<number>();
  // this event is emitted when the user zooms on the specan
  @Output() onViewExtentChange: EventEmitter<ZoomEventInfo> = new EventEmitter<ZoomEventInfo>();
  private elementRef: ElementRef;
  private d3: D3;
  private d3Service: D3Service = new D3Service();
  private specan: SpectrumAnalyzer;

  @ViewChild('graphContainer') private graphContainer: ElementRef;

  // for the purpose of determining whether the user clicked once or if it was a double click
  private idleTimeout: any;

  // graph configuration/display items
  // shows whether the user is currently editing these values
  private editingSpan: boolean;
  private editingRbw: boolean;
  private editingVbw: boolean;
  private editingDbDiv: boolean;
  private editingCenterFreq: boolean;
  private specanInitialized: boolean;
  private zoomMenuVisible: boolean;

  private rbwSub: Subscription;
  private vbwSub: Subscription;
  private visibilitySub: Subscription;
  private dbPerDivChangedSub: Subscription;

  constructor(d3Service: D3Service, private ref: ElementRef) {
    this.d3 = d3Service.getD3();
    window.onresize = () => {
      if (this.config.graphVisible) {
        this.initializeSpecanIfNeeded();
        if (this.specanIsInitialized()) {
          this.specan.resize();
        }
      }
    };
    this.specan = new SpectrumAnalyzer(this.d3);
    this.elementRef = ref;
  }

  @HostListener('document:click', ['$event']) onClick(event) {
    this.handleClick(event);
  }
  ngAfterViewInit() {
    if (this.config.yAxisTitle == null) {
      this.config.yAxisTitle = 'Power (dBm)';
    }
    this.rbwSub = this.dataParams.rbwValueChange.subscribe((value) => {
      this.rbwChanged(value);
    });
    this.vbwSub = this.dataParams.vbwValueChange.subscribe((value) => {
      this.vbwChanged(value);
    });
    this.specan.onSampleFreqChanged = (newval) => {
      this.sampleFreqValueChange.emit(newval);
    };
    this.visibilitySub = this.config.graphVisibilityChanged.subscribe((visible) => {
      if (visible) {
        // timeout is necessary to give html elements enough time to appear before attempting to access them
        let timeout = setTimeout(() => {
          this.initializeSpecanIfNeeded();
          clearTimeout(timeout);
        })
      }
    });
    this.dbPerDivChangedSub = this.dataParams.dbPerDivValueChange.subscribe((value) => {
      if (this.config.graphVisible && this.htmlTemplateVars.graphInitialized) {
        this.specan.dbDivChanged(value);
      }
    });
    //initialize specan subs as well
    this.syncModelsWithSpecan();
    this.specan.initializeSubscriptions();

    // check to make sure an array type was passed in
    if ((this.dataModels == null)) {
      throw new Error('Attribute \'dataModels\' is required');
    }
    if (!(this.dataModels instanceof Array)) {
      throw new Error('Attribute \'dataModels\' must be of type SpectrumAnalyzerSeries[]');
    }
    if (!(this.units instanceof SpectrumAnalyzerUnits)) {
      throw new Error('Attribute \'units\' must be of type SpectrumAnalyzerUnits');
    }
    this.syncModelsWithSpecan();
    this.initializeSpecanIfNeeded();
  }

  resizeGraph() {
    if (this.config.graphVisible) {
      this.initializeSpecanIfNeeded();
      if (this.specanIsInitialized()) {
        this.specan.resize();
      }
    }
  }

  // can be called if graph attributes have been changed from a parent component (i.e. tooltip visibility)
  refreshGraph() {
    if (this.config.graphVisible) {
      this.syncModelsWithSpecan();
      this.initializeSpecanIfNeeded();
      if (this.specanIsInitialized()) {
        this.specan.refreshGraph();
      }
    }
  }

  initializeSpecanIfNeeded() {
    if (!this.specanInitialized) {
      this.specan.initialize();
      if (this.specan.containerDimensionsAreGreaterThanZero()) {
        this.specanInitialized = true;
      }
    }
  }

  centerFreqChanged(param) {
    if (this.config.graphVisible) {
      this.dataParams.centerFreqChanged.next(param.target.value);
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.dataDomainX = this.specan.getCurrentDomainX();
      this.onViewExtentChange.next(zoomEvent);
    }
  }

  dbDivChanged() {
    if (this.config.graphVisible) {
      this.dataParams.dbPerDiv = this.htmlTemplateVars.graphDbPerDiv;
    }
  }

  getCurrentGraphHeight() {
    return this.specan.getInnerGraphHeight();
  }

  getCurrentGraphWidth() {
    return this.specan.getInnerGraphWidth();
  }

  moveCanvasX(direction) {
    if (this.config.graphVisible) {
      this.specan.moveCanvasX(direction);
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.dataDomainX = this.specan.getCurrentDomainX();
      this.dataParams.centerFreqChanged.next(this.specan.htmlTemplateVars.graphCenterFreq);
      this.onViewExtentChange.next(zoomEvent);
    }
  }

  moveCanvasY(direction) {
    if (this.config.graphVisible) {
      this.specan.moveCanvasY(direction);
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.dataDomainY = this.specan.getCurrentDomainY();
      this.onViewExtentChange.next(zoomEvent);
    }
  }

  renderGraph() {
    if (this.config.graphVisible) {
      this.initializeSpecanIfNeeded();
      this.syncModelsWithSpecan();
      if (this.specanIsInitialized()) {
        this.specan.render();
      }
    }
  }
  autoscaleGraph() {
    if (this.config.graphVisible) {
      this.specan.autoscaleGraph();
    }
  }
  getCurrentDomainX() {
    if (this.config.graphVisible) {
      return this.specan.getCurrentDomainX();
    }
  }
  getCurrentDomainY() {
    if (this.config.graphVisible) {
      return this.specan.getCurrentDomainY();
    }
  }
  setSampleFrequency(freq: number) {
    if (this.config.graphVisible) {
      this.specan.setFrequencyLine(freq);
    }
  }
  vbwChanged(param) {
    if (param != null && param.target) {
      this.dataParams.vbwValueChange.next(param.target.value);
    }
    this.editingVbw = false;
  }
  rbwChanged(param) {
    if (param != null && param.target) {
      this.dataParams.rbwValueChange.next(param.target.value);
    }
    this.editingRbw = false;
  }
  showZoomMenu() {
    this.zoomMenuVisible = true;
  }
  hideZoomMenu() {
    this.zoomMenuVisible = false;
  }
  toggleZoom() {
    if (this.htmlTemplateVars.zoomMode) {
      this.htmlTemplateVars.zoomMode = false;
    } else {
      this.specan.hideTooltip();
      this.htmlTemplateVars.zoomMode = true;
    }
    this.specan.toggleZoom(this.htmlTemplateVars.zoomMode);
  }

  scrollZoom = (d, i) => {
    if (this.htmlTemplateVars.zoomMode && (this.d3.event.sourceEvent != null && this.d3.event.sourceEvent.type === 'wheel')) {
      // sign of 'direction' indicates left or right
      const wheelDelta = this.d3.event.sourceEvent.wheelDelta;
      const direction = wheelDelta > 0 ? 1 : -1;
      this.specan.scrollZoom(direction);
      this.dataParams.spanChanged.next(this.specan.htmlTemplateVars.graphSpan);
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.transform = this.d3.event.transform;
      if (this.htmlTemplateVars.zoomOnXAxis) {
        zoomEvent.dataDomainX = this.specan.getCurrentDomainX();
      }
      if (this.htmlTemplateVars.zoomOnYAxis) {
        zoomEvent.dataDomainY = this.specan.getCurrentDomainY();
      }
      this.onViewExtentChange.next(zoomEvent);
    }
  }
  removeMarker(markerName: string):string {
    return this.specan.removeMarker(markerName);
  }
  toggleModelSelect(model: SpectrumAnalyzerSeries) {
    if (!model.selected && model.visible) {
      // deselect all other models
      for (let i = 0; i < this.dataModels.length; i++) {
        this.dataModels[i].selected = false;
      }
      model.selected = true;
      this.specan.setCurrentPath(model);
    } else {
      // check to see if one of the other models is selected
      let someModelIsSelected = false;
      for (let i = 0; i < this.dataModels.length; i++) {
        if (this.dataModels[i].selected) {
          someModelIsSelected = true;
        }
      }
      // if not, select the first model
      if (!someModelIsSelected) {
        // select the first one so we always have a path selected
        this.dataModels[0].selected = true;
        this.specan.setCurrentPath(this.dataModels[0]);
      }
      model.selected = false;
    }
  }
  setDomainX(domainX: [number, number]) {
    if (this.config.graphVisible) {
      this.specan.zoomToDomain(domainX, null);
    }
  }
  setDomainY(domainY: [number, number]) {
    if (this.config.graphVisible) {
      this.specan.zoomToDomain(null, domainY);
    }
  }
  setDomainXAndY(domainX: [number, number], domainY: [number, number]) {
    if (this.config.graphVisible) {
      this.specan.setScaling(domainY, domainX);
      this.specan.zoomToDomain(domainX, domainY);
    }
  }
  setDbPerDiv(value) {
    this.htmlTemplateVars.graphDbPerDiv = value;
    this.dataParams.dbPerDiv = value;
  }
  addMarkerToGraph(pathName: string, markerName: string, markerText: string[], shape: MarkerShape): SvgMarker {
    return this.specan.addNewMarker(pathName, markerName, markerText, shape);
  }
  mouseMove = (d, i) => {
    this.specan.mouseIsInsideSvg = true;
    const svg = document.getElementById(this.htmlTemplateVars.graphId + '-container');
    const currentMouse = this.d3.mouse(svg);
    // used to set the specan variables currentX and currentY for later reference
    this.specan.setCurrentMouseCoords(currentMouse);
    const newCoords = this.specan.getScaledMouseCoords(this.d3.mouse(svg)[0], this.d3.mouse(svg)[1]);
    this.mouseCoords[0] = +newCoords[0].toFixed(this.config.roundXToDecimal);
    this.mouseCoords[1] = newCoords[1];

    // calculate whether we are inside the graph itself or whether we are over an axis
    const insideGraph = this.specan.isMouseInsideGraph();

    // set the coordinates for the circle and the focus lines
    // but only if we're using focus tracking
    if (this.config.showFocusTracking) {
      if (insideGraph && !this.specan.noDataPathsAreVisible() && this.htmlTemplateVars.graphInitialized) {
        this.specan.showFocusTrackingAtCurrentMousePosition();
      } else {
        this.specan.hideFocusTracking();
      }
    }

    if (this.config.showTooltip) {
      if (insideGraph && !this.specan.noDataPathsAreVisible() && this.htmlTemplateVars.graphInitialized) {
        this.specan.showTooltipAtCurrentMousePosition();
      } else {
        this.specan.hideTooltip();
      }
    }
  }
  mouseLeave = () => {
    this.mouseCoords = [0, 0];
    this.specan.mouseIsInsideSvg = false;
    this.specan.hideTooltip();
  }
  brushEnded = (d, i) => {
    const s = this.d3.event.selection;
    if (!s) {
      //  the idle timeout determines whether it was a single or double click
      //  only zoom back out if it was a double click
      if (!this.idleTimeout) {
        return this.idleTimeout = setTimeout(() => {
          this.idleTimeout = null;
        }, 300);
      }
      this.specan.zoomToExtent();
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.transform = this.d3.event.transform;
      zoomEvent.dataDomainX = this.specan.getCurrentDomainX();
      zoomEvent.dataDomainY = this.specan.getCurrentDomainY();
      this.onViewExtentChange.next(zoomEvent);

    } else {
      this.specan.zoomToCoordinates([s[0][0], s[1][0]], [s[1][1], s[0][1]]);
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.transform = this.d3.event.transform;
      zoomEvent.dataDomainX = this.specan.getCurrentDomainX();
      zoomEvent.dataDomainY = this.specan.getCurrentDomainY();
      this.onViewExtentChange.next(zoomEvent);
    }
  }

  toggleModelVisible(model: SpectrumAnalyzerSeries) {
    if (!model.visible) {
      model.selected = false;
      model.getDataSetByName('avg').visible = false;
      model.getDataSetByName('min').visible = false;
      model.getDataSetByName('max').visible = false;
      model.getDataSetByName('main').visible = false;
      // todo: implement markers
      // for (let i = 0; i < this.graphMarkers.length; i++) {
      //    if (this.graphMarkers[i].pathId === model.pathId) {
      //        this.graphMarkers[i].visible = false;
      //        this.markerContainer.select('#' + this.graphMarkers[i].markerId).remove();
      //    }
      // }

    } else {
      // show main by default when path's visibility is toggled back on
      model.getDataSetByName('main').visible = true;
      // for (let i = 0; i < this.graphMarkers.length; i++) {
      //    if (this.graphMarkers[i].pathId === model.pathId) {
      //        this.graphMarkers[i].visible = true;
      //        this.addMarkerToSvg(this.graphMarkers[i]);
      //    }
      // }
    }
    this.renderGraph();
  }

  handleClick(event) {
    if (this.config.graphVisible) {
      const clickedComponent = event.target;
      // test if we're in the unit input
      if (clickedComponent.parentElement != null &&
        clickedComponent.parentElement.parentElement != null &&
        clickedComponent.parentElement.parentElement.parentElement != null &&
        clickedComponent.parentElement.parentElement.parentElement.parentElement != null) {
        if (clickedComponent.parentElement.parentElement.parentElement.parentElement.id.indexOf('-graph-span') > 0) {
          // only do this if we aren't already editing
          if (!this.editingSpan) {
            this.editingSpan = true;
            // setTimeout is necessary to put the selection in a different digest cycle,
            // because otherwise the input doesn't exist in the dom yet
            const timeout = setTimeout(() => {
              this.elementRef.nativeElement.querySelector('.graph-span').querySelector('input').focus();
              clearTimeout(timeout);
            });
          }
        } else {
          this.editingSpan = false;
        }
        if (clickedComponent.parentElement.parentElement.parentElement.parentElement.id.indexOf('-graph-db-div') > 0) {
          // only do this if we aren't already editing
          if (!this.editingDbDiv) {
            this.editingDbDiv = true;
            // setTimeout is necessary to put the selection in a different digest cycle,
            // because otherwise the input doesn't exist in the dom yet
            const timeout = setTimeout(() => {
              const elem = this.elementRef.nativeElement.querySelector('.graph-db-div');
              if (elem! + null) {
                elem.querySelector('input').focus();
              }
              clearTimeout(timeout);
            });
          }
        } else {
          this.editingDbDiv = false;
        }
        if (clickedComponent.parentElement.parentElement.parentElement.parentElement.id.indexOf('-graph-rbw') > 0) {
          // only do this if we aren't already editing
          if (!this.editingRbw) {
            this.editingRbw = true;
            const timeout = setTimeout(() => {
              this.elementRef.nativeElement.querySelector('.graph-rbw').querySelector('input').focus();
              clearTimeout(timeout);
            });
          }
        } else {
          this.editingRbw = false;
        }
        if (clickedComponent.parentElement.parentElement.parentElement.parentElement.id.indexOf('-graph-vbw') > 0) {
          // only do this if we aren't already editing
          if (!this.editingVbw) {
            this.editingVbw = true;
            const timeout = setTimeout(() => {
              this.elementRef.nativeElement.querySelector('.graph-vbw').querySelector('input').focus();
              clearTimeout(timeout);
            });
          }
        } else {
          this.editingVbw = false;
        }
        if (clickedComponent.parentElement.parentElement.parentElement.parentElement.id.indexOf('-graph-cf') > 0) {
          // only do this if we aren't already editing
          if (!this.editingCenterFreq) {
            this.editingCenterFreq = true;
            const timeout = setTimeout(() => {
              this.elementRef.nativeElement.querySelector('.graph-cf').querySelector('input').focus();
              clearTimeout(timeout);
            });
          }
        } else {
          this.editingCenterFreq = false;
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:pf-resize', ['$event'])
  onResize(event: Event): void {
    setTimeout(() => {
      if (this.config.graphVisible) {
        this.initializeSpecanIfNeeded();
        if (this.specanIsInitialized()) {
          this.specan.resize();
        }
      }
    }, 200);
  }

  ngOnDestroy(): void {
    if (this.config.graphVisible) {
      this.specan.removeSvg();
    }
    this.rbwSub.unsubscribe();
    this.vbwSub.unsubscribe();
    this.dbPerDivChangedSub.unsubscribe();

    this.visibilitySub.unsubscribe();
    this.specan.destroy();
  }
  private specanIsInitialized() {
    return this.specanInitialized;
  }
  private graphSpanChanged(param) {
    this.dataParams.spanChanged.next(param.target.value);
    const zoomEvent = new ZoomEventInfo();
    zoomEvent.dataDomainY = this.specan.getCurrentDomainY();
    zoomEvent.dataDomainX = this.specan.getCurrentDomainX();
    this.onViewExtentChange.next(zoomEvent);
  }

  private syncModelsWithSpecan() {
    this.specan.htmlTemplateVars = this.htmlTemplateVars;
    this.specan.dataModels = this.dataModels;
    this.specan.units = this.units;
    this.specan.dataParams = this.dataParams;
    this.specan.carrierRegions = this.carrierRegions; 
    this.specan.graphElements = this.graphElements; 
    this.specan.config = this.config;
    this.specan.scrollZoomFunc = this.scrollZoom;
    this.specan.mouseMoveFunc = this.mouseMove;
    this.specan.mouseLeaveFunc = this.mouseLeave;
    this.specan.brushEndedFunc = this.brushEnded;
    this.specan.graphContainer = this.graphContainer;
  }

}
