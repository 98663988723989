import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpectrumAnalyzerXY } from 'app/components/graphs/specan';
import { WaterfallPlotDataModel } from 'app/components/graphs/waterfall';
import { EntityOperation } from 'app/enums/entity-operation';
import { EntityType } from 'app/enums/entity-type';
import { AuthToken } from 'app/models/auth-response';
import { TraceRequestResponse } from 'app/models/trace-request';
import { UserService } from 'app/services/user-service';
import { Observable, ReplaySubject } from 'rxjs/Rx';
import { ServiceResponse } from '../models/index';
import { AppUtilities } from './app-utilities';

@Injectable()
export class DataViewDemoService {
  public currentWaterfall: ReplaySubject<any> = new ReplaySubject(1);
  public currentSpecan: ReplaySubject<any> = new ReplaySubject(100000);
  public endpoint: string;
  public address: string;
  largeWaterfallData: WaterfallPlotDataModel = new WaterfallPlotDataModel();
  specanData = new Array<SpectrumAnalyzerXY[]>();
  //holds the timestamps for the specanData array. The index in this array
  //corresponds with the index of the corresponding trace in specanData
  largeSpecanTraceTimestampIndex = new Array<number>();
  constructor(public http: HttpClient, public utilityService: AppUtilities, public userService: UserService) {
    this.address = 'http://localhost:51191/';
    this.endpoint = this.address + 'api/';
  }
  getAddress() {
    return this.address;
  }
  initializeEndPoint(address: string) {
    this.address = address;
    this.endpoint = address + 'api/';
    this.userService.initializeEndPoint(this.endpoint);
  }

  private parseDateString(date: string): Date {
    let dayTime = date.split(' ');
    let ymd = dayTime[0].split('-');
    let hms = dayTime[1].split(':');
    //subtract one from month because they are 0 based in JS Date
    return new Date(+ymd[0], +ymd[1] - 1, +ymd[2], +hms[0], +hms[1], +hms[2]);
  }

  //*************************************** Get Data from the Database *******************************************************
  performEntitiesOperation(type, op, data = null) {
    let requestBody = new RequestBody();
    requestBody.entityType = type;
    requestBody.operation = op;
    if (data != null)
      requestBody.data = data;
    return this.makeServerRequest('inventory/entities', requestBody);
  }

  performEntityOperation(type, op, data = null) {
    let requestBody = new RequestBody();
    requestBody.entityType = type;
    requestBody.operation = op;
    if (data != null) {
      requestBody.data = data;
    }
    return this.makeServerRequest('inventory/entity', requestBody);
  }
  makeServerRequest(link: string, requestBody: any): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap(response => {
      if (response) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        requestBody.data.token = token.access_token;
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
        headers = headers.append('Access-Control-Allow-Credentials', 'true');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post(this.endpoint + link, requestBody, { headers: headers })
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }
  getWaterfallData(satelliteId, transponderId, traceCount, traceLength, start, stop, startFreq, endFreq, carrierId): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        if (carrierId != null) {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/carriers/' + encodeURIComponent(carrierId) + '/waterfall?traceCount='
            + Math.round(traceCount) + '&traceLength=' + Math.round(traceLength) + '&channelType=CAR'
            + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq)
            + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop, { headers: headers });
        } else {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/waterfall?traceCount=' + Math.round(traceCount) + '&traceLength=' + Math.round(traceLength) + '&channelType=XPN'
            + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq)
            + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop, { headers: headers });
        }
      } else {
        return Observable.of(response);
      }
    });
   
  }
  getModWaterfallData(satelliteId, transponderId, traceCount, traceLength, start, stop, startFreq, endFreq,carrierId: string): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        if (carrierId != null) {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/carriers/' + encodeURIComponent(carrierId) + '/modwaterfall?traceCount=' + Math.round(traceCount) + '&traceLength=' + Math.round(traceLength) + '&channelType=CAR'
            + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq)
            + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
        } else {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/modwaterfall?traceCount=' + Math.round(traceCount) + '&traceLength=' + Math.round(traceLength) + '&channelType=XPN'
            + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq)
            + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop, { headers: headers });
        }
      } else {
        return Observable.of(response);
      }
    });    
  }
  getWaterfallAnalysisData(satelliteId, transponderId, start, stop, freq, traceLength,carrierId): Observable<ServiceResponse> {
    if (carrierId != null) {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/waterfallanalysis?traceLength=' + Math.round(traceLength) + '&channelType=CAR'
        + '&frequency=' + Math.round(freq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    } else {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/waterfallanalysis?traceLength=' + Math.round(traceLength) + '&channelType=XPN'
        + '&frequency=' + Math.round(freq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    }
  }
  getModWaterfallAnalysisData(satelliteId, transponderId, start, stop, freq, traceLength,carrierId): Observable<ServiceResponse> {
    if (carrierId != null) {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/modwaterfallanalysis?traceLength=' + Math.round(traceLength) + '&channelType=CAR'
        + '&frequency=' + Math.round(freq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    } else {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/modwaterfallanalysis?traceLength=' + Math.round(traceLength) + '&channelType=XPN'
        + '&frequency=' + Math.round(freq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    }

  }
  getSABurstData(satelliteId, transponderId, start, stop, startFreq, endFreq,carrierId): Observable<ServiceResponse> {
    if (carrierId != null) {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/bursts?channelType=CAR'
        + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    } else {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/bursts?channelType=XPN'
        + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    }
  }
  getModBurstData(satelliteId, transponderId, start, stop, startFreq, endFreq,carrierId): Observable<ServiceResponse> {
    if (carrierId != null) {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/modbursts?channelType=CAR'
        + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    } else {
      return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/modbursts?channelType=XPN'
        + '&startFreq=' + Math.round(startFreq) + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + start + '&stopTimeInMs=' + stop);
    }
  }
  getRangingData(satelliteId, startTime, stopTime, traceLength) {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/ranging?startInMs=' + startTime
          + '&stopInMs=' + stopTime + '&traceLength=' + traceLength, { headers: headers });
      } else {
        return Observable.of(response);
      }
    });
  }
  getRangingDataAlerts(satelliteId, startTime, stopTime) {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/rangingalerts?startInMs=' + startTime
          + '&stopInMs=' + stopTime, { headers: headers });
      } else {
        return Observable.of(response);
      }
    });
  }
  getBeams(satelliteId): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.get<ServiceResponse>(this.endpoint + 'inventory/satellites/' + encodeURIComponent(satelliteId) + '/beams', { headers: headers });
      } else {
        return Observable.of(response);
      }
    });
  }

  getBeaconsForSatellite(satelliteId): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.get<ServiceResponse>(this.endpoint + 'inventory/satellites/' + encodeURIComponent(satelliteId) + '/beacons', { headers: headers });
      } else {
        return Observable.of(response);
      }
    });
  } 
  getBeamTransponders(satelliteId, beamId): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.get<ServiceResponse>(this.endpoint + 'inventory/satellites/' + encodeURIComponent(satelliteId) + '/beams/' + beamId + '/transponders', { headers: headers });
      } else {
        return Observable.of(response);
      }
    });
  }
  getTranspondersForSatellite(satelliteId): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let token = response.data as AuthToken;
        let requestBody = new RequestBody();
        requestBody.entityType = EntityType.Transponder;
        requestBody.operation = EntityOperation.Get;
        requestBody.data.satelliteId = satelliteId;
        requestBody.data.token = token.access_token;
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        let options = {};
        (options as any).headers = headers;
        return this.http.post<ServiceResponse>(this.endpoint + 'inventory/entities/', requestBody, { headers: headers })
          .catch((error: any) => Observable.throw(error || 'Server error'));
      } else {
        return Observable.of(response);
      }
    });
  }
  getSatellites(): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let token = response.data as AuthToken;
        let requestBody = new RequestBody();
        requestBody.entityType = EntityType.Satellite;
        requestBody.operation = EntityOperation.Get;
        requestBody.data.token = token.access_token;
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        let options = {};
        (options as any).headers = headers;
        return this.http.post<ServiceResponse>(this.endpoint + 'inventory/entities', requestBody, { headers: headers })
          .catch((error: any) => Observable.throw(error || 'Server error'));
      } else {
        return Observable.of(response);
      }
    });
  }
  getCarriersForTransponder(transponderId, satelliteId): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let token = response.data as AuthToken;
        let requestBody = new RequestBody();
        requestBody.entityType = EntityType.Satellite;
        requestBody.operation = EntityOperation.Get;
        requestBody.data.token = token.access_token;
        requestBody.data.satelliteId = satelliteId;
        requestBody.data.transponderId = transponderId;
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        let options = {};
        (options as any).headers = headers;
        return this.http.get<ServiceResponse>(this.endpoint + 'inventory/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/carriers', { headers: headers })
          .catch((error: any) => Observable.throw(error || 'Server error'));
      } else {
        return Observable.of(response);
      }
    });
  }
  getTrends(satelliteId, transponderId,carrierId: string): Observable<ServiceResponse> {   
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        if (carrierId == null) {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/trends', { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        } else {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/carriers/' + encodeURIComponent(carrierId) + '/trends', { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        }
      } else {
        return Observable.of(response);
      }
    });
    
  }
  getSawtooth(satelliteId, transponderId, carrierId, start, stop): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;

        if (carrierId != null) {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/carriers/' + encodeURIComponent(carrierId) + '/sawtooth?startFreq=' +
             Math.round(start) + '&stopTimeInMs=' + Math.round(stop), { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        } else {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/sawtooth?startFreq=' +
            Math.round(start) + '&stopTimeInMs=' + Math.round(stop), { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        }
      } else {
        return Observable.of(response);
      }
    });
  }
  getTraces(satelliteId, transponderId,carrierId, start, stop, startFreq, endFreq, traceLength, traceCount): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        
        if (carrierId != null) {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/carriers/' + encodeURIComponent(carrierId) + '/traces?startFreq=' +
            Math.round(startFreq) + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + Math.round(start) + '&stopTimeInMs=' + Math.round(stop) + '&traceLength=' + traceLength + '&limit=' + traceCount, { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        } else {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/traces?startFreq=' + Math.round(startFreq)
            + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + Math.round(start) + '&stopTimeInMs=' + Math.round(stop) + '&traceLength=' + traceLength + '&limit=' + traceCount, { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        }
      } else {
        return Observable.of(response);
      }
    });   
  }
  getMultipleTraces(requests: TraceRequestResponse[]): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post<ServiceResponse>(this.endpoint + 'measurements/multiviewtrace/', requests, { headers: headers })
          .catch((error: any) => Observable.throw(error || 'Server error'));
      } else {
        return Observable.of(response);
      }
    });
  } 
  getNextTrace(satelliteId, transponderId,carrierId, traceLength): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        if (carrierId != null) {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' +
            encodeURIComponent(transponderId) + '/carriers/' + encodeURIComponent(carrierId) + '/nexttrace?traceLength=' + traceLength, { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        } else {
          return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' +
            encodeURIComponent(transponderId) + '/nexttrace?traceLength=' + traceLength, { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        }
      } else {
        return Observable.of(response);
      }
    });
  } 
  getLiveTraces(satelliteId, transponderId,carrierId, idx): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;

        return this.http.get<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/livetrace?traceIdx=' + idx, { headers: headers })
          .catch((error: any) => Observable.throw(error || 'Server error'));
      } else {
        return Observable.of(response);
      }
    });
  } 
  getModTraces(satelliteId, transponderId,carrierId, start, stop, startFreq, endFreq, traceLength, traceCount): Observable<ServiceResponse> {
    return this.userService.checkUserAuthenticated().flatMap((response: ServiceResponse) => {
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;

        if (carrierId != null) {
          return this.http.post<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/carriers/' + encodeURIComponent(carrierId) + '/modtraces?startFreq=' +
            Math.round(startFreq) + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + Math.round(start) + '&stopTimeInMs=' + Math.round(stop) + '&traceLength=' + traceLength + '&limit=' + traceCount, { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        } else {
          return this.http.post<ServiceResponse>(this.endpoint + 'measurements/satellites/' + encodeURIComponent(satelliteId) + '/transponders/' + encodeURIComponent(transponderId) + '/modtraces?startFreq=' + Math.round(startFreq)
            + '&endFreq=' + Math.round(endFreq) + '&startTimeInMs=' + Math.round(start) + '&stopTimeInMs=' + Math.round(stop) + '&traceLength=' + traceLength + '&limit=' + traceCount, { headers: headers })
            .catch((error: any) => Observable.throw(error || 'Server error'));
        }
      } else {
        return Observable.of(response);
      }
    });
    
  }
}
class RequestBody {
  operation: EntityOperation;
  entityType: EntityType;
  data = {} as any;
}
