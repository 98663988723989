import { Component, ViewChild, isDevMode, HostListener, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { AppUtilities } from './services/app-utilities';

import { UserMessage } from './models/index';
import { LoginComponent } from './components/index';
import { DataViewDemoService } from 'app/services/data-view-demo-service';
import { UserService } from 'app/services/user-service';
import { NavbarComponent, NavbarState } from '@kratos/pf-ui/navbar';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'app/components/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  @ViewChild('login') loginDialog: LoginComponent;
  @ViewChild('navbar') navbar: NavbarComponent;
  navbarState = new NavbarState();
  title = 'NDS Demo Solution';
  showLoginDialog: boolean;
  userMsgs = [];
  msgDuration = 5000;
  loggingUserIn: boolean;
  checkingUserLoggedIn: boolean;
  showOverlay: boolean;
  loginSub: Subscription;
  showLoginSub: Subscription;
  userIsAdmin = false;

  @HostListener('window:beforeunload', ['$event'])
  logoutUser() {
    this.loginSub.unsubscribe();
    //this.userService.logout();
  }
  //this.utilityService.currentUser.username = 'SpectrumDemo';
  //this.utilityService.currentUser.password = 'SpectrumView1!2@3#';
  constructor(private utilityService: AppUtilities, private viewContainer: ViewContainerRef, private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver, private dataService: DataViewDemoService, private userService: UserService) {
    this.navbarState.slidePinned = false;
    this.navbarState.slideVisible = false;
    this.navbarState.navVisible = false;
    this.dataService.initializeEndPoint(window.location.origin + '/');
    this.utilityService.toggleSlideoutObs.subscribe((route) => {
      if (route != null) {
        this.navbar.navigateSlideoutTo(route);
      } else {
        this.navbar.showSlide(false);
      }
    })
    this.utilityService.toggleNavbarObs.subscribe((show) => {
      this.navbar.showNav(show);
    });
    //  subscribe to the notification service to display notifications from other components in the app
    this.utilityService.showNotificationObs.subscribe(
      (message: UserMessage) => {
        if (!message.sticky) {
          this.userMsgs = [];
        }
        if (message.durationInMs != null) {
          this.msgDuration = message.durationInMs;
        }
        this.userMsgs.push({ severity: message.messageType, summary: message.messageTitle, detail: message.messageDetail });
      });

  }
  ngOnDestroy() {
    this.loginSub.unsubscribe();
    this.userService.logout();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      let sub = this.userService.checkUserAuthenticated().subscribe((response) => {
        if (response.success) {
          this.userService.userLoggedInSource.next(true);
          this.userService.checkIfCurrentUserIsAdmin().subscribe((isAdmin) => {
            this.userIsAdmin = isAdmin;
          });
        } else {
          this.userService.userLoggedInSource.next(false);
        }
      }, error => {
        this.utilityService.showError("Unable to communicate with server", error.statusText, true);
        this.showLogin();
      });
      this.loginSub = this.userService.userLoggedInObs.subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.hideLogin();
          this.userService.checkIfCurrentUserIsAdmin().subscribe((isAdmin) => {
            this.userIsAdmin = isAdmin;
          }, error => {
            this.showLogin();
          });
        } else {
          this.showLogin();
        }
      }, error => {
        this.showLogin();
      });
    });
  }
  //ngAfterViewChecked() {

  //  if (!this.checkingUserLoggedIn && !this.loggingUserIn) {
  //    this.checkingUserLoggedIn = true;
  //    let timeout = setTimeout(() => {
  //      this.userService.checkUserAuthenticated().subscribe((response) => {
  //        this.checkingUserLoggedIn = false;
  //        if (!response.success) {
  //          this.showLogin();
  //          clearTimeout(timeout);
  //        } else {
  //          this.slideoutService.toggleOverlay(false);
  //          this.showLoginDialog = false;
  //        }
  //      });
  //    });
  //  }
  //}

  showSessionExpireWarning() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ConfirmationDialogComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    componentRef.instance.open('Session is about to expire', 'Press OK to stay logged in.').then((result) => {
      // result will be null if they cancelled
      if (result) {
        this.userService.refreshSession().subscribe((result) => {
          if (!result.success) {
            const msg = new UserMessage();
            msg.messageTitle = 'Refresh Failed';
            msg.messageDetail = 'Unable to refresh session';
            msg.messageType = 'error';
            this.utilityService.showNotification(msg);
          }
        })
      }
      componentRef.destroy();
    });
  }
  hideLogin() {
    this.navbarState.navVisible = true;
    this.navbar.redraw();
    this.showLoginDialog = false;
    this.loggingUserIn = false;
    this.showOverlay = false;
  }
  showLogin() {
    if (this.loginDialog != null && !this.loggingUserIn) {
    this.navbarState.navVisible = false;
    this.navbar.redraw();
      this.showLoginDialog = true;
      this.loggingUserIn = true;
      this.showOverlay = true;

      this.loginDialog.open().then((result) => {
        if (result != null) {
          const msg = new UserMessage();
          msg.messageTitle = 'Login Successful';
          msg.messageDetail = 'User ' + this.utilityService.currentUser.name + ' successfully logged in.';
          msg.messageType = 'success';
          this.utilityService.showNotification(msg);
          let redirectUrl = localStorage.getItem('redirectUrl');
          if (redirectUrl != null && redirectUrl != '/')
            this.router.navigateByUrl(redirectUrl);
        }
        this.loggingUserIn = false;
        this.showLoginDialog = false;
        this.showOverlay = false;

      });
    }
  }
}
