import { WaterfallXY } from './pf-waterfall-xy';
import { WaterfallData } from './pf-waterfall-data';
import { ChartSeries, XYPoint } from '../../../chart/index';
import { WaterfallPlotDataModel } from './pf-waterfall-data-model';

export class WaterfallSeries extends ChartSeries {

  legendName: string;

  //  indicates whether this data model is selected in the UI
  selected = false;

  // redeclare this inherited member as an instance of an array containing WaterfallData elements
  // so we can easily perform operations on the data
  protected _data = new Array<WaterfallData>();
  private stopFreq: number;
  private startFreq: number;
  private stepFreq: number;

  constructor(name: string) {
    super(name);
    this.legendName = name;
  }

  //  this is the param that contains the y values of the data
  initializeDataSet(dataSet: WaterfallPlotDataModel): void {
    // if we haven't initialized the chart data and points
    if (this._data.length <= 0) {
      this._data = dataSet.dataPoints;
    } else {
      this._data = new Array<WaterfallData>();
      for (let i = 0; i < dataSet.dataPoints.length; i++) {
        this._data[i] = new WaterfallData();
        this._data[i].timestamp = dataSet.dataPoints[i].timestamp;
        for (let j = 0; j < dataSet.dataPoints[i].length(); j++) {
          this._data[i].push(new WaterfallXY(dataSet.dataPoints[i].pointAtIndex(j)));
        }
      }

    }
    const dataDomain = this.getDomain();
    if (dataDomain != null) {
      this.startFreq = dataDomain[0];
      this.stopFreq = dataDomain[1];
      this.stepFreq = (this.stopFreq - this.startFreq) / this._data.length;
    }
  }
  // returns the frequency start and stop for the current data set
  // we can shortcut getting the domain if this dataset is using start/step freq without iterating through the dataset
  getDomain(): [number, number] {
    if (this._data.length > 0) {
      const initialDomain = this._data[0].getDomain();
      let min = initialDomain[0];
      let max = initialDomain[1];
      for (let i = 0; i < this._data.length; i++) {
        const nextDomain = this._data[i].getDomain();
        if (nextDomain[0] < min && nextDomain[0] != null) {
          min = nextDomain[0];
        }
        if (nextDomain[1] > max && nextDomain[1] != null) {
          max = nextDomain[0];
        }
      }
      return [min, max];
    }
  }

  getGraphRange(): [number, number] {
    if (this._data.length > 0) {
      const initialRange = this._data[0].getRange();
      let min = this._data[0].timestamp;
      let max = this._data[0].timestamp;
      for (let i = 1; i < this._data.length; i++) {
        if (this._data[i].timestamp < min) {
          min = this._data[i].timestamp;
        }
        if (this._data[i].timestamp > max) {
          max = this._data[i].timestamp;
        }
      }
      // if we only have one data set, give a buffer
      if (min === max) {
        min -= 100000;
        max += 100000;
      }
      return [min, max];
    }
  }
  getDataRange(): [number, number] {
    if (this._data.length > 0) {
      const initialRange = this._data[0].getRange();
      let min = initialRange[0];
      let max = initialRange[1];
      for (let i = 0; i < this._data.length; i++) {
        const nextRange = this._data[i].getRange();
        if (nextRange[0] < min && nextRange[0] != null) {
          min = nextRange[0];
        }
        if (nextRange[1] > max && nextRange[1] != null) {
          max = nextRange[1];
        }
      }
      return [min, max];
    }
  }
  getNearestTimestampAsc(timestamp: number) {
    if (this._data.length > 0) {
      let firstTimestamp = this._data[0].timestamp;
      for (let i = 0; i < this._data.length; i++) {
        if (this._data[i].timestamp >= timestamp) {
          return this._data[i].timestamp;
        }
      }
      return firstTimestamp;
    }
    return null;
  }
  getNearestTimestampDesc(timestamp: number) {
    // always assume points are in order ascending
    if (this._data.length > 0) {
      let firstTimestamp = this._data[this._data.length - 1].timestamp;
      for (let i = this._data.length - 1; i >= 0; i--) {
        if (this._data[i].timestamp <= timestamp) {
          return this._data[i].timestamp;
        }
      }
      return firstTimestamp;
    }
    return null;
  }
  getPointNearestFrequency(frequency: number) {
    // always assume points are in order ascending
    if (this._data.length > 0) {
      let firstPoint = this._data[0].pointAtIndex(0);
      for (let i = 0; i < this._data.length; i++) {
        for (let j = 0; j < this._data[i].length(); j++) {
          if (this._data[i].pointAtIndex(j).x >= frequency) {
            return this._data[i].pointAtIndex(j);
          }
        }
      }
      return firstPoint;
    }
    return null;
  }
}
