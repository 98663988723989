import { Axis } from 'd3-ng2-service';

export class AxisSelection {

  constructor(public axis: Axis<{}>, public selection: any) { }

  domainZoom(domainScale: any, transform: any): void {
    const t = transform ? transform.rescaleX(domainScale) : domainScale;
    this.selection.call(this.axis.scale(t));
  }

  rangeZoom(rangeScale: any, transform: any): void {
    const t = transform ? transform.rescaleY(rangeScale) : rangeScale;
    this.selection.call(this.axis.scale(t));
  }

  remove(): void {
    this.selection.remove();
  }
}