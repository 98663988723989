﻿import { Axis, D3, Selection } from 'd3-ng2-service';
import { XYPoint } from '../../../chart/index';

export class SpectrumAnalyzerFrequencyLine {
  private d3: D3;
  private id = 'frequencyline';
  private freqLineElem: any;
  private height: number;
  private width: number;

  // we need to get these methods from the parent to coordinate hiding/showing other specan elements
  // even though we need these functions to call back to our actual functions to do the dragging/etc
  // for this frequency line element
  private mouseOverFunc: any;
  private mouseLeaveFunc: any;
  private dragStartFunc: any;
  private draggingFunc: any;
  private dragEndFunc: any;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  initialize(id: string, height: number, width: number, mouseOverFunc: any,
    mouseLeaveFunc: any, dragStartFunc: any, draggingFunc: any, dragEndFunc: any) {
    this.id = id;
    this.height = height;
    this.width = width;
    this.mouseOverFunc = mouseOverFunc;
    this.mouseLeaveFunc = mouseLeaveFunc;
    this.dragStartFunc = dragStartFunc;
    this.draggingFunc = draggingFunc;
    this.dragEndFunc = dragEndFunc;
  }
  addElementToSvg(svg: any, currentHeight: number, currentWidth: number) {
    this.height = currentHeight;
    this.width = currentWidth;
    const data = [
      {
        'x1': this.width / 2,
        'y1': 0,
        'x2': this.width / 2,
        'y2': this.height
      }];
    this.freqLineElem = svg
      .append('g');

    // need two line elements to allow a larger surface area for the user to grab and drag, but apply different CSS
    // so that the thick line does not obscure data until dragged
    this.freqLineElem.append('line')
      .attr('x1', this.width / 2)
      .attr('y1', 0)
      .attr('x2', this.width / 2)
      .attr('y2', this.height)
      .attr('class', 'pf-specan-sample-freq-midline')
      .on('mouseover', this.mouseOverFunc)
      .on('mouseleave', this.mouseLeaveFunc)
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
    this.freqLineElem.append('line')
      .attr('x1', this.width / 2)
      .attr('y1', 0)
      .attr('x2', this.width / 2)
      .attr('y2', this.height)
      .attr('class', 'pf-specan-sample-freq')
      .on('mouseover', this.mouseOverFunc)
      .on('mouseleave', this.mouseLeaveFunc)
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
    this.freqLineElem.attr('display', 'none');
  }
  dragStart() {
    this.freqLineElem.select('.pf-specan-sample-freq').attr('class', 'pf-specan-sample-freq-drag');
  }
  dragging(newX: number) {
    if (newX >= this.width) {
        newX = this.width;
    } else if (newX <= 0) {
        newX = 0;
    }
    this.freqLineElem.selectAll('line').attr('x1', newX)
      .attr('y1', 0)
      .attr('x2', newX)
      .attr('y2', this.height);
  }
  dragEnd() {
    this.freqLineElem.select('.pf-specan-sample-freq-drag').attr('class', 'pf-specan-sample-freq');
  }
  setFrequencyLine(newX) {
    this.freqLineElem.selectAll('line').attr('x1', newX)
      .attr('y1', 0)
      .attr('x2', newX)
      .attr('y2', this.height);
  }
  showFrequencyLine() {
    if (this.freqLineElem != null) {
      this.freqLineElem.attr('display', null);
    }
  }
  hideFrequencyLine() {
    if (this.freqLineElem != null) {
      this.freqLineElem.attr('display', 'none');
    }
  }
  reset() {
    this.freqLineElem = null;
  }
}