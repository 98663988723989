import {
  Component, ViewChild, HostListener
} from '@angular/core';
import { EntityType } from '../shared/enums/entity-type';
import { EntityOperation } from '../shared/enums/entity-operation';
import { SatelliteDatabaseComponent } from '../satellites/satellites.component';
import { SitesDatabaseComponent } from '../sites/sites.component';
import { TransponderDatabaseComponent } from '../transponders/transponders.component';
import { AntennaDatabaseComponent } from '../antennas/antennas.component';
import { UserService } from '../../../services/user-service';
@Component({
  selector: 'database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.css']
})

export class DatabaseComponent {
  tabIndex: number = 0;
  resizeTimer: any;
  @ViewChild('satellites') satellites: SatelliteDatabaseComponent;
  @ViewChild('sites') sites: SitesDatabaseComponent;
  @ViewChild('transponders') transponders: TransponderDatabaseComponent;
  @ViewChild('antennas') antennas: AntennaDatabaseComponent;
  EntityOperation = EntityOperation;
  EntityType = EntityType;
  pages = [];

  constructor(private userService: UserService) {

  }

  ngAfterViewInit() {
    // add pages to an array to easily call each of their tabInView and tabOff methods when appropriate
    this.pages = [this.satellites, this.transponders, this.sites, this.antennas];
    setTimeout(() => {
      if (typeof this.pages[0].tabInView === 'function') {
        this.pages[0].tabInView();
      }
    })

    // call child tab's resize methods when the page is resized
    window.onresize = () => {
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
      };
      this.resizeTimer = setTimeout(() => {
        if (this.tabIndex > 0) {
          this.pages[this.tabIndex].windowResized();
        }
      }, 500);
    };
    let sub = this.userService.checkUserAuthenticated().subscribe((response) => {
      if (response.success) {
        if (typeof this.pages[0].query === 'function') {
          this.pages[0].query();
        }
      } else {
        let newSub = this.userService.userLoggedInObs.subscribe(() => {
          if (typeof this.pages[0].query === 'function') {
            this.pages[0].query();
          }
        })
      }
    });
  }

  tabChange(event) {
    this.pages[this.tabIndex].tabOff();
    this.pages[event.index].tabInView();
    this.tabIndex = event.index;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.pages[this.tabIndex].windowResized();
    });
  }
}
