import { D3, Selection } from 'd3-ng2-service';

import { ChartAddon } from '../addons/pf-chart-addon';
import { ChartMargins } from '../addons/pf-chart-margins';
import { ChartTicks } from '../addons/pf-chart-ticks';
import { AxisSelection } from './pf-chart-axis-selection';

export class ChartAxesBottomLeft implements ChartAddon {
  svg: Selection<any, {}, null, undefined>;
  d3: D3;
  height: number;
  width: number;

  private xAxis: AxisSelection;
  private yAxis: AxisSelection;
  private transform: any;

  private readonly elementClass = 'axis';

  constructor(private margins?: ChartMargins, private ticks?: ChartTicks) {
    if (!this.margins) {
      this.margins = new ChartMargins();
    }
  }

  init(d3: D3, height: number, width: number): void {
    this.d3 = d3;
    this.height = height;
    this.width = width;
  }

  create(svg: Selection<any, {}, null, undefined>, domainScale: any, rangeScale: any): void {
    this.svg = svg;

    if (this.xAxis) {
      this.xAxis.remove();
    }
    if (this.yAxis) {
      this.yAxis.remove();
    }
    this.xAxis = this.createX(domainScale);
    this.yAxis = this.createY(rangeScale);
  }

  zoom(domainScale: any, rangeScale: any, transform: any): void {
    this.transform = transform;
    this.xAxis.domainZoom(domainScale, transform);
    this.yAxis.rangeZoom(rangeScale, transform);
  }

  onScaleOrResize(height: number, width: number, domainScale: any, rangeScale: any): void {
    this.height = height;
    this.width = width;
    this.create(this.svg, domainScale, rangeScale);
    this.zoom(domainScale, rangeScale, this.transform);
  }

  private createX(domainScale: any): AxisSelection {
    const ticks = this.ticks ? this.ticks.numXTicks(this.width - this.margins.left - this.margins.right) : undefined;
    let axis = this.d3.axisBottom(domainScale);
    if (ticks) {
      axis = axis.ticks(ticks);
    }

    const selection = this.svg.append('g')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${this.margins.left}, ${this.height - this.margins.bottom})`)
      .call(axis);

    return new AxisSelection(axis, selection);
  }

  private createY(rangeScale: any): AxisSelection {
    const ticks = this.ticks ? this.ticks.numYTicks(this.height - this.margins.top - this.margins.bottom) : undefined;
    let axis = this.d3.axisLeft(rangeScale);
    if (ticks) {
      axis = axis.ticks(ticks);
    }

    const selection = this.svg.append('g')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`)
      .call(axis);

    return new AxisSelection(axis, selection);
  }
}