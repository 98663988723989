﻿import { XYPoint } from '../../../chart/index';
export class SpectrumAnalyzerXY implements XYPoint {
  x: number;
  y: number;
  constructor(fromPoint: SpectrumAnalyzerXY = null, x: number = null, y: number = null) {
    if (fromPoint != null) {
      this.x = fromPoint.x;
      this.y = fromPoint.y;
    } else if (x != null && y != null) {
      this.x = x;
      this.y = y;
    }
  }

}