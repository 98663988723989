import { Component, OnDestroy, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppUtilities } from '../../services/app-utilities';
import { DataViewConfiguration } from '../../models/index';
import { ConfigurationService } from '../../services/config-service';
@Component({
    selector: 'data-view-config',
    templateUrl: './data-view-config.component.html',
    styleUrls: ['./data-view-config.component.css']
})

export class DataViewConfigComponent {
    configObject: DataViewConfiguration = new DataViewConfiguration();

    constructor(private configService: ConfigurationService) {
    }

    saveConfig() {
        this.configService.ConfigurationChangedSource.next(this.configObject);
    }
    checkIfEnter(event) {
        if (event.keyCode == 13) {
            this.saveConfig();
        }
    }
}
