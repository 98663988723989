﻿
export class WaterfallDataParameters {
    minPowerVal: number;
    maxPowerVal: number;
    // the axis mins/maxes are not required, but can be set manually
    // if they are set, they will trump the bounds of the dataset
    axisMinX: number;
    axisMinY: number;
    axisMaxX: number;
    axisMaxY: number;
}