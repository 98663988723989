import { ElementRef } from '@angular/core';
import { ScaleContinuousNumeric } from 'd3-ng2-service';
import { D3, D3Service } from 'd3-ng2-service';
import { SpectrumAnalyzerSeries } from '../../index';
import { SpecanLineType } from '../data-models/pf-specan-line-type';
import { SvgRenderer, SvgZoom, ChartData } from '../../../chart/index';
import { SpectrumAnalyzerGraphAttributes } from './pf-specan-graph-attributes';

export class SpectrumAnalyzerRenderer {
  d3: D3;
  attributes: SpectrumAnalyzerGraphAttributes = new SpectrumAnalyzerGraphAttributes();
  svg: any;

  svgDataContainer: any;
  private borderColorElem: any;
  constructor(d3: D3) {
    this.d3 = d3;
  }

  appendCanvas(canvas: any, chartContainerElem: any) {
    canvas = this.d3.select(chartContainerElem)
      .append('canvas')
      .attr('width', this.attributes.outerGraphWidth)
      .attr('height', this.attributes.outerGraphHeight)
      .attr('class', 'pf-specan-canvas');
    return canvas;
  }
  setElementLocation(element, dimensions: any) {
      element.style('left', (dimensions.leftInPixels) + 'px');
      element.style('top', (dimensions.topInPixels) + 'px');
      element.attr('height', dimensions.height + 'px');
      element.attr('width', dimensions.width + 'px');
  }
  //not using
  //render(svg: any, data: SpectrumAnalyzerSeries[],
  //  domainScale: ScaleContinuousNumeric<number, number>, rangeScale: ScaleContinuousNumeric<number, number>,
  //  height: number, width: number): void {

  //  if (!!data) {
  //    data.forEach(dataset => {
  //      if (!!dataset) {
  //        svg = this.renderLine(svg, dataset, domainScale, rangeScale, height, width);
  //      }
  //    });
  //  }
  //  return svg;
  //}

  selectElementById(svg: any, id: string): any {
    return svg.select('#' + id);
  }

  removeElementById(svg: any, id: string): any {
    svg.select('#' + id).remove();
  }
  updateLineScaling(svg: any, scaleX, scaleY) {
    const dAttr = this.d3.line()
      .x(function (d) {
        return scaleX((d as any).x);
      })
      .y(function (d) {
        return scaleY((d as any).y);
      });
    const paths = svg.selectAll('path');
    paths.transition().duration(0).attr('d', dAttr);
    return svg;
  }
  //not using
  //updateLineData(svgContainer, path: SpectrumAnalyzerSeries, scaleX, scaleY) {
  //  const dAttr = this.d3.line()
  //    .x(function (d) {
  //      return scaleX((d as any).x);
  //    })
  //    .y(function (d) {
  //      return scaleY((d as any).y);
  //    });
  //  // using a path string that is created before adding to the DOM to prevent a memory leak
  //  const mainDataSet = path.getDataSetByName('main');
  //  let pathString = dAttr((mainDataSet.points() as any));
  //  const pathElem = svgContainer.select('#' + path.pathId)
  //    .data([mainDataSet.points()]).transition().duration(0).attr('d', pathString);
  //  if (path.visible && mainDataSet.visible) {
  //    pathElem.attr('display', null);
  //  } else {
  //    pathElem.attr('display', 'none');
  //  }
  //  const avgDataSet = path.getDataSetByName('avg');
  //  if (avgDataSet.visible && path.visible) {
  //    pathString = dAttr((avgDataSet.points() as any));
  //    if (svgContainer.select('#' + path.pathId + '-avg').empty()) {
  //      const avgLine = svgContainer.insert('path', '.brush')
  //        .attr('id', path.pathId + '-avg')
  //        .data([avgDataSet.points()])
  //        .style('stroke', path.graphColor)
  //        .attr('class', 'pf-specan-line')
  //        .attr('d', pathString);

  //      if (avgDataSet.color != null) {
  //        avgLine.style('stroke', avgDataSet.color);
  //      } else {
  //        avgLine.style('opacity', '.5');
  //      }
  //    } else {
  //      svgContainer.select('#' + path.pathId + '-avg').transition().attr('d', pathString);
  //    }
  //  } else {
  //    svgContainer.select('#' + path.pathId + '-avg').remove();
  //  }
  //  const minDataSet = path.getDataSetByName('min');
  //  if (minDataSet.visible && path.visible) {
  //    pathString = dAttr((minDataSet.points() as any));
  //    if (svgContainer.select('#' + path.pathId + '-min').empty()) {
  //      const minLine = svgContainer.insert('path', '.brush')
  //        .attr('id', path.pathId + '-min')
  //        .style('stroke', path.graphColor)
  //        .attr('class', 'pf-specan-line')
  //        .data([minDataSet.points()])
  //        .attr('d', pathString);

  //      if (minDataSet.color != null) {
  //        minLine.style('stroke', minDataSet.color);
  //      } else {
  //        minLine.style('opacity', '.5');
  //      }
  //    } else {
  //      svgContainer.select('#' + path.pathId + '-min').transition().attr('d', pathString);
  //    }
  //  } else {
  //    svgContainer.select('#' + path.pathId + '-min').remove();
  //  }
  //  const maxDataSet = path.getDataSetByName('max');
  //  if (maxDataSet.visible && path.visible) {
  //    pathString = dAttr((maxDataSet.points() as any));
  //    if (svgContainer.select('#' + path.pathId + '-max').empty()) {
  //      const maxLine = svgContainer.insert('path', '.brush')
  //        .attr('id', path.pathId + '-max')
  //        .style('stroke', path.graphColor)
  //        .data([maxDataSet.points()])
  //        .attr('class', 'pf-specan-line')
  //        .attr('d', pathString);

  //      if (maxDataSet.color != null) {
  //        maxLine.style('stroke', maxDataSet.color);
  //      } else {
  //        maxLine.style('opacity', '.5');
  //      }
  //    } else {
  //      svgContainer.select('#' + path.pathId + '-max').transition().attr('d', pathString);
  //    }
  //  } else {
  //    svgContainer.select('#' + path.pathId + '-max').remove();
  //  }
  //  return svgContainer;
  //}

  changeBorderColor(svg, color) {
    this.attributes.graphBorderColor = color;
    if (this.borderColorElem != null) {
      this.borderColorElem.style('stroke', this.attributes.graphBorderColor);
    } else {
      this.addBorder(svg, color);
    }
    return svg;
  }

  clearCanvas(context) {
    context.clearRect(0, 0, this.attributes.outerGraphWidth, this.attributes.outerGraphHeight);
  }

  appendLineToCanvas(context, dataPath: SpectrumAnalyzerSeries, scaleX, scaleY) {
    const mainDataSet = dataPath.getDataSetByName('main');
    if (dataPath.visible && mainDataSet.visible) {
      if (dataPath.lineType === SpecanLineType.dashed) {
          context.setLineDash([7, 5]);
      } else {
          context.setLineDash([]);
      }
      context.beginPath();
      context.lineWidth = dataPath.lineWidth;
      context.strokeStyle = dataPath.graphColor;
      mainDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
    const avgDataSet = dataPath.getDataSetByName('avg');
    if (avgDataSet.visible && dataPath.visible) {
      if (dataPath.lineType === SpecanLineType.dashed) {
          context.setLineDash([7, 5]);
      } else {
          context.setLineDash([]);
      }
      context.beginPath();
      context.strokeStyle = dataPath.getDataSetByName('avg').color;
      avgDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
    const minDataSet = dataPath.getDataSetByName('min');
    if (minDataSet.visible && dataPath.visible) {
      if (dataPath.lineType === SpecanLineType.dashed) {
          context.setLineDash([7, 5]);
      } else {
          context.setLineDash([]);
      }
      context.beginPath();
      context.strokeStyle = dataPath.getDataSetByName('min').color;
      minDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
    const maxDataSet = dataPath.getDataSetByName('max');
    if (maxDataSet.visible && dataPath.visible) {
      if (dataPath.lineType === SpecanLineType.dashed) {
          context.setLineDash([7, 5]);
      } else {
          context.restore();
      }
      context.beginPath();
      context.strokeStyle = dataPath.getDataSetByName('max').color;
      maxDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
  }

  // appendLine(svgContainer, path: SpectrumAnalyzerSeries, scaleX, scaleY) {
  //  const dAttr = this.d3.line()
  //    .x(function (d) {
  //      return scaleX((d as any).x);
  //    })
  //    .y(function (d) {
  //      return scaleY((d as any).y);
  //    });
  //  const mainDataSet = path.getDataSetByName('main');
  //  const pathString = dAttr((mainDataSet.points() as any));
  //  // the insert instead of the append adds the path as the first element under the svgContainer.
  //  // This is necessary to prevent the crosshair element (for zooming) from being overlayed by the graph elements
  //  const newPath = svgContainer.insert('path', '.brush')
  //    .data([mainDataSet.points()])
  //    .attr('id', path.pathId)
  //    .attr('class', 'pf-specan-line')
  //    .style('stroke', path.graphColor)
  //    .attr('d', pathString);

  //  if (!path.visible || !mainDataSet.visible) {
  //    newPath.attr('display', 'none');
  //  }
  //  return svgContainer;
  // }
  spanChanged(svg, newSpan: number, scaleX, scaleY) {
    this.attributes.span = newSpan;
    return svg;
  }

  addMouseEvents(svg: any, mouseMove: Function, mouseLeave: Function) {
    svg.on('mousemove', mouseMove).on('mouseleave', mouseLeave);
  }
  resize(): number {
    let parentComponent;
    const currentComponent = document.getElementById(this.attributes.graphId + '-pf-specan-container');
    if (currentComponent != null) {
      parentComponent = currentComponent.parentElement.parentElement;
    }
    if (parentComponent != null) {
      const style = window.getComputedStyle(parentComponent, null);
      const rect = parentComponent.getBoundingClientRect();
      this.attributes.outerGraphHeight = rect.height - this.attributes.topMenuHeight - this.attributes.bottomMenuHeight;
      this.attributes.outerGraphWidth = rect.width;
      // have to subtract the padding on the div to get the correct height and width
      this.attributes.outerGraphHeight -= parseFloat(style.paddingTop.replace('px', ''))
        + parseFloat(style.paddingBottom.replace('px', ''));
      this.attributes.outerGraphWidth -= parseFloat(style.paddingLeft.replace('px', ''))
        + parseFloat(style.paddingRight.replace('px', ''));

      if (!this.attributes.showOnlyGraphWindow) {
        this.attributes.innerGraphHeight = this.attributes.outerGraphHeight - 20;
        this.attributes.innerGraphWidth = this.attributes.outerGraphWidth - 30 - this.attributes.marginLeft;
      } else {
        this.attributes.innerGraphHeight = this.attributes.outerGraphHeight;
        this.attributes.innerGraphWidth = this.attributes.outerGraphWidth;
      }
      if (this.attributes.innerGraphHeight < 0) {
        this.attributes.innerGraphHeight = 0;
        this.attributes.outerGraphHeight = 0;
      }
      if (this.attributes.innerGraphWidth < 0) {
        this.attributes.innerGraphWidth = 0;
        this.attributes.outerGraphWidth = 0;
      }
      if (this.attributes.innerGraphHeight < 175) {
        this.attributes.ticks = 5;
      }
      // else {
      //    this.attributes.ticks = this.config.numberOfTicks;
      // }
      return this.attributes.innerGraphHeight;
    }
  }
  appendSvg(svg: any, graphContainer: any, elementId) {
    svg = this.d3.select(graphContainer)
        .append('svg')
        .attr('id', elementId)
      .classed('pf-specan-svg', true);

    if (this.attributes.outerGraphHeight > 0 && this.attributes.outerGraphWidth > 0) {
      svg.attr('viewBox', '0 0 ' + this.attributes.outerGraphWidth + ' ' + this.attributes.outerGraphHeight)
        .attr('width', this.attributes.outerGraphWidth)
        .attr('height', this.attributes.outerGraphHeight);
    } else {
      svg.attr('width', '100%')
        .attr('height', '100%');
    }
    return svg;
  }
  appendSvgBackgroundContainer(svg: any, graphContainer: any, elementId) {
      svg = this.appendSvg(svg, graphContainer, elementId);

      svg = svg.append('g').attr('id', this.attributes.graphId + '-axis-container')
          .style('position', 'absolute')
          .classed('pf-specan-svg', true)
          .attr('transform', `translate(${this.attributes.marginLeft}, ${this.attributes.marginTop})`);
      // add this rect for the ability to set a background color
      svg.append('rect')
          .style('width', (this.attributes.innerGraphWidth) + 'px')
          .style('height', (this.attributes.innerGraphHeight) + 'px')
          .attr('class', 'pf-specan-background')
          .attr('id', 'pf-specan-background');


      return svg;
  }
  appendSvgForegroundContainer(svg: any, graphContainer: any, elementId) {
      svg = this.d3.select(graphContainer)
          .append('svg')
          .attr('id', elementId)
          .classed('pf-specan-svg', true);

      if (this.attributes.outerGraphHeight > 0 && this.attributes.outerGraphWidth > 0) {
          svg.attr('viewBox', '0 0 ' + this.attributes.innerGraphWidth + ' ' + this.attributes.innerGraphHeight)
              .attr('width', this.attributes.innerGraphWidth)
              .attr('height', this.attributes.innerGraphHeight);
      } else {
          svg.attr('width', '100%')
              .attr('height', '100%');
      }
      svg.append('g').attr('id', this.attributes.graphId + '-container')
          .style('position', 'absolute')
          .classed('pf-specan-svg', true);

    // add this rect for the ability to set a background color
      svg.append('rect')
      .style('width', (this.attributes.innerGraphWidth) + 'px')
      .style('height', (this.attributes.innerGraphHeight) + 'px')
      .attr('class', 'pf-specan-background');

    return svg;
  }
  addGraphBorder(svg: any) {
      if (this.attributes.graphBorderColor !== '') {
          this.addBorder(svg, this.attributes.graphBorderColor);
      }
  }
  appendSvgPathContainer(svg: any) {
    svg.append('g').attr('id', this.attributes.graphId + '-container')
      .attr('clip-path', 'url(#' + this.attributes.graphId + 'clip)');
    const clip = svg.append('defs').append('svg:clipPath')
      .attr('id', this.attributes.graphId + 'clip')
      .append('svg:rect')
      .attr('width', this.attributes.innerGraphWidth)
      .attr('height', this.attributes.innerGraphHeight)
      .attr('x', 0)
      .attr('y', 0).style('fill', 'none');

    return svg;
  }
  getDataContainer(svg: any) {
    return svg.select('#' + this.attributes.graphId + '-container');
  }
  getDataContainerHTML() {
      return document.querySelector('#' + this.attributes.graphId + '-axisSvg');
  }
  private renderLine(svg: any, series: SpectrumAnalyzerSeries,
    domainScale: ScaleContinuousNumeric<number, number>, rangeScale: ScaleContinuousNumeric<number, number>,
    height: number, width: number): void {

    const dAttr = this.d3.line()
      .x(function (d) {
        return domainScale((d as any).x);
      })
      .y(function (d) {
        return rangeScale((d as any).y);
      });
    // iterate over all data sets in the series
    // for specans, this is usually main, max, min, and avg
    for (let i = 0; i < series.length(); i++) {
      const dataSet = series.dataSetAt(i);
      if (dataSet.visible) {
        const pathString = dAttr((series.dataSetAt(i).points() as any));
        // the insert instead of the append adds the path as the first element under the svgContainer.
        // This is necessary to prevent the crosshair element (for zooming) from being overlayed by the graph elements
        const newPath = svg.insert('path', '.brush')
          .data((series.dataSetAt(i).points() as any))
          .attr('id', series.pathId)
          .attr('class', 'pf-specan-line')
          .style('stroke', series.dataSetAt(i).color)
          .attr('d', pathString);
      }
    }
    return svg;
  }

  private addBorder(svg, color) {
    if (this.borderColorElem != null) {
      svg.select('#' + this.attributes.graphId + '-border').remove();
    }
    this.borderColorElem = svg.append('rect')
      .attr('x', 1)
      .attr('y', 1)
      .attr('height', this.attributes.innerGraphHeight - 2)
      .attr('width', this.attributes.innerGraphWidth - 2)
      .style('stroke', color)
      .attr('id', this.attributes.graphId + '-border')
      .style('fill', 'none')
      .style('stroke-width', 3);
  }


}
