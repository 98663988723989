﻿import { D3 } from 'd3-ng2-service';

import { XYPoint } from '../..';

export class SvgFocusTracking {
  private d3: D3;
  private id = 'focus-tracking';
  private className: string;
  private focusTrackingElem: any;
  private height: number;
  private width: number;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  initialize(id: string, className: string) {
    this.id = id;
    this.className = className;
  }
  addFocusTrackingElementToSvg(svg: any, width: number, height: number) {
      this.height = height;
      this.width = width;
    //  focus tracking
    this.focusTrackingElem = svg.append('g');
    // append the lines and circle for the crosshairs and tracking circle on the line
    this.focusTrackingElem.append('line')
      .attr('id', this.id + 'x')
      .attr('class', this.className).attr('display', 'none');
    this.focusTrackingElem.append('line')
      .attr('id', this.id + 'y')
      .attr('class', this.className).attr('display', 'none');
  }
  showFocusTrackingAtPosition(position: XYPoint) {
    this.focusTrackingElem.select('#' + this.id + 'x')
      .attr('display', null)
      .attr('x1', position.x).attr('y1', 0)
      .attr('x2', position.x).attr('y2', this.height);
    this.focusTrackingElem.select('#' + this.id + 'y')
      .attr('display', null)
      .attr('x1', 0).attr('y1', position.y)
        .attr('x2', this.width).attr('y2', position.y);
  }
  hide() {
    if (this.focusTrackingElem != null) {
      this.focusTrackingElem.select('#' + this.id + 'x').attr('display', 'none');
      this.focusTrackingElem.select('#' + this.id + 'y').attr('display', 'none');
    }
  }
  reset() {
    this.focusTrackingElem = null;
  }
}