export class AnalysisParams {
  satelliteId: string;
  transponderId: string;
  carrierId: string;
  limit: number;
  startInMs: number;
  stopInMs: number;
  loadRecent: boolean;
  liveUpdate: boolean;
  dataModelNeedsUpdate: boolean;
}
