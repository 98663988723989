export class SpectrumAnalyzerGraphAttributes {
  span: number;
  graphBorderColor = '#000';
  ticks: number;
  marginLeft = 40;
  marginBottom = 10;
  marginTop = 5;
  // each pf-specan-menu element is ~40px high
  topMenuHeight = 35;
  bottomMenuHeight = 70;
  // outer refers to the whole graph including axes
  // inner refers to the inside group that needs a different size, excluding the axes, to get the correct scale
  innerGraphWidth: number;
  innerGraphHeight: number;
  outerGraphWidth: number;
  outerGraphHeight: number;
  graphId: string;
  showOnlyGraphWindow: boolean;
}
