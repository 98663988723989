import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DataTableModule, DropdownModule, CalendarModule, AutoCompleteModule, InputTextModule, TabViewModule, CheckboxModule, ContextMenuModule, TabMenuModule, TooltipModule, ListboxModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { TransponderDatabaseComponent } from './transponders/transponders.component';
import { SitesDatabaseComponent } from './sites/sites.component';
import { SatelliteDatabaseComponent } from './satellites/satellites.component';
import { AntennaDatabaseComponent } from './antennas/antennas.component';
import { FormsModule } from '@angular/forms';
import { PFUnitModule } from './unit';
import { TransponderFormComponent } from './transponders/transponders-update.component';
import { SiteFormComponent } from './sites/sites-update.component';
import { SatelliteFormComponent } from './satellites/satellites-update.component';
import { AntennaFormComponent } from './antennas/antennas-update.component';
import { SatcomEditorBase } from './models/SATCOMEditorBase';
import { DatabaseComponent } from './database/database.component';
import { SharedModule } from './shared';
import { ConfirmationDialogComponent } from './alert/confirmation-dialog.component';
import { FreqBlockDialogComponent } from './antennas/freq-block-dialog/freq-block-dialog.component';

export { DatabaseComponent } from './database/database.component';

@NgModule({
  imports: [
    DataTableModule,
    DropdownModule,
    CalendarModule,
    TooltipModule,
    ListboxModule,
    CheckboxModule,
    ContextMenuModule,
    TabViewModule,
    FormsModule,
    TabMenuModule,
    PFUnitModule,
    TabViewModule,
    CommonModule,
    AutoCompleteModule,
    InputTextModule,
    SharedModule
  ],
  declarations: [
    TransponderDatabaseComponent,
    SitesDatabaseComponent,
    SatelliteDatabaseComponent,
    AntennaDatabaseComponent,
    FreqBlockDialogComponent,
    ConfirmationDialogComponent,
    DatabaseComponent,
    SiteFormComponent,
    TransponderFormComponent,
    SatelliteFormComponent,
    AntennaFormComponent,
  ],
  entryComponents: [SiteFormComponent, FreqBlockDialogComponent,
    TransponderFormComponent,
    SatelliteFormComponent,
    AntennaFormComponent,]
})
export class DatabaseModule {
}
