import { Component } from '@angular/core';
import { UserService } from 'app/services/user-service';
import { Router } from '@angular/router';
import { Theme } from '@kratos/pf-ui';
import { AppUtilities } from 'app/services/app-utilities';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent {
  theme = new Theme('spectrum-viewer-theme');
  constructor(private userService: UserService, private router: Router, private utilities: AppUtilities) { }
  logout() {
    this.userService.logout();
    this.utilities.toggleSlideoutSource.next(null);
    this.router.navigate(['/home']);
  }
}
