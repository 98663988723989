import { EntityAlias } from "./entity-alias";

export class Transponder{
  constructor(
    public transponder_id?: string,
    public satellite_id?: string,
    public name?: string,
    public aliases?: EntityAlias[],
    public uplinkcf: number = 0,
    public downlinkcf: number = 0,
    public bw: number = 0,
    public translation: number = 0,
    public uguard_band: number = 0,
    public lguard_band: number = 0,
    public active?: boolean
  ) {
    this.aliases = [];
  }
  static copy(existingItem) {
    let newObj = JSON.parse(JSON.stringify(existingItem));
    if (newObj.aliases == null) {
      newObj.aliases = [];
    }
    return newObj;
  }
}
