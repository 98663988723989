import { ChartTicks } from './pf-chart-ticks';

export class ChartMinSpacingTicks implements ChartTicks {

  constructor(public numTicks = 10, public minSpacing = 50) {
  }

  numXTicks(width: number): number {
    if (this.minSpacing && this.numTicks) {
      const ticks = width / this.minSpacing;
      if (ticks <= 0) {
        return 1;
      }
      return ticks < this.numTicks ? ticks : this.numTicks;
    }
    return undefined;
  }

  numYTicks(height: number): number {
    if (this.minSpacing && this.numTicks) {
      const ticks = height / this.minSpacing;
      if (ticks <= 0) {
        return 1;
      }
      return ticks < this.numTicks ? ticks : this.numTicks;
    }
    return undefined;
  }
}