import { Component, Input } from "@angular/core";
import { AppUtilities } from "../../services/app-utilities";

@Component({
  selector: 'activation-popup',
  templateUrl: './activation-popup.component.html',
})
export class ActivationPopupComponent {
  activateMethod;
  deactivateMethod;
  visible: boolean;

  linkNames = [];
  selectedItem: any;
  antennaNames = [];
  antenna = [];
  type = "";

  constructor(private dataGenerator: AppUtilities) {

  }

  parseAntenna() {
    this.antennaNames = [];
    this.linkNames = [];
    let array = [];
    for (let antenna of this.antenna) {
      let data = {} as any;
      data.id = antenna.id;
      this.antennaNames.push(antenna.name);
    }
  }

  cancel() {
    this.visible = false;
  }

  DeactivateAll() {
    let sub = this.deactivateMethod.subscribe((result) => {
      if (result.success) {
        this.dataGenerator.showError("Item Deactivated", "");
        this.cancel();
      } else {
        this.dataGenerator.showError("Item Not Deactivated", result.responseMsg);
      }
      sub.unsubscribe();
    });
  }

  ActivateAll() {
    let sub = this.activateMethod.subscribe((result) => {
      if (result.success) {
        this.dataGenerator.showError("Item Activated", "");
        this.cancel();
      } else {
        this.dataGenerator.showError("Item Not Activated", result.responseMsg);
      }
      sub.unsubscribe();
    });
  }

}
