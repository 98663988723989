import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule, CheckboxModule, ContextMenuModule, OverlayPanelModule, RadioButtonModule } from 'primeng/primeng';

import { PFChartModule } from '../chart/index';
import { PFUnitModule } from '../unit/index';
import { WaterfallPlotComponent } from './pf-waterfall/pf-waterfall.component';

export { WaterfallSeries } from './pf-waterfall/data-models/pf-waterfall-series';
export { WaterfallData } from './pf-waterfall/data-models/pf-waterfall-data';
export { WaterfallPlotConfiguration } from './pf-waterfall/configuration/pf-waterfall-config';
export { WaterfallRenderer } from './pf-waterfall/render/pf-waterfall-renderer';
export { WaterfallDataParameters } from './pf-waterfall/configuration/pf-waterfall-data-params';
export { WaterfallPlotComponent } from './pf-waterfall/pf-waterfall.component';
export { WaterfallXY } from './pf-waterfall/data-models/pf-waterfall-xy';
export { HorizontalWaterfallTrackingLine } from './pf-waterfall/graph-elements/pf-waterfall-tracking-line-horizontal';
export { VerticalWaterfallTrackingLine } from './pf-waterfall/graph-elements/pf-waterfall-tracking-line-vertical';
export { WaterfallPlotDataModel } from './pf-waterfall/data-models/pf-waterfall-data-model';
export { WaterfallAxes } from './pf-waterfall/axes/pf-waterfall-axes';
export { AxisGridDimensions } from './pf-waterfall/axes/pf-waterfall-axis-grid-dimensions';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ContextMenuModule,
    CheckboxModule,
    ButtonModule,
    RadioButtonModule,
    OverlayPanelModule,
    PFChartModule,
    PFUnitModule
  ],
  declarations: [
    WaterfallPlotComponent
  ],
  exports: [
    WaterfallPlotComponent
  ],
})
export class PFWaterfallModule { }
