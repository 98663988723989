import { SpectrumAnalyzerSeries, SpectrumAnalyzerDataParameters, SpectrumAnalyzerThumbnailConfiguration } from "app/components/graphs/specan";
import { EntityState } from "app/models/entity-state";

export class MultiSpecanFullscreenDataSet {
  satelliteName = '';
  transponderName = '';
  carrierName = '';
  tileWidth: number = 100;
  tileHeight: number = 75;
  timestamp: number;
  dataSeries: SpectrumAnalyzerSeries[];
  config: SpectrumAnalyzerThumbnailConfiguration;
  dataParams: SpectrumAnalyzerDataParameters;
  state = EntityState.Normal;
  hasBeenDestroyed = false;
  constructor(sat, xpdr, carrier) {
    this.satelliteName = sat;
    this.transponderName = xpdr;
    this.carrierName = carrier;
    this.dataSeries = new Array<SpectrumAnalyzerSeries>();
    this.config = new SpectrumAnalyzerThumbnailConfiguration();
    this.dataParams = new SpectrumAnalyzerDataParameters();
    this.config.yPercentageBuffer = .25;
  }
}
