import { ChartSeries } from './../data/pf-chart-series';

export interface Grid {
  cells: Cell[];
  cellWidth: number;
  cellHeight: number;
}

export interface Cell {
  leftX: number;
  topY: number;
  count: number;
  color?: string;
}

export class ChartQuadbinTransform {

  transform(
    series: ChartSeries,
    domainRange: number,
    rangeRange: number,
    divisions: number,
    height: number,
    width: number): Grid {

    let max = 1;
    const cellWidth = width / divisions, cellHeight = height / divisions;

    const grid: Grid = {
      cells: [],
      cellWidth: cellWidth,
      cellHeight: cellHeight
    };

    const bins = new Map<string, Cell>();

    const xPerTick = domainRange / divisions;
    const yPerTick = rangeRange / divisions;

    series.data().forEach(chartData => {
      chartData.points().forEach(point => {

        const xbin = Math.floor(point.x / xPerTick);
        const ybin = Math.ceil(point.y / yPerTick);
        const xbinStr = String(xbin);
        const ybinStr = String(ybin);

        const key = xbinStr + '.' + ybinStr;
        let cell = bins.get(key);
        if (!!cell) {
          cell.count++;
        } else {
          cell = {
            leftX: xbin * xPerTick,
            topY: ybin * yPerTick,
            count: 1
          };
        }

        if (cell.count > max) {
          max = cell.count;
        }

        bins.set(key, cell);
      });
    });

    // 10 percent will be at the max
    max = .9 * max;

    bins.forEach((cell: Cell, key: string) => {
      let hue = 226 - 226 / max * cell.count;
      if (hue < 0) {
        hue = 0;
      }
      cell.color = `hsla(${hue}, 100%, 50%, 0.6)`;
      grid.cells.push(cell);
    });

    return grid;
  }
}