import {
    OnInit, Component, Input, EventEmitter, Output, Inject, InjectionToken, ViewChild,
    ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver, AfterViewInit, OnDestroy
} from '@angular/core';
import { SatelliteFormComponent } from './satellites-update.component';
import { AppUtilities } from '../shared';
import { DataViewDemoService } from '../../../services/data-view-demo-service';
import { Satellite } from '../models/satellite';
import { UserService } from '../../../services/user-service';

import { EntityType } from '../shared/enums/entity-type';
import { EntityOperation } from '../shared/enums/entity-operation';
import { SatcomEditorBase } from '../models/SATCOMEditorBase';

@Component({
    selector: 'satellites',
    templateUrl: './satellites.component.html',
    styleUrls: ['./satellites.component.css']
})

export class SatelliteDatabaseComponent extends SatcomEditorBase {
  @Input() entityType;
  @Input() entityOperation;
  constructor(private dataGen: DataViewDemoService, private utilities: AppUtilities, private vc: ViewContainerRef,
    private cfr: ComponentFactoryResolver, public userService: UserService) {
    super(dataGen, utilities, vc, cfr, userService);
    this.tableContainerId = 'satellitesTable';
  }
  ngAfterViewInit() {
    super.setEnumTypes(this.entityType, this.entityOperation);
  }

  protected initialize() {
    this.cols = this.utilities.getColumns(EntityType.Satellite);
    this.identifierFieldName = this.utilities.getIdentifier(EntityType.Satellite);
      this.query();
  }
  // implement inherited methods
  protected filterChanged() {
    if (this.nameFilter != null) {
      this.filter = this.nameFilter;
    }
    //enable if server side filtering is wanted
    //this.query();
  }

  windowResized() {
    if (this.inView) {
      this.resizeTable();
    }
  }
  delete(entity) {
    let data = {} as any;
    data.satelliteId = entity.satellite_id;
    this.dataService.performEntityOperation(this.entityType, this.entityOperation.Delete, data).subscribe(
      (response) => {
        if (response.success) {
          this.utilities.showSuccess('Satellite Deleted', 'Satellite successfully deleted.');
          this.editing = false;
          this.query();
        } else {
          this.utilities.showError('Could not delete satellite', response.responseMsg);

        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.utilities.showError('Could not delete satellite', error);

      });
  }
  protected updateEntity(event, existingItem = null) {
    this.editing = true;
    if (this.editorComponentRef != null)
      this.editorComponentRef.destroy();
    const factory = this.cfr.resolveComponentFactory(SatelliteFormComponent);
    this.editorComponentRef = this.updatePanel.createComponent(factory) as any;
    this.editorComponentRef.instance.open(existingItem).then((update) => {
      if (update) {
        this.query();
      }
      this.editing = false;
      this.editorComponentRef.destroy();
      this.editorComponentRef = null;
    });
    this.editorComponentRef.instance.updateGrid.subscribe(() => {
      this.query();
    })
    this.setRightClickedRow(event);
  }

  tabInView() {
    this.inView = true;
    setTimeout(() => {
      this.resizeTable();
    });
  }
  protected createSatellite() {
    this.editing = true;
    this.selectedEntity = null;
    if (this.editorComponentRef != null)
      this.editorComponentRef.destroy();
    const factory = this.cfr.resolveComponentFactory(SatelliteFormComponent);
    this.editorComponentRef = this.updatePanel.createComponent(factory) as any;
    this.editorComponentRef.instance.open().then((update) => {
      if (update) {
        this.query();
      }
      this.editing = false;
      this.editorComponentRef.destroy();
      this.editorComponentRef = null;
    });
    this.editorComponentRef.instance.updateGrid.subscribe(() => {
      this.query();
    })
  }
}
