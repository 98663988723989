﻿export class CarrierRegion {
    startFreq: number;
    stopFreq: number;
    name: string;
    color: string;
    constructor(name, color, start, stop) {
        this.name = name;
        this.color = color;
        this.startFreq = start;
        this.stopFreq = stop;
    }
}