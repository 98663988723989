import { D3, Selection } from 'd3-ng2-service';

import { ChartAddon } from '../addons/pf-chart-addon';
import { ChartLabels } from '../addons/pf-chart-labels';

export class ChartAxesLabels implements ChartAddon {
  svg: Selection<any, {}, null, undefined>;
  d3: D3;
  height: number;
  width: number;

  private readonly elementClass = 'axis-label';
  private xLabel: any;
  private yLabel: any;

  constructor(private labels: ChartLabels) { }

  init(d3: D3, height: number, width: number): void {
    this.d3 = d3;
    this.height = height;
    this.width = width;
  }

  create(svg: Selection<any, {}, null, undefined>, domainScale: any, rangeScale: any): void {
    this.svg = svg;

    if (this.xLabel) {
      this.xLabel.remove();
    }
    if (this.yLabel) {
      this.yLabel.remove();
    }

    if (this.labels.xAxisLabel) {
      this.createXAxisLabel();
    }

    if (this.labels.yAxisLabel) {
      this.createYAxisLabel();
    }
  }

  onScaleOrResize(height: number, width: number, domainScale: any, rangeScale: any): void {
    this.height = height;
    this.width = width;
    this.create(this.svg, domainScale, rangeScale);
  }

  private createXAxisLabel(): void {
    const anchorX = (this.width - this.labels.left - this.labels.right) / 2 + this.labels.left;
    this.xLabel = this.svg.append('text')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${anchorX}, ${this.height - this.labels.xLabelMargin})`)
      .style('text-anchor', 'middle')
      .text(this.labels.xAxisLabel);
  }

  private createYAxisLabel(): void {
    // .style('alignment-baseline', 'hanging') /* can't use yet since FF does not support it */
    const anchorY = (this.height - this.labels.top - this.labels.bottom) / 2 + this.labels.top;
    this.yLabel = this.svg.append('text')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${this.labels.yLabelMargin}, ${anchorY}) rotate(-90)`)
      .style('text-anchor', 'middle')
      .text(this.labels.yAxisLabel);
  }
}