import { Injectable } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs/Rx';
import { UserMessage, User } from '../models/index';
import { ServiceResponse } from 'app/models/service-response';


@Injectable()
export class AppUtilities {
  currentUser: User = new User();
  showNotificationSource = new Subject<UserMessage>();
  showNotificationObs = this.showNotificationSource.asObservable();
  toggleSlideoutSource = new Subject<string>();
  toggleSlideoutObs = this.toggleSlideoutSource.asObservable();
  toggleNavbarSource = new Subject<boolean>();
  toggleNavbarObs = this.toggleNavbarSource.asObservable();

  // global notification center, which passes notifications to the app.component for display
  showNotification(message: UserMessage): void {
    this.showNotificationSource.next(message);
  }
  getFormattedDuration(ticks: number) {
    var seconds = ticks / 1000;
    var hour = Math.floor(seconds / 3600);
    var minute = Math.floor((seconds / 60) % 60);
    var second = seconds % 60;

    return hour + ':' + minute + ':' + second;
  }
  convertToMhz(value) {
    if (value == 0) {
      return value;
    } else {
      value = Math.round(value / 1000);
      //need to do this so the string shows up with 3 decimal places
      return value / 1000;
    }
  }
  showServiceError(errorTitle: string, response: ServiceResponse) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    if (response.statusCode == 500) {
      msg.messageDetail = 'Something went wrong on the server';
    } else if (response.statusCode == 401) {
      msg.messageDetail = 'You are not authorized to perform this action';
    } else {
      msg.messageDetail = response.responseMsg;
    }
    msg.messageType = 'error';
    this.showNotification(msg);
  }
  // comparison function for use in UI selects
  compareNames(o1, o2) {
    if (o1 != null && o2 != null) {
      return o1.name === o2.name;
    } else if (o1 == null && o2 == null) {
      return true;
    } else {
      return false;
    }
  }
  truncateString(str, length = 50) {
    if (str.length >= length)
      str = str.substr(0, length) + '...';
    return str;
  }
  showError(errorTitle: string, errorDetail: string, sticky = false) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'error';
    msg.sticky = sticky;
    this.showNotification(msg);
  }
  showSuccess(title: string, detail: string) {
    const msg = new UserMessage();
    msg.messageTitle = title;
    msg.messageDetail = detail;
    msg.messageType = 'success';
    this.showNotification(msg);
  }
  showInfo(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'info';
    this.showNotification(msg);
  }
  
}
