import { Component, ViewChild, ElementRef, Output, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs";
import { Antenna, FrequencyBlock } from "../models/antenna";
import { Site } from "../models/site";
import { DataViewDemoService } from "../../../services/data-view-demo-service";
import { EntityAlias } from "../models/entity-alias";
import { EntityOperation } from "../shared/enums/entity-operation";
import { EntityType } from "../shared/enums/entity-type";
import { AppUtilities } from 'app/services/app-utilities';
import { FreqBlockDialogComponent } from './freq-block-dialog/freq-block-dialog.component';
import { Listbox } from 'primeng/primeng';

@Component({
  selector: 'antennas-update',
  templateUrl: './antennas-update.component.html',
  styleUrls: ['./antennas-update.component.css']
})
export class AntennaFormComponent {
  newType: string;
  selectedSite: Site;
  selectedItem: Antenna;
  inputsThatHaveChanged = new Array<HTMLSpanElement>();
  @ViewChild('addButton') public addButton: ElementRef;
  @ViewChild('closeButton') public closeButton: ElementRef;
  @ViewChild('updateButton') public updateButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;
  @Output() updateGrid = new Subject();
  aliasEntity: string;
  aliasName: string;
  altitudeOptions = ['LEO', 'MEO', 'GEO', 'HEO'];
  processing: boolean;
  addingAlias: boolean;
  addingTypes: boolean;
  hasError: boolean;
  sites: Site[];
  selectedFreqBlock: FrequencyBlock;
  updating: boolean;

  constructor(private dataService: DataViewDemoService, private utilities: AppUtilities, private vc: ViewContainerRef,
    private cfr: ComponentFactoryResolver) {
  }
  inputChanged(label: HTMLSpanElement) {
    label.classList.add('text-yellow');
    this.inputsThatHaveChanged.push(label);
  }
  removeFreqBlock(item) {
    let idx = this.selectedItem.frequency_blocks.findIndex(block => {
      return block.frequency_block_id == item.frequency_block_id;
    });
    this.selectedItem.frequency_blocks.splice(idx, 1);
    this.selectedItem.frequency_blocks = [...this.selectedItem.frequency_blocks];
  }
  addOrUpdateFreqBlock(existingItem = null) {
    const factory = this.cfr.resolveComponentFactory(FreqBlockDialogComponent);
    const componentRef = this.vc.createComponent(factory);
    componentRef.instance.open(existingItem).then((result) => {
      if (result != null) {
        result.display_name = this.utilities.convertToMhz(result.LNB) + " MHz";
        if (existingItem != null) {
          let idx = this.selectedItem.frequency_blocks.findIndex(block => {
            return block.frequency_block_id == result.frequency_block_id;
          });
          this.selectedItem.frequency_blocks[idx] = result;
          this.selectedItem.frequency_blocks = [...this.selectedItem.frequency_blocks];
        } else {
          this.selectedItem.frequency_blocks = [...this.selectedItem.frequency_blocks, result];
        }
      }
      componentRef.destroy();
    });
  }
  removeDirtyClasses() {
    this.inputsThatHaveChanged.forEach(label => {
      label.classList.remove('text-yellow');
    });
    this.inputsThatHaveChanged.splice(0, this.inputsThatHaveChanged.length);
  }
  open(sites, selectedSite, existingItem: Antenna = null): Promise<boolean> {
    this.sites = sites;
    if (existingItem != null) {
      this.selectedItem = Antenna.copy(existingItem);
      this.updating = true;
    } else {
      this.selectedItem = new Antenna();
    }
    this.selectedSite = selectedSite;
    this.addFreqBlockDisplayNames();

    return new Promise<boolean>((resolve, reject) => {
      this.cancelButton.nativeElement.onclick = ((e: any) => {
        e.preventDefault();
        resolve(false);
      });
      setTimeout(() => {
        this.closeButton.nativeElement.onclick = ((e: any) => {
          e.preventDefault();
          resolve(true);
        });
        if (this.addButton != null)
          this.addButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.removeFreqBlockDisplayNames();
            this.addOrUpdate(EntityOperation.Create, resolve);
          });
        else
          this.updateButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.removeFreqBlockDisplayNames();
            this.addOrUpdate(EntityOperation.Update, resolve);
          });
      });
    });
  }
  removeFreqBlockDisplayNames() {
    this.selectedItem.frequency_blocks.forEach((block) => {
      block.display_name = null;
    });
  }
  addFreqBlockDisplayNames() {
    this.selectedItem.frequency_blocks.forEach((block) => {
      block.display_name = this.utilities.convertToMhz(block.LNB) + " MHz";
    });
  }
  update() {
    this.addOrUpdate(EntityOperation.Update, null);
  }
  addOrUpdate(entityOp, resolve) {
    this.selectedItem.site_id = this.selectedSite.site_id;
    if (this.validate()) {
      this.processing = true;
      let data = {} as any;
      data.data = this.selectedItem;
      data.siteId = this.selectedItem.site_id;
      if (this.selectedItem.antenna_id != null) {
        data.antennaId = this.selectedItem.antenna_id;
      }
      this.dataService.performEntityOperation(EntityType.Antenna, entityOp, data).subscribe(
        (result) => {
          this.processing = false;
          if (result.success) {
            this.removeDirtyClasses();
            this.updateGrid.next();
            if (this.selectedItem.antenna_id != null) {
              this.utilities.showSuccess('Antenna Updated', 'Antenna was updated successfully');
            } else {
              this.utilities.showSuccess('Antenna Created', 'Antenna was created successfully');
              let newAntenna = JSON.parse(result.data);
              this.selectedItem.antenna_id = newAntenna.antenna_id;
              resolve(true);
            }
          } else {
            this.utilities.showError('Error Saving Antenna', result.responseMsg);
          }
        },
        (err) => {
          this.processing = false;
          this.utilities.showError('Error Saving Antenna', err);
        }
      );
    }
  }
  onSiteChange(newval, input) {
    this.inputChanged(input);
  }
  addAlias(aliasInput: HTMLSpanElement) {
    this.inputChanged(aliasInput);
    let newAlias = new EntityAlias();
    newAlias.name = this.aliasName;
    newAlias.entity = this.aliasEntity;
    this.selectedItem.aliases.push(newAlias);
    this.aliasEntity = '';
    this.aliasName = '';
  }
  addType() {
    this.selectedItem.antenna_types.push(this.newType);
    this.newType = '';
  }
  validate() {
    if (!(this.selectedItem).name) {
      this.hasError = true;
      return false;
    } else if (!(this.selectedItem).site_id) {
      this.hasError = true;
      return false;
    }
    else if (this.selectedItem.antenna_types.length == 0) {
      this.hasError = true;
      return false;
    }
    return true;
  }
}
