import { Component, ViewChild, ElementRef } from '@angular/core';
import { User, UserMessage, ServiceResponse, Carrier } from 'app/models';
import { UserService } from 'app/services/user-service';
import { AppUtilities } from 'app/services/app-utilities';
import { EntityDef } from 'app/models/entity-def';
import { DataViewDemoService } from 'app/services/data-view-demo-service';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import { Transponder } from 'app/models/transponder';
import { Organization } from '../../../models/organization';
import { EntityType } from '../../../enums/entity-type';
import { EntityOperation } from '../../../enums/entity-operation';
@Component({
  selector: 'add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent {
  companies = ['RT Logic', 'Kratos'];
  currentUser = new User();
  userPassword: string;
  confirmUserPassword: string;
  passwordError: boolean;
  loading: boolean;
  @ViewChild('okButton') public okButton: ElementRef;
  organizations: Organization[];
  @ViewChild('cancelButton') public cancelButton: ElementRef;
  constructor(public utilityService: AppUtilities, public userService: UserService, private dataService: DataViewDemoService) {
        this.getOrganizations();

  }
  getOrganizations() {
    let sub = this.dataService.performEntitiesOperation(EntityType.Organization, EntityOperation.Get).subscribe((results) => {
      if (results.success) {
        this.organizations = JSON.parse(results.data);
      } else {
        this.utilityService.showError('Error Retrieving Organizations', results.responseMsg);
      }
      sub.unsubscribe();
    }, (error) => {
      sub.unsubscribe();
      this.utilityService.showError('Error Retrieving Organizations', error.statusText);
    });
  }
  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.cancelButton != null) {

        this.cancelButton.nativeElement.onclick = ((e: any) => {
          e.preventDefault();
          resolve(null);
        });
      }
      if (this.okButton != null) {
        this.okButton.nativeElement.onclick = ((e: any) => {
          this.loading = true;
          this.currentUser.password = this.userPassword;
          if (this.currentUser.isAdmin) {
            this.currentUser.roles.push('Administrator');
            this.currentUser.isAdmin = null;
          }
          if (this.currentUser.parent) {
            this.currentUser.parents = [this.currentUser.parent];
          }
          this.userService.addNewUser(this.currentUser).subscribe((response: ServiceResponse) => {
            this.loading = false;
            if (response.success) {
              resolve(true);
            } else {
              const msg = new UserMessage();
              msg.messageTitle = 'Error Creating User';
              msg.messageDetail = response.responseMsg;
              msg.messageType = 'error';
              this.utilityService.showNotification(msg);
            }
           
          }, error => {
            const msg = new UserMessage();
            msg.messageTitle = 'Server Error';
            msg.messageDetail = error;
            msg.messageType = 'error';
            this.utilityService.showNotification(msg);
          });
        });
      }
    });
  }
  checkMatch(newValue) {
    this.confirmUserPassword = newValue;
    if (this.userPassword != this.confirmUserPassword)
      this.passwordError = true;
    else
      this.passwordError = false;
  }
  private showSuccess(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'success';
    this.utilityService.showNotification(msg);
  }
}
