import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { EntityDef } from 'app/models/entity-def';
import { DataViewDemoService } from 'app/services/data-view-demo-service';
import { AppUtilities } from 'app/services/app-utilities';
import { UserMessage, Carrier } from 'app/models';
import { MultiSpecanDataSet } from 'app/components/multi-specan-view/multi-specan-dataset';
import { Transponder } from 'app/models/transponder';
import { EntityOperation } from 'app/enums/entity-operation';
import { EntityType } from 'app/enums/entity-type';
import { MonitorGroup, MonitorGroupItem } from 'app/models/monitor-group';
import { UserService } from 'app/services/user-service';
import { Subscription } from 'rxjs';
import { Satellite } from 'app/components/database/models/satellite';
@Component({
  selector: 'add-update-monitor-group-dialog',
  templateUrl: './add-update-monitor-group-dialog.component.html',
  styleUrls: ['./add-update-monitor-group-dialog.component.css']
})
export class AddUpdateMonitorGroupDialogComponent {
  loading: boolean;
  availableSatellites = new Array<EntityDef>();
  availableTransponders = new Array<Transponder>();
  availableCarriers = new Array<Carrier>();
  selectedEntities = new Array<EntityDef>();

  loadingSelectionData = false;
  carriersLoading: boolean;
  selectedSatellite: EntityDef;
  selectedTransponder: Transponder;
  selectedCarrier: Carrier;
  selectAllTransponders = false;
  selectAllCarriers = false;
  transponderLoading = false;
  satelliteLoading = false;

  monitorGroup = new MonitorGroup();
  addingNewGroup = false;

  @ViewChild('updateButton') public updateButton: ElementRef;
  @ViewChild('addButton') public addButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;

  private satSub: Subscription;
  private xpdrSub: Subscription;
  private carSub: Subscription;

  constructor(public utilityService: AppUtilities, public dataService: DataViewDemoService, public userService: UserService) {
    this.getSatellites();
  }

  addTransponderToMonitorGroup(event, entity) {
    let xpn = this.availableTransponders.find(xpn => {
      return xpn.identifier == entity.value.identifier;
    });
    this.selectEntity(xpn);
  }

  addCarrierToMonitorGroup(event, entity) {
    let car = this.availableCarriers.find(car => {
      return car.identifier == entity.value.identifier;
    });
    this.selectEntity(car);
  }

  selectEntity(entity) {
    entity.selected = true;
    let idx = this.selectedEntities.findIndex(ent => {
      return ent.identifier == entity.identifier;
    });
    if (idx == -1)
      this.selectedEntities.push(entity);
    else
      this.utilityService.showError('Cannot Add to Group', entity.name + ' has already been added to the group');
  }

  removeEntity(entity) {
    this.removeEntityFromSelected(entity.value.identifier);
  }

  private removeEntityFromSelected(identifier) {
    let idx = this.selectedEntities.findIndex(entity => {
      return entity.identifier == identifier;
    });
    if (idx > -1) {
      this.selectedEntities[idx].selected = false;
      this.selectedEntities.splice(idx, 1);
    }
  }

  removeAllTransponders() {
    for (let i = 0; i < this.availableTransponders.length; i++) {
      this.removeEntityFromSelected(this.availableTransponders[i].identifier);
    }
  }

  addAllTransponders() {
    this.availableTransponders.forEach(xpdr => {
      this.selectEntity(xpdr);
    });
  }

  removeAllCarriers() {
    for (let i = 0; i < this.availableCarriers.length; i++) {
      this.removeEntityFromSelected(this.availableCarriers[i].identifier);
    }
  }

  addAllCarriers() {
    this.availableCarriers.forEach(car => {
      this.selectEntity(car);
    });
  }

  getCarriersForTransponder(transponder: Transponder, selectWhenDone, concat = false) {
    let data = {} as any;
    data.satelliteId = transponder.satelliteName;
    data.transponderId = transponder.identifier;
    this.carSub = this.dataService.performEntitiesOperation(EntityType.Carrier, EntityOperation.GetList, data).subscribe((results) => {
      this.carriersLoading = false;
      if (results.success) {
        if (concat && this.availableCarriers != null) {
          this.availableCarriers = this.availableCarriers.concat(JSON.parse(results.data));
        } else {
          this.availableCarriers = JSON.parse(results.data);
        }
        this.availableCarriers.forEach((car) => {
          car.transponderName = transponder.identifier;
          car.satelliteName = transponder.satelliteName;
          if (selectWhenDone) {
            car.selected = true;
          }
        });
      } else {
        this.utilityService.showError('Error Retrieving Carriers', results.responseMsg);
      }
    }, (error) => {
      this.carriersLoading = false;
      this.utilityService.showError('Error Retrieving Carriers for Transponder', error.statusText);
    });

  }
  addOrUpdate(entityOp, resolve) {
    this.loading = true;
    let data = {} as any;
    this.monitorGroup.items = [];
    this.selectedEntities.forEach(entity => {
      let newItem = new MonitorGroupItem();
      if (entity.hasOwnProperty('transponderName')) {
        let car = entity as Carrier;
        newItem.satellite_id = car.satelliteName;
        newItem.transponder_id = car.transponderName;
        newItem.carrier_id = car.name;
      } else if (entity.hasOwnProperty('satelliteName')) {
        let xpdr = entity as Transponder;
        newItem.satellite_id = xpdr.satelliteName;
        newItem.transponder_id = xpdr.identifier;
      }

      this.monitorGroup.items.push(newItem);
    });
    this.monitorGroup.profile_id = this.userService.getCurrentUser().profile_id;
    data.data = this.monitorGroup;
    data.monitorGroupId = this.monitorGroup.monitor_group_id;
    this.dataService.performEntityOperation(EntityType.MonitorGroup, entityOp, data).subscribe(
      (result) => {
        this.loading = false;
        if (result.success) {
            resolve(JSON.parse(result.data));
        } else {
          this.utilityService.showError('Error Saving Monitor Group', result.responseMsg);
        }
      },
      (err) => {
        this.loading = false;
        this.utilityService.showError('Error Saving Monitor Group', err);
      }
    );
  }
  update() {
    this.addOrUpdate(EntityOperation.Update, null);
  }
  selectTransponder(event) {
    this.selectAllCarriers = false;
    this.carriersLoading = true;
    this.getCarriersForTransponder(event.value, false);
  }
  selectSatellite(event) {
    this.transponderLoading = true;
    this.availableCarriers = [];
    this.getTransponders(event.value, false);
    this.selectAllTransponders = false;
  }
  getTransponders(satellite, selectWhenDone) {
    let data = {} as any;
    data.satelliteId = satellite.identifier;
    this.xpdrSub = this.dataService.performEntitiesOperation(EntityType.Transponder, EntityOperation.GetList, data).subscribe((results) => {
      this.transponderLoading = false;
      if (results.success) {
        this.availableTransponders = JSON.parse(results.data);
        this.availableTransponders.forEach((xpdr) => {
          xpdr.satelliteName = satellite.identifier;
          if (selectWhenDone) {
            xpdr.selected = true;
            this.getCarriersForTransponder(xpdr, true, true);
          }
        });
      } else {
        this.utilityService.showError('Error Retrieving Transponders', results.responseMsg);
      }
    }, (error) => {
      this.transponderLoading = false;
      this.utilityService.showError('Error Retrieving Transponders for Satellite', error.statusText);
    });
  }
  transponderChanged() {
    this.selectedCarrier = null;
  }
  getSatellites() {
    this.satelliteLoading = true;
    this.satSub = this.dataService.performEntitiesOperation(EntityType.Satellite, EntityOperation.GetList).subscribe((results) => {
      if (results.success) {
        this.availableSatellites = JSON.parse(results.data);
      } else {
        this.showError('Error Retrieving Satellites', results.responseMsg);
      }
      this.satelliteLoading = false;
    }, (error) => {
      this.satelliteLoading = false;
      this.utilityService.showError('Error Retrieving Satellites', error.statusText);
    });
  }

  private getExistingGroup(groupId) {
    this.loading = true;
    let data = {};
    (data as any).monitorGroupId = groupId;
    this.dataService.performEntityOperation(EntityType.MonitorGroup, EntityOperation.Get, data).subscribe((results) => {
      if (results.success) {
        this.monitorGroup = JSON.parse(results.data);
        this.monitorGroup.items.forEach((item) => {
          let newItem;
          if (item.carrier_id != null) {
            newItem = new Carrier();
            newItem.satelliteName = item.satellite_id;
            newItem.transponderName = item.transponder_id;
            newItem.name = item.carrier_id;
            newItem.identifier = item.carrier_id;
          } else if (item.transponder_id != null) {
            newItem = new Transponder();
            newItem.satelliteName = item.satellite_id;
            newItem.name = item.transponder_id;
            newItem.identifier = item.transponder_id;
          } else {
            newItem = new Satellite();
            newItem.name = item.satellite_id;
            newItem.identifier = item.satellite_id;
          }
          this.selectedEntities.push(newItem);
        })
      } else {
        this.utilityService.showError('Error Retrieving Selected Monitor Group', results.responseMsg);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.utilityService.showError('Error Retrieving Selected Monitor Group', error.statusText);
    });
  }

  open(monitorGroup = null): Promise<MonitorGroup> {
    this.addingNewGroup = true;
    if (monitorGroup != null) {
      this.getExistingGroup(monitorGroup.monitor_group_id);
      this.addingNewGroup = false;
    }
    return new Promise<MonitorGroup>(resolve => {
      setTimeout(() => {
        if (this.cancelButton != null) {
          this.cancelButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            resolve(null);
          });
        }
        if (this.addButton != null)
          this.addButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.addOrUpdate(EntityOperation.Create, resolve);
          });
        else
          this.updateButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.addOrUpdate(EntityOperation.Update, resolve);
          });
      });
    });
  }
  ngOnDestroy() {
    if (this.satSub != null)
      this.satSub.unsubscribe();
    if (this.xpdrSub != null)
      this.xpdrSub.unsubscribe();
    if(this.carSub != null)
      this.carSub.unsubscribe();
  }
  private showError(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'error';
    this.utilityService.showNotification(msg);
  }
  private showInfo(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'info';
    this.utilityService.showNotification(msg);
  }
}
