import { ChartMargins } from './pf-chart-margins';

export class ChartLabels extends ChartMargins {
  xAxisLabel: string;
  yAxisLabel: string;
  xLabelMargin: number;
  yLabelMargin: number;

  constructor(xAxisLabel?: string, yAxisLabel?: string) {
    super();

    this.xAxisLabel = xAxisLabel;
    this.yAxisLabel = yAxisLabel;
    this.xLabelMargin = 5;
    this.yLabelMargin = 20;

    this.left = this.yAxisLabel ? 50 : 30;
    this.bottom = this.xAxisLabel ? 40 : 20;
    this.top = 10;
    this.right = 10;
  }
}