﻿import { Axis, D3, Selection } from 'd3-ng2-service';
import { XYPoint } from '../../../chart/index';
import { WaterfallTrackingLine } from './pf-waterfall-tracking-line';

export class HorizontalWaterfallTrackingLine extends WaterfallTrackingLine {

  addElementToSvg(svg: any, currentHeight: number, currentWidth: number) {
    this.height = currentHeight;
    this.width = currentWidth - 20;
    if (!svg.select('#' + this.id).empty()) {
        svg.select('#' + this.id).remove();
    }
    this.lineElem = svg
      .append('g').attr('id', this.id);

    // need two line elements to allow a larger surface area for the user to grab and drag, but apply different CSS
    // so that the thick line does not obscure data until dragged
    this.lineElem.append('line')
        .attr('x1', 0)
        .attr('y1', this.height)
        .attr('x2', this.width)
        .attr('y2', this.height)
        .attr('class', 'pf-waterfall-tracking-line-midline pf-waterfall-tracking-line-midline-h')
      .on('mouseover', this.mouseOverFunc)
      .on('mouseleave', this.mouseLeaveFunc)
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
    this.lineElem.append('line')
        .attr('x1', 0)
        .attr('y1', this.height)
        .attr('x2', this.width)
        .attr('y2', this.height)
        .attr('class', 'pf-waterfall-tracking-line-h')
      .on('mouseover', this.mouseOverFunc)
      .on('mouseleave', this.mouseLeaveFunc)
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
  }
  dragStart() {
      this.lineElem.select('.pf-waterfall-tracking-line-h').attr('class', 'pf-waterfall-tracking-line-h pf-waterfall-tracking-line-drag');
  }
  dragEnd() {
      this.lineElem.select('.pf-waterfall-tracking-line-drag').attr('class', 'pf-waterfall-tracking-line-h');
  }
  dragging(newY: number) {
      if (newY >= this.height) {
          newY = this.height;
      } else if (newY <= 0) {
          newY = 0;
      }
      this.lineElem.selectAll('line').attr('y1', newY)
      .attr('x1', 0)
      .attr('y2', newY)
      .attr('x2', this.width);
  }
  moveTrackingLineTo(newY) {
      this.lineElem.attr('display', null);
      this.lineElem.selectAll('line').attr('y1', newY)
          .attr('y2', newY)
          .attr('x1', 0)
          .attr('x2', this.width);
  }
  getTrackingLinePixelLocation(): number {
      return this.lineElem.select('line').attr('y1');
  }
}