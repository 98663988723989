import { MenuItem } from 'primeng/primeng';
import { EventEmitter, Output } from '@angular/core';
import { XYPoint } from '../../../chart/index';
import { Subject } from 'rxjs';

// this class handles the visual settings of the specan, but nothing data related. That is handled by the pf-specan-data-params class
export class SpectrumAnalyzerThumbnailConfiguration {
  numberOfYTicks: number = 10;
  numberOfXTicks: number = 10;
  //indicates the percentage of the domain that will be 'padded' above and below the trace on the y axis when autoscale is used
  yPercentageBuffer: number = .2;
  graticuleColor: string = '#c6c6c6';
  showLoadingOverlay: boolean;
  graphVisibilityChanged: Subject<boolean> = new Subject<boolean>();
  borderColorChanged: Subject<string> = new Subject<string>();
  private _graphVisible = true;
  private _numberOfTicks;
  private _borderColor;

  set graphVisible(value: boolean) {
    this._graphVisible = value;
    this.graphVisibilityChanged.next(value);
  }
  get graphVisible() {
    return this._graphVisible;
  }

  set borderColor(value: string) {
    this.borderColorChanged.next(value);
    this._borderColor = value;
  }

  get borderColor() {
    return this._borderColor;
  }
}
