import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { AppUtilities } from '../services/app-utilities';
import { UserService } from 'app/services/user-service';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {

  constructor(private authService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot) {
    return this.authService.checkUserAuthenticated().flatMap((response) => {
      if (response.success) {
        localStorage.removeItem('redirectUrl');
        return Observable.of(true);
      } else {
        localStorage.setItem('redirectUrl', state.url);
        this.authService.userLoggedInSource.next(false);
        return Observable.of(false);
      }
    });
  }
}
