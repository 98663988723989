import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs/Rx';
import { ServiceResponse, UserMessage } from '../models/index';
import { AppUtilities } from './app-utilities';
import { AuthToken, AuthResponse } from 'app/models/auth-response';
import { Subject } from 'rxjs/Subject';
import { User } from 'app/models/user';

import { AuthConfig } from 'angular2-jwt';
import { CookieManager } from 'app/models/cookie-manager';

@Injectable()
export class UserService {
  private endpoint: string;
  private currentUser = new User();
  private refreshingSession = false;
  userLoggedInSource = new BehaviorSubject<boolean>(false);
  userLoggedInObs = this.userLoggedInSource.asObservable();
  //showLoginSource = new BehaviorSubject<boolean>(true);
  //showLoginObs = this.showLoginSource.asObservable();
  constructor(private http: HttpClient, private cookieService: CookieManager) {
    this.endpoint = 'http://localhost:51191/api/';
  }

  initializeEndPoint(endpoint: string) {
    this.endpoint = endpoint;
  }
  login(username, pw): Observable<ServiceResponse> {
    let headers = new HttpHeaders();
    let body = [pw];
    headers.append('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    let options = {};
    (options as any).headers = headers;
    this.currentUser.name = username;
    return this.http.post(this.endpoint + 'users/' + username, body, options)
      .catch((error: any) => Observable.throw(error || 'Server error')).flatMap((response: ServiceResponse) => {
        if (response.success) {          
          this.setAuthFromResponse(response.data);
          this.userLoggedInSource.next(true);
        } else {
          this.userLoggedInSource.next(false);
        }
          return Observable.of(response);
      }) as Observable<ServiceResponse>;
  }
  private setAuthFromResponse(data) {
    let auth = JSON.parse(data) as AuthResponse;
    auth.token.retrieved_at = Date.now();
    auth.token.expires_in *= 1000;
    this.currentUser.profile_id = auth.profile_id;
    this.currentUser.account_id = auth.account_id;
    this.cookieService.setWithExpiryInYears('svu-auth', JSON.stringify(auth), 1);
  }
  changeUserPassword(username, oldpassword, newpassword): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
      
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post(this.endpoint + 'users/changepassword', { username: username, newPassword: newpassword, oldPassword: oldpassword }, options)
          .catch((error: any) =>
            Observable.throw(error || 'Server error')
        ) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }
  addNewUser(user): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
     
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post(this.endpoint + 'users/', user, options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }
  deleteUser(user): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
     
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post(this.endpoint + 'users/' + user.profileId + '/delete', user, options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }
  saveUserInfo(user:User): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
      
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post(this.endpoint + 'users/' + user.profile_id + '/update', user, options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }

  toggleUserDisabled(userId, disableUser): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
     
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post(this.endpoint + 'users/' + userId + '/toggledisable?disableUser=' + disableUser, null, options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }

  toggleUserLocked(userId, lockUser): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
      
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.post(this.endpoint + 'users/' + userId + '/togglelock?lockUser=' + lockUser, null, options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }

  getAllUsers(): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
      
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.get(this.endpoint + 'users/', options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }
  getUser(user:User): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
      
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        return this.http.get(this.endpoint + 'users/' + user.account_id, options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });
  }
  setCurrentUser(user:User) {
    this.currentUser = user;
  }
  getCurrentUser(): User {
    return this.currentUser;
  }
  getCurrentUserInfo(): Observable<ServiceResponse> {
    return this.checkUserAuthenticated().flatMap((response) => {
     
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        let userId = AuthToken.getAccountId(token);
        return this.http.get(this.endpoint + 'users/' + userId, options)
          .catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>;
      } else {
        return Observable.of(response);
      }
    });  
  }
  saveValueToUserSession(name, value) {
    return this.getCurrentUserInfo().flatMap((response) => {
      if (response.success) {
        let user;
        let userObj = JSON.parse(response.data);
        if (userObj != null) {
          user = userObj.details;
          user[name] = value;
        }
        return this.saveUserInfo(userObj);
      } else {
        return Observable.of(response);
      }
    }).catch((error: any) => Observable.throw(error || 'Server error')) as Observable<ServiceResponse>; 
  }
  checkIfCurrentUserIsAdmin() {
    return this.checkUserAuthenticated().flatMap((response) => {
     
      if (response.success) {
        let headers = new HttpHeaders();
        let token = response.data as AuthToken;
        headers = headers.append('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.append("Authorization", "Bearer " + token.access_token);
        let options = {};
        (options as any).headers = headers;
        let auth = JSON.parse(this.cookieService.get('svu-auth')) as AuthResponse;
        let userId = auth.account_id;
        let userRoles = AuthToken.getTokenInformation(token).roles;
        if (userRoles.length > 0) {
          for (let i = 0; i < userRoles.length; i++) {
            if (userRoles[i] == 'Administrator') {
              return Observable.of(true);
            }
          };
          return Observable.of(false);
        } else {
          return Observable.of(false);
        }
      } else {
        return Observable.of(false);
      }
    });  
  }
  logout() {
    this.cookieService.remove();
    this.userLoggedInSource.next(false);
  }
  checkUserAuthenticated(): Observable<ServiceResponse> {
    let authCookie = this.cookieService.get('svu-auth');
    if (authCookie != '') {
      let auth = JSON.parse(authCookie) as AuthResponse;
      let needToRefresh = AuthToken.isTokenAboutToExpire(auth.token);
      if (needToRefresh && !this.refreshingSession) {
        this.refreshingSession = true;
        return this.refreshSession();
      } else {
        let response = new ServiceResponse();
        response.success = true;
        response.data = auth.token;
        this.currentUser.profile_id = auth.profile_id;
        this.currentUser.account_id = auth.account_id;
        return Observable.of(response);
      }
    } else {
      let response = new ServiceResponse();
      response.success = false;
      return Observable.of(response);
    }     
  }
  getToken() {
    let authCookie = this.cookieService.get('svu-auth');
    if (authCookie != '') {
      let auth = JSON.parse(authCookie) as AuthResponse;
      return auth.token.access_token;
    }
  }
  refreshSession(): Observable<ServiceResponse>  {
    let auth = JSON.parse(this.cookieService.get('svu-auth')) as AuthResponse;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    let options = {};
    (options as any).headers = headers;
    return this.http.post(this.endpoint + 'users/refreshsession', auth.token, options).flatMap((response: ServiceResponse) => {
      this.refreshingSession = false;
      if (response.success) {
        this.setAuthFromResponse(response.data);
        let auth = JSON.parse(this.cookieService.get('svu-auth')) as AuthResponse;
        response.data = auth.token;
        this.userLoggedInSource.next(true);
      } else {
        this.userLoggedInSource.next(false);
      }
      return Observable.of(response);
    });
  }
}

