import { EntityAlias } from "./entity-alias";


export class Satellite {
  constructor(
    public satellite_id?: string,
    public name?: string,
    public aliases?: EntityAlias[],
    public norad_id?: number,
    public cospar_id?: string,
    public object_type?: string,
    public active?: boolean,
    public longitude?: number,
    public inclination?: number,
    public bus_type?: string[],
    public altitude_classification?: string
  ) {
    this.aliases = [];
    this.bus_type = [];
  }
  static copy(existingItem) {
    let newObj = JSON.parse(JSON.stringify(existingItem));
    if (newObj.aliases == null) {
      newObj.aliases = [];
    }
    if (newObj.bus_type == null) {
      newObj.bus_type = [];
    }
    return newObj;
  }
}

