import { EntityAlias } from "./entity-alias";
import { RangedProperty } from "./ranged-property";


export class Antenna {
  constructor(
    public site_id?: string,
    public antenna_id?: string,
    public name?: string,
    public aliases?: EntityAlias[],
    public antenna_types?: string[],
    public longitude?: number,
    public latitude?: number,
    public altitude?: number,
    public size?: number,
    public azimuth?: RangedProperty,
    public elevation?: RangedProperty,
    public polarity?: RangedProperty,
    public active?: boolean,
    public fixed?: boolean,
    public receive?: boolean,
    public transmit?: boolean,
    public frequency_blocks?:FrequencyBlock[]
  ) {
    this.azimuth = new RangedProperty();
    this.elevation = new RangedProperty();
    this.polarity = new RangedProperty();
    this.frequency_blocks = new Array<FrequencyBlock>();
    this.antenna_types = [];
    this.aliases = [];
  }
  static copy(existingItem) {
    let newObj = JSON.parse(JSON.stringify(existingItem));
    if (newObj.azimuth == null) {
      newObj.azimuth = new RangedProperty();
    }
    if (newObj.elevation == null) {
      newObj.elevation = new RangedProperty();
    }
    if (newObj.polarity == null) {
      newObj.polarity = new RangedProperty();
    }
    if (newObj.frequency_blocks == null) {
      newObj.frequency_blocks = new Array<FrequencyBlock>();
    }
    return newObj;
  }
}
export class FrequencyBlock {
  display_name: string;
  antenna_id: string;
  frequency_block_id: string;
  polarity: string;
  LNB: number;
  stop: number;
  start: number;
  band_id: string;
  band: string;
}
