import { Component, ElementRef, ViewChild } from '@angular/core';
import { FrequencyBlock } from '../../models/antenna';
import { UnitConversion, UnitOptions } from 'app/components/graphs/unit';

@Component({
  selector: 'freq-block-dialog.',
  templateUrl: './freq-block-dialog.component.html',
  styleUrls: ['./freq-block-dialog.component.css']

})
export class FreqBlockDialogComponent {
  newFreqBlock = new FrequencyBlock();
  updating = false;
  mhzUnits: UnitConversion;
  @ViewChild('okButton') public okButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;

  constructor() {
    this.mhzUnits = new UnitConversion(UnitOptions.Hz, UnitOptions.MHz,
      [UnitOptions.kHz, UnitOptions.MHz, UnitOptions.GHz]);
  }

  open(existingItem: FrequencyBlock): Promise<FrequencyBlock> {
    if (existingItem != null) {
      this.newFreqBlock = JSON.parse(JSON.stringify(existingItem));
      this.updating = true;
    } else {
      this.newFreqBlock = new FrequencyBlock();
    }
    return new Promise<FrequencyBlock>((resolve, reject) => {
      this.cancelButton.nativeElement.onclick = ((e: any) => {
        e.preventDefault();
        resolve(null);
      });
      this.okButton.nativeElement.onclick = ((e: any) => {
        e.preventDefault();
        resolve(this.newFreqBlock);
      });
    });
  }
}
