import { UUID } from 'angular2-uuid';
import { D3 } from 'd3-ng2-service';
import { Subscription } from 'rxjs/Rx';

import {
  SvgFocusTracking, SvgLegend, SvgTooltip, SvgTooltipConfig, SvgZoom, XYPoint,
  CanvasGraphElementModel, CanvasGraphElementRenderer, SvgMarker} from '../../chart/index';
import { SpectrumAnalyzerAxes } from './axes/pf-specan-axes';
import { SpectrumAnalyzerConfiguration } from './configuration/pf-specan-config';
import { SpectrumAnalyzerDataParameters } from './configuration/pf-specan-data-params';
import { CarrierRegion } from './data-models/pf-specan-carrier-region';
import { SpectrumAnalyzerSeries } from './data-models/pf-specan-series';
import { SpectrumAnalyzerXY } from './data-models/pf-specan-xy';
import { SpectrumAnalyzerCarrierRegionElement } from './graph-elements/pf-specan-carrier-region-element';
import { SpectrumAnalyzerFrequencyLine } from './graph-elements/pf-specan-frequency-line';
import { SpectrumAnalyzerTemplateVariables } from './pf-specan-html-template-vars';
import { SpectrumAnalyzerRenderer } from './render/pf-specan-renderer';
import { SpectrumAnalyzerUtility } from './utilities/pf-specan-utility';
import { MarkerShape } from '../../chart/pf-chart/marker/pf-svg-marker-shape';
import { SpectrumAnalyzerUnits } from '../index';

export class SpectrumAnalyzer {
  config: SpectrumAnalyzerConfiguration;
  dataParams: SpectrumAnalyzerDataParameters;
  dataModels: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();
  units: SpectrumAnalyzerUnits = new SpectrumAnalyzerUnits();
  carrierRegions: CarrierRegion[] = new Array<CarrierRegion>();
  graphElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();

  // this is our connection to the enclosing component. Rather than directly exposing elements
  // of the renderer and axes to the html template,
  // we will use this object to set the variables accordingly and use them in this component and in the html
  htmlTemplateVars: SpectrumAnalyzerTemplateVariables;

  // the parent specan.component needs access to these
  graphContainer: any;
  // this should be a pointer to a function
  onSampleFreqChanged: any;

  // function pointers
  mouseMoveFunc: any;
  mouseLeaveFunc: any;
  scrollZoomFunc: any;
  brushEndedFunc: any;
  gridDimensions: any;
  mouseIsInsideSvg: boolean;

  // private graph elements
  private axes: SpectrumAnalyzerAxes;
  private zoom: SvgZoom;
  private tooltip: SvgTooltip;
  private focusTracking: SvgFocusTracking;
  private legend: SvgLegend;
  private frequencyLine: SpectrumAnalyzerFrequencyLine;
  private renderer: SpectrumAnalyzerRenderer;
  private graphElementRenderer: CanvasGraphElementRenderer;

  // currentPath is the representation of the current data model
  private currentPath: SpectrumAnalyzerSeries;

  // todo: implement markers later
  //  this is the container for markers that the user might add to the graph
  // private markerContainer: any;
  // private graphMarkers: SpectrumAnalyzerMarker[] = new Array<SpectrumAnalyzerMarker>();

  // svg refers to the entire graph object
  // svgElementsInFrontOfCanvasContainer is the inner container that holds all elements that should be placed
  // in front of the canvas. We need this container because if we were to put the canvas in the very front,
  // we would have no access to mouse events.
  // Additionally, we need an axis container (since it will be larger than the canvas and the scaling will be off,
  // we can't use it for elements such as a carrier region)
  // svgElementsBehindCanvasContainer contains things the same size and scale as the canvas, but that need to be shown behind
  // the traces themselves so as not to obscure them
  // including the axis that all other components get appended to
  private svgAxisContainer;
  private svgElementsInFrontOfCanvasContainer;
  private context: any;
  private canvas: any;
  private svgElementsBehindCanvasContainer;
  private markers = new Array<SvgMarker>();

  //  this means we shouldn't refresh the svg if the user is dragging something
  private userInteractingWithGraph: boolean;

  //  a value keeping track of whether we are in a state where no paths are visible
  //  i.e., if none are visible, we need to hide the tooltip
  private noPathsVisible: boolean;

  //  this variable tells us whether we have already initialized the data that needs to be captured on the first render
  private graphDataInitialized: boolean;
  private currentX: number;
  private currentY: number;
  // this variable is determined by whether min/max x/y coords were given to the graph
  // we want to use these for rendering until the user performs an action that changes the min/max y of the graph
  private usingMinMaxXY: boolean;
  // need a function pointer for this to call the parent com
  private sampleFreqValueChangedFunc: any;

  //  these are internal values independent from the input values
  //  they have to be independent because this is more of a state value (whether the graph is in a state
  //  where it shows or not) whereas the config value may never change
  private tooltipVisible = true;
  private focusTrackingVisible = true;

  // hold the current graph scales and domains
  private scaleY: any;
  private scaleX: any;
  private transformedScaleY: any;
  private transformedScaleX: any;
  private domainX: any;
  private maxY: number;
  private minY: number;
  private maxX: number;
  private minX: number;

  private spanChangedSub: Subscription;
  private borderColorChangedSub: Subscription;
  private centerFreqChangedSub: Subscription;
  private numberOfTicksChangedSub: Subscription;


  //  these should only be used as a last resort. Colors should be provided as properties on each dataset object
  private extraColors = ['#731b36', '#f4bf42', '#41b8f4', '#6bd6ad', '#00E5EE', '#00868B', '#97FFFF',
    '#7FFFD4', '#FF4040', '#800000', '#C67171'];

  constructor(private d3: D3) {

    this.zoom = new SvgZoom(this.d3);
    this.tooltip = new SvgTooltip(this.d3);
    this.focusTracking = new SvgFocusTracking(this.d3);
    this.frequencyLine = new SpectrumAnalyzerFrequencyLine(this.d3);
    this.legend = new SvgLegend(this.d3);
    this.renderer = new SpectrumAnalyzerRenderer(this.d3);
    this.axes = new SpectrumAnalyzerAxes(this.d3);
    this.graphElementRenderer = new CanvasGraphElementRenderer(this.d3);

  }

  initializeSubscriptions() {
    // event handlers
    this.spanChangedSub = this.dataParams.spanChanged.subscribe((value) => {
      if (this.htmlTemplateVars.graphInitialized && this.htmlTemplateVars.graphSpan !== value) {
        this.graphSpanChanged(value);
        if (this.dataParams.spanRbwRatio != null) {
          this.dataParams.graphRBW = value * this.dataParams.spanRbwRatio;
        }
      }
    });
    this.borderColorChangedSub = this.config.borderColorChanged.subscribe((value) => {
      if (this.svgElementsInFrontOfCanvasContainer != null) {
        this.renderer.changeBorderColor(this.svgElementsInFrontOfCanvasContainer, value);
      }
    });
    this.centerFreqChangedSub = this.dataParams.centerFreqChanged.subscribe((value) => {
      if (this.htmlTemplateVars.graphInitialized && this.htmlTemplateVars.graphCenterFreq !== value) {
        this.centerFreqChanged(value);
      }
    });
    this.numberOfTicksChangedSub = this.config.numberOfTicksChanged.subscribe((value) => {
      this.axes.setTicks(value);
    });

  }

  initialize() {

    // make initial assignments
    if (this.config.showOnlyGraphWindow) {
      this.config.showSampleFreq = false;
    }

    this.initRenderer();
    if (this.containerDimensionsAreGreaterThanZero()) {
      this.initAxisVars();

      // set initial scales
      this.setInitialScales();

      this.initGraphElements();
      this.appendSvg();
      this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;

      //const timeout = setTimeout(() => {
      this.createAxes(true);
      this.htmlTemplateVars.specanContainerStyle = { 'height': this.renderer.attributes.outerGraphHeight + 'px' };
      this.alignCanvasAndSvgWithAxes();
      // clearTimeout(timeout);
      // });
    }
  }

  containerDimensionsAreGreaterThanZero() {
    return this.renderer.attributes.innerGraphHeight > 0 && this.renderer.attributes.innerGraphHeight > 0 &&
      this.renderer.attributes.innerGraphWidth > 0 && this.renderer.attributes.innerGraphWidth > 0;
  }

  setInitialScales() {
    if (this.dataParams.dbPerDiv != null) {
      let ratioOfInnerToOuterHeight = (this.renderer.attributes.outerGraphHeight / this.renderer.attributes.innerGraphHeight);
      let ratioOfInnerToOuterWidth = (this.renderer.attributes.outerGraphWidth / this.renderer.attributes.innerGraphWidth);

      let scaleYdomain = (this.dataParams.dbPerDiv * this.config.numberOfTicks) * ratioOfInnerToOuterHeight;
      let transformedScaleYdomain = (this.dataParams.dbPerDiv * this.config.numberOfTicks);

      this.scaleY = this.d3.scaleLinear().domain([0, scaleYdomain])
        .range([this.renderer.attributes.innerGraphHeight, 0]);
      this.scaleX = this.d3.scaleLinear().domain([0, this.renderer.attributes.outerGraphWidth])
        .range([0, this.renderer.attributes.innerGraphWidth]);
      this.transformedScaleX = this.d3.scaleLinear().domain([0, this.renderer.attributes.innerGraphWidth])
        .range([this.renderer.attributes.innerGraphHeight, 0]);
      this.transformedScaleY = this.d3.scaleLinear().domain([0, transformedScaleYdomain])
        .range([0, this.renderer.attributes.innerGraphWidth]);
    } else {
      this.scaleY = this.d3.scaleLinear().domain([0, this.renderer.attributes.outerGraphHeight])
        .range([this.renderer.attributes.innerGraphHeight, 0]);
      this.scaleX = this.d3.scaleLinear().domain([0, this.renderer.attributes.outerGraphWidth])
        .range([0, this.renderer.attributes.innerGraphWidth]);
      this.transformedScaleX = this.d3.scaleLinear().domain([0, this.renderer.attributes.innerGraphHeight])
        .range([this.renderer.attributes.innerGraphHeight, 0]);
      this.transformedScaleY = this.d3.scaleLinear().domain([0, this.renderer.attributes.innerGraphWidth])
        .range([0, this.renderer.attributes.innerGraphWidth]);
    }
  }

  graphSpanChanged(newSpan = null) {
    // we need to check if the type is a number because the htmlTemplateVars value is the ngModel of the pf unit input in the html template
    // thus, if this gets called from the html by the user entering a value, that model will already equal the correct value
    // however, if it is called programmatically from the enclosing component, we need to manually update it
    if (typeof newSpan === "number") {
      this.htmlTemplateVars.graphSpan = newSpan;
    }
    const newDomain = SpectrumAnalyzerUtility.calculateNewDomain(this.htmlTemplateVars.graphSpan, this.transformedScaleX.domain());

    //  don't need to change the y domain
    this.setScaling(this.transformedScaleY.domain(), newDomain);
    this.renderer.spanChanged(this.svgElementsBehindCanvasContainer, newSpan, this.transformedScaleX, this.transformedScaleY);
    this.renderer.spanChanged(this.svgElementsInFrontOfCanvasContainer, newSpan, this.transformedScaleX, this.transformedScaleY);
    this.renderer.spanChanged(this.svgAxisContainer, newSpan, this.transformedScaleX, this.transformedScaleY);
    this.render();
  }

  scrollZoom(direction: number) {
    const d3 = this.d3;
    let newDomainX = this.transformedScaleX.domain();
    let newDomainY = this.transformedScaleY.domain();
    if (this.htmlTemplateVars.zoomOnXAxis) {
      newDomainX = SpectrumAnalyzerUtility.zoomDomainByPercentage(direction, newDomainX, .05);
    }
    if (this.htmlTemplateVars.zoomOnYAxis) {
      newDomainY = SpectrumAnalyzerUtility.zoomDomainByPercentage(direction, newDomainY, .05);
    }
    this.setScaling(newDomainY, newDomainX);
    //  reset the zoom translate
    this.zoom.resetTranslate(this.svgElementsInFrontOfCanvasContainer);
    this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
  }

  toggleZoom(zoomMode: boolean) {
    this.htmlTemplateVars.zoomMode = zoomMode;
    if (!this.htmlTemplateVars.zoomMode) {
      this.tooltipVisible = true;
      this.focusTrackingVisible = true;
      this.zoom.removeBrush(this.svgElementsInFrontOfCanvasContainer);
    } else {
      this.tooltipVisible = false;
      this.focusTrackingVisible = false;
      this.zoom.appendZoom(this.svgElementsInFrontOfCanvasContainer, this.brushEndedFunc,
        this.getInnerGraphWidth(), this.getInnerGraphHeight());
    }
  }

  rbwValueChange(newRBW: number = null) {
    if (typeof newRBW === "number" && newRBW != null) {
      this.dataParams.graphRBW = newRBW;
    }
  }

  vbwValueChange(newVBW: number = null) {
    if (typeof newVBW === "number" && newVBW != null) {
      this.dataParams.graphVBW = newVBW;
    }
  }

  centerFreqChanged(newCF: number = null) {
    if (typeof newCF === "number") {
      this.htmlTemplateVars.graphCenterFreq = newCF;
    }
    //  get the current x and y axis domains
    const domainY = this.transformedScaleY.domain();
    const domainX = this.transformedScaleX.domain();

    //  change the x domain to reflect the specified center freq and current span
    const spanDelta = this.htmlTemplateVars.graphSpan / 2;

    //  apply the new changes
    domainX[0] = this.htmlTemplateVars.graphCenterFreq - spanDelta;
    domainX[1] = this.htmlTemplateVars.graphCenterFreq + spanDelta;

    //  apply the changes
    //  don't need to change the y domain
    this.setScaling(domainY, domainX);
    this.render();
  }

  moveCanvasX(direction) {
    //  just take 1/20 of the current window and move that much
    //  sign of 'direction' indicates left or right
    const newDomain = SpectrumAnalyzerUtility.moveDomain(direction, this.transformedScaleX.domain(), 20);
    this.setScaling(this.transformedScaleY.domain(), newDomain);
    this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
    //  reset the zoom translate
    this.zoom.resetTranslate(this.svgElementsInFrontOfCanvasContainer);
  }
  private calculateAutoscaleScalesAndSet() {
    let newDomainY = this.calculateDomainY();
    const marginY = .03 * Math.abs(newDomainY[1] - newDomainY[0]);
    newDomainY[0] -= marginY;
    newDomainY[1] += marginY;
    this.setScaling(newDomainY, this.calculateDomainX());
  }

  autoscaleGraph() {
    if (this.graphDataInitialized) {
      this.dataParams.removeDbPerDivSetting();
      this.calculateAutoscaleScalesAndSet();
      this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
    }
  }

  getScaledMouseCoords(posX: number, posY: number) {
    const x = +this.transformedScaleX.invert(this.currentX).toFixed(this.config.roundXToDecimal);
    const y = +this.transformedScaleY.invert(this.currentY).toFixed(2);
    return [x, y];
  }

  setCurrentMouseCoords(pos: [number, number]) {
    this.currentX = pos[0];
    this.currentY = pos[1];
  }

  isMouseInsideGraph(): boolean {
    return (this.currentX > 0 && this.currentY >
      0 && this.currentX <= this.gridDimensions.width
      && this.currentY <= this.gridDimensions.height);
  }

  moveCanvasY(direction) {
    const x = this.transformedScaleX;
    const y = this.transformedScaleY;
    const xDomain = [0, this.renderer.attributes.innerGraphWidth].map((x as any).invert, this.transformedScaleX);
    let yDomain = [this.renderer.attributes.innerGraphHeight, 0].map((y as any).invert, this.transformedScaleY);
    yDomain = SpectrumAnalyzerUtility.moveDomain(direction, yDomain as [number, number], 20);
    this.setScaling(yDomain, xDomain);
    this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
    //  reset the zoom translate
    this.zoom.resetTranslate(this.svgElementsInFrontOfCanvasContainer);
  }

  destroy() {
    this.borderColorChangedSub.unsubscribe();
    this.centerFreqChangedSub.unsubscribe();
    this.numberOfTicksChangedSub.unsubscribe();
    this.spanChangedSub.unsubscribe();
  }

  resize() {
    this.renderer.resize();
    this.htmlTemplateVars.specanContainerStyle = { 'height': (this.renderer.attributes.outerGraphHeight) + 'px' };
    if (this.htmlTemplateVars.graphInitialized) {
      this.setScaling(this.calculateDomainY(), this.domainX);
      this.refreshGraph();
    } else {
      this.appendSvg();
      this.setInitialScales();
      this.createAxes(true);
      this.alignCanvasAndSvgWithAxes();
      this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;
    }
  }

  // can be called if graph attributes have been changed from a parent component (i.e. tooltip visibility)
  refreshGraph() {
    this.graphDataInitialized = false;
    this.removeSvg();
    this.svgElementsInFrontOfCanvasContainer = null;
    this.svgElementsBehindCanvasContainer = null;
    this.svgAxisContainer = null;
    this.tooltip.reset();
    this.frequencyLine.reset();
    this.legend.reset();
    this.axes.reset();
    this.initAxisVars();
    this.zoom.initialize(this.scrollZoomFunc, this.htmlTemplateVars.graphId);
    this.appendSvg();
    this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;
    this.initGraphScalingAndElements();
    this.createAxes(false);
    this.axes.zoom(this.transformedScaleX, this.transformedScaleY, this.dataParams.dbPerDiv);
    this.alignCanvasAndSvgWithAxes();
    this.renderer.addMouseEvents(this.svgElementsInFrontOfCanvasContainer, this.mouseMoveFunc, this.mouseLeaveFunc);
    this.renderer.addGraphBorder(this.svgElementsInFrontOfCanvasContainer);
    this.resetDataModels();
    this.resetMarkers();
    this.renderGraphData();
    this.domainX = this.calculateDomainX();
    this.addCarrierRegions();
  }

  private resetDataModels() {
    for (let i = 0; i < this.dataModels.length; i++) {
      this.dataModels[i].graphInfoInitialized = false;
    }
  }

  render(): void {
    this.createDataModels();
    this.renderGraphData();
  }

  setFrequencyLine(frequency) {
    const newX = this.transformedScaleX(frequency);
    if (!isNaN(newX)) {
      this.frequencyLine.setFrequencyLine(newX);
    }
  }
  removeSvg() {
    if (this.graphContainer != null) {
      this.d3.select(this.graphContainer.nativeElement).selectAll('svg').remove();
    }
  }
  zoomToExtent() {
    this.usingMinMaxXY = false;
    this.domainX = this.calculateDomainX();
    let domainY;
    domainY = this.calculateDomainY();
    const marginY = .03 * Math.abs(domainY[1] - domainY[0]);
    domainY[0] -= marginY;
    domainY[1] += marginY;
    this.setScaling(domainY, this.domainX);
    this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
  }

  zoomToDomain(xDomain, yDomain) {
    if (xDomain == null) {
      xDomain = this.calculateDomainX();
    }
    if (yDomain == null) {
      yDomain = this.calculateDomainY();
    }
    this.setScaling(yDomain, xDomain);
    //  this removes the translucent zoom selection area
    this.zoom.removeZoomSelectionArea(this.svgElementsInFrontOfCanvasContainer);
    this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
  }

  scaleValueByX(val) {
    return (this.transformedScaleX as any).invert(val).toFixed(this.config.roundXToDecimal);
  }

  getOuterGraphHeight(): number {
    return this.renderer.attributes.outerGraphHeight;
  }

  getOuterGraphWidth(): number {
    return this.renderer.attributes.outerGraphWidth;
  }

  getInnerGraphHeight(): number {
    return this.renderer.attributes.innerGraphHeight;
  }

  getInnerGraphWidth(): number {
    return this.renderer.attributes.innerGraphWidth;
  }

  setCurrentPath(model: SpectrumAnalyzerSeries) {
    this.currentPath = model;
  }

  showTooltipAtCurrentMousePosition() {
    if (this.config.showTooltip) {
      let pos;
      if (!this.config.floatingTooltip) {
        pos = this.getCurrentPointOnPath();
      } else {
        pos = new SpectrumAnalyzerXY(null, this.currentX, this.currentY);
      }
      this.showTooltipAtPosition(pos);
    }
  }

  showTooltipAtPosition(position) {
    if (position != null && this.tooltipVisible) {
      const scaledCoords = new SpectrumAnalyzerXY();
      scaledCoords.x = this.transformedScaleX.invert(position.x);
      scaledCoords.y = this.transformedScaleY.invert(position.y);
      const text = this.config.tooltipValueFunc(scaledCoords);
      this.tooltip.showTooltipAtPosition(position, text);
    }
  }

  showFocusTrackingAtCurrentMousePosition() {
    if (this.config.showFocusTracking && this.focusTrackingVisible && this.currentX != null && this.currentY != null) {
      this.focusTracking.showFocusTrackingAtPosition(new SpectrumAnalyzerXY(null,
        this.currentX, this.currentY));
    }
  }

  removeMarker(markerName: string): string {
    let markerId;
    let idx = this.markers.findIndex((marker) => {
      return marker.name == markerName;
    });
    if (idx > -1) {
      markerId = this.markers[idx].id;
      this.markers[idx].reset();
      this.markers.splice(idx, 1);
    }
    return markerId;
  }

  addNewMarker(pathName: string, markerName: string, markerText: string[], shape: MarkerShape): SvgMarker {
    if (this.currentPath != null) {
      let pos = this.getCurrentPointOnPath(pathName);
      let newMarker = new SvgMarker(this.d3, this.markerMouseover, this.markerMouseleave, this.markerDragStart, this.markerDragged, this.markerDragEnd);
      newMarker.addMarkerElementToSvg(this.svgElementsInFrontOfCanvasContainer, this.renderer.attributes.innerGraphHeight, this.renderer.attributes.innerGraphWidth,
        shape, pathName, markerName);
      if (pos != null) {
        if (markerText == null || markerText.length == 0)
          markerText = this.getMarkerText(newMarker, pos);
        newMarker.showMarkerAtPosition(this.transformedScaleX, this.transformedScaleY, pos, markerText);
      }
      newMarker.markerMoved.subscribe(() => {
        this.moveMarkerTo(newMarker, new SpectrumAnalyzerXY(null, this.transformedScaleX(newMarker.x), this.transformedScaleY(newMarker.y)));
      });
      this.markers.push(newMarker);
      return newMarker;
    }
    return null;
  }

  private addMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      let marker = this.markers[i];
      let pos = this.getNewYCoord(marker.pathDataSetName, marker.x);
      if (pos != null) {
        marker.showMarkerAtPosition(this.transformedScaleX, this.transformedScaleY, pos, marker.markerText);
      }
    }
  }
  resetMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      let marker = this.markers[i];
      marker.addMarkerElementToSvg(this.svgElementsInFrontOfCanvasContainer, this.renderer.attributes.innerGraphHeight, this.renderer.attributes.innerGraphWidth,
        marker.markerShape, marker.pathDataSetName, marker.name);
    }
  }

  removeBrush() {
    //  this removes the translucent zoom selection area
    this.zoom.removeZoomSelectionArea(this.svgElementsInFrontOfCanvasContainer);
  }

  getCurrentDomainX() {
    return this.transformedScaleX.domain();
  }

  getCurrentDomainY() {
    return this.transformedScaleY.domain();
  }

  zoomToCoordinates(xCoords, yCoords) {
    let newXDomain, newYDomain;
    if (xCoords != null) {
      newXDomain = xCoords.map(this.transformedScaleX.invert, this.transformedScaleX);
    }
    if (yCoords != null) {
      newYDomain = yCoords.map(this.transformedScaleY.invert, this.transformedScaleY);
    }
    this.zoomToDomain(newXDomain, newYDomain);
  }

  dbDivChanged(value) {
    this.axes.changeDbDivVal(this.dataParams.dbPerDiv);
    const newDomainY = this.determineNewDomainForDbDiv(this.transformedScaleY.domain(), this.config.numberOfTicks);
    this.setScaling(newDomainY);
    this.zoomToDomain(null, newDomainY);
  }

  hideFocusTracking() {
    this.focusTracking.hide();
  }

  hideTooltip() {
    this.tooltip.hide();
  }

  noDataPathsAreVisible() {
    return this.noPathsVisible;
  }

  setScaling(domainY, domainX = null) {
    if (domainX == null) {
      domainX = this.calculateDomainX();
    }
    if (domainY == null) {
      domainY = this.calculateDomainY();
    }
    if (this.dataParams.hasDbPerDivBeenSet()) {
      domainY = this.determineNewDomainForDbDiv(domainY, this.config.numberOfTicks);
    }
    this.scaleY = this.d3.scaleLinear().domain(domainY).range([this.renderer.attributes.outerGraphHeight, 0]);
    this.scaleX = this.d3.scaleLinear().domain(domainX).range([0, this.renderer.attributes.outerGraphWidth]);
    this.transformedScaleY = this.d3.scaleLinear().domain(domainY).range([this.renderer.attributes.innerGraphHeight, 0]);
    this.transformedScaleX = this.d3.scaleLinear().domain(domainX).range([0, this.renderer.attributes.innerGraphWidth]);
    this.setGraphLabels(this.config.roundXToDecimal, domainX);
  }

  private zoomingOnYAxisInZoomMode() {
    return this.htmlTemplateVars.zoomMode && this.htmlTemplateVars.zoomOnYAxis;
  }

  //  the data model needs to be constructed by taking the input data (x coordinates) and creating y components
  //  for them using the starting frequency and successively adding the step value to construct the graph
  private createDataModels() {
    // we need to know if no paths are visible, because we wont want to render a tooltip if so
    let noPathsVisible = true;
    for (let i = 0; i < this.dataModels.length; i++) {
      //  pass in 'true' for first data set, otherwise false
      //  we only need to keep track of maxes and mins for the main data set, which is the first one in the list
      if ((this.dataModels[i].continuousUpdate || this.dataModels[i].needsUpdate) && this.dataModels[i].visible) {
        this.dataModels[i].createDataModel();
      }
      this.dataModels[i].needsUpdate = false;
      if (this.dataModels[i].hasVisiblePathElements()) {
        noPathsVisible = false;
      }
    }
    if (noPathsVisible) {
      this.noPathsVisible = true;
    } else {
      this.noPathsVisible = false;
    }
  }

  private zoomToNewScale(xScale, yScale) {
    const dbPerDiv = this.dataParams.hasDbPerDivBeenSet() ? this.dataParams.dbPerDiv : null;
    this.axes.zoom(xScale, yScale, dbPerDiv);
    this.renderGraphData();
    this.addCarrierRegions();
    this.htmlTemplateVars.graphDbPerDiv = this.axes.getDbDiv();
  }

  private renderGraphData() {
    this.clearCanvas();
    //  if we have data models, display the data
    if (this.dataModels.length > 0 && this.dataModels[0].dataSetAt(0).length() > 0) {

      //  initialize graph UI elements
      if (!this.htmlTemplateVars.graphInitialized) {
        this.initGraphScalingAndElements();
      }

      //  initialize data-related graph elements
      if (!this.graphDataInitialized) {
        this.initializeGraphData();
      }
      //  we could check this variable in the mouseMove like we do the focus tracking, but checking it that often isn't necessary
      //  once per refresh is enough
      if (this.config.showSampleFreq) {
        this.frequencyLine.showFrequencyLine();
      }
      this.addCarrierRegions();
      this.addGraphElements();
      this.addLineElements();
      this.addMarkers();
      this.createFocusTracking();
    } else { //  if we have don't have data models, just create the empty axes
      this.createAxes(true);
      this.alignCanvasAndSvgWithAxes();
    }
  }

  //  this must be a separate method from the mousemove method, because 'this' here means the TS class,
  //  while in the graph event, it refers to the html element that fired the event,
  //  so even though the methods perform the same function, they must be handled differently for each case
  private createFocusTracking() {
    if (this.currentPath != null && this.mouseIsInsideSvg) {
      let pos = this.getCurrentPointOnPath();
      if (pos != null) {
        //  if the line's main trace is visible, show the tooltip. Otherwise we dont want to
        let pathClearWriteVisible = true;
        for (let i = 0; i < this.dataModels.length; i++) {
          if (this.dataModels[i].pathId === this.currentPath.pathId) {
            // access each data model's clear write to test for visibility
            if (!this.dataModels[i].dataSetAt(0).visible || !this.dataModels[i].visible) {
              pathClearWriteVisible = false;
            }
            break;
          }
        }
        //  set the coordinates for the circle and the focus lines
        //  but only if we're using focus tracking
        if (this.config.showFocusTracking) {
          if (this.isMouseInsideGraph()) {
            this.showFocusTrackingAtCurrentMousePosition();
          } else {
            this.hideFocusTracking();
          }
        }
        //  invert the scaling we used to find the actual frequency of the x value to show on the tooltip
        //  but only if we're showing the tooltip
        if (this.config.showTooltip) {
          if (this.config.floatingTooltip) {
            pos = new SpectrumAnalyzerXY(null, this.currentX, this.currentY);
            this.showTooltipAtPosition(pos);
          } else if (this.isMouseInsideGraph() && !this.noPathsVisible && pathClearWriteVisible) {
            this.showTooltipAtPosition(pos);
          } else {
            this.hideTooltip();
          }
        }
      } else {
        this.hideFocusTracking();
        this.hideTooltip();
      }
    } else {
      this.hideFocusTracking();
      this.hideTooltip();
    }
  }

  private initGraphScalingAndElements() {
    this.renderer.clearCanvas(this.context);
    this.transformedScaleX = this.scaleX;
    this.transformedScaleY = this.scaleY;
    this.addLegend();

    // TODO: add this functionality
    // this.appendMarkersToSvg();
    this.addTooltip();
    this.tooltip.addTooltipElementToSvg(this.svgElementsInFrontOfCanvasContainer);
    this.focusTracking.addFocusTrackingElementToSvg(this.svgElementsInFrontOfCanvasContainer,
      this.renderer.attributes.innerGraphWidth, this.renderer.attributes.innerGraphHeight);
    this.appendSampleFreqLine();
    this.htmlTemplateVars.graphInitialized = true;
  }

  private determineNewDomainForDbDiv(domain, ticks) {
    const domainRange = Math.abs(domain[1] - domain[0]);
    const newDomainRange = ticks * this.dataParams.dbPerDiv;
    const difference = newDomainRange - domainRange;
    const diffToAdjustDomainBy = difference / 2;
    domain[0] -= diffToAdjustDomainBy;
    domain[1] += diffToAdjustDomainBy;
    return domain;
  }

  private addCarrierRegions() {
    this.svgElementsBehindCanvasContainer.selectAll('.pf-specan-carrier-region').remove();
    for (let i = 0; i < this.carrierRegions.length; i++) {
      const cr = this.carrierRegions[i];
      const currentFreqWindow = this.transformedScaleX.domain();
      if ((cr.startFreq > currentFreqWindow[0] && cr.startFreq < currentFreqWindow[1]) ||
        (cr.stopFreq < currentFreqWindow[1] && cr.stopFreq > currentFreqWindow[0])) {
        const region = new SpectrumAnalyzerCarrierRegionElement(this.d3);
        let crStartX = this.transformedScaleX(cr.startFreq);
        let crStopX = this.transformedScaleX(cr.stopFreq);
        if (crStartX < 0) {
          crStartX = 0;
        }
        if (crStopX > this.renderer.attributes.innerGraphWidth) {
          crStopX = this.renderer.attributes.innerGraphWidth;
        }
        region.initialize(this.htmlTemplateVars.graphId, crStartX, crStopX, this.renderer.attributes.innerGraphHeight,
          cr.name, cr.color);
        region.addElementToSvg(this.svgElementsBehindCanvasContainer);
      }
    }
  }

  private addLegend() {
    if (this.config.showLegend) {
      const top = 10;
      const legendBoxWidth = 20;
      const left = this.getInnerGraphWidth() - legendBoxWidth;
      this.legend.addElementToSvg(this.svgElementsInFrontOfCanvasContainer, top, left);
    }
  }

  private setGraphLabels(roundTo: number, domainX) {
    if (domainX[0] != null && domainX[1] != null) {
      this.htmlTemplateVars.graphStartFreq = (domainX[0]).toFixed(roundTo);
      this.htmlTemplateVars.graphStopFreq = (domainX[1]).toFixed(roundTo);
      this.htmlTemplateVars.graphSpan = +(domainX[1] - domainX[0]).toFixed(roundTo);
      this.htmlTemplateVars.graphCenterFreq = +((+this.htmlTemplateVars.graphStopFreq
        + +this.htmlTemplateVars.graphStartFreq) / 2).toFixed(roundTo);
    }
    this.htmlTemplateVars.graphDbPerDiv = this.dataParams.dbPerDiv;
  }

  private appendSampleFreqLine() {
    this.frequencyLine.addElementToSvg(this.svgElementsInFrontOfCanvasContainer, this.getInnerGraphHeight(), this.getInnerGraphWidth());
  }

  private sampleFreqLineDragStart = (d, i) => {
    this.userInteractingWithGraph = true;
    this.tooltipVisible = false;
    this.focusTrackingVisible = false;
    this.tooltip.hide();
    this.focusTracking.hide();
    this.frequencyLine.dragStart();
  }

  private sampleFreqLineMouseover = (d, i) => {
    this.tooltipVisible = false;
    this.focusTrackingVisible = false;
    this.tooltip.hide();
    this.focusTracking.hide();
  }

  private sampleFreqLineMouseleave = (d, i) => {
    //   have to check this variable to see if the mouse left the line because they are currently dragging it
    if (!this.userInteractingWithGraph) {
      this.tooltipVisible = true;
      this.focusTrackingVisible = true;
    }
  }

  private sampleFreqLineDragEnd = (d, i) => {
    this.userInteractingWithGraph = false;
    this.frequencyLine.dragEnd();
    const svg = document.getElementById(this.htmlTemplateVars.graphId + '-container');
    const newX = this.d3.mouse(svg)[0];
    const newFreqVal = this.scaleValueByX(newX);

    //  call the samplefreqchanged function in case the parent component is listening and wants to know
    this.onSampleFreqChanged(newFreqVal);
    this.tooltipVisible = true;
    this.focusTrackingVisible = true;
  }

  private sampleFreqLineDragged = (d, i) => {
    //   set the freq line's x value to that of the current mouse position
    const svg = document.getElementById(this.htmlTemplateVars.graphId + '-container');
    const newX = this.d3.mouse(svg)[0];
    this.frequencyLine.dragging(newX);
  }

  //   might be good to implement at a later time, but this code doesn't currently work
  private markerDragStart = (d, i) => {
    this.userInteractingWithGraph = true;
  }
  private markerDragEnd = (d, i, elem) => {
    this.userInteractingWithGraph = false;
    this.tooltipVisible = true;
    this.focusTrackingVisible = true;
    this.showTooltipAtCurrentMousePosition();
    this.showFocusTrackingAtCurrentMousePosition();
  }
  private markerDragged = (d, i, elem) => {
    let idx = this.markers.findIndex(m => m.id == elem[0].parentElement.id);
    let currentMarker = this.markers[idx];
    //  set the freq line's x value to that of the current mouse position
    const svg = document.getElementById(this.htmlTemplateVars.graphId + '-container');
    let newLoc = this.d3.mouse(svg);
    let newPosition = this.getNewYCoord(currentMarker.pathDataSetName, this.transformedScaleX.invert(newLoc[0]));
    this.moveMarkerTo(currentMarker, newPosition);
  }
  private moveMarkerTo(currentMarker, newPosition) {
    currentMarker.showMarkerAtPosition(this.transformedScaleX, this.transformedScaleY, newPosition, currentMarker.markerText);
  }
  private markerMouseover = (d, i) => {
    this.tooltipVisible = false;
    this.focusTrackingVisible = false;
    this.hideTooltip();
    this.hideFocusTracking();
  }
  private markerMouseleave = (d, i) => {
    //  have to check this variable to see if the mouse left the marker because they are currently dragging it
    if (!this.userInteractingWithGraph) {
      this.tooltipVisible = true;
      this.focusTrackingVisible = true;
      this.showTooltipAtCurrentMousePosition();
      this.showFocusTrackingAtCurrentMousePosition();
    }
  }

  private getMarkerText(marker, position: XYPoint) {
    let markerText = [];
    markerText.push(marker.name);
    markerText.push(this.transformedScaleY.invert(position.y).toPrecision(6) + ' ' + this.units.yAxisUnit.defaultUnit.name);
    markerText.push((this.transformedScaleX.invert(position.x) * this.units.xAxisUnit.defaultUnit.multiplier).toPrecision(6) + ' ' + this.units.xAxisUnit.defaultUnit.name);
    return markerText;
  }

  private initializeGraphData() {
    this.domainX = this.calculateDomainX();
    if (this.dataParams.axisMinY != null && this.dataParams.axisMaxY != null ||
      this.dataParams.axisMinX != null && this.dataParams.axisMaxX != null) {
      this.usingMinMaxXY = true;
    }
    this.maxY = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).y;
    this.minY = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).y;
    this.maxX = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).x;
    this.minX = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).x;
    if (this.domainX[0] != null && this.domainX[1] != null) {
      //  call this initially to apply the span given by the user
      this.setScaling(this.calculateDomainY(), this.domainX);
      this.createAxes(false);
      this.alignCanvasAndSvgWithAxes();
      //  don't add mouse events until after we have data in the graph, because event code will fail if fired when the data is null
      this.renderer.addMouseEvents(this.svgElementsInFrontOfCanvasContainer, this.mouseMoveFunc, this.mouseLeaveFunc);
      this.graphDataInitialized = true;
    }
  }

  private addGraphElements() {
    if (this.graphElements != null) {
      for (let i = 0; i < this.graphElements.length; i++) {
        this.graphElementRenderer.addElementToCanvas(this.context, this.graphElements[i], this.transformedScaleX, this.transformedScaleY);
      }
    }
  }

  private createAxes(empty: boolean) {
    this.axes.setTicks(this.config.numberOfTicks);
    let scaleX, scaleY;
    if (empty) {
      scaleX = this.scaleX;
      scaleY = this.scaleY;
    } else {
      scaleY = this.transformedScaleY;
      scaleX = this.transformedScaleX;
    }
    this.gridDimensions = this.axes.create(this.svgAxisContainer, empty, this.renderer.attributes.innerGraphHeight,
      this.renderer.attributes.innerGraphWidth, scaleX, scaleY, this.config.xAxisTickFormatFunc,
      this.config.yAxisTickFormatFunc, this.dataParams.hasDbPerDivBeenSet() ? this.dataParams.dbPerDiv : null, this.config.showGraticule);

    const containerDimensions = this.renderer.getDataContainerHTML().getBoundingClientRect();
    if (this.config.showOnlyGraphWindow) {
      this.gridDimensions.bottomInPixel = this.renderer.attributes.outerGraphHeight;
      this.gridDimensions.topInPixels = 0;
      this.gridDimensions.leftInPixels = 0;
      this.gridDimensions.rightInPixels = this.renderer.attributes.outerGraphWidth;
      this.gridDimensions.width = this.renderer.attributes.outerGraphWidth;
      this.gridDimensions.height = this.renderer.attributes.outerGraphHeight;
    } else {
      this.gridDimensions.bottomInPixels -= containerDimensions.top;
      this.gridDimensions.topInPixels -= containerDimensions.top;
      this.gridDimensions.leftInPixels = this.gridDimensions.leftInPixels - containerDimensions.left;
      this.gridDimensions.rightInPixels -= containerDimensions.left;
      this.gridDimensions.width = this.renderer.attributes.innerGraphWidth;
      this.gridDimensions.height = this.renderer.attributes.innerGraphHeight;
    }
    this.renderer.addGraphBorder(this.svgElementsInFrontOfCanvasContainer);
    this.htmlTemplateVars.graphDbPerDiv = this.axes.getDbDiv();
  }
  private calculateDomainY() {
    if (this.dataModels.length > 0) {
      if (this.usingMinMaxXY && this.dataParams.axisMaxY != null && this.dataParams.axisMinY != null) {
        return [this.dataParams.axisMinY, this.dataParams.axisMaxY];
      } else {
        let min;
        let max;
        //  set initial values to first data model
        let idxOfFirstVisibleModel = -1;
        for (let i = 0; this.dataModels.length; i++) {
          if (this.dataModels[i].visible) {
            idxOfFirstVisibleModel = i;
            break;
          }
        }
        if (idxOfFirstVisibleModel !== -1) {
          const firstY = this.dataModels[idxOfFirstVisibleModel].getRange();
          min = firstY[0];
          max = firstY[1];

          //  iterate through each data model, call the method to set its min/max, compare to global mix/max
          for (let i = 1; i < this.dataModels.length; i++) {
            if (this.dataModels[i].visible) {
              const nextY = this.dataModels[i].getRange();
              if (nextY[0] != null && nextY[0] < min) {
                min = nextY[0];
              }
              if (nextY[1] != null && nextY[1] > max) {
                max = nextY[1];
              }
            }
          }
        }
        this.setDomainY(min, max);
        return [min, max];
      }
    } else {
      return this.scaleY.domain();
    }
  }
  private calculateDomainX() {
    if (this.usingMinMaxXY && this.dataParams.axisMaxX != null && this.dataParams.axisMinX != null) {
      return [this.dataParams.axisMinX, this.dataParams.axisMaxX];
    } else if (this.dataModels.length > 0) {
      let min: number;
      let max: number;
      //  set initial values to first data model
      let idxOfFirstVisibleModel = -1;
      for (let i = 0; i < this.dataModels.length; i++) {
        if (this.dataModels[i].visible) {
          idxOfFirstVisibleModel = i;
          break;
        }
      }
      if (idxOfFirstVisibleModel !== -1) {
        const minMax = this.dataModels[idxOfFirstVisibleModel].getDomain();
        min = minMax[0];
        max = minMax[1];

        //  keep track of these in case we run out of data at any point
        if (this.maxX < max || this.maxX == null) {
          this.maxX = max;
        }
        if (this.minX > min || this.minX == null) {
          this.minX = min;
        }
        //  if these two are equal, we've run out of data
        if (min === max) {
          return [this.minX, this.maxX];
        }
      }
      return [min, max];
    } else {
      return this.scaleX.domain();
    }
  }

  private setDomainY(max: number, min: number) {
    //  reset global values
    if (this.minY > min) {
      this.minY = min;
    }
    if (this.maxY < max) {
      this.maxY = max;
    }
  }

  private getNewYCoord(dataSetName: string, currentX: number): XYPoint {
    let pos;
    pos = SpectrumAnalyzerUtility.getPositionOnDataPath(this.transformedScaleX, this.transformedScaleY,
      this.currentPath, currentX, dataSetName);
    return pos;
  }

  private getMidpointOnGraph(dataSetName: string): XYPoint {
    let pos;
    pos = SpectrumAnalyzerUtility.getPositionOnDataPath(this.transformedScaleX, this.transformedScaleY,
      this.currentPath, this.transformedScaleX.invert(this.renderer.attributes.innerGraphWidth / 2), dataSetName);
    // pos will be null if there is no point along the line at the current mouse coordinates
    return pos;
  }

  private getCurrentPointOnPath(dataSetName: string = 'main'): XYPoint {
    let pos;
    pos = SpectrumAnalyzerUtility.getPositionOnDataPath(this.transformedScaleX, this.transformedScaleY,
      this.currentPath, this.transformedScaleX.invert(this.currentX), dataSetName);
    // pos will be null if there is no point along the line at the current mouse coordinates
    return pos;
  }

  private translateSvgToCanvasCoordinateX(value) {
    const svgRect = this.graphContainer.nativeElement.querySelector('svg').getBoundingClientRect();
    value -= svgRect.left;
    return value;
  }

  private initializeDataSet(dataSet, i) {
    let color = '';
    let name = '';
    if (dataSet.legendName != null) {
      name = dataSet.legendName;
    } else {
      const legendName = this.config.legendPrefix + (i + 1);
      dataSet.legendName = legendName;
      name = dataSet.legendName;
    }
    if (dataSet.graphColor != null) {
      color = dataSet.graphColor;
    } else {
      dataSet.graphColor = this.extraColors[0];
      this.extraColors.splice(0, 1);
      color = dataSet.graphColor;
    }
    if (dataSet.pathId == null) {
      dataSet.pathId = this.htmlTemplateVars.graphId + '-line-' + dataSet.legendName.replace(/[^A-Z0-9]/ig, '') + UUID.UUID();
    }
    dataSet.graphInfoInitialized = true;
    if (!this.legend.itemAdded(name)) {
      this.legend.addItem(name, color);
    }
    if (this.config.showLegend) {
      this.legend.update(this.svgElementsInFrontOfCanvasContainer);
    }
  }

  private getLengthOfLargestDataSet() {
    let largest = 0;
    for (let i = 0; i < this.dataModels.length; i++) {
      if (this.dataModels[i].length() > largest) {
        largest = this.dataModels[i].length();
      }
    }
    return largest;
  }
  private appendZoomToSvg() {
    this.zoom.appendZoom(this.svgElementsInFrontOfCanvasContainer, this.brushEndedFunc,
      this.renderer.attributes.innerGraphHeight, this.renderer.attributes.innerGraphWidth);
  }
  // initialization of the renderer. Should only be done once
  private initRenderer() {
    // make initial assignments
    this.renderer.attributes.bottomMenuHeight = 70;
    this.renderer.attributes.showOnlyGraphWindow = this.config.showOnlyGraphWindow;
    if (this.config.showOnlyGraphWindow) {
      this.renderer.attributes.bottomMenuHeight = 0;
      this.renderer.attributes.marginBottom = 2;
      this.renderer.attributes.marginLeft = 2;
      this.renderer.attributes.marginTop = 2;
      this.renderer.attributes.topMenuHeight = 0;
      this.config.showSampleFreq = false;
    } else if (!this.config.showDataMenus) {
      this.renderer.attributes.bottomMenuHeight = 15;
    }
    if (this.config.displayAsNormalGraph) {
      this.renderer.attributes.bottomMenuHeight = 5;
      this.renderer.attributes.marginBottom = 10;
      this.renderer.attributes.topMenuHeight = 25;
    }
    if (this.config.borderColor != null) {
      this.renderer.attributes.graphBorderColor = this.config.borderColor;
    }
    this.renderer.attributes.ticks = this.config.numberOfTicks;
    this.renderer.attributes.graphId = this.htmlTemplateVars.graphId;
    // initializes renderer variables and properly sizes graph
    this.renderer.resize();

  }

  // initialization of the axes
  private initAxisVars() {
    this.axes.yAxisTitle = this.config.yAxisTitle;
    this.axes.showYAxesTickLabels = this.config.showYAxesTickLabels;
    this.axes.showXAxesTickLabels = this.config.showXAxesTickLabels;
    this.axes.showOnlyGraphWindow = this.config.showOnlyGraphWindow;
    this.axes.displayAsNormalGraph = this.config.displayAsNormalGraph;
    // initialize axes
    this.axes.init(this.htmlTemplateVars.graphId, this.config.yAxisTitle,
      this.renderer.attributes.marginLeft, this.renderer.attributes.marginBottom, this.config.numberOfTicks,
      this.dataParams.dbPerDiv);
  }

  // initialization of the graph elements. Should only be done once
  private initGraphElements() {
    this.tooltipVisible = this.config.showTooltip;
    this.focusTrackingVisible = this.config.showFocusTracking;

    this.zoom.initialize(this.scrollZoomFunc, this.htmlTemplateVars.graphId);
    this.focusTracking.initialize(this.htmlTemplateVars.graphId + '-focus-tracking', 'pf-specan-focus-line');
    this.addTooltip();

    //  initialize frequency line with dimensions and event function pointers
    this.frequencyLine.initialize(this.htmlTemplateVars.graphId + 'frequencyline',
      this.getInnerGraphHeight(), this.getInnerGraphWidth(), this.sampleFreqLineMouseover,
      this.sampleFreqLineMouseleave, this.sampleFreqLineDragStart, this.sampleFreqLineDragged, this.sampleFreqLineDragEnd);
    const top = 10;
    const legendBoxWidth = 20;
    const left = this.getInnerGraphWidth() - legendBoxWidth;
    this.legend.initialize(this.htmlTemplateVars.graphId + 'legend', top, left);
  }

  private addTooltip() {
    const tooltipConfig = new SvgTooltipConfig();
    tooltipConfig.tooltipTextHeight = 16;
    tooltipConfig.tooltipWidth = 80;
    tooltipConfig.graphHeight = this.getInnerGraphHeight();
    tooltipConfig.graphWidth = this.getInnerGraphWidth();
    tooltipConfig.fontSize = '12px';
    tooltipConfig.fontWeight = 'bold';
    tooltipConfig.bgClassName = 'pf-specan-tooltip-bg';
    tooltipConfig.textClassName = 'pf-specan-tooltip-text';
    tooltipConfig.circleClassName = 'pf-specan-focus-circle';
    this.tooltip.initialize(this.htmlTemplateVars.graphId + '-tooltip', tooltipConfig);
  }

  private appendSvg() {
    //  append the svg and the data container (svgContainer)
    //  the data elements will be appended to the svgContainer (such as line elements)
    //  while the axes and other structural elements will be appended to the svg itself
    //  the segregation makes manipulation of the elements easier and more compartmentalized
    this.removeSvg();
    this.svgAxisContainer = this.renderer.appendSvgBackgroundContainer(this.svgAxisContainer,
      this.graphContainer.nativeElement, this.htmlTemplateVars.graphId + '-axisSvg');
    this.svgElementsBehindCanvasContainer = this.renderer.appendSvgForegroundContainer(this.svgElementsBehindCanvasContainer,
      this.graphContainer.nativeElement, this.htmlTemplateVars.graphId + '-backgroundSvg');
    this.appendCanvas();
    this.svgElementsInFrontOfCanvasContainer = this.renderer.appendSvgForegroundContainer(this.svgElementsInFrontOfCanvasContainer,
      this.graphContainer.nativeElement, this.htmlTemplateVars.graphId + '-foregroundSvg');
    if (this.htmlTemplateVars.zoomMode) {
      this.appendZoomToSvg();
    }
  }
  private clearCanvas() {
    //  reset the path to null so we can select the correct one to show the tooltip on
    this.renderer.clearCanvas(this.context);
  }
  private addLineElements() {
    this.currentPath = null;

    //  update all lines
    for (let i = 0; i < this.dataModels.length; i++) {
      if (!this.dataModels[i].graphInfoInitialized) {
        this.initializeDataSet(this.dataModels[i], i);
      }
      //  pass in the data points and the id for the path
      this.renderer.appendLineToCanvas(this.context, this.dataModels[i], this.transformedScaleX, this.transformedScaleY);

      if (this.dataModels[i].selected) {
        this.currentPath = this.dataModels[i];
      }

      // this.updatePathMarkers(this.dataModels[i]);
    }

    //  if the path is still null, none of the data sets were specifically selected
    //  and we should find the first visible path to select for the tooltip
    if (this.currentPath == null) {
      for (let i = 0; i < this.dataModels.length; i++) {
        if (this.dataModels[i].visible) {
          this.currentPath = this.dataModels[i];
          this.dataModels[i].selected = true;
          break;
        }
      }
    }
  }
  private appendCanvas() {
    this.d3.select(this.graphContainer.nativeElement).select('canvas').remove();
    this.canvas = this.renderer.appendCanvas(this.canvas, this.graphContainer.nativeElement);
  }
  private alignCanvasAndSvgWithAxes() {
    this.renderer.setElementLocation(this.canvas, this.gridDimensions);
    this.renderer.setElementLocation(this.d3.select(this.graphContainer.nativeElement)
      .select('#' + this.htmlTemplateVars.graphId + '-foregroundSvg'), this.gridDimensions);
    this.renderer.setElementLocation(this.d3.select(this.graphContainer.nativeElement)
      .select('#' + this.htmlTemplateVars.graphId + '-backgroundSvg'), this.gridDimensions);
  }
}
