﻿import { Component, ViewEncapsulation} from '@angular/core';
import { ContextMenuService } from './context-menu-service';

declare var $: any;

@Component({
    selector: 'context-menu-element',
    host: {
        '(document:click)': 'clickedOutside($event)'
    },
    template:
    `<div [ngStyle]="locationCss" id="context-menu" class="ui-contextmenu ui-widget ui-widget-content ui-corner-all ui-helper-clearfix ui-menu-dynamic ui-shadow">
      <div class="ui-menu-list ui-helper-reset">
                <ng-content></ng-content>
          </div>
      </div>
  `,
    encapsulation: ViewEncapsulation.None
})
export class ContextMenuComponent {
    links = [];
    isShown = false;
    width: number;
    height: number;
    private mouseLocation: { left: number, top: number } = { left: 0, top: 0 };
    constructor(private _contextMenuService: ContextMenuService) {
        _contextMenuService.show.subscribe(e => this.showMenu(e.event, e.obj));
    }
    // the css for the container div
    get locationCss() {
        return {
            'position': 'fixed',
            'padding': '.25em',
            'z-index': '11',
            'display': this.isShown ? 'block' : 'none',
            left: this.mouseLocation.left + 'px',
            top: this.mouseLocation.top + 'px',
        };
    }
    clickedOutside(event) {
        const clickedComponent = event.target;
        const insideCM = $(clickedComponent).parents('#context-menu').length === 1;
        if (!insideCM) {
            this.isShown = false;
        }
    }

    // show the menu and set the location of the mouse
    showMenu(event, object) {
        this.isShown = true;
        this.links = ['test','test2'];
        this.mouseLocation = {
            left: event.clientX,
            top: event.clientY
        }
    }
}