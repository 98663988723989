import { Axis, D3, Selection } from 'd3-ng2-service';

export class SpectrumAnalyzerThumbnailAxes {
  private yAxisTicks: number[];
  private xAxisTicks: number[];
  private xAxisTickCount: number;
  private yAxisTickCount: number;
  private d3: D3;
  private graphId: any;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  init(graphId: string, xAxisTickCount: number, yAxisTickCount: number) {
    this.graphId = graphId;
    this.yAxisTickCount = yAxisTickCount;
    this.xAxisTickCount = xAxisTickCount;
  }

  create(context: CanvasRenderingContext2D, scaleX: any, scaleY: any, dbPerDiv:number, graticuleColor: string, borderColor: string) {
    // calculate the tick values for both axes
    const xDomain = scaleX.domain();
    const yDomain = scaleY.domain();

    const marginY = .03 * Math.abs(yDomain[1] - yDomain[0]);
    yDomain[0] -= marginY;
    yDomain[1] += marginY;

    const xAxisTicks = this.determineXAxisTicks(xDomain, this.xAxisTickCount);
    const yAxisTicks = this.determineYAxisTicks(yDomain, this.yAxisTickCount, dbPerDiv);


    context.beginPath();
    context.strokeStyle = graticuleColor;
    context.lineWidth = 1;
    xAxisTicks.forEach(point => {
      context.moveTo(scaleX(point), scaleY(yDomain[0]));
      context.lineTo(scaleX(point), scaleY(yDomain[1]));
    });
    yAxisTicks.forEach(point => {
      context.moveTo(scaleX(xDomain[0]), scaleY(point));
      context.lineTo(scaleX(xDomain[1]), scaleY(point));
    });
    context.stroke();
    let width = scaleX.range()[1];
    let height = scaleY.range()[0];
    //add border
    context.beginPath();
    context.strokeStyle = borderColor;
    context.lineWidth = 2;
    context.moveTo(1, 1);
    context.lineTo(1, height - 1);
    context.lineTo(width - 1, height - 1);
    context.lineTo(width - 1, 1);
    context.lineTo(1, 1);
    context.stroke();
  }

  calculateAxisDimensions(svg) {
    const yAxisBounds = document.querySelector('#' + this.graphId + '-yaxis').getBoundingClientRect();
    const xAxisBounds = document.querySelector('#' + this.graphId + '-xaxis').getBoundingClientRect();
    const yAxisGridBounds = document.querySelector('#' + this.graphId + '-yaxis').querySelector('path').getBoundingClientRect();
    const xAxisGridBounds = document.querySelector('#' + this.graphId + '-xaxis').querySelector('path').getBoundingClientRect();
    const axisDimensions = {
      leftInPixels: yAxisBounds.left,
      rightInPixels: yAxisBounds.right,
      topInPixels: yAxisBounds.top,
      bottomInPixels: yAxisBounds.bottom,
      width: yAxisGridBounds.left - yAxisBounds.left,
      height: xAxisGridBounds.bottom - xAxisBounds.bottom
    };
    return axisDimensions;
  }

  private determineXAxisTicks(domain, ticks) {

    const tickIncr = (domain[1] - domain[0]) / ticks;
    const axisTicks = [];

    for (let i = 0; i < ticks + 1; i++) {
      axisTicks.push(domain[0] + (tickIncr * i));
    }

    return axisTicks;
  }
  private determineYAxisTicks(domain, ticks, dbPerDiv) {
    let tickIncr;
    if (dbPerDiv != null) {
      tickIncr = (domain[1] - domain[0]) / dbPerDiv;
    } else {
      dbPerDiv = (domain[1] - domain[0]) / ticks;
      tickIncr = ticks;
    }
    const axisTicks = [];

    for (let i = 0; i < tickIncr + 1; i++) {
      axisTicks.push(domain[0] + (dbPerDiv * i));
    }

    return axisTicks;
  }
}
