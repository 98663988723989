import { D3 } from 'd3-ng2-service';

import { ChartSeries } from './data/pf-chart-series';
import { ChartRenderer } from './render/pf-chart-renderer';

export class Chart {
  private domainScale: any;
  private rangeScale: any;
  private data: ChartSeries[];
  private transform: any;

  constructor(private d3: D3, private context: CanvasRenderingContext2D, private height: number,
    private width: number, private renderer: ChartRenderer) {
  }

  setScales(domainScale: any, rangeScale: any): void {
    this.domainScale = domainScale;
    this.rangeScale = rangeScale;
  }

  zoom(transform: any): void {
    this.transform = transform;
    this.render(this.data);
  }

  render(data: ChartSeries[]): void {
    this.data = data;
    if (!!this.transform) {
      this.context.save();
      this.context.clearRect(0, 0, this.width, this.height);
      this.context.translate(this.transform.x, this.transform.y);
      this.context.scale(this.transform.k, this.transform.k);
      this.renderData();
      this.context.restore();
    } else {
      this.context.clearRect(0, 0, this.width, this.height);
      this.renderData();
    }
  }

  onScaleOrResize(height: number, width: number, domainScale: any, rangeScale: any): void {
    this.height = height;
    this.width = width;
    this.domainScale = domainScale;
    this.rangeScale = rangeScale;
    this.render(this.data);
  }

  private renderData(): void {
    if (!!this.data) {
      this.renderer.render(this.context, this.data, this.domainScale, this.rangeScale, this.height, this.width);
    }
  }
}