export enum EntityOperation {
  Create = 0,
  Get = 1,
  Update = 2,
  Delete = 3,
  Activate = 4,
  Deactivate = 5,
  GetData,
  GetList,
  Find
}
