import { UnitConversion, UnitOptions } from '../../../unit/index';

export class SpectrumAnalyzerUnits {
  // these are initialized to the most commonly used units but can be reinitialized
  xAxisUnit: UnitConversion = new UnitConversion(UnitOptions.GHz);
  yAxisUnit: UnitConversion = new UnitConversion(UnitOptions.dBm);
  spanUnit: UnitConversion = new UnitConversion(UnitOptions.GHz);
  rbwUnit: UnitConversion = new UnitConversion(UnitOptions.kHz);
  vbwUnit: UnitConversion = new UnitConversion(UnitOptions.kHz);
  dbDivUnit: UnitConversion = new UnitConversion(UnitOptions.dBDiv);
  xAxisDecimalPlaces: number = 6;
  spanDecimalPlaces: number = 6;
}
