﻿import { Axis, D3, Selection } from 'd3-ng2-service';
import { XYPoint } from '../../../chart/index';

export abstract class WaterfallTrackingLine {
    protected d3: D3;
    protected id = 'trackingline';
    protected lineElem: any;
    protected height: number;
    protected width: number;

    // we need to get these methods from the parent to coordinate hiding/showing other specan elements
    // even though we need these functions to call back to our actual functions to do the dragging/etc
    // for this frequency line element
    protected mouseOverFunc: any;
    protected mouseLeaveFunc: any;
    protected dragStartFunc: any;
    protected draggingFunc: any;
    protected dragEndFunc: any;

    constructor(d3: D3) {
        this.d3 = d3;
    }
    initialize(id: string, height: number, width: number, mouseOverFunc: any,
        mouseLeaveFunc: any, dragStartFunc: any, draggingFunc: any, dragEndFunc: any) {
        this.id = id;
        this.height = height;
        this.width = width;
        this.mouseOverFunc = mouseOverFunc;
        this.mouseLeaveFunc = mouseLeaveFunc;
        this.dragStartFunc = dragStartFunc;
        this.draggingFunc = draggingFunc;
        this.dragEndFunc = dragEndFunc;
    }
    abstract addElementToSvg(svg: any, currentHeight: number, currentWidth: number);

    abstract dragStart();

    abstract dragging(newLocation: number);

    abstract dragEnd();

    abstract moveTrackingLineTo(newLocation);

    abstract getTrackingLinePixelLocation();

    showTrackingLine() {
        if (this.lineElem != null) {
            this.lineElem.attr('display', null);
        }
    }
    hideTrackingLine() {
        if (this.lineElem != null) {
            this.lineElem.attr('display', 'none');
        }
    }
    reset() {
        this.lineElem = null;
    }
}