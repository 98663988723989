import { SpectrumAnalyzerSeries } from '../data-models/pf-specan-series';
import { SpectrumAnalyzerXY } from '../data-models/pf-specan-xy';
import { ChartUtility } from '../../../chart/index';
export class SpectrumAnalyzerUtility extends ChartUtility {
  static getPositionOnPath(svg: any, pathEl: any, currentMouseX: number): SpectrumAnalyzerXY {
    let pos;
    const pathLength = pathEl.getTotalLength();
    let beginning = 0;
    // this checks to see what the x coordinate is at the beginning of the line
    const beginningX = pathEl.getPointAtLength(0).x;
    const endX = pathEl.getPointAtLength(pathLength).x;
    let end = pathLength;
    let target;

    // if the mouse x has a corresponding point along the current line
    // if not, we don't want to show the tooltip/focus circle over it
    let inRangeOfLine = true;
    if (currentMouseX < beginningX || currentMouseX > endX) {
      inRangeOfLine = false;
    }
    if (inRangeOfLine) {
      pos = new SpectrumAnalyzerXY();
      // calculate the x value for the point on the line that corresponds to the x value of the mouse
      while (true) {
        target = Math.floor((beginning + end) / 2);
        pos = pathEl.getPointAtLength(target);

        if ((target === end || target === beginning) && pos.x !== currentMouseX) {
          break;
        }
        if (pos.x > currentMouseX) {
          end = target;
        } else if (pos.x < currentMouseX) {
          beginning = target;
        } else {
          break; // position found
        }
      }
    }
    return pos;
  }
  static getPositionOnDataPath(scaleX, scaleY, dataSet: SpectrumAnalyzerSeries, currentX: number, dataSetName = 'main'): SpectrumAnalyzerXY {
    let pos;
    // this checks to see what the x coordinate is at the beginning of the line
    const dataDomain = dataSet.getDomain();
    const beginningX = dataDomain[0];
    const endX = dataDomain[1];
    // if the mouse x has a corresponding point along the current line
    // if not, we don't want to show the tooltip/focus circle over it
    if (dataSet.getDataSetByName(dataSetName).visible) {
      let inRangeOfLine = true;
      if (currentX < beginningX || currentX > endX) {
        inRangeOfLine = false;
      }
      if (inRangeOfLine) {
        const dataPointAtFrequency = dataSet.getPointAtXValue(currentX, dataSetName);
        if (dataPointAtFrequency != null) {
          pos = new SpectrumAnalyzerXY();
          pos.x = scaleX(dataPointAtFrequency.x);
          pos.y = scaleY(dataPointAtFrequency.y);
        }
      }
    }
    return pos;
  }
}
