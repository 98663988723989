import { Component, OnDestroy, OnInit, ViewChild, HostListener, Input, ElementRef, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { D3, D3Service, Selection } from 'd3-ng2-service';
import { Subscription } from 'rxjs/Rx';
import { WaterfallSeries } from './data-models/pf-waterfall-series';
import { WaterfallPlot } from './pf-waterfall';
import { ZoomEventInfo, CanvasGraphElementModel } from './../../chart/index';
import { WaterfallRenderer } from './render/pf-waterfall-renderer';
import { WaterfallPlotConfiguration } from './configuration/pf-waterfall-config';
import { WaterfallDataParameters } from './configuration/pf-waterfall-data-params';
import { WaterfallPlotTemplateVariables } from './pf-waterfall-html-template-vars';
import { WaterfallPlotUnits } from './configuration/pf-waterfall-units';
import { WaterfallXY } from './data-models/pf-waterfall-xy';
import { OverlayPanel } from 'primeng/primeng';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
@Component({
  selector: 'pf-waterfall',
  templateUrl: './pf-waterfall.component.html',
  styleUrls: ['./pf-waterfall.component.css']
})
export class WaterfallPlotComponent implements OnDestroy, AfterViewInit {
  @Input() dataModel: WaterfallSeries;
  @Input() config: WaterfallPlotConfiguration = new WaterfallPlotConfiguration();
  @Input() dataParams: WaterfallDataParameters = new WaterfallDataParameters();
  @Input() units: WaterfallPlotUnits = new WaterfallPlotUnits();
  @Input() graphElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();

  @Output() onSelectedDomainAndRangeChanged: EventEmitter<ZoomEventInfo> = new EventEmitter<ZoomEventInfo>();
  // this is the current sample frequency value which can be captured by the enclosing component
  // when the user drags it to a new location
  @Output() horizontalTrackingLineChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() verticalTrackingLineChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onRenderBegin: EventEmitter<ZoomEventInfo> = new EventEmitter<ZoomEventInfo>();
  @Output() onRenderEnd: EventEmitter<ZoomEventInfo> = new EventEmitter<ZoomEventInfo>();
  // @Input() contextMenuItems: MenuItem[];

  // this is the connection between the private waterfall plot object and the html template for this component.
  // rather than directly exposing elements of the renderer and axes to the html template,
  // we will use this object to set the variables accordingly and use them in this component and in the html
  htmlTemplateVars: WaterfallPlotTemplateVariables = new WaterfallPlotTemplateVariables();
  mouseCoords = [0, 0];
  private d3: D3;

  @ViewChild('waterfallContainer') private graphContainer: ElementRef;
  @ViewChild('configMenu') private configMenu: OverlayPanel;
  private waterfall: WaterfallPlot;
  private idleTimeout: any;
  private resizeFiredOnce = false;

  private interval: any;
  private playingTraces = false;
  private waterfallInitialized: boolean;
  private visibilitySub: Subscription;
  private waitingToResize = false;

  constructor(d3Service: D3Service, private changeDetector: ChangeDetectorRef) {
    this.d3 = d3Service.getD3();

    this.waterfall = new WaterfallPlot(this.d3, this.changeDetector);
    this.waterfall.htmlTemplateVars = this.htmlTemplateVars;
  }
  @HostListener('window:pf-resize', ['$event'])
  @HostListener('window:resize') onResize() {
    if (this.config.graphVisible && !this.waitingToResize) {
      //need this because the resize event will fire twice so we need to wait until the page stops resizing
      this.waitingToResize = true;
      setTimeout(() => {
        this.waterfall.resize();
        this.waitingToResize = false;
      }, 300)
    }
  }
  showTooltipAtCurrentMousePosition() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.waterfall.showTooltipAtCurrentMousePosition();
    }
  }
  getCurrentDomainX() {
    return this.waterfall.getCurrentDomainX();
  }
  getCurrentGraphDomainY() {
    return this.waterfall.getCurrentGraphDomainY();
  }
  getCurrentDataDomainY() {
    return this.waterfall.getCurrentDataDomainY();
  }
  getWaterfallElementHeight() {
    return this.waterfall.getWaterfallElementHeight();
  }
  getWaterfallElementWidth() {
    return this.waterfall.getWaterfallElementWidth();
  }
  getGraphCanvasHeight() {
    return this.waterfall.getGraphCanvasHeight();
  }
  getGraphCanvasWidth() {
    return this.waterfall.getGraphCanvasWidth();
  }
  refreshGraph() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.assignWaterfallVariableValues();
      this.waterfall.refreshGraph();
    }
  }

  stepForward() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.stopPlayback();
      const currentLocationOfTrackingLine = this.waterfall.getHTrackingLineLocationInPixels();
      if (currentLocationOfTrackingLine > 0) {
        let currentTimestampOfTrackingLine = this.waterfall.getHTrackingLineLocationAsTimestamp();
        // decrement value to step forward
        currentTimestampOfTrackingLine = this.waterfall.findNextTimestampForwardFromData(currentTimestampOfTrackingLine);
        this.waterfall.setHTrackingLineLocationByTimestamp(currentTimestampOfTrackingLine);
        this.horizontalTrackingLineChange.emit(currentTimestampOfTrackingLine);
      }
    }
  }
  isPlaying() {
      return this.playingTraces;
  }
  togglePlayback() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      if (this.playingTraces) {
        this.stopPlayback();
      } else {
        this.startPlayback();
      }
    }
  }
  startPlayback() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.playingTraces = true;
      // start from the current line location and play from there
      let currentTimestampOfTrackingLine = this.waterfall.getHTrackingLineLocationAsTimestamp();
      let currentLocationOfTrackingLine = this.waterfall.getHTrackingLineLocationInPixels();
      this.interval = setInterval(() => {
        if (currentLocationOfTrackingLine > 0) {
          // move line 
          currentTimestampOfTrackingLine = this.waterfall.findNextTimestampForwardFromData(currentTimestampOfTrackingLine);
          this.waterfall.setHTrackingLineLocationByTimestamp(currentTimestampOfTrackingLine);
          this.horizontalTrackingLineChange.emit(currentTimestampOfTrackingLine);
          currentLocationOfTrackingLine = this.waterfall.getHTrackingLineLocationInPixels();
        } else {
          this.playingTraces = false;
          clearInterval(this.interval);
        }
      }, this.htmlTemplateVars.playbackSpeedInMs);
    }
  }
  stopPlayback() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.playingTraces = false;
      clearInterval(this.interval);
    }
  }
  stepBackward() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.stopPlayback();
      const currentLocationOfTrackingLine = this.waterfall.getHTrackingLineLocationInPixels();
      if (currentLocationOfTrackingLine < this.waterfall.getGraphCanvasHeight()) {
        let currentTimestampOfTrackingLine = this.waterfall.getHTrackingLineLocationAsTimestamp();
        // increment value to step backward
        currentTimestampOfTrackingLine = this.waterfall.findNextTimestampBackwardFromData(currentTimestampOfTrackingLine);
        this.waterfall.setHTrackingLineLocationByTimestamp(currentTimestampOfTrackingLine);
        this.horizontalTrackingLineChange.emit(currentTimestampOfTrackingLine);
      }
    }
  }
  setHTrackingLineToBeginning() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.stopPlayback();
      this.waterfall.resetTrackingLineH();
      let currentTimestampOfTrackingLine = this.waterfall.getHTrackingLineLocationAsTimestamp();
      this.horizontalTrackingLineChange.emit(currentTimestampOfTrackingLine);
    }
  }
  setHTrackingLineLocationByPixel(pixel: number) {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.waterfall.setHTrackingLineLocationByPixel(pixel);
      let currentTimestampOfTrackingLine = this.waterfall.getHTrackingLineLocationAsTimestamp();
      this.horizontalTrackingLineChange.emit(currentTimestampOfTrackingLine);
    }
  }
  setVTrackingLineLocationByPixel(pixel: number) {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.waterfall.setVTrackingLineLocationByPixel(pixel);
      let currentTimestampOfTrackingLine = this.waterfall.getHTrackingLineLocationAsTimestamp();
      this.horizontalTrackingLineChange.emit(currentTimestampOfTrackingLine);
    }
  }
  setDomainX(domainX: number[]) {
    if (this.config.graphVisible) {
      if (!this.waterfallInitialized) {
        this.waterfall.initialize();
        this.waterfallInitialized = true;
      }
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.dataDomainX = domainX;
      this.waterfall.zoomToEvent(zoomEvent);
    }
  }
  setDomainY(domainY: number[]) {
    if (this.config.graphVisible) {
      if (!this.waterfallInitialized) {
        this.waterfall.initialize();
        this.waterfallInitialized = true;
      }
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.graphDomainY = domainY;
      this.waterfall.zoomToEvent(zoomEvent);
    }
  }
  setDomainXAndY(domainX: number[], domainY: number[]) {
    if (this.config.graphVisible) {
      if (!this.waterfallInitialized) {
        this.waterfall.initialize();
        this.waterfallInitialized = true;
      }
      this.dataParams.axisMinX = domainX[0];
      this.dataParams.axisMaxX = domainX[1];
      this.dataParams.axisMinY = domainY[0];
      this.dataParams.axisMaxY = domainY[1];
    }
  }
  clearCanvas() {
    this.waterfall.clearCanvas();
  }
  renderCanvasOnly() {
    this.waterfall.renderCanvasOnly();
  }
  zoomToExtent() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      if (!this.config.serverSideDataZooming) {
        this.waterfall.zoomToExtent();
      }
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.dataDomainX = this.waterfall.getCurrentDomainX();
      zoomEvent.dataDomainY = this.getCurrentDataDomainY();
      zoomEvent.graphDomainY = this.waterfall.getCurrentGraphDomainY();
      zoomEvent.zoomToExtent = true;
      this.configMenu.hide();
      this.onSelectedDomainAndRangeChanged.next(zoomEvent);
    }
  }

  resizeGraph() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.waterfall.resize();
    }
  }
  brushed = () => {
    if (this.d3.event.sourceEvent && this.d3.event.sourceEvent.type === 'zoom') { return; } // ignore brush-by-zoom
    const s = this.d3.event.selection;
    if (!s) {
      //  the idle timeout determines whether it was a single or double click
      //  only zoom back out if it was a double click
      if (!this.idleTimeout) {
        return this.idleTimeout = setTimeout(() => {
          this.idleTimeout = null;
        }, 300);
      }
      if (!this.config.serverSideDataZooming) {
        this.waterfall.zoomToExtent();
      }
      this.waterfall.resetTrackingLineH();
      const zoomEvent = new ZoomEventInfo();
      zoomEvent.dataDomainX = this.waterfall.getCurrentDomainX();
      zoomEvent.dataDomainY = this.getCurrentDataDomainY();
      zoomEvent.graphDomainY = this.waterfall.getCurrentGraphDomainY();
      this.onSelectedDomainAndRangeChanged.next(zoomEvent);
    } else {
      const brushSelection = this.d3.event.selection;
      if (!this.config.serverSideDataZooming) {
        this.waterfall.brushed(brushSelection);
      } else {
        const xdomain = [brushSelection[0][0], brushSelection[1][0]];
        const ydomain = [brushSelection[1][1], brushSelection[0][1]];
        const domainX = this.waterfall.getScaledXDomain(xdomain as any);
        const domainY = this.waterfall.getScaledYDomain(ydomain as any);
        this.waterfall.removeZoomSelectionArea();
        const zoomEvent = new ZoomEventInfo();
        zoomEvent.dataDomainX = domainX;
        zoomEvent.dataDomainY = this.getCurrentDataDomainY();
        zoomEvent.graphDomainY = domainY;
        this.onSelectedDomainAndRangeChanged.next(zoomEvent);
      }
      this.waterfall.resetTrackingLineH();
    }
  }
  getGradientLinePixelLocation() {
    return this.waterfall.getGradientLinePixelLocation();
  }

  getCurrentGradient() {
    return this.waterfall.getCurrentGradient();
  }
  getVTrackingLineFrequency(): number {
    if (this.config.graphVisible && this.waterfallInitialized) {
      return this.waterfall.getVTrackingLineLocationAsFrequency();
    }
  }
  getHTrackingLineTimestamp(): number {
    if (this.config.graphVisible && this.waterfallInitialized) {
      return this.waterfall.getHTrackingLineLocationAsTimestamp();
    }
  }
  getHTrackingLinePixels(): number {
    if (this.config.graphVisible && this.waterfallInitialized) {
      return this.waterfall.getHTrackingLineLocationInPixels();
    }
  }
  setTrackingLineLocationByTimestamp(timestamp: number) {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.waterfall.setHTrackingLineLocationByTimestamp(timestamp);
    }
  }
  setTrackingLineLocationByFrequency(freq: number) {
    if (this.config.graphVisible && this.waterfallInitialized) {
      this.waterfall.setVTrackingLineLocationByFrequency(freq);
    }
  }
  click = (d, i) => {

  }
  mouseMove = (d, i) => {
    // this.waterfall.mouseIsInsideSvg = true;
    const svg = document.getElementById(this.htmlTemplateVars.graphId);
    const currentMouse = this.d3.mouse(svg);
    // used to set the specan variables currentX and currentY for later reference
    this.waterfall.setCurrentMouseCoords(currentMouse);
    const newCoords = this.waterfall.getScaledMouseCoords(this.d3.mouse(svg)[0], this.d3.mouse(svg)[1]);
    this.mouseCoords[0] = +newCoords[0].toFixed(this.config.roundXToDecimal);
    this.mouseCoords[1] = newCoords[1];

    // calculate whether we are inside the graph itself or whether we are over an axis
    const insideGraph = this.waterfall.isMouseInsideGraph();

    if (this.config.showTooltip && !this.htmlTemplateVars.zoomMode) {
      if (insideGraph && this.htmlTemplateVars.graphInitialized) {
        this.waterfall.showTooltipAtCurrentMousePosition();
      } else {
        this.waterfall.hideTooltip();
      }
    }
  }
  mouseLeave = () => {
    this.mouseCoords = [0, 0];
    // this.waterfall.mouseIsInsideSvg = false;
    this.waterfall.hideTooltip();
  }
  toggleZoom() {
    if (this.htmlTemplateVars.zoomMode) {
      this.htmlTemplateVars.zoomMode = false;
    } else {
      this.htmlTemplateVars.zoomMode = true;
    }
    this.configMenu.hide();
    this.waterfall.toggleZoom(this.htmlTemplateVars.zoomMode);
  }
  // disabling for now, doesn't seem like a good feature for a waterfall
  // scrollZoom = (d, i) => {
  //    if (this.d3.event.sourceEvent != null && this.d3.event.sourceEvent.type === 'wheel') {
  //        // sign of 'direction' indicates left or right
  //        const direction = this.d3.event.sourceEvent.wheelDelta > 0 ? 1 : -1;
  //        let zoomEvent = new ZoomEventInfo();
  //        zoomEvent.transform = this.d3.event.transform;
  //        this.waterfall.scrollZoom(direction, zoomEvent);
  //    }
  //    this.waterfall.resetTrackingLineH();
  // }

  zoomToNewDomains(eventInfo: ZoomEventInfo) {
    if (this.config.graphVisible) {
      if (!this.waterfallInitialized) {
        this.waterfall.initialize();
        this.waterfallInitialized = true;
      }
      this.waterfall.resetTrackingLineH();
      this.waterfall.zoomToEvent(eventInfo);
    }
  }
  initializeGraph() {
      if (!this.waterfallInitialized) {
          this.waterfall.initialize();
          this.waterfallInitialized = true;
      }
  }

  renderGraph() {
    if (this.config.graphVisible) {
      if (!this.waterfallInitialized) {
        this.waterfall.initialize();
        this.waterfallInitialized = true;
      }
      this.assignWaterfallVariableValues();
      this.waterfall.render();
      this.htmlTemplateVars.graphInitialized = true;
    }
  }

  grayscaleChanged() {
    if (this.config.graphVisible) {
      if (!this.waterfallInitialized) {
        this.waterfall.initialize();
        this.waterfallInitialized = true;
      }
      if (this.htmlTemplateVars.grayscaleChoice != null) {
        this.configMenu.hide();
        this.renderGraph();
      }
    }
  }

  ngAfterViewInit() {
    // check to make sure an array type was passed in
    if ((this.dataModel == null)) {
      throw new Error('Attribute \'dataModel\' is required');
    }
    if (!(this.dataModel instanceof WaterfallSeries)) {
      throw new Error('Attribute \'dataModel\' must be of type WaterfallSeries');
    }
    this.waterfall.onSelectedDomainAndRangeChanged = (selection) => {
      this.onSelectedDomainAndRangeChanged.emit(selection);
    };
    this.waterfall.onHorizontalTrackingLineChange = (newval) => {
      this.horizontalTrackingLineChange.emit(newval);
    };
    this.waterfall.onVerticalTrackingLineChange = (newval) => {
      this.verticalTrackingLineChange.emit(newval);
    };
    this.waterfall.onRenderBegin = (zoomEvent) => {
      this.onRenderBegin.emit(zoomEvent);
    };
    this.waterfall.onRenderEnd = (newval) => {
      this.onRenderEnd.emit(newval);
    };
    this.visibilitySub = this.config.graphVisibilityChanged.subscribe((visible) => {
      if (!this.waterfallInitialized && visible) {
        // timeout is necessary to give html elements enough time to appear before attempting to access them
          let timeout = setTimeout(() => {
            if (!this.waterfallInitialized) {
              this.initializePlot();
              this.waterfallInitialized = true;
            }
              this.resizeGraph();
          clearTimeout(timeout);
        });
      }
    });
    if (this.config.xAxisTickFormatFunc == null) {
      this.config.xAxisTickFormatFunc = (d: number) => {
        return (this.units.xAxisUnit.defaultUnit.multiplier * d).toPrecision(this.config.roundXToDecimal);
      };
    }
    this.initializePlot();
  }

  getTimestampsForEachPixel() {
    if (this.config.graphVisible && this.waterfallInitialized) {
      return this.waterfall.getTimestampsForEachPixel();
    }
  }

  ngOnDestroy(): void {
    if (this.config.graphVisible || this.waterfallInitialized) {
      this.waterfall.removeSvg();
      }
    this.visibilitySub.unsubscribe();
  }

  private initializePlot() {
    this.assignWaterfallVariableValues();
    if (this.config.graphVisible) {
      this.waterfall.initialize();
      this.waterfallInitialized = true;
    }
    const newHeight = this.waterfall.getWaterfallElementHeight();
    this.htmlTemplateVars.waterfallContainerStyle = { 'height': (newHeight) + 'px' };
  }
  private assignWaterfallVariableValues() {
    this.waterfall.dataModel = this.dataModel;
    this.waterfall.dataParams = this.dataParams;
    this.waterfall.config = this.config;
    this.waterfall.graphContainer = this.graphContainer;
    this.waterfall.units = this.units;
    this.waterfall.graphElements = this.graphElements;
    this.waterfall.onBrushed = this.brushed;
    this.waterfall.mouseMoveFunc = this.mouseMove;
    this.waterfall.mouseLeaveFunc = this.mouseLeave;
  }
}
