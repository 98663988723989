import { Component, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { Transponder } from "../models/transponder";
import { Site } from "../models/site";
import { DataViewDemoService } from "../../../services/data-view-demo-service";
import { EntityType } from '../shared/enums/entity-type';
import { EntityOperation } from '../shared/enums/entity-operation';
import { EntityAlias } from "../models/entity-alias";
import { Subject } from "rxjs";
import { AppUtilities } from 'app/services/app-utilities';

@Component({
  selector: 'sites-update',
  templateUrl: './sites-update.component.html',
  styleUrls: ['./sites-update.component.css']
})
export class SiteFormComponent {
  newType: string;
  selectedItem: Site;
  inputsThatHaveChanged = new Array<HTMLSpanElement>();
  @ViewChild('addButton') public addButton: ElementRef;
  @ViewChild('updateButton') public updateButton: ElementRef;
  @ViewChild('closeButton') public closeButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;
  @Output() updateGrid = new Subject();
  updating: boolean;
  aliasEntity: string;
  aliasName: string;
  altitudeOptions = ['LEO', 'MEO', 'GEO', 'HEO'];
  processing: boolean;
  addingAlias: boolean;
  hasError: boolean;
  addingTypes: boolean;

  constructor(private dataService: DataViewDemoService, private utilities: AppUtilities) {
  }
  inputChanged(label: HTMLSpanElement) {
    label.classList.add('text-yellow');
    this.inputsThatHaveChanged.push(label);
  }
  open(existingItem: Site = null): Promise<boolean> {
    if (existingItem != null) {
      this.selectedItem = Site.copy(existingItem);
      this.updating = true;
    } else {
      this.selectedItem = new Site();
    }
    
    return new Promise<boolean>((resolve, reject) => {
      this.cancelButton.nativeElement.onclick = ((e: any) => {
        e.preventDefault();
        resolve(false);
      });
      setTimeout(() => {
        this.closeButton.nativeElement.onclick = ((e: any) => {
          e.preventDefault();
          resolve(true);
        });
        if (this.addButton != null)
          this.addButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.addOrUpdate(EntityOperation.Create, resolve);
          });
        else
          this.updateButton.nativeElement.onclick = ((e: any) => {
            e.preventDefault();
            this.addOrUpdate(EntityOperation.Update, resolve);
          });
      });      
    });
  }

  addOrUpdate(entityOp, resolve) {
    if (this.validate()) {
      this.processing = true;
      let data = {} as any;
      data.data = this.selectedItem;
      if (this.selectedItem.site_id != null){
        data.siteId = this.selectedItem.site_id;
      }
      this.dataService.performEntityOperation(EntityType.Site, entityOp, data).subscribe(
        (result) => {
          this.processing = false;
          if (result.success) {
            this.updateGrid.next();
            if (this.selectedItem.site_id != null) {
              this.utilities.showSuccess('Site Updated', 'Site was updated successfully');
            } else {
              this.utilities.showSuccess('Site Created', 'Site was created successfully');
              let newsite = JSON.parse(result.data);
              this.selectedItem.site_id = newsite.site_id;
              resolve(true);
            }
          } else {
            this.utilities.showError('Error Saving Site', result.responseMsg);
          }
        },
        (err) => {
          this.processing = false;
          this.utilities.showError('Error Saving Site', err);
        }
      );
    }
  }
  update() {
    this.addOrUpdate(EntityOperation.Update, null);
  }
  addAlias(aliasInput: HTMLSpanElement) {
    this.inputChanged(aliasInput);
    let newAlias = new EntityAlias();
    newAlias.name = this.aliasName;
    newAlias.entity = this.aliasEntity;
    this.selectedItem.aliases.push(newAlias);
    this.aliasEntity = '';
    this.aliasName = '';
  }
  addType(typeInput: HTMLSpanElement) {
    this.inputChanged(typeInput);
    this.selectedItem.site_types.push(this.newType);
    this.newType = '';
  }
  validate() {
    if (!(this.selectedItem).name) {
      this.hasError = true;
      return false;
    }
    if (this.selectedItem.site_types.length == 0) {
      this.hasError = true;
      return false;
    }
    return true;
  }
}
