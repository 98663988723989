import { Axis, D3, Selection } from 'd3-ng2-service';
import { XYPoint } from '../../../chart/index';

import { UUID } from 'angular2-uuid';
import { MarkerShape } from './pf-svg-marker-shape';
import { Output, EventEmitter } from '@angular/core';
export class SvgMarker {
  @Output() markerMoved = new EventEmitter();
  pathDataSetName: string;
  name: string;
    x: number;
    y: number;
    markerShape: MarkerShape;
    id = 'marker' + UUID.UUID();
    markerText: string[];
    private d3: D3;
    private markerElem: any;
    private dragElem: any;
    private graphHeight: number;
    private graphWidth: number;

    private mouseOverFunc: any;
    private mouseLeaveFunc: any;
    private dragStartFunc: any;
    private draggingFunc: any;
    private dragEndFunc: any;

    constructor(d3: D3, mouseOverFunc: any,
      mouseLeaveFunc: any, dragStartFunc: any, draggingFunc: any, dragEndFunc: any) {
      this.d3 = d3;
      this.mouseOverFunc = mouseOverFunc;
      this.mouseLeaveFunc = mouseLeaveFunc;
      this.dragStartFunc = dragStartFunc;
      this.draggingFunc = draggingFunc;
      this.dragEndFunc = dragEndFunc;
    }
    addMarkerElementToSvg(svg: any, graphHeight: number, graphWidth: number, shape: MarkerShape, pathDataSetName: string, name: string) {
      this.pathDataSetName = pathDataSetName;
      this.graphHeight = graphHeight;
      this.graphWidth = graphWidth;
      this.markerShape = shape;
      this.name = name;
      this.markerElem = svg.append('g').attr('id', this.id)
        .attr('display', 'none');
      this.dragElem = svg.append('g').attr('id', this.id + 'drag')
        .attr('display', 'none')
        
      if (shape == MarkerShape.circle) {
        this.markerElem.append('circle')
          .attr('id', this.id)
          .attr('r', 6)
          .attr('class', 'svg-marker').on('mouseover', this.mouseOverFunc)
          .on('mouseleave', this.mouseLeaveFunc)
          .call(this.d3.drag()
            .on('start', this.dragStartFunc)
            .on('drag', this.draggingFunc)
            .on('end', this.dragEndFunc));;
      } else if (shape == MarkerShape.square) {
        this.markerElem.append('rect')
          .attr('id', this.id)
          .attr('height', 10)
          .attr('width', 10)
          .attr('class', 'svg-marker').on('mouseover', this.mouseOverFunc)
          .on('mouseleave', this.mouseLeaveFunc)
          .call(this.d3.drag()
            .on('start', this.dragStartFunc)
            .on('drag', this.draggingFunc)
            .on('end', this.dragEndFunc));;
      } else if (shape == MarkerShape.cross) {
        this.markerElem.append('line')
          .attr('id', this.id)
          .attr('class', 'svg-marker').on('mouseover', this.mouseOverFunc)
          .on('mouseleave', this.mouseLeaveFunc)
          .call(this.d3.drag()
            .on('start', this.dragStartFunc)
            .on('drag', this.draggingFunc)
            .on('end', this.dragEndFunc));;
      }
      this.markerElem.append('rect')
          .attr('id', 'label-rect')
        .attr('width', 100)
        .attr('x', 5)
        .attr('height', 30)
        .attr('class', 'marker-text-background-class');

      this.markerElem.append('text')
        .attr('x', 35)
        .attr('dy', '1.2em')
        .style('text-anchor', 'middle')
        .attr('class', 'marker-text-class')
        .attr('font-size', 12);
    }
    showMarkerAtPosition(scaleX, scaleY, position: XYPoint, text: string[]) {
      if (text.length > 0) {
        this.markerText = text;
        this.markerElem.select('text').selectAll('tspan').remove();
        let longestTextLength = 0;
        for (let i = 0; i < text.length; i++) {
          const textMarginLeft = (text[i].length / 2) * 6;
          const extraMargin = 10;
          this.markerElem.select('text').append('tspan')
            .attr('x', textMarginLeft + extraMargin).attr('dy', '1.2em')
            .text(text[i]);
          if (longestTextLength < text[i].length) {
            longestTextLength = text[i].length;
          }
        }
        const tooltipWidth = longestTextLength * 7;
        let textHeight = 17;
        const tooltipHeight = textHeight * text.length;
        this.markerElem.select('#label-rect')
          .attr('height', tooltipHeight)
          .attr('width', tooltipWidth);

        // if the tooltip would go off the graph, attach it to the other side
        let translateX = position.x;
        let translateY = position.y;
        let circleLocationX = 3;
        let circleLocationY = 1;
        if (position.x + tooltipWidth > this.graphWidth) {
          translateX -= tooltipWidth;
          circleLocationX += tooltipWidth;
        }
        if (position.y + tooltipHeight > this.graphHeight) {
          translateY -= tooltipHeight;
          circleLocationY += tooltipHeight;
        }
        this.markerElem.attr('transform', 'translate(' + translateX + ',' + translateY + ')').attr('display', null);
        if (this.markerShape == MarkerShape.circle) {
          this.markerElem.select('#' + this.id)
            .attr('cx', circleLocationX)
            .attr('cy', circleLocationY);
        } else if (this.markerShape == MarkerShape.square) {
          this.markerElem.select('#' + this.id)
            .attr('x', circleLocationX)
            .attr('y', circleLocationY);
        } else if (this.markerShape == MarkerShape.cross) {
          this.markerElem.select('#' + this.id)
            .attr('x1', circleLocationX - 2)
            .attr('x2', circleLocationX + 2)
            .attr('y1', circleLocationY - 2)
            .attr('y2', circleLocationY + 2);
        }
        this.x = scaleX.invert(position.x);
        this.y = scaleY.invert(position.y);
      } else {
        this.hide();
      }
    }
    hide() {
      if (this.markerElem != null) {
        this.markerElem.attr('display', 'none');
        }
    }
    reset() {
      this.markerElem.remove();
      this.markerElem = null;
    }
}
