import { Component, OnDestroy, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuItem, Calendar } from 'primeng/primeng';
import { Subscription, Observable } from 'rxjs/Rx';
import { ZoomEventInfo, CanvasGraphElementModel } from 'app/components/graphs/chart';
import { UnitConversion, UnitOptions } from 'app/components/graphs/unit';
import {
  SpectrumAnalyzerDataParameters, SpectrumAnalyzerConfiguration, CarrierRegion, SpecanLineType,
  SpectrumAnalyzerSeries, SpectrumAnalyzerComponent, SpectrumAnalyzerUnits
} from 'app/components/graphs/specan';
import {
  WaterfallDataParameters,
  WaterfallPlotConfiguration,
  WaterfallSeries,
  WaterfallXY, WaterfallData,
  WaterfallPlotDataModel, WaterfallPlotComponent
} from 'app/components/graphs/waterfall';
import { DataViewDemoService } from '../../services/data-view-demo-service';
import { AppUtilities } from '../../services/app-utilities';
import { ConfigurationService } from '../../services/config-service';
import { UserMessage, Trace, Waterfall, WaterfallAnalysis, Carrier, DataViewConfiguration, Burst, TrendDataSet } from '../../models/index';

import { EntityDef } from 'app/models/entity-def';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'app/services/user-service';
import { MarkerShape } from 'app/components/graphs/chart';
import { AnalysisSelectionBarComponent } from 'app/components/analysis-selection-bar/analysis-selection-bar.component';
import { AnalysisParams } from 'app/models/analysis-params';
@Component({
  selector: 'data-view-demo',
  templateUrl: './data-view-demo.component.html',
  styleUrls: ['./data-view-demo.component.css']
})

export class DataViewDemoComponent {
  currentAnalysisParams = new AnalysisParams();
  configuration: DataViewConfiguration = new DataViewConfiguration();
  @ViewChild('waterfall') waterfallPlot: WaterfallPlotComponent;
  @ViewChild('modWaterfall') modWaterfallPlot: WaterfallPlotComponent;
  @ViewChild('specan') specan: SpectrumAnalyzerComponent;
  @ViewChild('modSpecan') modSpecan: SpectrumAnalyzerComponent;
  @ViewChild('trends') trendsPlot: SpectrumAnalyzerComponent;
  @ViewChild('calendar') calendar: Calendar;
  @ViewChild('waterfallAnalysis') waterfallAnalysisPlot: SpectrumAnalyzerComponent;
  @ViewChild('waterfallModAnalysis') waterfallModAnalysisPlot: SpectrumAnalyzerComponent;
  @ViewChild(AnalysisSelectionBarComponent) analysisSelectionBar: AnalysisSelectionBarComponent;

  interval: any;
  selectedMarker: string;
  showingZoom: boolean;
  markers = new Array<string>();
  loadingTitle: string;
  waterfallDataSeries: WaterfallSeries = new WaterfallSeries('Interfered');
  modWaterfallDataSeries: WaterfallSeries = new WaterfallSeries('Mod');
  specanDataSeries: SpectrumAnalyzerSeries = new SpectrumAnalyzerSeries('SA Trace');
  modSpecanDataSeries: SpectrumAnalyzerSeries = new SpectrumAnalyzerSeries('Mod Trace');
  waterfallAnalysisSeries: SpectrumAnalyzerSeries = new SpectrumAnalyzerSeries('Waterfall Analysis');
  waterfallModAnalysisSeries: SpectrumAnalyzerSeries = new SpectrumAnalyzerSeries('Mod Waterfall Analysis');
  trendMeasEirpDataSeries = new SpectrumAnalyzerSeries('MEAS EIRP');
  trendNomEirpDataSeries = new SpectrumAnalyzerSeries('NOM EIRP');
  trendMeasCFDataSeries = new SpectrumAnalyzerSeries('MEAS CF');
  trendNomCFDataSeries = new SpectrumAnalyzerSeries('NOM CF');
  trendNomEbnoDataSeries = new SpectrumAnalyzerSeries('NOM EBNO');
  trendMeasEbnoDataSeries = new SpectrumAnalyzerSeries('MEAS EBNO');
  trendAbsMeasEirpDataSeries = new SpectrumAnalyzerSeries('MEAS EIRP');
  trendAbsNomEirpDataSeries = new SpectrumAnalyzerSeries('NOM EIRP');
  trendAbsMeasCFDataSeries = new SpectrumAnalyzerSeries('MEAS CF');
  trendAbsNomCFDataSeries = new SpectrumAnalyzerSeries('NOM CF');
  trendAbsNomEbnoDataSeries = new SpectrumAnalyzerSeries('NOM EBNO');
  trendAbsMeasEbnoDataSeries = new SpectrumAnalyzerSeries('MEAS EBNO');
  trendCNDataSeries = new SpectrumAnalyzerSeries('CN');
  trendN0DataSeries = new SpectrumAnalyzerSeries('N0');
  trendMeasCN0DataSeries = new SpectrumAnalyzerSeries('MEAS C/N0');
  trendAbsMeasCN0DataSeries = new SpectrumAnalyzerSeries('MEAS C/N0');
  trendNomCN0DataSeries = new SpectrumAnalyzerSeries('NOM C/N0');
  trendAbsNomCN0DataSeries = new SpectrumAnalyzerSeries('NOM C/N0');
  trendCFDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS CF');
  trendCFDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS CF');
  trendEbnoDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS EBNO');
  trendEbnoDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS EBNO');
  trendEirpDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS EIRP');
  trendEirpDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS EIRP');
  trendCN0DeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS C/N0');
  trendCN0DeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS C/N0');
  trendOccBWDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS BW');
  trendOccBWDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS BW');
  trendMeasSymbolRateDataSeries = new SpectrumAnalyzerSeries('MEAS SymbRate');
  trendMeasDataRateDataSeries = new SpectrumAnalyzerSeries('MEAS DataRate');
  trendNomOccBWDataSeries = new SpectrumAnalyzerSeries('NOM Occ BW');
  trendMeasOccBWDataSeries = new SpectrumAnalyzerSeries('MEAS Occ BW');
  trendAbsNomOccBWDataSeries = new SpectrumAnalyzerSeries('NOM Occ BW');
  trendAbsMeasOccBWDataSeries = new SpectrumAnalyzerSeries('MEAS Occ BW');
  trendAssignedBWDataSeries = new SpectrumAnalyzerSeries('Assgn BW');
  trendAbsCFDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS CF');
  trendAbsCFDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS CF');
  trendAbsEbnoDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS EBNO');
  trendAbsEbnoDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS EBNO');
  trendAbsEirpDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS EIRP');
  trendAbsEirpDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS EIRP');
  trendAbsCN0DeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS C/N0');
  trendAbsCN0DeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS C/N0');
  trendAbsOccBWDeltaMaxDataSeries = new SpectrumAnalyzerSeries('LIMITS BW');
  trendAbsOccBWDeltaMinDataSeries = new SpectrumAnalyzerSeries('LIMITS BW');
  allTrendDataSetList: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();

  waterfallAnalysisSeriesList: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();
  waterfallModAnalysisSeriesList: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();
  specanUnits: SpectrumAnalyzerUnits = new SpectrumAnalyzerUnits();
  specanDataModelList: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();
  waterfallConfig: WaterfallPlotConfiguration = new WaterfallPlotConfiguration();
  modWaterfallConfig: WaterfallPlotConfiguration = new WaterfallPlotConfiguration();
  waterfallDataParams: WaterfallDataParameters = new WaterfallDataParameters();
  modWaterfallDataParams: WaterfallDataParameters = new WaterfallDataParameters();
  specanConfig: SpectrumAnalyzerConfiguration = new SpectrumAnalyzerConfiguration();
  specanDataParams: SpectrumAnalyzerDataParameters = new SpectrumAnalyzerDataParameters();
  analysisGraphConfig: SpectrumAnalyzerConfiguration = new SpectrumAnalyzerConfiguration();
  modAnalysisGraphConfig: SpectrumAnalyzerConfiguration = new SpectrumAnalyzerConfiguration();
  analysisGraphDataParams: SpectrumAnalyzerDataParameters = new SpectrumAnalyzerDataParameters();
  modAnalysisGraphDataParams: SpectrumAnalyzerDataParameters = new SpectrumAnalyzerDataParameters();

  trendDataModelList: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();
  trendConfig: SpectrumAnalyzerConfiguration = new SpectrumAnalyzerConfiguration();
  trendDataParams: SpectrumAnalyzerDataParameters = new SpectrumAnalyzerDataParameters();
  trendsContextMenuItems: MenuItem[];
  specanContextMenuItems: MenuItem[];
  carrierRegions: CarrierRegion[] = new Array<CarrierRegion>();
  showLoading: boolean;
  modWaterfallDataExists: boolean;
  atLeastOneAnalysisHasRun: boolean;
  waterfallUnits = new Object();

  //represents all data available for the specan
  //the specan series' data will change based on the selected date range in the waterfall
  allWaterfallData: WaterfallSeries = new WaterfallSeries('all data');
  serverWaterfallData: WaterfallPlotDataModel = new WaterfallPlotDataModel();
  saSpecanData: Trace[] = new Array<Trace>();
  modSpecanData: Trace[] = new Array<Trace>();
  carrierRegionColors = ['#f44242', '#f49541', '#f4ee41', '#91f441', '#41f461', '#41f4b5', '#41f4e2', '#41c7f4', '#418bf4', '#414cf4',
    '#6a41f4', '#a041f4', '#d041f4', '#f441eb', '#f44197', '#f44167'];

  mainTraceColor = '#b9f5eb';
  minTraceColor = '#b9f5eb';
  maxTraceColor = '#b9f5eb';
  avgTraceColor = '#b9f5eb';
  minTraceVisible = false;
  maxTraceVisible = false;
  avgTraceVisible = false;
  
  autoscaleDuringPlayback = false;

  burstData: Burst[] = new Array<Burst>();
  burstGraphElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();
  burstWaterfallElements: CanvasGraphElementModel[] = this.burstGraphElements;
  modBurstData: Burst[] = new Array<Burst>();
  modBurstGraphElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();
  modBurstWaterfallElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();

  currentTrendData: TrendDataSet;

  calendarVisible = true;
  configServiceSubscription: Subscription;
  traceSub: Subscription;

  testInterval: any;
  startAnalysisOnLogin: boolean;

  constructor(private waterfallDataService: DataViewDemoService, private configService: ConfigurationService, private userService: UserService,
    private utilityService: AppUtilities, private route: ActivatedRoute) {
    this.configureSAAnalysisGraph();
    this.configureSAWFGraph();
    this.configureSpecan();
    this.configureModWFGraph();
    this.configureModAnalysisGraph();
    this.configureTrendGraph();
    this.refreshCMIcons();
    this.constructAllTrendDataSets();
    let timeout = setTimeout(() => {
      this.specanDataModelList.push(this.specanDataSeries);
      clearTimeout(timeout);
    });
    this.specanDataParams.dbPerDivValueChange.subscribe((value) => {
      this.analysisGraphDataParams.dbPerDiv = value;
      this.modAnalysisGraphDataParams.dbPerDiv = value;
    });
  }

  findBurstForFreq(freq: number, timestamp: number): Burst {
    let burst;
    for (let i = 0; i < this.burstData.length; i++) {
      if (freq < this.burstData[i].stopFreq && freq > this.burstData[i].startFreq &&
        this.burstData[i].burstStartDate < timestamp && this.burstData[i].burstEndDate > timestamp) {
        burst = this.burstData[i];
        break;
      }
    }
    return burst;
  }
  findModBurstForFreq(freq: number, timestamp: number): Burst {
    let burst;
    for (let i = 0; i < this.modBurstData.length; i++) {
      if (freq < this.modBurstData[i].stopFreq && freq > this.modBurstData[i].startFreq &&
        this.modBurstData[i].burstStartDate < timestamp && this.modBurstData[i].burstEndDate > timestamp) {
        burst = this.modBurstData[i];
        break;
      }
    }
    return burst;
  }
  private getDateString(timestamp) {
    let string = new Date(timestamp).toUTCString();
    return string.replace(/GMT-.*$/g, 'UTC');
  }
  yAxisTickFormatFunc = function (d: number) {
    let string = new Date(d).toUTCString();
    return string.replace(/GMT-.*$/g, 'UTC');
  };
  analysisXAxisTickFormatFunc = function (d: number) {
    let date = new Date(d);
    let mm = date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate();
    let hh = date.getHours();
    let minmin = date.getMinutes();
    let ss = date.getSeconds();

    let string = [(mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,
      date.getFullYear()
    ].join('/');
    string += ' ' + [(hh > 9 ? '' : '0') + hh,
      (minmin > 9 ? '' : '0') + minmin,
      (ss > 9 ? '' : '0') + ss
    ].join(':');
    return string;
  };
  ngOnInit() {
    this.route.queryParamMap
      .subscribe((paramMap: any) => {
        let satId = paramMap.params['satelliteId'];
        let noradId = paramMap.params['noradId'];
        let transponderId = paramMap.params['transponderId'];
        let carrierId = paramMap.params['carrierId'];
        let alarmState = paramMap.params['alarm'];
        let loadLast250 = paramMap.params['loadLast250'];
        let timestamp = paramMap.params['timestamp'];
        if (satId != null && transponderId != null) {
          this.currentAnalysisParams.satelliteId = satId;
          this.currentAnalysisParams.transponderId = transponderId;
          this.currentAnalysisParams.carrierId = carrierId;
          this.currentAnalysisParams.stopInMs = timestamp;
          this.currentAnalysisParams.startInMs = timestamp;
          if (loadLast250 != null && loadLast250 == 'false') {
            this.currentAnalysisParams.loadRecent = false;
          } else
            this.currentAnalysisParams.loadRecent = true;
          this.startAnalysisOnLogin = true;
        }
      })
  }
  selectedDomainAndRangeChanged(zoomEvent: ZoomEventInfo) {
    this.stopAnalysis();
    if (zoomEvent.graphDomainY == null) {
      zoomEvent.graphDomainY = this.waterfallPlot.getCurrentGraphDomainY();
    }
    this.initializeLoadingSpinners();
    if (!zoomEvent.zoomToExtent) {
      this.getCurrentWaterfallAndSpecanData(Math.round(zoomEvent.graphDomainY[0]), Math.round(zoomEvent.graphDomainY[1]), Math.round(zoomEvent.dataDomainX[0]), Math.round(zoomEvent.dataDomainX[1]), this.currentAnalysisParams.limit);
      this.specan.setDomainX([Math.round(zoomEvent.dataDomainX[0]), Math.round(zoomEvent.dataDomainX[1])]);
      this.waterfallPlot.setDomainX([Math.round(zoomEvent.dataDomainX[0]), Math.round(zoomEvent.dataDomainX[1])]);
    } else {
      this.getCurrentWaterfallAndSpecanData(this.currentAnalysisParams.startInMs, this.currentAnalysisParams.stopInMs, 0, 0, this.currentAnalysisParams.limit);
    }
  }

  openConfigPanel() {
    this.utilityService.toggleSlideoutSource.next('dataview-config');
  }

  toggleSpecanZoom(show = null) {
    if (show != null) {
      this.showingZoom = show;
    } else {
      this.showingZoom = !this.showingZoom;
    }
    if (this.showingZoom) {
      this.specan.showZoomMenu();
    } else {
      this.specan.hideZoomMenu();
    }
  }
  saveColors() {
    this.specanDataSeries.graphColor = this.mainTraceColor;
    this.specanDataSeries.getDataSetByName('min').color = this.minTraceColor;
    this.specanDataSeries.getDataSetByName('max').color = this.maxTraceColor;
    this.specanDataSeries.getDataSetByName('avg').color = this.avgTraceColor;
    this.specan.refreshGraph();
  }
  restoreDefaultColors() {
    this.mainTraceColor = '#b9f5eb';
    this.minTraceColor = '#b9f5eb';
    this.maxTraceColor = '#b9f5eb';
    this.avgTraceColor = '#b9f5eb';
    this.specanDataSeries.graphColor = this.mainTraceColor;
    this.specanDataSeries.getDataSetByName('min').color = this.minTraceColor;
    this.specanDataSeries.getDataSetByName('max').color = this.maxTraceColor;
    this.specanDataSeries.getDataSetByName('avg').color = this.avgTraceColor;
    this.specan.refreshGraph();
  }
  resetMinMaxAvg() {
    this.specanDataSeries.resetDataModel();
    this.minTraceVisible = false;
    this.maxTraceVisible = false;
    this.avgTraceVisible = false;
    this.showSpecanDataAtTimestamp(this.waterfallPlot.getHTrackingLineTimestamp());
    this.specan.refreshGraph();
  }
  startAnalysis(params) {
    this.currentAnalysisParams = params;
    if (this.currentAnalysisParams.liveUpdate) {
      this.utilityService.showInfo('Feature Coming Soon', 'This feature is not yet implemented for this analysis view.');
      //this.startLiveAnalysis();
      this.stopAnalysis();
    } else {
      this.resetMarkers();
      this.resetTraceVisibility();
      this.specanDataSeries.resetDataModel();
      this.modSpecanDataSeries.resetDataModel();
      this.stopWaterfallPlayback();
      this.initializeLoadingSpinners();
      this.atLeastOneAnalysisHasRun = true;
      this.waterfallConfig.graphVisible = true;
      setTimeout(() => {
        if (this.currentAnalysisParams.liveUpdate) {
          //this.utilityService.showInfo('Feature Coming Soon', 'This feature is not yet implemented for this analysis view.');
          //this.startLiveAnalysis();
        } else {
          this.getCurrentWaterfallAndSpecanData(this.currentAnalysisParams.startInMs, this.currentAnalysisParams.stopInMs, 0, 0, this.currentAnalysisParams.limit);
        }
        if (this.waterfallAnalysisSeriesList.length === 0) {
          this.waterfallAnalysisSeriesList.push(this.waterfallAnalysisSeries);
        }
      });
    }
  }
  resetMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.specan.removeMarker(this.markers[i]);
    }
    this.markers.splice(0, this.markers.length);
  }
  removeSelectedMarker() {
    this.specan.removeMarker(this.selectedMarker);
    let markerToRemove = this.markers.findIndex((marker) => {
      return marker == this.selectedMarker;
    });
    this.markers.splice(markerToRemove, 1);
  }
  startLiveAnalysis() {
    let idx = 0;
    this.setDefaultGradientConfig();
    this.saSpecanData = new Array<Trace>();
    this.waterfallConfig.disableGraphInteraction = true;
    let renderedOnce = false;

    let timeout = setTimeout(() => {
      this.waterfallPlot.clearCanvas();
      let intervalLength = 1000;
      let needToInitializeEmpty = true;
      this.traceSub = this.getNextTrace(intervalLength).subscribe((result) => {
        if (result.success) {
          let now = Date.now();
          let newData = result.data as Trace;
          if (needToInitializeEmpty) {
            this.initializeEmptyData(intervalLength, now, newData.startFreq, newData.stepFreq, newData.powerValues.length);
            needToInitializeEmpty = false;
          }
          if (this.saSpecanData.length > this.waterfallPlot.getGraphCanvasHeight()) {
            this.waterfallDataSeries.splice(0, 1);
            this.saSpecanData.pop();
          }
          newData.timestamp = now;
          this.saSpecanData.unshift(newData);
          this.specanDataSeries.resetDataModel();
          this.specanDataSeries.initializePowerValues(newData.powerValues, newData.startFreq, newData.stepFreq);
          this.specan.renderGraph();

          // let waterfallModel = new WaterfallPlotDataModel();
          let waterfallDataLine = new WaterfallData();
          waterfallDataLine.timestamp = newData.timestamp;
          waterfallDataLine.initializePowerValues(newData.powerValues,
            newData.startFreq, newData.stepFreq);
          this.waterfallDataSeries.push(waterfallDataLine);
          if (!renderedOnce) {
            this.waterfallPlot.renderGraph();
            renderedOnce = true;
          } else {
            this.waterfallPlot.renderCanvasOnly();
          }
          this.analysisSelectionBar.stopAnalysis();
        } else {
          this.showError('Error Retrieving SA Specan Data', result.responseMsg);
        }


      },
        (err) => {
          this.specanConfig.showLoadingOverlay = false;
          this.traceSub.unsubscribe();
          this.showError('Error Retrieving SA Specan Data', err.statusText);
        });
    }, 75);
  }

  getNextTrace(interval) {
    let idx = 0;
    return Observable.interval(interval).flatMap(() => {
      if (idx > 350) {
        idx = 0;
      }
      return this.waterfallDataService.getLiveTraces(this.currentAnalysisParams.satelliteId, this.currentAnalysisParams.transponderId, this.currentAnalysisParams.carrierId, idx++);
    });
  }

  initializeWaterfalls() {
    if (this.modWaterfallPlot != null) {
      this.modWaterfallPlot.renderGraph();
    }
    this.waterfallPlot.renderGraph();
  }

  initializeEmptyData(interval, startingTimestamp, startFreq, stepFreq, traceLength) {
    this.saSpecanData = new Array<Trace>();
    let currentTimestamp = startingTimestamp;
    let count = this.waterfallPlot.getGraphCanvasHeight();
    for (let i = 0; i < count; i++) {
      currentTimestamp -= interval;
      let newTrace = new Trace();
      let powerVals = new Array<number>();
      for (let j = 0; j < traceLength; j++) {
        powerVals.push(-105);
      }
      newTrace.startFreq = startFreq;
      newTrace.stepFreq = stepFreq;
      newTrace.timestamp = currentTimestamp;
      newTrace.powerValues = powerVals;
      this.saSpecanData.push(newTrace);
      let waterfallDataLine = new WaterfallData();
      waterfallDataLine.timestamp = newTrace.timestamp;
      waterfallDataLine.initializePowerValues(newTrace.powerValues,
        newTrace.startFreq, newTrace.stepFreq);
      this.waterfallDataSeries.push(waterfallDataLine);
    }
  }

  getTrendData() {
    let sub = this.waterfallDataService.getTrends(this.currentAnalysisParams.satelliteId, this.currentAnalysisParams.transponderId, this.currentAnalysisParams.carrierId)
      .subscribe((response) => {
        this.trendConfig.showLoadingOverlay = false;
        if (response.success) {
          let trend = response.data as TrendDataSet;
          this.currentTrendData = trend;
          this.resetTrendDataSetVisibility();
          this.updateTrendsPlot();
          this.initializeTrendData(trend);
        } else {
          this.showError('Error Retrieving Trend Information', response.responseMsg);
        }
        sub.unsubscribe();
      }, error => {
        sub.unsubscribe();
        this.trendConfig.showLoadingOverlay = false;
        this.showError('Error Retrieving Trend Information', error.statusText);
        this.stopAnalysis();
      });
  }

  setMeasNomValuesToMatch() {
    this.trendAbsMeasOccBWDataSeries.visible = this.trendMeasOccBWDataSeries.visible;
    this.trendAbsNomOccBWDataSeries.visible = this.trendNomOccBWDataSeries.visible;
    this.trendAbsMeasCFDataSeries.visible = this.trendMeasCFDataSeries.visible;
    this.trendAbsNomCFDataSeries.visible = this.trendNomCFDataSeries.visible;
    this.trendAbsMeasEbnoDataSeries.visible = this.trendMeasEbnoDataSeries.visible;
    this.trendAbsMeasEbnoDataSeries.visible = this.trendMeasEbnoDataSeries.visible;
    this.trendAbsNomEbnoDataSeries.visible = this.trendNomEbnoDataSeries.visible;
    this.trendAbsNomEirpDataSeries.visible = this.trendNomEirpDataSeries.visible;
    this.trendAbsMeasEirpDataSeries.visible = this.trendMeasEirpDataSeries.visible;
    this.trendAbsNomCN0DataSeries.visible = this.trendNomCN0DataSeries.visible;
    this.trendAbsMeasCN0DataSeries.visible = this.trendMeasCN0DataSeries.visible;
  }
  //models for these in the view are the delta max data series, so only update the other ones on change of that model
  setCFLimitVisibility(visible) {
    this.trendCFDeltaMinDataSeries.visible = visible;
    this.trendAbsCFDeltaMaxDataSeries.visible = visible;
    this.trendAbsCFDeltaMinDataSeries.visible = visible;
    this.updateTrendsPlot();
  }

  setBWLimitVisibility(visible) {
    this.trendOccBWDeltaMinDataSeries.visible = visible;
    this.trendAbsOccBWDeltaMaxDataSeries.visible = visible;
    this.trendAbsOccBWDeltaMinDataSeries.visible = visible;
    this.updateTrendsPlot();
  }

  setCN0LimitVisibility(visible) {
    this.trendCN0DeltaMinDataSeries.visible = visible;
    this.trendAbsCN0DeltaMaxDataSeries.visible = visible;
    this.trendAbsCN0DeltaMinDataSeries.visible = visible;
    this.updateTrendsPlot();
  }

  setEirpLimitVisibility(visible) {
    this.trendEirpDeltaMinDataSeries.visible = visible;
    this.trendAbsEirpDeltaMaxDataSeries.visible = visible;
    this.trendAbsEirpDeltaMinDataSeries.visible = visible;
    this.updateTrendsPlot();
  }

  setEbnoLimitVisibility(visible) {
    this.trendEbnoDeltaMinDataSeries.visible = visible;
    this.trendAbsEbnoDeltaMaxDataSeries.visible = visible;
    this.trendAbsEbnoDeltaMinDataSeries.visible = visible;
    this.updateTrendsPlot();
  }

  setSelectedDataSet(dataset: SpectrumAnalyzerSeries) {
    if (dataset.visible) {
      this.trendConfig.floatingTooltip = false;
      let prevSelectionIdx = this.trendsPlot.dataModels.findIndex((data) => {
        return data.selected;
      });
      if (prevSelectionIdx != -1) {
        this.trendsPlot.dataModels[prevSelectionIdx].selected = false;
      }
      let datasetIdx = this.trendsPlot.dataModels.findIndex((data) => {
        return data.legendName == dataset.legendName;
      });
      if (datasetIdx != -1) {
        this.trendsPlot.dataModels[datasetIdx].selected = true;
      }
    } else {
      for (let i = 0; i < this.trendsPlot.dataModels.length; i++) {
        if (this.trendsPlot.dataModels[i].visible == true) {
          this.trendsPlot.dataModels[i].selected = true;
          break;
        }
      }
    }
  }

  updateTrendsPlot(dataset = null) {
    this.setMeasNomValuesToMatch();
    //timeout is necessary to apply changes
    let timeout1 = setTimeout(() => {

      let onlyEirpVisible = true;
      let onlyEbnoVisible = true;
      let axisVisibilityChanged = this.trendConfig.showYAxesTickLabels;
      let onlyCFVisible = true;
      let onlyOccBWVisible = true;
      let onlyCN0Visible = true;
      let autoscaleGraph = false;
      for (let i = 0; i < this.allTrendDataSetList.length; i++) {
        if (this.allTrendDataSetList[i].legendName != 'MEAS EIRP' && this.allTrendDataSetList[i].legendName != 'NOM EIRP'
          && this.allTrendDataSetList[i].legendName != 'LIMITS EIRP' && this.allTrendDataSetList[i].visible) {
          onlyEirpVisible = false;
        }
        if (this.allTrendDataSetList[i].legendName != 'MEAS EBNO' && this.allTrendDataSetList[i].legendName != 'NOM EBNO'
          && this.allTrendDataSetList[i].legendName != 'LIMITS EBNO' && this.allTrendDataSetList[i].visible) {
          onlyEbnoVisible = false;
        }
        if (this.allTrendDataSetList[i].legendName != 'MEAS CF' && this.allTrendDataSetList[i].legendName != 'NOM CF'
          && this.allTrendDataSetList[i].legendName != 'LIMITS CF' && this.allTrendDataSetList[i].visible) {
          onlyCFVisible = false;
        }
        if (this.allTrendDataSetList[i].legendName != 'MEAS Occ BW' && this.allTrendDataSetList[i].legendName != 'NOM Occ BW'
          && this.allTrendDataSetList[i].legendName != 'LIMITS BW' && this.allTrendDataSetList[i].visible) {
          onlyOccBWVisible = false;
        }
        if (this.allTrendDataSetList[i].legendName != 'MEAS C/N0' && this.allTrendDataSetList[i].legendName != 'NOM C/N0'
          && this.allTrendDataSetList[i].legendName != 'LIMITS C/N0' && this.allTrendDataSetList[i].visible) {
          onlyCN0Visible = false;
        }
      }
      if (onlyEirpVisible) {
        this.setAbsValuesForEirp();
        autoscaleGraph = true;
      } else if (onlyEbnoVisible) {
        this.setAbsValuesForEbno();
        autoscaleGraph = true;
      } else if (onlyCFVisible) {
        this.setAbsValuesForCF();
        autoscaleGraph = true;
      } else if (onlyOccBWVisible) {
        this.setAbsValuesForOccBW();
        autoscaleGraph = true;
      } else if (onlyCN0Visible) {
        this.setAbsValuesForCN0();
        autoscaleGraph = true;
      } else {
        this.setGraphForNormalizedValues();
      }
      if (dataset != null) {
        this.setSelectedDataSet(dataset);
      }
      let timeout = setTimeout(() => {
        if (axisVisibilityChanged != this.trendConfig.showYAxesTickLabels) {
          this.trendsPlot.renderGraph();
        }
        this.trendsPlot.refreshGraph();
        if (autoscaleGraph) {
          this.trendsPlot.autoscaleGraph();
        }
        clearTimeout(timeout);
      });

      clearTimeout(timeout1);
    });

  }
  // these methods toggle the graph between the normalized data (shown when multiple types of data are selected, such as both eirp and esn0)
  // and absolute data, when only one type is selected, such as eirp
  setGraphForNormalizedValues() {
    this.trendConfig.graphTitle = 'Trends (Normalized)';
    this.trendConfig.showYAxesTickLabels = false;
    this.initializeTrendData(this.currentTrendData);
    this.trendConfig.yAxisTickFormatFunc = null;
    this.trendDataParams.axisMaxY = 1.1;
    this.trendDataParams.axisMinY = -.1;
    this.trendConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(this.waterfallConfig.yAxisTickFormatFunc(position.x));
      values.push(position.y.toPrecision(6));
      return values;
    };
  }
  setAbsValuesForOccBW() {
    this.trendConfig.graphTitle = 'Trends (Absolute)';
    this.trendsPlot.dataModels = [];
    this.trendAbsNomOccBWDataSeries.visible = this.trendNomOccBWDataSeries.visible;
    this.trendAbsMeasOccBWDataSeries.visible = this.trendMeasOccBWDataSeries.visible;
    this.trendsPlot.dataModels.push(this.trendAbsNomOccBWDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsMeasOccBWDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsOccBWDeltaMaxDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsOccBWDeltaMinDataSeries);
    this.trendConfig.showYAxesTickLabels = true;
    this.trendConfig.yAxisTickFormatFunc = null;
    this.trendDataParams.axisMaxY = null;
    this.trendDataParams.axisMinY = null;
    this.trendConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(this.waterfallConfig.yAxisTickFormatFunc(position.x));
      values.push(position.y.toPrecision(6));
      return values;
    };
  }
  setAbsValuesForCN0() {
    this.trendConfig.graphTitle = 'Trends (Absolute)';
    this.trendsPlot.dataModels = [];
    this.trendAbsNomCN0DataSeries.visible = this.trendNomCN0DataSeries.visible;
    this.trendAbsMeasCN0DataSeries.visible = this.trendMeasCN0DataSeries.visible;
    this.trendsPlot.dataModels.push(this.trendAbsNomCN0DataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsMeasCN0DataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsCN0DeltaMaxDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsCN0DeltaMinDataSeries);
    this.trendConfig.showYAxesTickLabels = true;
    this.trendConfig.yAxisTickFormatFunc = null;
    this.trendDataParams.axisMaxY = null;
    this.trendDataParams.axisMinY = null;
    this.trendConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(this.waterfallConfig.yAxisTickFormatFunc(position.x));
      values.push(position.y.toPrecision(6));
      return values;
    };
  }
  setAbsValuesForEbno() {
    this.trendConfig.graphTitle = 'Trends (Absolute)';
    this.trendsPlot.dataModels = [];
    this.trendAbsMeasEbnoDataSeries.visible = this.trendMeasEbnoDataSeries.visible;
    this.trendAbsNomEbnoDataSeries.visible = this.trendNomEbnoDataSeries.visible;
    this.trendsPlot.dataModels.push(this.trendAbsMeasEbnoDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsNomEbnoDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsEbnoDeltaMaxDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsEbnoDeltaMinDataSeries);
    this.trendConfig.showYAxesTickLabels = true;
    this.trendDataParams.axisMaxY = null;
    this.trendDataParams.axisMinY = null;
    this.trendConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(this.waterfallConfig.yAxisTickFormatFunc(position.x));
      values.push(position.y.toPrecision(6));
      return values;
    };
    this.trendConfig.yAxisTickFormatFunc = null;
  }
  setAbsValuesForCF() {
    this.trendConfig.graphTitle = 'Trends (Absolute)';
    this.trendsPlot.dataModels = [];
    this.trendAbsMeasCFDataSeries.visible = this.trendMeasCFDataSeries.visible;
    this.trendAbsNomCFDataSeries.visible = this.trendNomCFDataSeries.visible;
    this.trendsPlot.dataModels.push(this.trendAbsCFDeltaMaxDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsCFDeltaMinDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsMeasCFDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsNomCFDataSeries);
    this.trendConfig.showYAxesTickLabels = true;
    this.trendDataParams.axisMaxY = null;
    this.trendDataParams.axisMinY = null;
    this.trendConfig.yAxisTickFormatFunc = (num) => {
      return (this.specanUnits.xAxisUnit.defaultUnit.multiplier * num).toPrecision(5);
    };
    this.trendConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(this.waterfallConfig.yAxisTickFormatFunc(position.x));
      values.push((this.specanUnits.xAxisUnit.defaultUnit.multiplier * position.y).toPrecision(8) + ' ' + this.specanUnits.xAxisUnit.defaultUnit.name);
      return values;
    };
  }
  setAbsValuesForEirp() {
    this.trendConfig.graphTitle = 'Trends (Absolute)';
    this.trendsPlot.dataModels = [];
    this.trendAbsMeasEirpDataSeries.visible = this.trendMeasEirpDataSeries.visible;
    this.trendAbsNomEirpDataSeries.visible = this.trendNomEirpDataSeries.visible;
    this.trendsPlot.dataModels.push(this.trendAbsMeasEirpDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsNomEirpDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsEirpDeltaMaxDataSeries);
    this.trendsPlot.dataModels.push(this.trendAbsEirpDeltaMinDataSeries);
    this.trendConfig.showYAxesTickLabels = true;
    this.trendConfig.yAxisTickFormatFunc = null;
    this.trendDataParams.axisMaxY = null;
    this.trendDataParams.axisMinY = null;
    this.trendConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(this.waterfallConfig.yAxisTickFormatFunc(position.x));
      values.push(position.y.toPrecision(6));
      return values;
    };
  }
  setEirpCFOccBWCN0AndEbnoVisibility(visible) {
    this.trendMeasEirpDataSeries.visible = visible;
    this.trendNomEirpDataSeries.visible = visible;
    this.trendMeasCFDataSeries.visible = visible;
    this.trendNomCFDataSeries.visible = visible;
    this.trendNomEbnoDataSeries.visible = visible;
    this.trendMeasEbnoDataSeries.visible = visible;
    this.trendMeasOccBWDataSeries.visible = visible;
    this.trendNomOccBWDataSeries.visible = visible;
    this.trendNomCN0DataSeries.visible = visible;
    this.trendMeasCN0DataSeries.visible = visible;
  }
  setNormalizedDataSetsVisibility(visible: boolean) {
    this.trendCNDataSeries.visible = visible;
    this.trendN0DataSeries.visible = visible;
    this.trendEirpDeltaMaxDataSeries.visible = visible;
    this.trendEirpDeltaMinDataSeries.visible = visible;
    this.trendCFDeltaMinDataSeries.visible = visible;
    this.trendCFDeltaMaxDataSeries.visible = visible;
    this.trendOccBWDeltaMinDataSeries.visible = visible;
    this.trendAssignedBWDataSeries.visible = visible;
    this.trendOccBWDeltaMaxDataSeries.visible = visible;
    this.trendCN0DeltaMaxDataSeries.visible = visible;
    this.trendCN0DeltaMinDataSeries.visible = visible;
    this.trendEbnoDeltaMinDataSeries.visible = visible;
    this.trendEbnoDeltaMaxDataSeries.visible = visible;
    this.trendMeasDataRateDataSeries.visible = visible;
    this.trendMeasSymbolRateDataSeries.visible = visible;
  }
  setAbsoluteDataSetsVisibility(visible: boolean) {
    this.trendAbsMeasCFDataSeries.visible = visible;
    this.trendAbsMeasEbnoDataSeries.visible = visible;
    this.trendAbsMeasEirpDataSeries.visible = visible;
    this.trendAbsNomCFDataSeries.visible = visible;
    this.trendAbsNomEbnoDataSeries.visible = visible;
    this.trendAbsNomEirpDataSeries.visible = visible;
    this.trendAbsNomOccBWDataSeries.visible = visible;
    this.trendAbsMeasOccBWDataSeries.visible = visible;
    this.trendAbsMeasCN0DataSeries.visible = visible;
    this.trendAbsNomCN0DataSeries.visible = visible;

    this.trendAbsEirpDeltaMaxDataSeries.visible = visible;
    this.trendAbsEirpDeltaMinDataSeries.visible = visible;
    this.trendAbsCFDeltaMinDataSeries.visible = visible;
    this.trendAbsCFDeltaMaxDataSeries.visible = visible;
    this.trendAbsOccBWDeltaMinDataSeries.visible = visible;
    this.trendAbsOccBWDeltaMaxDataSeries.visible = visible;
    this.trendAbsCN0DeltaMaxDataSeries.visible = visible;
    this.trendAbsCN0DeltaMinDataSeries.visible = visible;
    this.trendAbsEbnoDeltaMinDataSeries.visible = visible;
    this.trendAbsEbnoDeltaMaxDataSeries.visible = visible;
  }
  resetTrendDataSetVisibility() {
    this.trendConfig.floatingTooltip = false;
    this.setNormalizedDataSetsVisibility(false);
    this.setAbsoluteDataSetsVisibility(false);
    this.setEirpCFOccBWCN0AndEbnoVisibility(false);
    //set these to true by default
    this.trendMeasEirpDataSeries.visible = true;
    this.trendNomEirpDataSeries.visible = true;
  }
  initializeTrendData(trend: TrendDataSet) {
    this.currentTrendData = trend;
    this.trendsPlot.dataModels = [];
    this.trendsPlot.dataModels = [];

    if (trend.measEirpTrace != null && trend.measEirpTrace.points.length > 0) {
      this.trendMeasEirpDataSeries.initializeXYPoints(trend.measEirpTrace.points);
      this.trendsPlot.dataModels.push(this.trendMeasEirpDataSeries);
    }
    if (trend.nomEirpTrace != null && trend.nomEirpTrace.points.length > 0) {
      this.trendNomEirpDataSeries.initializeXYPoints(trend.nomEirpTrace.points);
      this.trendsPlot.dataModels.push(this.trendNomEirpDataSeries);
    }
    if (trend.measCFTrace != null && trend.measCFTrace.points.length > 0) {
      this.trendMeasCFDataSeries.initializeXYPoints(trend.measCFTrace.points);
      this.trendsPlot.dataModels.push(this.trendMeasCFDataSeries);
    }
    if (trend.nomCFTrace != null && trend.nomCFTrace.points.length > 0) {
      this.trendNomCFDataSeries.initializeXYPoints(trend.nomCFTrace.points);
      this.trendsPlot.dataModels.push(this.trendNomCFDataSeries);
    }
    if (trend.absMeasCFTrace != null && trend.absMeasCFTrace.points.length > 0) {
      this.trendAbsMeasCFDataSeries.initializeXYPoints(trend.absMeasCFTrace.points);
    }
    if (trend.absNomCFTrace != null && trend.absNomCFTrace.points.length > 0) {
      this.trendAbsNomCFDataSeries.initializeXYPoints(trend.absNomCFTrace.points);
    }
    if (trend.absMeasEbnoTrace != null && trend.absMeasEbnoTrace.points.length > 0) {
      this.trendAbsMeasEbnoDataSeries.initializeXYPoints(trend.absMeasEbnoTrace.points);
    }
    if (trend.absNomEbnoTrace != null && trend.absNomEbnoTrace.points.length > 0) {
      this.trendAbsNomEbnoDataSeries.initializeXYPoints(trend.absNomEbnoTrace.points);
    }
    if (trend.absMeasEirpTrace != null && trend.absMeasEirpTrace.points.length > 0) {
      this.trendAbsMeasEirpDataSeries.initializeXYPoints(trend.absMeasEirpTrace.points);
    }
    if (trend.absNomEirpTrace != null && trend.absNomEirpTrace.points.length > 0) {
      this.trendAbsNomEirpDataSeries.initializeXYPoints(trend.absNomEirpTrace.points);
    }
    if (trend.absNomOccBWTrace != null && trend.absNomOccBWTrace.points.length > 0) {
      this.trendAbsNomOccBWDataSeries.initializeXYPoints(trend.absNomOccBWTrace.points);
    }
    if (trend.absMeasOccBWTrace != null && trend.absMeasOccBWTrace.points.length > 0) {
      this.trendAbsMeasOccBWDataSeries.initializeXYPoints(trend.absMeasOccBWTrace.points);
    }
    if (trend.nomEbnoTrace != null && trend.nomEbnoTrace.points.length > 0) {
      this.trendNomEbnoDataSeries.initializeXYPoints(trend.nomEbnoTrace.points);
      this.trendsPlot.dataModels.push(this.trendNomEbnoDataSeries);
    }
    if (trend.measEbnoTrace != null && trend.measEbnoTrace.points.length > 0) {
      this.trendMeasEbnoDataSeries.initializeXYPoints(trend.measEbnoTrace.points);
      this.trendsPlot.dataModels.push(this.trendMeasEbnoDataSeries);
    }
    if (trend.measCNTrace != null && trend.measCNTrace.points.length > 0) {
      this.trendCNDataSeries.initializeXYPoints(trend.measCNTrace.points);
      this.trendsPlot.dataModels.push(this.trendCNDataSeries);
    }
    if (trend.measN0Trace != null && trend.measN0Trace.points.length > 0) {
      this.trendN0DataSeries.initializeXYPoints(trend.measN0Trace.points);
      this.trendsPlot.dataModels.push(this.trendN0DataSeries);
    }
    if (trend.measCN0Trace != null && trend.measCN0Trace.points.length > 0) {
      this.trendMeasCN0DataSeries.initializeXYPoints(trend.measCN0Trace.points);
      this.trendsPlot.dataModels.push(this.trendMeasCN0DataSeries);
    }
    if (trend.nomCN0Trace != null && trend.nomCN0Trace.points.length > 0) {
      this.trendNomCN0DataSeries.initializeXYPoints(trend.nomCN0Trace.points);
      this.trendsPlot.dataModels.push(this.trendNomCN0DataSeries);
    }
    if (trend.absMeasCN0Trace != null && trend.absMeasCN0Trace.points.length > 0) {
      this.trendAbsMeasCN0DataSeries.initializeXYPoints(trend.absMeasCN0Trace.points);
      this.trendsPlot.dataModels.push(this.trendAbsMeasCN0DataSeries);
    }
    if (trend.absNomCN0Trace != null && trend.absNomCN0Trace.points.length > 0) {
      this.trendAbsNomCN0DataSeries.initializeXYPoints(trend.absNomCN0Trace.points);
      this.trendsPlot.dataModels.push(this.trendAbsNomCN0DataSeries);
    }

    if (trend.eirpDeltaMaxTrace != null && trend.eirpDeltaMaxTrace.points.length > 0) {
      this.trendEirpDeltaMaxDataSeries.initializeXYPoints(trend.eirpDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendEirpDeltaMaxDataSeries);
    }
    if (trend.eirpDeltaMinTrace != null && trend.eirpDeltaMinTrace.points.length > 0) {
      this.trendEirpDeltaMinDataSeries.initializeXYPoints(trend.eirpDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendEirpDeltaMinDataSeries);
    }
    if (trend.cfDeltaMinTrace != null && trend.cfDeltaMinTrace.points.length > 0) {
      this.trendCFDeltaMinDataSeries.initializeXYPoints(trend.cfDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendCFDeltaMinDataSeries);
    }
    if (trend.cfDeltaMaxTrace != null && trend.cfDeltaMaxTrace.points.length > 0) {
      this.trendCFDeltaMaxDataSeries.initializeXYPoints(trend.cfDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendCFDeltaMaxDataSeries);
    }
    if (trend.occBWDeltaMinTrace != null && trend.occBWDeltaMinTrace.points.length > 0) {
      this.trendOccBWDeltaMinDataSeries.initializeXYPoints(trend.occBWDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendOccBWDeltaMinDataSeries);
    }
    if (trend.assignedBWTrace != null && trend.assignedBWTrace.points.length > 0) {
      this.trendAssignedBWDataSeries.initializeXYPoints(trend.assignedBWTrace.points);
      this.trendsPlot.dataModels.push(this.trendAssignedBWDataSeries);
    }
    if (trend.occBWDeltaMaxTrace != null && trend.occBWDeltaMaxTrace.points.length > 0) {
      this.trendOccBWDeltaMaxDataSeries.initializeXYPoints(trend.occBWDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendOccBWDeltaMaxDataSeries);
    }
    if (trend.cN0DeltaMaxTrace != null && trend.cN0DeltaMaxTrace.points.length > 0) {
      this.trendCN0DeltaMaxDataSeries.initializeXYPoints(trend.cN0DeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendCN0DeltaMaxDataSeries);
    }
    if (trend.cN0DeltaMinTrace != null && trend.cN0DeltaMinTrace.points.length > 0) {
      this.trendCN0DeltaMinDataSeries.initializeXYPoints(trend.cN0DeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendCN0DeltaMinDataSeries);
    }
    if (trend.ebnoDeltaMinTrace != null && trend.ebnoDeltaMaxTrace.points.length > 0) {
      this.trendEbnoDeltaMinDataSeries.initializeXYPoints(trend.ebnoDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendEbnoDeltaMinDataSeries);
    }
    if (trend.ebnoDeltaMaxTrace != null && trend.ebnoDeltaMaxTrace.points.length > 0) {
      this.trendEbnoDeltaMaxDataSeries.initializeXYPoints(trend.ebnoDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendEbnoDeltaMaxDataSeries);
    }
    if (trend.measDataRateTrace != null && trend.measDataRateTrace.points.length > 0) {
      this.trendMeasDataRateDataSeries.initializeXYPoints(trend.measDataRateTrace.points);
      this.trendsPlot.dataModels.push(this.trendMeasDataRateDataSeries);
    }
    if (trend.measSymbolRateTrace != null && trend.measSymbolRateTrace.points.length > 0) {
      this.trendMeasSymbolRateDataSeries.initializeXYPoints(trend.measSymbolRateTrace.points);
      this.trendsPlot.dataModels.push(this.trendMeasSymbolRateDataSeries);
    }

    if (trend.absEirpDeltaMaxTrace != null && trend.absEirpDeltaMaxTrace.points.length > 0) {
      this.trendAbsEirpDeltaMaxDataSeries.initializeXYPoints(trend.absEirpDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsEirpDeltaMaxDataSeries);
    }
    if (trend.absEirpDeltaMinTrace != null && trend.absEirpDeltaMinTrace.points.length > 0) {
      this.trendAbsEirpDeltaMinDataSeries.initializeXYPoints(trend.absEirpDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsEirpDeltaMinDataSeries);
    }
    if (trend.absCFDeltaMinTrace != null && trend.absCFDeltaMinTrace.points.length > 0) {
      this.trendAbsCFDeltaMinDataSeries.initializeXYPoints(trend.absCFDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsCFDeltaMinDataSeries);
    }
    if (trend.absCFDeltaMaxTrace != null && trend.absCFDeltaMaxTrace.points.length > 0) {
      this.trendAbsCFDeltaMaxDataSeries.initializeXYPoints(trend.absCFDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsCFDeltaMaxDataSeries);
    }
    if (trend.absOccBWDeltaMinTrace != null && trend.absOccBWDeltaMinTrace.points.length > 0) {
      this.trendAbsOccBWDeltaMinDataSeries.initializeXYPoints(trend.absOccBWDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsOccBWDeltaMinDataSeries);
    }
    if (trend.absOccBWDeltaMaxTrace != null && trend.absOccBWDeltaMaxTrace.points.length > 0) {
      this.trendAbsOccBWDeltaMaxDataSeries.initializeXYPoints(trend.absOccBWDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsOccBWDeltaMaxDataSeries);
    }
    if (trend.absCN0DeltaMaxTrace != null && trend.absCN0DeltaMaxTrace.points.length > 0) {
      this.trendAbsCN0DeltaMaxDataSeries.initializeXYPoints(trend.absCN0DeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsCN0DeltaMaxDataSeries);
    }
    if (trend.absCN0DeltaMinTrace != null && trend.absCN0DeltaMinTrace.points.length > 0) {
      this.trendAbsCN0DeltaMinDataSeries.initializeXYPoints(trend.absCN0DeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsCN0DeltaMinDataSeries);
    }
    if (trend.absEbnoDeltaMinTrace != null && trend.absEbnoDeltaMinTrace.points.length > 0) {
      this.trendAbsEbnoDeltaMinDataSeries.initializeXYPoints(trend.absEbnoDeltaMinTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsEbnoDeltaMinDataSeries);
    }
    if (trend.absEbnoDeltaMaxTrace != null && trend.absEbnoDeltaMaxTrace.points.length > 0) {
      this.trendAbsEbnoDeltaMaxDataSeries.initializeXYPoints(trend.absEbnoDeltaMaxTrace.points);
      this.trendsPlot.dataModels.push(this.trendAbsEbnoDeltaMaxDataSeries);
    }

    if (trend.nomOccBWTrace != null && trend.nomOccBWTrace.points.length > 0) {
      this.trendNomOccBWDataSeries.initializeXYPoints(trend.nomOccBWTrace.points);
      this.trendsPlot.dataModels.push(this.trendNomOccBWDataSeries);
    }
    if (trend.measOccBWTrace != null && trend.measOccBWTrace.points.length > 0) {
      this.trendMeasOccBWDataSeries.initializeXYPoints(trend.measOccBWTrace.points);
      this.trendsPlot.dataModels.push(this.trendMeasOccBWDataSeries);
    }
    this.trendsPlot.refreshGraph();
    this.trendsPlot.renderGraph();
  }
  stopWaterfallPlayback() {
    if (this.waterfallPlot != null) {
      if (this.testInterval != null)
        clearInterval(this.testInterval);
      if (this.waterfallPlot != null && this.waterfallPlot.isPlaying()) {
        this.waterfallPlot.stopPlayback();
        this.waterfallPlot.setHTrackingLineToBeginning();
      }
      if (this.modWaterfallPlot != null && this.modWaterfallPlot.isPlaying()) {
        this.modWaterfallPlot.stopPlayback();
        this.modWaterfallPlot.setHTrackingLineToBeginning();

      }
    }
  }

  getModBurstData(satellite, transponder, carrier, startTime, stopTime, startFreq, stopFreq) {
    this.waterfallDataService.getModBurstData(satellite, transponder, startTime, stopTime, startFreq, stopFreq, carrier).subscribe((response) => {
      if (response.success) {
        this.modBurstData = response.data;
        for (let i = 0; i < this.modBurstData.length; i++) {
          //let carrierForBurst = this.findCarrierForFreq(this.modBurstData[i].centerFreq);
          //if (carrierForBurst != null) {
          //  this.modBurstData[i].carrierName = carrierForBurst.primaryName;
          //}
        }
        this.addModBursts();
      }
    }, error => {
      if (this.configuration.showBursts) {
        this.showError('Error Retrieving Mod Burst Data for Selection', error.statusText);
      }
    });
  }
  getBurstData(satellite, transponder, carrier, startTime, stopTime, startFreq, stopFreq) {
    let sub = this.waterfallDataService.getSABurstData(satellite.identifier, transponder.identifier, startTime, stopTime, startFreq, stopFreq, carrier != null ? carrier.identifier : null).subscribe((response) => {
      if (response.success) {
        this.burstData = response.data;
        for (let i = 0; i < this.burstData.length; i++) {
          //let carrierForBurst = this.findCarrierForFreq(this.burstData[i].centerFreq);
          //if (carrierForBurst != null) {
          //  this.burstData[i].carrierName = carrierForBurst.primaryName;
          //} else {
          //  this.burstData[i].carrierName = 'Unknown';
          //}
        }
        this.addBursts();
        if (this.configuration.showBursts) {
          this.burstWaterfallElements = this.burstGraphElements;
        }
      } else {
        if (this.configuration.showBursts) {
          this.showInfo('No SA Burst Data for Selection', response.responseMsg);
        }
      }
      sub.unsubscribe();
    }, error => {
      sub.unsubscribe();
      if (this.configuration.showBursts) {
        this.showError('Error Retrieving Burst Data', error.statusText);
      }
    });
  }
  //reimplement once carrier regions are needed
  //findCarrierForFreq(freq: number): Carrier {
  //  let carrier;
  //  for (let i = 0; i < this.availableCarriers.length; i++) {
  //    if (freq < this.availableCarriers[i].stopFreq && freq > this.availableCarriers[i].startFreq) {
  //      carrier = this.availableCarriers[i];
  //      break;
  //    }
  //  }
  //  return carrier;
  //}
  addBursts() {
    this.burstGraphElements = [];
    for (let i = 0; i < this.burstData.length; i++) {
      let burst = this.burstData[i];
      let waterfallElement = new CanvasGraphElementModel();
      waterfallElement.backgroundColor = 'rgba(114, 114, 95, 0.4)';
      waterfallElement.connectStartAndEndPoints = true;
      waterfallElement.fillInShapeWithBackground = true;
      waterfallElement.lineColor = 'rgba(114, 114, 95, 1)';
      waterfallElement.xyPoints = new Array<WaterfallXY>();
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.startFreq, burst.burstStartDate));
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.stopFreq, burst.burstStartDate));
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.stopFreq, burst.burstEndDate));
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.startFreq, burst.burstEndDate));
      this.burstGraphElements.push(waterfallElement);
    }
  }
  addModBursts() {
    for (let i = 0; i < this.modBurstData.length; i++) {
      let burst = this.modBurstData[i];
      let waterfallElement = new CanvasGraphElementModel();
      waterfallElement.backgroundColor = 'rgba(114, 114, 95, 0.4)';
      waterfallElement.connectStartAndEndPoints = true;
      waterfallElement.fillInShapeWithBackground = true;
      waterfallElement.lineColor = 'rgba(114, 114, 95, 1)';
      waterfallElement.xyPoints = new Array<WaterfallXY>();
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.startFreq, burst.burstStartDate));
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.stopFreq, burst.burstStartDate));
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.stopFreq, burst.burstEndDate));
      waterfallElement.xyPoints.push(new WaterfallXY(null, burst.startFreq, burst.burstEndDate));
      this.modBurstGraphElements.push(waterfallElement);
    }
  }



  toggleMinVisibility(checked) {
    if (checked) {
      this.specanDataSeries.setMinVisibility(true);
    } else {
      this.specanDataSeries.setMinVisibility(false);
    }
    this.specan.refreshGraph();
  }

  toggleMaxVisibility(checked) {
    if (checked) {
      this.specanDataSeries.setMaxVisibility(true);
    } else {
      this.specanDataSeries.setMaxVisibility(false);
    }
    this.specan.refreshGraph();
  }

  toggleAvgVisibility(checked) {
    if (checked) {
      this.specanDataSeries.setAvgVisibility(true);
    } else {
      this.specanDataSeries.setAvgVisibility(false);
    }
    this.specan.refreshGraph();
  }

  resetTraceVisibility() {
    this.specanDataSeries.setMinVisibility(false);
    this.specanDataSeries.setMaxVisibility(false);
    this.specanDataSeries.setAvgVisibility(false);
    this.avgTraceVisible = false;
    this.minTraceVisible = false;
    this.maxTraceVisible = false;
  }

  getAllWaterfallData(traceCount, traceLength, start, stop, startFreq, endFreq) {
    let timeout2 = setTimeout(() => {
      this.getSaWaterfall(this.currentAnalysisParams.satelliteId, this.currentAnalysisParams.transponderId, this.currentAnalysisParams.carrierId, traceCount, traceLength, start, stop, startFreq, endFreq);
      clearTimeout(timeout2);
    });
    let timeout4 = setTimeout(() => {
      //this.getBurstData(satellite, transponder, carrier, start, stop, startFreq, endFreq);
      clearTimeout(timeout4);
    });
  }

  private getSaWaterfall(satelliteId: string, transponderId: string, carrierId: string, traceCount, traceLength, start, stop, startFreq, endFreq) {
    let sub = this.waterfallDataService.getWaterfallData(satelliteId, transponderId, traceCount, traceLength, start, stop, startFreq, endFreq, carrierId).subscribe(
      (results) => {
        this.analysisSelectionBar.stopAnalysis();
        this.waterfallConfig.showLoadingOverlay = false;
        this.specanConfig.showLoadingOverlay = false;
        this.analysisGraphConfig.showLoadingOverlay = false;
        if (results.success) {
          let waterfall = results.data as Waterfall;
          if (waterfall.traces.length > 0) {
            let dataModel = this.getWaterfallModelFromData(waterfall);
            this.waterfallDataSeries.initializeDataSet(dataModel);
            this.setWaterfallYAxisTickFunctions(dataModel);
            this.showSpecanDataAtTimestamp(waterfall.traces[waterfall.traces.length - 1].timestamp);
            // set this in a different digest cycle so we can start going to get the specan data while it renders
            let timeout1 = setTimeout(() => {
              this.renderWaterfallAndGetAnalysisData();
              this.specan.autoscaleGraph();
              this.specan.renderGraph();
              this.specan.setDbPerDiv(5);

              clearTimeout(timeout1);
            });
            this.getModWaterfall(satelliteId, transponderId, carrierId, traceCount, traceLength, start, stop, startFreq, endFreq);
          } else {
            this.showError('No Data Found', 'No waterfall data was found for the specified parameters.');
            this.stopAnalysis();
          }
        } else {
          this.showError('Error Retrieving Waterfall Data', results.responseMsg);
          this.stopAnalysis();

        }
        sub.unsubscribe();
      },
      (err) => {
        this.waterfallConfig.showLoadingOverlay = false;
        sub.unsubscribe();
        this.showError('Error Retrieving Waterfall Data', err.statusText);
        this.stopAnalysis();

      }
    );
  }

  private getModWaterfall(satellite, transponder, carrier, traceCount, traceLength, start, stop, startFreq, endFreq) {
    let sub = this.waterfallDataService.getModWaterfallData(satellite, transponder, traceCount, traceLength, start, stop, startFreq, endFreq, carrier).subscribe(
      (results) => {
        this.modWaterfallConfig.showLoadingOverlay = false;
        if (results.success) {
          let waterfall = results.data as Waterfall;
          let dataModel = this.getWaterfallModelFromData(waterfall);

          let timeout = setTimeout(() => {
            if (dataModel.dataPoints.length > 0) {
              this.modWaterfallDataExists = true;
              this.refreshCMIcons();
              this.modWaterfallDataSeries.initializeDataSet(dataModel);

              this.getModBurstData(satellite, transponder, carrier, start, stop, startFreq, endFreq);
              if (this.modWaterfallPlot != null && this.modWaterfallPlot.dataModel.length() > 0) {
                let timeout3 = setTimeout(() => {
                  this.updateModWaterfallAndRender();
                  clearTimeout(timeout3);
                });
              }
              if (this.waterfallModAnalysisSeriesList.length === 0) {
                this.waterfallModAnalysisSeriesList.push(this.waterfallModAnalysisSeries);
              }
            } else {
              this.modWaterfallDataExists = false;
              this.hideModWaterfall();
            }
            clearTimeout(timeout);
          });
        } else {
          this.hideModWaterfall();
        }
        sub.unsubscribe();
      },
      (err) => {
        sub.unsubscribe();
        this.modWaterfallConfig.showLoadingOverlay = false;
        this.hideModWaterfall();
        this.showError('Error Retrieving Mod Waterfall Data', err.statusText);
      }
    );
  }

  private renderWaterfallAndGetAnalysisData() {
    this.waterfallPlot.setHTrackingLineToBeginning();
    let startStopForWaterfall = this.waterfallPlot.getCurrentGraphDomainY();
    this.getWaterfallAnalysisData(this.waterfallPlot.getVTrackingLineFrequency());
    let timeout1 = setTimeout(() => {
      this.waterfallPlot.renderGraph();
      this.waterfallPlot.resizeGraph();
      clearTimeout(timeout1);
    });
  }

  private updateModWaterfallAndRender() {
    this.modWaterfallConfig.graphVisible = true;

    let timeout = setTimeout(() => {
      this.updateModWaterfallScaling();
      this.modWaterfallPlot.renderGraph();
      clearTimeout(timeout);
    });

  }

  private updateModWaterfallScaling() {
    let domainX = this.waterfallPlot.getCurrentDomainX();
    let domainY = this.waterfallPlot.getCurrentGraphDomainY();
    this.modWaterfallPlot.setDomainXAndY(domainX as any, domainY as any);

  }

  onModWfRenderEnd(event) {
    let startStopForWaterfall = this.modWaterfallPlot.getCurrentGraphDomainY();
    this.getModWaterfallAnalysisData(this.currentAnalysisParams.satelliteId, this.currentAnalysisParams.transponderId, this.modWaterfallPlot.getGraphCanvasWidth(),
      startStopForWaterfall[0], startStopForWaterfall[1], this.modWaterfallPlot.getVTrackingLineFrequency());
  }

  getWaterfallAnalysisData(frequency) {
    //Calculate the index of the point at the desired frequency
    if (this.saSpecanData.length > 0) {
      let sampleData = this.saSpecanData[0];
      let frequencyDiff = frequency - sampleData.startFreq;
      let idxOfPointAtFrequency = Math.floor(frequencyDiff / sampleData.stepFreq);
      let analysisData = new Array<WaterfallXY>();
      for (let i = 0; i < this.saSpecanData.length; i++) {
        let newPoint = new WaterfallXY();
        if (this.saSpecanData[i] != null && this.saSpecanData[i].powerValues != null && this.saSpecanData[i].powerValues.length >= idxOfPointAtFrequency) {
          newPoint.y = this.saSpecanData[i].powerValues[idxOfPointAtFrequency];
          newPoint.x = this.saSpecanData[i].timestamp;
        }
        //forward fill empty values for now
        else if (i > 0) {
          newPoint.y = this.saSpecanData[i - 1].powerValues[idxOfPointAtFrequency];
          newPoint.x = this.saSpecanData[i - 1].timestamp;
        }
        analysisData.push(newPoint);
      }
      this.waterfallAnalysisSeries.initializeXYPoints(analysisData);
      this.analysisGraphConfig.showLoadingOverlay = false;
      if (this.waterfallAnalysisPlot != null) {
        this.waterfallAnalysisPlot.refreshGraph();
        this.waterfallAnalysisPlot.autoscaleGraph();
        this.waterfallAnalysisPlot.setDbPerDiv(5);
      }
    }
  }

  getModWaterfallAnalysisData(satelliteId, transponderId, traceLength, start, stop, frequency) {
    let sub = this.waterfallDataService.getModWaterfallAnalysisData(satelliteId, transponderId, start, stop, frequency, traceLength, this.currentAnalysisParams.carrierId).subscribe(
      (results) => {
        if (results.success) {
          //this.modAnalysisGraphConfig.graphVisible = true;
          let waterfallAnalysis = results.data as WaterfallAnalysis;
          this.waterfallModAnalysisSeries.initializeXYPoints(waterfallAnalysis.dataPoints);
          if (this.waterfallModAnalysisPlot != null) {
            this.updateModAnalysisGraphAndShow();
          }
        } else {
          this.hideModAnalysisGraph();
        }
        this.modAnalysisGraphConfig.showLoadingOverlay = false;
        sub.unsubscribe();
      },
      (err) => {
        sub.unsubscribe();
        this.modAnalysisGraphConfig.showLoadingOverlay = false;
        //this.modAnalysisGraphConfig.graphVisible = false;
        this.showError('Error Retrieving Mod Waterfall Analysis Data', err.statusText);
      }
    );
  }

  private updateModAnalysisGraphAndShow() {
    this.modAnalysisGraphConfig.graphVisible = true;
    this.updateModTraceVisibility();
    let timeout = setTimeout(() => {
      this.refreshModAnalysisGraph();
      clearTimeout(timeout);
    });
  }

  private refreshModAnalysisGraph() {
    this.waterfallModAnalysisPlot.renderGraph();
    this.waterfallModAnalysisPlot.resizeGraph();
    this.waterfallModAnalysisPlot.setDbPerDiv(this.modAnalysisGraphDataParams.dbPerDiv);
    this.waterfallModAnalysisPlot.autoscaleGraph();
  }

  private updateModTraceVisibility() {
    if (this.modSpecanDataSeries.dataSetAt(0).length() > 0 || this.modSpecanData.length > 0) {
      if (this.specanDataModelList.length < 2) {
        this.specanDataModelList.push(this.modSpecanDataSeries);
      }
    }
    else {
      let modIdx = this.specanDataModelList.findIndex(i => i.legendName == this.modSpecanDataSeries.legendName);
      if (modIdx != -1) {
        this.specanDataModelList.splice(modIdx, 1);
        this.specan.refreshGraph();
      }
    }
  }

  private hideModAnalysisGraph() {
    this.modAnalysisGraphConfig.graphVisible = false;
  }
  private hideModWaterfall() {
    this.modWaterfallConfig.graphVisible = false;
  }
  private getWaterfallModelFromData(data: Waterfall) {
    let waterfallModel = new WaterfallPlotDataModel();
    let traces = data.traces;
    this.saSpecanData = new Array<Trace>();
    for (let i = 0; i < traces.length; i++) {
      if (traces[i] != null) {
        let waterfallDataLine = new WaterfallData();
        waterfallDataLine.timestamp = traces[i].timestamp;
        waterfallDataLine.initializePowerValues(traces[i].powerValues,
          traces[i].startFreq, traces[i].stepFreq);
        waterfallModel.dataPoints.push(waterfallDataLine);
        this.saSpecanData.push(traces[i]);
      }
    }
    return waterfallModel;
  }

  private getCurrentWaterfallAndSpecanData(startInMs, stopInMs, startFreq, endFreq, limit) {
    let currentWFHeight = this.waterfallPlot.getGraphCanvasHeight();
    let currentWFWidth = this.waterfallPlot.getGraphCanvasWidth();

    if (currentWFWidth == null) {
      currentWFWidth = 1500;
    }

    if (limit > currentWFHeight)
      limit = currentWFHeight;

    this.getAllWaterfallData(limit, Math.floor(currentWFWidth * 2), startInMs, stopInMs, startFreq, endFreq);
  }
  //private addCarrierRegions() {
  //  this.carrierRegions = [];
  //  for (let i = 0; i < this.availableCarriers.length; i++) {
  //    let carrierName = this.availableCarriers[i];
  //    this.carrierRegions.push(new CarrierRegion(carrierName, this.carrierRegionColors[i], this.availableCarriers[i].startFreq, this.availableCarriers[i].stopFreq));
  //  }
  //}
  private removeCarrierRegions() {
    this.carrierRegions = [];
  }
  private setSpecanDomain() {
    let dataDomainY = this.waterfallPlot.getCurrentDataDomainY();
    let domainX = this.waterfallPlot.getCurrentDomainX();
    this.specanDataParams.axisMinY = dataDomainY[0];
    this.specanDataParams.axisMaxY = dataDomainY[1];
    this.specanDataParams.axisMinX = domainX[0];
    this.specanDataParams.axisMaxX = domainX[1];
    this.specan.setDomainXAndY(domainX as any, dataDomainY as any);
  }
  showSpecanDataAtTimestamp(timestamp: number) {
    let specanData;
    if (this.currentAnalysisParams.liveUpdate) {
      specanData = this.getSpecanTraceForTimestampDesc(this.saSpecanData, timestamp);
    } else {
      specanData = this.getSpecanTraceForTimestampAsc(this.saSpecanData, timestamp);
    }
    if (specanData != null) {
      this.specanDataSeries.initializePowerValues(specanData.powerValues, specanData.startFreq, specanData.stepFreq);
      this.specanConfig.graphTitle = ('Captured: ' + this.getDateString(specanData.timestamp));
      this.specan.renderGraph();
      if (this.autoscaleDuringPlayback)
        this.specan.autoscaleGraph();
    }
  }

  showModSpecanDataAtTimestamp(timestamp: number) {
    let specanData;
    if (this.currentAnalysisParams.liveUpdate) {
      specanData = this.getSpecanTraceForTimestampDesc(this.modSpecanData, timestamp);
    } else {
      specanData = this.getSpecanTraceForTimestampAsc(this.modSpecanData, timestamp);
    }
    if (specanData != null) {
      this.modSpecanDataSeries.visible = true;
      this.modSpecanDataSeries.setClearWriteVisibility(true);
      this.modSpecanDataSeries.initializePowerValues(specanData.powerValues, specanData.startFreq, specanData.stepFreq);
    } else {
      this.modSpecanDataSeries.visible = false;
      this.modSpecanDataSeries.setClearWriteVisibility(false);
    }
  }
  getSpecanTraceForTimestampDesc(data: Trace[], timestamp: number) {
    let traceTsIsGreaterThanGivenTs = true;
    let traceIdx = 0;
    for (; traceIdx < data.length; traceIdx++) {
      // as soon as this becomes false, we've found our trace
      if (!traceTsIsGreaterThanGivenTs)
        break;
      if (data[traceIdx].timestamp <= timestamp)
        traceTsIsGreaterThanGivenTs = false;
    }
    if (traceIdx < data.length) {
      let oneTraceBefore = data[traceIdx + 1];
      let diffTimeOneBefore = Math.abs(oneTraceBefore.timestamp - timestamp);
      let diffSelected = Math.abs(data[traceIdx].timestamp - timestamp);
      if (diffSelected < diffTimeOneBefore) {
        return data[traceIdx];
      } else {
        return data[traceIdx + 1];
      }
    }
    else if (traceIdx == data.length) {
      return data[traceIdx - 1];
    }
    else if (traceIdx == 0) {
      return data[traceIdx];
    }
    else
      return null;
  }
  getSpecanTraceForTimestampAsc(data: Trace[], timestamp: number) {
    let traceTsIsLessThanGivenTs = true;
    let traceIdx = 0;
    for (; traceIdx < data.length; traceIdx++) {
      // as soon as this becomes false, we've found our trace
      if (!traceTsIsLessThanGivenTs)
        break;
      if (data[traceIdx].timestamp >= timestamp)
        traceTsIsLessThanGivenTs = false;
    }
    if (traceIdx < data.length) {
      let oneTraceBefore = data[traceIdx - 1];
      let diffTimeOneBefore = Math.abs(oneTraceBefore.timestamp - timestamp);
      let diffSelected = Math.abs(data[traceIdx].timestamp - timestamp);
      if (diffSelected < diffTimeOneBefore) {
        return data[traceIdx];
      } else {
        return data[traceIdx - 1];
      }
    }
    else if (traceIdx == data.length) {
      return data[traceIdx - 1];
    }
    else if (traceIdx == 0) {
      return data[traceIdx];
    }
    else
      return null;
  }

  horizontalTrackingLineChanged(event) {
    const timestamp = parseFloat(event);
    this.showSpecanDataAtTimestamp(timestamp);
    if (this.modWaterfallPlot != null) {
      this.modWaterfallPlot.setTrackingLineLocationByTimestamp(timestamp);
    }
    this.modHorizontalTrackingLineChanged(timestamp);
  }

  modHorizontalTrackingLineChanged(event) {
    const timestamp = parseFloat(event);
    this.showModSpecanDataAtTimestamp(timestamp);
  }

  verticalTrackingLineChanged(event) {
    this.analysisGraphConfig.showLoadingOverlay = true;
    let startStopForWaterfall = this.waterfallPlot.getCurrentGraphDomainY();
    this.getWaterfallAnalysisData(this.waterfallPlot.getVTrackingLineFrequency());
    if (this.modWaterfallPlot != null) {
      if (this.configuration.disableModWaterfall) {
        this.modWaterfallPlot.setTrackingLineLocationByFrequency(this.waterfallPlot.getVTrackingLineFrequency());
        this.modVerticalTrackingLineChanged(event);
      }
    }
  }
  modVerticalTrackingLineChanged(event) {
    this.modAnalysisGraphConfig.showLoadingOverlay = true;
    let startStopForWaterfall = this.waterfallPlot.getCurrentGraphDomainY();
    this.getModWaterfallAnalysisData(this.currentAnalysisParams.satelliteId, this.currentAnalysisParams.transponderId, this.waterfallPlot.getGraphCanvasWidth(),
      startStopForWaterfall[0], startStopForWaterfall[1], this.modWaterfallPlot.getVTrackingLineFrequency());
  }

  dataReady(event) {
    this.showLoading = false;
    this.specanConfig.graphTitle = 'Data Available for Viewing';
  }

  renderSpecan() {
    let waterfallDomainX = this.waterfallPlot.getCurrentDomainX();
    let waterfallDomainY = this.waterfallPlot.getCurrentDataDomainY();
    this.specan.dataParams.axisMinX = waterfallDomainX[0];
    this.specan.dataParams.axisMaxX = waterfallDomainX[1];
    this.specan.dataParams.axisMinY = waterfallDomainY[0];
    this.specan.dataParams.axisMaxY = waterfallDomainY[1];
    this.specan.renderGraph();
  }
  stopAnalysis() {
    clearInterval(this.interval);
    this.waterfallPlot.stopPlayback();
    if (this.modWaterfallPlot != null) {
      this.modWaterfallPlot.stopPlayback();
    }
    if (this.traceSub != null) {
      this.waterfallConfig.disableGraphInteraction = false;
      this.traceSub.unsubscribe();
    }
    this.analysisSelectionBar.stopAnalysis();
    this.analysisGraphConfig.showLoadingOverlay = false;
    this.specanConfig.showLoadingOverlay = false;
    this.waterfallConfig.showLoadingOverlay = false;
    this.modWaterfallConfig.showLoadingOverlay = false;
    this.trendConfig.showLoadingOverlay = false;
  }

  zoomOnWaterfall(zoomInfo: ZoomEventInfo) {
    this.selectedDomainAndRangeChanged(zoomInfo);
    //if (zoomInfo.dataDomainX != null) {
    //    this.waterfallPlot.zoomToNewDomains(zoomInfo);
    //}
  }
  ngOnDestroy() {
    if (this.traceSub != null) {
      this.traceSub.unsubscribe();
    }
    this.configServiceSubscription.unsubscribe();
    this.saSpecanData = [];
    this.modSpecanData = [];
  }
  private showError(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'error';
    this.utilityService.showNotification(msg);
  }
  private showInfo(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'info';
    this.utilityService.showNotification(msg);
  }

  private isDataSetOnGraph(dataset: SpectrumAnalyzerSeries) {
    let idx = this.trendsPlot.dataModels.findIndex((series) => series.legendName == dataset.legendName);
    return idx != -1;
  }
  private constructAllTrendDataSets() {
    this.allTrendDataSetList.push(this.trendAbsMeasCFDataSeries);
    this.allTrendDataSetList.push(this.trendAbsMeasEbnoDataSeries);
    this.allTrendDataSetList.push(this.trendAbsMeasEirpDataSeries);
    this.allTrendDataSetList.push(this.trendAbsNomCFDataSeries);
    this.allTrendDataSetList.push(this.trendAbsNomEbnoDataSeries);
    this.allTrendDataSetList.push(this.trendAbsNomEirpDataSeries);
    this.allTrendDataSetList.push(this.trendNomCFDataSeries);
    this.allTrendDataSetList.push(this.trendNomEbnoDataSeries);
    this.allTrendDataSetList.push(this.trendNomEirpDataSeries);
    this.allTrendDataSetList.push(this.trendNomOccBWDataSeries);
    this.allTrendDataSetList.push(this.trendAbsNomOccBWDataSeries);
    this.allTrendDataSetList.push(this.trendMeasCFDataSeries);
    this.allTrendDataSetList.push(this.trendMeasDataRateDataSeries);
    this.allTrendDataSetList.push(this.trendMeasEbnoDataSeries);
    this.allTrendDataSetList.push(this.trendMeasEirpDataSeries);
    this.allTrendDataSetList.push(this.trendMeasOccBWDataSeries);
    this.allTrendDataSetList.push(this.trendAbsMeasOccBWDataSeries);
    this.allTrendDataSetList.push(this.trendMeasSymbolRateDataSeries);
    this.allTrendDataSetList.push(this.trendN0DataSeries);
    this.allTrendDataSetList.push(this.trendEirpDeltaMinDataSeries);
    this.allTrendDataSetList.push(this.trendEirpDeltaMaxDataSeries);
    this.allTrendDataSetList.push(this.trendEbnoDeltaMinDataSeries);
    this.allTrendDataSetList.push(this.trendEbnoDeltaMaxDataSeries);
    this.allTrendDataSetList.push(this.trendCNDataSeries);
    this.allTrendDataSetList.push(this.trendCN0DeltaMinDataSeries);
    this.allTrendDataSetList.push(this.trendCN0DeltaMaxDataSeries);
    this.allTrendDataSetList.push(this.trendMeasCN0DataSeries);
    this.allTrendDataSetList.push(this.trendAbsMeasCN0DataSeries);
    this.allTrendDataSetList.push(this.trendNomCN0DataSeries);
    this.allTrendDataSetList.push(this.trendCFDeltaMinDataSeries);
    this.allTrendDataSetList.push(this.trendCFDeltaMaxDataSeries);
    this.allTrendDataSetList.push(this.trendAssignedBWDataSeries);
    this.allTrendDataSetList.push(this.trendOccBWDeltaMaxDataSeries);
    this.allTrendDataSetList.push(this.trendOccBWDeltaMinDataSeries);
  }
  private configureTrendGraph() {
    this.trendConfig.displayAsNormalGraph = true;
    this.trendConfig.showMouseCoordinates = false;
    this.trendConfig.yAxisTitle = '';
    this.trendConfig.showYAxesTickLabels = false;
    this.trendConfig.showConfigButton = true;
    this.trendConfig.numberOfTicks = 3;
    this.trendConfig.floatingTooltip = true;
    this.trendConfig.xAxisTickFormatFunc = this.yAxisTickFormatFunc;
    this.trendConfig.graphTitle = 'Trends (Normalized)';
    this.trendConfig.allowZoom = false;

    this.trendDataParams.axisMaxY = 1.1;
    this.trendDataParams.axisMinY = -.1;

    this.trendMeasCN0DataSeries.graphColor = '#ff0202';
    this.trendNomCN0DataSeries.graphColor = '#ff7777';
    this.trendAbsNomCN0DataSeries.graphColor = '#ff7777';
    this.trendAbsMeasCN0DataSeries.graphColor = '#ff0202';
    this.trendCN0DeltaMaxDataSeries.graphColor = '#770000';
    this.trendCN0DeltaMinDataSeries.graphColor = '#770000';
    this.trendAbsCN0DeltaMaxDataSeries.graphColor = '#770000';
    this.trendAbsCN0DeltaMinDataSeries.graphColor = '#770000';

    this.trendCNDataSeries.graphColor = '#006aff';
    this.trendN0DataSeries.graphColor = '#0015ff';
    this.trendMeasDataRateDataSeries.graphColor = '#4c00ff';
    this.trendMeasSymbolRateDataSeries.graphColor = 'b200ff';

    this.trendMeasCFDataSeries.graphColor = '#ff7505';
    this.trendNomCFDataSeries.graphColor = '#ffae6d';
    this.trendAbsMeasCFDataSeries.graphColor = '#ff7505';
    this.trendAbsNomCFDataSeries.graphColor = '#ffae6d';
    this.trendAbsCFDeltaMaxDataSeries.graphColor = '#873c00';
    this.trendAbsCFDeltaMinDataSeries.graphColor = '#873c00';
    this.trendCFDeltaMaxDataSeries.graphColor = '#873c00';
    this.trendCFDeltaMinDataSeries.graphColor = '#873c00';

    this.trendNomEbnoDataSeries.graphColor = '#ffe83a';
    this.trendMeasEbnoDataSeries.graphColor = '#f9dc00';
    this.trendAbsNomEbnoDataSeries.graphColor = '#ffe83a';
    this.trendAbsMeasEbnoDataSeries.graphColor = '#f9dc00';
    this.trendAbsEbnoDeltaMaxDataSeries.graphColor = '#847500';
    this.trendAbsEbnoDeltaMinDataSeries.graphColor = '#847500';
    this.trendEbnoDeltaMaxDataSeries.graphColor = '#847500';
    this.trendEbnoDeltaMinDataSeries.graphColor = '#847500';

    this.trendNomEirpDataSeries.graphColor = '#87ff77';
    this.trendMeasEirpDataSeries.graphColor = '#20ff02';
    this.trendAbsNomEirpDataSeries.graphColor = '#87ff77';
    this.trendAbsMeasEirpDataSeries.graphColor = '#20ff02';
    this.trendAbsEirpDeltaMaxDataSeries.graphColor = '#15b700';
    this.trendAbsEirpDeltaMinDataSeries.graphColor = '#15b700';
    this.trendEirpDeltaMaxDataSeries.graphColor = '#15b700';
    this.trendEirpDeltaMinDataSeries.graphColor = '#15b700';

    this.trendAbsMeasOccBWDataSeries.graphColor = '#00ffed';
    this.trendAbsNomOccBWDataSeries.graphColor = '#7cf9f1';
    this.trendAbsOccBWDeltaMaxDataSeries.graphColor = '#00a398';
    this.trendAbsOccBWDeltaMinDataSeries.graphColor = '#00a398';
    this.trendOccBWDeltaMaxDataSeries.graphColor = '#00a398';
    this.trendOccBWDeltaMinDataSeries.graphColor = '#00a398';
    this.trendMeasOccBWDataSeries.graphColor = '#00ffed';
    this.trendNomOccBWDataSeries.graphColor = '#7cf9f1';

    this.trendNomEirpDataSeries.lineType = SpecanLineType.dashed;
    this.trendNomEbnoDataSeries.lineType = SpecanLineType.dashed;
    this.trendNomCFDataSeries.lineType = SpecanLineType.dashed;
    this.trendNomCN0DataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsNomEirpDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsNomEbnoDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsNomCFDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsNomCN0DataSeries.lineType = SpecanLineType.dashed;


    this.trendCFDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendCN0DeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendEbnoDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendEirpDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendOccBWDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendCFDeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendCN0DeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendEbnoDeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendEirpDeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendOccBWDeltaMinDataSeries.lineType = SpecanLineType.dashed;

    this.trendAbsCFDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsCN0DeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsEbnoDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsEirpDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsOccBWDeltaMaxDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsCFDeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsCN0DeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsEbnoDeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsEirpDeltaMinDataSeries.lineType = SpecanLineType.dashed;
    this.trendAbsOccBWDeltaMinDataSeries.lineType = SpecanLineType.dashed;
  }

  private configureSpecan() {
    this.specanConfig.borderColor = 'white';
    this.specanConfig.numberOfTicks = 10;
    this.specanConfig.showLegend = true;
    this.specanConfig.showRBWandVBW = false;
    this.specanConfig.showMouseCoordinates = false;
    this.specanConfig.showDataMenus = false;
    this.specanConfig.showAutoscale = false;
    this.specanUnits.xAxisUnit = new UnitConversion(UnitOptions.Hz, UnitOptions.GHz, [UnitOptions.MHz, UnitOptions.GHz]);
    this.specanUnits.spanUnit = new UnitConversion(UnitOptions.Hz, UnitOptions.MHz, [UnitOptions.MHz, UnitOptions.GHz]);
    this.specanUnits.xAxisDecimalPlaces = 7;
    this.specanUnits.spanDecimalPlaces = 4;
    this.specanConfig.graphTitle = 'No Data to Display';
    this.specanConfig.showSampleFreq = false;
    this.specanConfig.showConfigButton = true;
    this.specanDataSeries.graphColor = this.mainTraceColor;
    this.specanDataSeries.getDataSetByName('min').color = this.minTraceColor;
    this.specanDataSeries.getDataSetByName('max').color = this.maxTraceColor;
    this.specanDataSeries.getDataSetByName('avg').color = this.avgTraceColor;
  }
  private configureSAWFGraph() {
    this.waterfallConfig.graphVisible = false;
    this.waterfallConfig.serverSideDataZooming = true;
    this.waterfallConfig.gradientColors = ['#0e171c', '#115A5D', '#57bbca', '#b9f5eb', '#e1edaf', '#ebf9bc', '#c9462a'];
    this.waterfallConfig.playTracesTopToBottom = true;
    //this.waterfallConfig.gradientColors = ['#5900ff', '#0026ff', '#00fff2', '#00ff2a', '#fffa00', '#ff9900', '#ff0000'];
    (this.waterfallUnits as any).xAxisUnit = new UnitConversion(UnitOptions.Hz, UnitOptions.GHz, [UnitOptions.MHz, UnitOptions.GHz]);
    this.waterfallConfig.showMouseCoordinates = false;
    this.waterfallConfig.yAxisTitle = 'Duration (hrs)';
  }
  private setWaterfallYAxisTickFunctions(dataModel: WaterfallPlotDataModel) {
    if (dataModel.dataPoints.length > 0) {
      let startTime = dataModel.dataPoints[0].timestamp;

      let yAxisTickFormatFunc = function (d: number) {
        //return duration in hours
        //we need to find the closest actual data point to the timestamp represented by the mouse coordinates
        let timestamp = this.waterfallDataSeries.getNearestTimestampAsc(d);
        return (Math.abs(timestamp - startTime) / 36e5).toFixed(2);
      };
      this.waterfallConfig.yAxisTickFormatFunc = yAxisTickFormatFunc.bind(this);
      this.modWaterfallConfig.yAxisTickFormatFunc = function (d: number) {
        //return duration in hours
        //we need to find the closest actual data point to the timestamp represented by the mouse coordinates
        let timestamp = this.waterfallDataSeries.getNearestTimestampAsc(d);
        return (Math.abs(timestamp - startTime) / 36e5).toFixed(2);
      };
      if (this.waterfallAnalysisPlot != null) {
        this.waterfallAnalysisPlot.refreshGraph();
      }
    }
  }
  private configureModWFGraph() {
    this.modWaterfallConfig.graphVisible = false;
    this.modWaterfallConfig.gradientColors = ['#0e171c', '#115A5D', '#57bbca', '#b9f5eb', '#e1edaf', '#ebf9bc', '#c9462a'];
    //this.modWaterfallConfig.showYAxisLabels = false;

    //this.modWaterfallConfig.disableGraphInteraction = true;
    this.modWaterfallConfig.showMouseCoordinates = false;
    this.modWaterfallConfig.serverSideDataZooming = true;
    this.modWaterfallConfig.yAxisTitle = 'Duration (hrs)';
  }
  private configureSAAnalysisGraph() {
    this.waterfallAnalysisSeries.graphColor = '#76fc91';
    this.analysisGraphConfig.displayAsNormalGraph = true;
    this.analysisGraphConfig.borderColor = 'white';
    this.analysisGraphConfig.showMouseCoordinates = false;
    this.analysisGraphConfig.showSampleFreq = false;
    this.analysisGraphConfig.xAxisTickFormatFunc = this.analysisXAxisTickFormatFunc;
    this.analysisGraphConfig.graphTitle = 'SA Waterfall Analysis';
    this.analysisGraphConfig.numberOfTicks = 3;
    this.analysisGraphDataParams.dbPerDiv = 5;
    this.modAnalysisGraphDataParams.dbPerDiv = 5;
  }
  private configureModAnalysisGraph() {
    this.waterfallModAnalysisSeries.graphColor = '#76fc91';
    this.modAnalysisGraphConfig.borderColor = 'white';
    this.modAnalysisGraphConfig.graphVisible = false;
    this.modAnalysisGraphConfig.xAxisTickFormatFunc = this.analysisXAxisTickFormatFunc;
    this.modAnalysisGraphConfig.numberOfTicks = 3;
    this.modAnalysisGraphConfig.displayAsNormalGraph = true;
    this.modAnalysisGraphConfig.graphTitle = 'Mod Waterfall Analysis';
    this.modAnalysisGraphConfig.showMouseCoordinates = false;
  }
  getTimeDurationFromTicks(ticks): string {
    let days = Math.floor(ticks / (1000 * 60 * 60 * 24));
    ticks -= days * (1000 * 60 * 60 * 24);

    let hours = Math.floor(ticks / (1000 * 60 * 60));
    ticks -= hours * (1000 * 60 * 60);

    let mins = Math.floor(ticks / (1000 * 60));
    ticks -= mins * (1000 * 60);

    let seconds = Math.floor(ticks / (1000));
    ticks -= seconds * (1000);

    let resultString = '';
    if (days > 0) {
      resultString += days + " days ";
    }
    if (hours > 0) {
      resultString += hours + ' hours ';
    }
    resultString += mins + ' mins ';
    return resultString;
  }
  ngAfterViewInit() {    
    this.setTooltipFunctions();
    this.setConfigSubscription();
    let sub = this.userService.checkUserAuthenticated().subscribe((response) => {
      if (response.success) {
        if (this.startAnalysisOnLogin) {
          this.analysisSelectionBar.startAnalysisWithParams(this.currentAnalysisParams);
        } else {
          setTimeout(() => {
            this.analysisSelectionBar.getSatellites();
          });
        }
      } else {
        let newSub = this.userService.userLoggedInObs.subscribe(() => {
          if (this.startAnalysisOnLogin) {
            this.analysisSelectionBar.startAnalysisWithParams(this.currentAnalysisParams);
          } else {
            setTimeout(() => {
              this.analysisSelectionBar.getSatellites();
            });
          }
        })
      }
    });
  }
  setConfigSubscription() {
    this.configServiceSubscription = this.configService.ConfigurationChanged.subscribe((configuration: DataViewConfiguration) => {
      this.configuration = configuration;
      if (configuration.minMaxForWaterfallGradient != null) {
        if (configuration.autoDetectGradientBounds) {
          this.waterfallDataParams.minPowerVal = null;
          this.waterfallDataParams.maxPowerVal = null;
          this.modWaterfallDataParams.minPowerVal = null;
          this.modWaterfallDataParams.maxPowerVal = null;
        } else {
          this.setDefaultGradientConfig();
        }
      }
      if (configuration.showCarrierRegions) {
        //this.addCarrierRegions();
        let timeout = setTimeout(() => {
          this.specan.renderGraph();
          clearTimeout(timeout);
        });
      } else {
        this.removeCarrierRegions();
        let timeout = setTimeout(() => {
          this.specan.renderGraph();
          clearTimeout(timeout);
        });
      }
      if (configuration.showModAnalysis) {
        let timeout = setTimeout(() => {
          if (this.modWaterfallDataExists) {
            let currentModDataDomain = this.modWaterfallPlot.getCurrentDataDomainY();
            if (currentModDataDomain[0] == null && currentModDataDomain[1] == null) {
              this.modWaterfallDataParams.minPowerVal = configuration.minMaxForWaterfallGradient[0];
              this.modWaterfallDataParams.maxPowerVal = configuration.minMaxForWaterfallGradient[1];
            }
            this.updateModWaterfallAndRender();
            clearTimeout(timeout);
          }
        });

      } else {
        this.hideModAnalysisGraph();
        this.hideModWaterfall();
      }
      if (configuration.showBursts) {
        this.burstWaterfallElements = this.burstGraphElements;
      } else {
        this.burstWaterfallElements = null;
      }
      if (configuration.disableModWaterfall) {
        this.modWaterfallConfig.disableGraphInteraction = true;
      } else {
        this.modWaterfallConfig.disableGraphInteraction = false;
      }
      this.waterfallPlot.htmlTemplateVars.playbackSpeedInMs = this.configuration.playbackSpeed;
      //timeout so the change in configuration has time to be applied to the waterfalls
      let timeout = setTimeout(() => {
        this.specan.refreshGraph();
        this.specan.resizeGraph();
        this.waterfallPlot.refreshGraph();
        this.waterfallPlot.resizeGraph();
        if (this.modWaterfallPlot != null) {
          this.modWaterfallPlot.refreshGraph();
          this.modWaterfallPlot.resizeGraph();
        }
        if (this.waterfallAnalysisPlot != null) {
          this.waterfallAnalysisPlot.refreshGraph();
          this.waterfallAnalysisPlot.resizeGraph();
        }
        clearTimeout(timeout);
      })
    });
  }
  setTooltipFunctions() {
    this.waterfallConfig.tooltipValueFunc = (position: WaterfallXY) => {
      let values = new Array<string>();
      let burst = this.findBurstForFreq(position.x, position.y);
      if (burst != null && this.configuration.showBursts) {
        values.push('Burst Label: ' + burst.label);
        values.push('Carrier: ' + burst.carrierName);
        values.push('Start Date: ' + this.waterfallConfig.yAxisTickFormatFunc(burst.burstStartDate));
        values.push('End Date: ' + this.waterfallConfig.yAxisTickFormatFunc(burst.burstEndDate));
        values.push('Duration: ' + this.getTimeDurationFromTicks(burst.burstDuration));
        values.push('BW (MHz): ' + (this.waterfallConfig.xAxisTickFormatFunc(burst.bandwidth))).toPrecision(2);
        values.push('CF (GHz): ' + this.waterfallConfig.xAxisTickFormatFunc(burst.centerFreq)).toPrecision(6);
      } else {
        //let carrier = this.findCarrierForFreq(position.x);
        //if (carrier != null) {
        //  values.push('Carrier: ' + carrier.primaryName);
        //  values.push('CF (GHz): ' + this.waterfallConfig.xAxisTickFormatFunc(carrier.measuredCenterFreq));
        //} else {
        //  values.push('No Carrier');
        //  values.push(this.waterfallConfig.xAxisTickFormatFunc(position.x) + ' (GHz)');
        //}
        values.push(this.waterfallConfig.xAxisTickFormatFunc(position.x) + ' GHz');
        let timestamp = (this.waterfallDataSeries as any).getNearestTimestampAsc(position.y);
        values.push(this.getDateString(timestamp));
      }
      return values;
    }
    this.modWaterfallConfig.tooltipValueFunc = (position: WaterfallXY) => {
      let values = new Array<string>();
      let burst = this.findModBurstForFreq(position.x, position.y);
      if (burst != null) {
        values.push('Burst Label: ' + burst.label);
        values.push('Carrier: ' + burst.carrierName);
        values.push('Start Date: ' + this.waterfallConfig.yAxisTickFormatFunc(burst.burstStartDate));
        values.push('End Date: ' + this.waterfallConfig.yAxisTickFormatFunc(burst.burstEndDate));
        values.push('Duration: ' + this.getTimeDurationFromTicks(burst.burstDuration));
        values.push('CF: ' + this.waterfallConfig.xAxisTickFormatFunc(burst.centerFreq));
      } else {
        //let carrier = this.findCarrierForFreq(position.x);
        //if (carrier != null) {
        //  values.push('Carrier: ' + carrier.primaryName);
        //  values.push('CF: ' + this.waterfallConfig.xAxisTickFormatFunc(carrier.measuredCenterFreq));
        //} else {
        //  values.push('No Carrier');
        //  values.push(this.waterfallConfig.xAxisTickFormatFunc(position.x) + ' ' + this.waterfallPlot.units.xAxisUnit.defaultUnit.name)
        //}
        values.push(this.waterfallConfig.xAxisTickFormatFunc(position.x) + ' GHz');
        let timestamp = (this.waterfallDataSeries as any).getNearestTimestampAsc(position.y);
        values.push(this.getDateString(timestamp));
      }
      return values;
    }
    this.specanConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(position.y.toFixed(this.specanConfig.roundXToDecimal) + ' dB');
      values.push((position.x * this.specan.units.xAxisUnit.defaultUnit.multiplier).toFixed(this.specanConfig.roundXToDecimal) + ' ' + this.specan.units.xAxisUnit.defaultUnit.name);
      //let carrier = this.findCarrierForFreq(position.x);
      //if (carrier != null) {
      //  values.push('Carrier: ' + carrier.primaryName);
      //} else {
      //  values.push('No Carrier');
      //}
      return values;
    };
    this.analysisGraphConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(position.y.toFixed(this.analysisGraphConfig.roundXToDecimal) + ' dB');
      values.push(this.analysisXAxisTickFormatFunc(position.x));
      return values;
    };
    this.modAnalysisGraphConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(position.y.toFixed(this.analysisGraphConfig.roundXToDecimal) + ' dB');
      values.push(this.analysisXAxisTickFormatFunc(position.x));
      return values;
    };
    this.trendConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(this.waterfallConfig.yAxisTickFormatFunc(position.x));
      values.push(position.y.toString());
      return values;
    };
  }
  setDefaultGradientConfig() {
    this.waterfallDataParams.minPowerVal = this.configuration.minMaxForWaterfallGradient[0];
    this.waterfallDataParams.maxPowerVal = this.configuration.minMaxForWaterfallGradient[1];
    this.modWaterfallDataParams.minPowerVal = this.configuration.minMaxForWaterfallGradient[0];
    this.modWaterfallDataParams.maxPowerVal = this.configuration.minMaxForWaterfallGradient[1];
  }
  initializeLoadingSpinners() {
    this.waterfallConfig.showLoadingOverlay = true;
    this.modWaterfallConfig.showLoadingOverlay = true;
    this.specanConfig.showLoadingOverlay = true;
    this.analysisGraphConfig.showLoadingOverlay = true;
    this.modAnalysisGraphConfig.showLoadingOverlay = true;
    this.trendConfig.showLoadingOverlay = true;
  }
  protected refreshCMIcons() {
    this.specanContextMenuItems = [
      {
        label: 'Autoscale',
        command: (event) => {
          this.specan.autoscaleGraph();
        }
      },
      {
        label: 'Set Marker',
        items: [
          {
            label: 'Main',
            command: (event) => {
              this.markers.push('Marker ' + ((this.markers.length + 1)));
              this.specan.addMarkerToGraph('main', this.markers[this.markers.length - 1], [], MarkerShape.circle);
            }
          },
          // { separator: true },
          {
            label: 'Min Hold',
            command: (event) => {
              this.markers.push('Marker ' + (this.markers.length + 1));
              this.specan.addMarkerToGraph('min', this.markers[this.markers.length - 1], [], MarkerShape.circle);
            }
          },
          {
            label: 'Max Hold',
            command: (event) => {
              this.markers.push('Marker ' + (this.markers.length + 1));
              this.specan.addMarkerToGraph('max', this.markers[this.markers.length - 1], [], MarkerShape.circle);
            }
          },
          {
            label: 'Average',
            command: (event) => {
              this.markers.push('Marker ' + (this.markers.length + 1));
              this.specan.addMarkerToGraph('avg', this.markers[this.markers.length - 1], [], MarkerShape.circle);
            }
          }
        ]
      }
    ];
  }
}
