import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UUID } from 'angular2-uuid';

import { Unit } from '../pf-unit/unit';
import { UnitConversion } from '../pf-unit/unit-conversion';
import { UnitValidator } from '../pf-unit/unit-validator';

@Component({
  selector: 'pf-unit-input',
  templateUrl: './pf-unit-input.component.html',
  styleUrls: ['./pf-unit-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitInputComponent),
      multi: true
    }
  ]
})

//  this component assumes it receives a preexisting value in the base units for that unit type
export class UnitInputComponent implements ControlValueAccessor, AfterViewInit {

  @Input() units: UnitConversion;
  @Input() validator: UnitValidator;
  @Input() inputClass: string;
  @Input() containerClass: string;
  @Input() disabled: boolean;
  @Input() readOnlyTooltip: string;
  @Input() inputTooltip: string;
  @Input() readOnly: boolean;
  @Input() decimalPlaces = 6;

  @ViewChild('inputElem') inputElem: ElementRef;
  @ViewChild('ddElem') ddElem: ElementRef;

  @Output() onBlur = new EventEmitter<Event>();
  @Output() onChange = new EventEmitter<Event>();
  @Output() onEnterKeyPress = new EventEmitter<Event>();

  inputId: string;
  inputValue: number;  //  inputValue represents the current value converted from base to the currently selected unit

  changeDetector: ChangeDetectorRef;
  showUnitPicker = false;
  unitOptionList: Unit[];
  selectedUnit = new Unit();

  //  baseValue represents either 1) the preexisting value passed to the control in base units or
  //  2) the value inputted manually by the user, but converted as necessary to always be in base units
  private baseValue: number;
  // need to track this to trigger change detection
  private selectedUnitName: string;

  constructor(ref: ChangeDetectorRef) {
    this.changeDetector = ref;
    this.inputId = 'complexInput' + UUID.UUID();
    this.unitOptionList = new Array<Unit>();
  }

  @HostListener('window:mouseup', ['$event']) onClick(event) {
    this.handleClick(event);
  }
  // ngmodel methods
  writeValue(value: any) {
    if (value != null) {
      if (this.baseValue == null) {
        this.baseValue = value / this.units.baseUnit.multiplier;
        this.validate();
      } else {
        this.baseValue = value;
      }

      // have to cast as any to preserve the decimal places
      this.inputValue = ((this.baseValue * this.selectedUnit.multiplier).toFixed(this.decimalPlaces) as any);
    }
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  ngAfterViewInit() {
    if (this.units.baseUnit != null && this.units.defaultUnit === null) {
      this.units.defaultUnit = this.units.baseUnit;
    }
    if (this.units.unitList != null) {
      for (let i = 0; i < this.units.unitList.length; i++) {
        this.unitOptionList.push(new Unit(this.units.unitList[i].name, this.units.unitList[i].multiplier));
        //  set the current selected unit if it's found in the given list
        if (this.units.defaultUnit.name === this.unitOptionList[i].name) {
          this.selectedUnit = this.unitOptionList[i];
        }
      }

    }
    // check to see if the default unit was already in the list
    // if selectedUnit doesn't have a name, then it was not, so we need to add it
    if (this.selectedUnit.name === null && this.units.defaultUnit != null) {
      this.selectedUnit = this.units.defaultUnit;
      this.unitOptionList.push(new Unit(this.units.defaultUnit.name, this.units.defaultUnit.multiplier));
    }

    if (this.unitOptionList.length > 0) {
      // if selectedUnit is still not set, set it to the first element in the list
      if (this.selectedUnit.name === null) {
        this.selectedUnit = this.unitOptionList[0];
      }
    }
    // explicitly running change detection after changing the model value
    this.changeDetector.detectChanges();
  }

  openPicker() {
    if (this.unitOptionList.length > 1 && !this.readOnly) {
      this.showUnitPicker = true;
    }
  }
  closePicker() {
    if (this.unitOptionList.length > 1) {
      this.showUnitPicker = false;
    }
  }
  emitKeyPress = (event) => {
    if (event.keyCode === 13) {
      this.onEnterKeyPress.emit(event);
      if (this.inputValue != null) {
        this.inputValue = (parseFloat(this.inputValue.toString()).toFixed(this.decimalPlaces) as any);
      }
    } else {
      this.onChange.emit(event);
    }
  }
  emitBlur = (event) => {
    this.onBlur.emit(event);
    if (this.inputValue != null) {
      this.inputValue = (parseFloat(this.inputValue.toString()).toFixed(this.decimalPlaces) as any);
    }
  }
  handleClick(event) {
    if (this.ddElem != null) {
      const clickedComponent = event.target;
      const insideComponent = clickedComponent.parentElement.parentElement.id === this.inputId ||
        clickedComponent.parentElement.id === this.inputId ||
        clickedComponent.id === this.inputId;

      if (!insideComponent) {
        this.closePicker();
      }
    }
  }

  unitChanged() {
    if (this.units.convertOnChange) {
      // in this case, we need to convert the input value to the new unit selected in the dropdown
      // and we dont need to change the base value
      this.inputValue = (parseFloat((this.baseValue * this.selectedUnit.multiplier).toString()).toFixed(this.decimalPlaces) as any);
      this.validate();
    }
  }

  modelChanged() {
    this.baseValue = (this.inputValue / this.selectedUnit.multiplier);
    this.propagateChange(this.baseValue);
    this.validate();
  }

  //  detects when user changes either the input value or the selected value in the dropdown
  validate() {
    // allow validator to be null
    if (this.validator != null) {
      this.validator.isValid(this.baseValue, this);
    }
  }

  private propagateChange = (_: any) => { };
}
