import { D3, Selection } from 'd3-ng2-service';

import { ChartAddon } from '../addons/pf-chart-addon';
import { ChartMargins } from '../addons/pf-chart-margins';
import { AxisSelection } from './pf-chart-axis-selection';

export class ChartGuide implements ChartAddon {
  svg: Selection<any, {}, null, undefined>;
  d3: D3;
  height: number;
  width: number;

  private xGuide: AxisSelection;
  private yGuide: AxisSelection;
  private transform: any;

  private readonly elementClass = 'guide';

  constructor(private margins: ChartMargins, private xGuideValue: number, private yGuideValue: number) { }

  init(d3: D3, height: number, width: number): void {
    this.d3 = d3;
    this.height = height;
    this.width = width;
  }

  create(svg: Selection<any, {}, null, undefined>, domainScale: any, rangeScale: any): void {
    this.svg = svg;

    if (this.xGuide) {
      this.xGuide.remove();
    }
    if (this.yGuide) {
      this.yGuide.remove();
    }
    this.xGuide = this.createX(domainScale);
    this.yGuide = this.createY(rangeScale);
  }

  zoom(domainScale: any, rangeScale: any, transform: any): void {
    this.transform = transform;
    this.domainZoom(domainScale, transform);
    this.rangeZoom(rangeScale, transform);
  }

  onScaleOrResize(height: number, width: number, domainScale: any, rangeScale: any): void {
    this.height = height;
    this.width = width;
    this.create(this.svg, domainScale, rangeScale);
    this.zoom(domainScale, rangeScale, this.transform);
  }

  private createX(domainScale: any): AxisSelection {
    const axis = this.d3.axisTop(domainScale)
      .tickSize(-this.margins.chartHeight(this.height))
      .tickFormat(() => { return ''; })
      .tickValues([this.xGuideValue]);

    const selection = this.svg.append('g')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`)
      .call(axis);

    return new AxisSelection(axis, selection);
  }

  private createY(rangeScale: any): AxisSelection {
    const axis = this.d3.axisRight(rangeScale)
      .tickSize(-this.margins.chartWidth(this.width))
      .tickFormat(() => { return ''; })
      .tickValues([this.yGuideValue]);

    const selection = this.svg.append('g')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${this.width - this.margins.right}, ${this.margins.top})`)
      .call(axis);

    return new AxisSelection(axis, selection);
  }

  private domainZoom(domainScale: any, transform: any) {
    const t = transform ? transform.rescaleX(domainScale) : domainScale;
    const pos = t(this.xGuideValue);
    const opacity = (pos >= 0 && pos <= this.margins.chartWidth(this.width)) ? 1 : 0;
    this.xGuide.selection.attr('opacity', opacity);
    this.xGuide.selection.call(this.xGuide.axis.scale(t));
  }

  private rangeZoom(rangeScale: any, transform: any) {
    const t = transform ? transform.rescaleY(rangeScale) : rangeScale;
    const pos = t(this.yGuideValue);
    const opacity = (pos >= 0 && pos <= this.margins.chartHeight(this.height)) ? 1 : 0;
    this.yGuide.selection.attr('opacity', opacity);
    this.yGuide.selection.call(this.yGuide.axis.scale(t));
  }
}