import { ChangeDetectorRef, Component, ComponentFactoryResolver, HostListener, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SpectrumAnalyzerSeries, SpectrumAnalyzerThumbnailComponent } from 'app/components/graphs/specan';
import { DataViewConfiguration, Trace, UserMessage } from 'app/models';
import { TraceRequestResponse } from 'app/models/trace-request';
import { AppUtilities } from 'app/services/app-utilities';
import { DataViewDemoService } from 'app/services/data-view-demo-service';
import { UserService } from 'app/services/user-service';
import { Subscription } from 'rxjs';
import { MultiSpecanFullscreenDataSet } from './multi-specan-fullscreen-dataset';
import { MonitorGroup } from 'app/models/monitor-group';
import { AddUpdateMonitorGroupDialogComponent } from './add-update-monitor-group-dialog/add-update-monitor-group-dialog.component';
import { MonitorGroupsDialogComponent } from './monitor-groups-dialog/monitor-groups-dialog.component';

@Component({
  selector: 'multi-specan-view',
  templateUrl: './multi-specan-view.component.html',
  styleUrls: ['./multi-specan-view.component.css']
})

export class MultiSpecanViewComponent {
  @HostListener('window:resize', ['$event']) onResize(event) {
    setTimeout(() => {
      this.determineTileSizes(this.dataSets.length, this.ensureEvenColumns);
    }, 200)
  }
  @ViewChildren('specans') specans: QueryList<SpectrumAnalyzerThumbnailComponent>;
  currentSpecan: SpectrumAnalyzerThumbnailComponent;
  analysisBLabel = 'Start Analysis';
  refreshing: boolean;
  ensureEvenColumns = true;
  requestSubscription: Subscription;
  loadingPastConfig: boolean;
  configuration: DataViewConfiguration = new DataViewConfiguration();
  interval: any;
  analysisRunning = false;
  atLeastOneAnalysisHasRun: boolean;
  playingTraces: boolean;
  updateRate = 300;
  normalSpecanColor = 'rgb(122, 203, 220)';
  warningSpecanColor = 'rgb(255, 252, 130)';
  dataSets = new Array<MultiSpecanFullscreenDataSet>();
  intervalCount = 0;
  currentConfigSaved = false;
  showAllMin: boolean;
  showAllMax: boolean;
  showAllAvg: boolean;
  avgTraceColor: string = '#dcce79';
  minTraceColor: string = '#79a3dc';
  maxTraceColor: string = '#dc7979';
  tileHeight = 200;
  tileWidth = 250;
  marginTop = 1;
  marginLeft = 1;
  resizing = false;
  traceOutdatedIntervalInMs = 43200000;

  constructor(private waterfallDataService: DataViewDemoService, private viewContainer: ViewContainerRef, private changeDetector: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver, private _sanitizer: DomSanitizer, private userService: UserService,
    private utilityService: AppUtilities) {
    this.setDefaultTraceColors();
  }
  removeAll() {
    this.dataSets = [];
    this.saveConfigurationToSession();
  }
  removeDataset(dataset) {
    let idx = this.dataSets.findIndex((data) => {
      return (data.carrierName != null ? data.carrierName == dataset.carrierName : data.transponderName == dataset.transponderName)
    });
    if (idx > -1) {
      this.dataSets.splice(idx, 1);
      this.saveConfigurationToSession();
      this.determineTileSizes(this.dataSets.length, true);
    }
  }
  private setDefaultTraceColors() {
    this.minTraceColor = '#9df791';
    this.maxTraceColor = '#f48bda';
    this.avgTraceColor = '#f9e77f';
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.retrievePastConfigFromLastSession();
    }, 100);
  }
  private retrievePastConfigFromLastSession() {
    this.loadingPastConfig = true;
    this.userService.getCurrentUserInfo().subscribe((response) => {
      this.loadingPastConfig = false;
      if (response.success) {
        let user = JSON.parse(response.data);
        if (user.details.gridConfig != null) {
          user.details.gridConfig.items.forEach((item) => {
            let newDataSet = this.initializeDataSet(new MultiSpecanFullscreenDataSet(item.dataSet.satelliteName, item.dataSet.transponderName, item.dataSet.carrierName));
            this.dataSets.push(newDataSet);
            this.changeDetector.detectChanges();
            this.specans.first.resizeGraph();
            // this.getDataForSpecan(newDataSet, this.specans.last, true);
          });
          this.determineTileSizes(this.dataSets.length, this.ensureEvenColumns);
          this.startPlayback();
        }
      }
    });
  }

  getColumns(numItems) {
    return Math.ceil(Math.sqrt(numItems));
  }

  getRows(numItems, numColumns) {
    return Math.ceil(numItems / numColumns);
  }
  openFullscreen() {
    window.open(window.location.origin + '/#/multi-specan-fullscreen');
  }
  getColumnWidth(numColumns, parentWidth) {
    return Math.floor(parentWidth / numColumns);
  }

  getRowHeight(numRows, parentHeight) {
    return Math.floor(parentHeight / numRows);
  }
  determineTileSizes(tileCount, ensureEvenColumns) {
    const parentComp = document.getElementById('tile-parent-component');
    const rect = parentComp.getBoundingClientRect();
    const parentHeight = rect.height;
    const parentWidth = rect.width;
    const margin = 1;
    let columns = this.getColumns(tileCount);
    if (ensureEvenColumns === true && columns > 1 && columns % 2 !== 0) {
      columns += 1;
    }

    let rows = this.getRows(tileCount, columns);
    //if (ensureEvenColumns === true && rows > 1 && rows % 2 !== 0) {
    //  rows += 1;
    //}

    const columnWidth = this.getColumnWidth(columns, parentWidth - (margin * 2 * columns));
    const rowHeight = this.getRowHeight(rows, parentHeight - (margin * 2 * rows));

    const marginX = (parentWidth - (columnWidth * columns)) / columns - (margin * 2 / columns);
    const marginY = (parentHeight - (rowHeight * rows)) / rows - (margin * 2 / rows);
    this.tileHeight = rowHeight;
    this.tileWidth = columnWidth;
    this.marginLeft = marginX;
    this.marginTop = marginY;
    setTimeout(() => {
      this.specans.toArray().forEach((specan) => {
        specan.resizeGraph()
      });
    }, 250);
  }
  toggleAllMin() {
    this.showAllMin = !this.showAllMin;
    this.specans.forEach((specan, i) => {
      this.dataSets[i].dataSeries[0].getDataSetByName('min').visible = this.showAllMin;
      specan.refreshGraph();
    });
  }
  toggleAllMax() {
    this.showAllMax = !this.showAllMax;
    this.specans.forEach((specan, i) => {
      this.dataSets[i].dataSeries[0].getDataSetByName('max').visible = this.showAllMax;
      specan.refreshGraph();
    });
  }
  toggleAllAvg() {
    this.showAllAvg = !this.showAllAvg;
    this.specans.forEach((specan, i) => {
      this.dataSets[i].dataSeries[0].getDataSetByName('avg').visible = this.showAllAvg;
      specan.refreshGraph();
    });
  }
  saveColors() {
    this.specans.forEach((specan, i) => {
      this.dataSets[i].dataSeries[0].getDataSetByName('avg').color = this.avgTraceColor;
      this.dataSets[i].dataSeries[0].getDataSetByName('min').color = this.minTraceColor;
      this.dataSets[i].dataSeries[0].getDataSetByName('max').color = this.maxTraceColor;
      specan.refreshGraph();
    });
  }
  loadMonitorGroups() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(MonitorGroupsDialogComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    componentRef.instance.open().then((result: MonitorGroup) => {
      if (result != null) {
        this.refreshing = true;
        setTimeout(() => {
          this.dataSets = [];
          result.items.forEach((item) => {
            let newDataset = new MultiSpecanFullscreenDataSet(item.satellite_id, item.transponder_id, item.carrier_id);
            newDataset = this.initializeDataSet(newDataset);
            this.dataSets.push(newDataset);
            this.changeDetector.detectChanges();
          });
          this.determineTileSizes(this.dataSets.length, this.ensureEvenColumns);
          this.manuallyUpdate();
          this.saveConfigurationToSession();
        }, 0);
      }
      componentRef.destroy();
    });
  }
  private initializeDataSet(dataset: MultiSpecanFullscreenDataSet) {
    let newSeries = new SpectrumAnalyzerSeries('');
    newSeries.graphColor = '#b9f5eb';
    dataset.dataSeries.push(newSeries);
    dataset.config.borderColor = this.normalSpecanColor;
    dataset.config.graticuleColor = 'rgba(169, 209, 202, .3)';
    dataset.config.showLoadingOverlay = true;
    dataset.dataParams.dbPerDiv = 5;
    dataset.config.numberOfYTicks = 5;
    return dataset;
  }
  openTacrat(dataset: MultiSpecanFullscreenDataSet) {
    let url = this.waterfallDataService.getAddress() + '/#/specan?satelliteId=' + dataset.satelliteName +
      '&transponderId=' + dataset.transponderName + '&loadLast250=true';
    if (dataset.carrierName != null && dataset.carrierName != '')
      url += '&carrierId=' + dataset.carrierName;
    window.open(url);
  }
  //toggleMinMaxAvg(dataset: MultiSpecanFullscreenDataSet, specan) {
  //  dataset.dataSeries[0].getDataSetByName('min').visible = !dataset.dataSeries[0].getDataSetByName('min').visible;
  //  dataset.dataSeries[0].getDataSetByName('max').visible = !dataset.dataSeries[0].getDataSetByName('max').visible;
  //  dataset.dataSeries[0].getDataSetByName('avg').visible = !dataset.dataSeries[0].getDataSetByName('avg').visible;
  //  specan.renderGraph();
  //}
  getBorder(dataSet, specan) {
    return this._sanitizer.bypassSecurityTrustStyle('solid 2px ' + specan.config.borderColor);
  }

  private saveConfigurationToSession() {
    let gridConfig = {};
    (gridConfig as any).items = [];
    this.dataSets.forEach((item, i) => {
      (gridConfig as any).items.push({
        dataSet: {
          satelliteName: this.dataSets[i].satelliteName,
          transponderName: this.dataSets[i].transponderName,
          carrierName: this.dataSets[i].carrierName
        }
      });
    });
    this.userService.saveValueToUserSession('gridConfig', gridConfig).subscribe((response) => {
      if (response.success) {

      } else {

      }
    });
  }

  getDateString(date) {
    if (date != null) {
      return ((new Date(date)).toUTCString()).replace('GMT', 'UTC');
    }
    return '';
  }

  startPlayback() {
    this.getDataForAllSpecans();
    if (this.intervalCount == 0)
      this.intervalCount++;
    this.interval = setInterval(() => {
      this.getDataForAllSpecans();
    }, this.updateRate * 1000);
  }

  getDataForAllSpecans() {
    this.atLeastOneAnalysisHasRun = true;
    this.refreshing = true;
    let requests = new Array<TraceRequestResponse>();
    this.specans.forEach((specan, i) => {
      let dataSet = this.dataSets[i];
      let traceRequest = new TraceRequestResponse();
      traceRequest.satelliteName = dataSet.satelliteName;
      traceRequest.transponderName = dataSet.transponderName;
      traceRequest.carrierName = dataSet.carrierName;
      traceRequest.mostRecentTimestamp = dataSet.timestamp;
      let specanSize = Math.ceil(specan.getCurrentGraphWidth());
      traceRequest.traceLength = specanSize;
      requests.push(traceRequest);
    });
    this.requestSubscription = this.waterfallDataService.getMultipleTraces(requests).subscribe((response) => {
      this.refreshing = false;
      if (response.success) {
        this.requestSubscription.unsubscribe();
        let responseData = response.data as TraceRequestResponse[];
        for (let i = 0; i < this.dataSets.length; i++) {
          var set = this.dataSets[i];
          set.config.showLoadingOverlay = false;
          let idxOfNewDataset = responseData.findIndex((data) => {
            return set.satelliteName == data.satelliteName && set.transponderName == data.transponderName
              && data.carrierName == set.carrierName;
          });
          //check first if the dataset still exists in the array and hasn't been deleted while the request was running
          if (!this.dataSets[i].hasBeenDestroyed) {
            let specan = this.specans.toArray()[i];
            if (idxOfNewDataset > -1) {
              let specanData = responseData[idxOfNewDataset].traces[0];
              let dataSet = this.dataSets[i];

              dataSet.config.showLoadingOverlay = false;
              dataSet.timestamp = specanData.timestamp;
              //if the response has data for the specan, render it
              //otherwise give the specan a yellow border
              if (responseData[idxOfNewDataset].traces[0].powerValues.length > 0) {
                if (specanData.powerValues != null) {
                  dataSet.config.borderColor = this.normalSpecanColor;
                  dataSet.dataSeries[0].resetDataModel();
                  dataSet.dataSeries[0].initializePowerValues(specanData.powerValues, specanData.startFreq, specanData.stepFreq);
                } else {
                  dataSet.config.borderColor = this.warningSpecanColor;
                }
                dataSet.timestamp = specanData.timestamp;
              } else {
                dataSet.config.borderColor = this.warningSpecanColor;
              }

            } else {
              set.config.borderColor = this.warningSpecanColor;
            }
            if (specan != null && this.intervalCount != 0 && !set.hasBeenDestroyed) {
              specan.detectChanges();
              specan.renderGraph();
              specan.resizeGraph();
            }
          }
        }
        this.checkIntervalsForDataSets();
      }
    });
  }
  openSpecanView(dataSet: MultiSpecanFullscreenDataSet) {
    let url = window.location.origin + '/#/specan?satelliteId=' + dataSet.satelliteName +
      '&transponderId=' + dataSet.transponderName;
    if (dataSet.carrierName != null)
      url += '&carrierId=' + dataSet.carrierName;
    window.open(url);
  }
  manuallyUpdate() {
    this.getDataForAllSpecans();
  }
  private checkIntervalsForDataSets() {
    let specans = this.specans.toArray();
    for (let i = 0; i < this.dataSets.length; i++) {
      if (Date.now() - this.dataSets[i].timestamp > this.traceOutdatedIntervalInMs) {
        this.dataSets[i].config.borderColor = this.warningSpecanColor;
        specans[i].resizeGraph();
      }
    }
  }
  getDataForSpecan(dataSet: MultiSpecanFullscreenDataSet, specan: SpectrumAnalyzerThumbnailComponent, needsResize = false) {
    let specanSize = Math.ceil(specan.getCurrentGraphWidth());
    this.waterfallDataService.getNextTrace(dataSet.satelliteName, dataSet.transponderName, dataSet.carrierName, specanSize).subscribe((response) => {
      dataSet.config.showLoadingOverlay = false;

      if (response.success && response.data.length > 0) {
        if (needsResize) {
          specan.resizeGraph();
          specan.dataParams.dbPerDiv = 5;
          specan.config.numberOfYTicks = 5;
        }
        let specanData = response.data[0] as Trace;
        if (specanData.powerValues != null) {
          dataSet.config.borderColor = this.normalSpecanColor;
          dataSet.dataSeries[0].resetDataModel();
          dataSet.dataSeries[0].initializePowerValues(specanData.powerValues, specanData.startFreq, specanData.stepFreq);
        } else {
          dataSet.config.borderColor = this.warningSpecanColor;
        }
        dataSet.timestamp = specanData.timestamp;
        if (specan != null && this.intervalCount != 0) {
          specan.detectChanges();
          specan.renderGraph();
        }
      } else {
        dataSet.config.borderColor = this.warningSpecanColor;
        if (specan != null && this.intervalCount != 0 && !dataSet.hasBeenDestroyed) {
          specan.detectChanges();
          specan.renderGraph();
        }
      }
    });
  }
  checkUpdateRateKeypress(event) {
    if (event.keyCode == 13) {
      this.updateRateChanged();
    }
  }
  updateRateChanged() {
    this.stopPlayback();
    setTimeout(() => {
      this.startPlayback();
    }, 0);
  }
  removeDatasetFromList(dataSet, gridItem) {
    let idx = this.dataSets.findIndex((set) => {
      return set.satelliteName == dataSet.satelliteName &&
        set.transponderName == dataSet.transponderName &&
        set.carrierName == dataSet.carrierName;
    });
    if (idx > -1) {
      this.dataSets[idx].hasBeenDestroyed = true;
      this.dataSets.splice(idx, 1);
    }
    this.stopPlayback();
    setTimeout(() => {
      this.saveConfigurationToSession();
      this.startPlayback();
    }, 0);
  }

  stopPlayback() {
    if (this.interval != null) {
      this.intervalCount = 0;
      clearInterval(this.interval);
    }
    if (this.requestSubscription != null) {
      this.requestSubscription.unsubscribe();
    }
  }
  private showSuccess(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'success';
    this.utilityService.showNotification(msg);
  }
  private showError(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'error';
    this.utilityService.showNotification(msg);
  }
  private showInfo(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'info';
    this.utilityService.showNotification(msg);
  }

  getTimeDurationFromTicks(ticks): string {
    let days = Math.floor(ticks / (1000 * 60 * 60 * 24));
    ticks -= days * (1000 * 60 * 60 * 24);

    let hours = Math.floor(ticks / (1000 * 60 * 60));
    ticks -= hours * (1000 * 60 * 60);

    let mins = Math.floor(ticks / (1000 * 60));
    ticks -= mins * (1000 * 60);

    let seconds = Math.floor(ticks / (1000));
    ticks -= seconds * (1000);

    let resultString = '';
    if (days > 0) {
      resultString += days + " days ";
    }
    if (hours > 0) {
      resultString += hours + ' hours ';
    }
    resultString += mins + ' mins ';
    return resultString;
  }

  ngOnDestroy() {
    this.stopPlayback();
  }
}
