import { XYPoint } from './pf-xy-point';

declare var timsort;

export class ChartData {
  protected data: XYPoint[] = [];

  push(point: XYPoint): void {
    this.data.push(point);
  }

  points(): XYPoint[] {
    return this.data;
  }

  reset() {
    this.data.splice(0, this.data.length - 1);
  }
  pointAtIndex(index: number): XYPoint {
    if (this.data.length > index) {
      return this.data[index];
    } else {
      return null;
    }
  }

  length(): number {
    return this.data.length;
  }

  splice(start: number, deleteCount?: number, ...items): XYPoint[] {
    return this.data.splice(start, deleteCount, ...items);
  }

  clear(): void {
    this.data.splice(0, this.data.length);
  }

  getDomain(): [number, number] {
    if (this.data.length !== 0) {
        let min = this.data[0].x;
        let max = min;
        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].x < min) {
                min = this.data[i].x;
            } else if (this.data[i].x > max) {
                max = this.data[i].x;
            }
        }
      return [min, max];
    }
    // must return null here since 0 could be perceived as a valid value to enclosing components
    return [null, null];
  }

  getRange(): [number, number] {
    if (this.data.length !== 0) {
      let min = this.data[0].y;
      let max = min;
      for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].y < min) {
              min = this.data[i].y;
          } else if (this.data[i].y > max) {
              max = this.data[i].y;
          }
      }
      return [min, max];
    }
    // must return null here since 0 could be perceived as a valid value to enclosing components
    return [null, null];
  }

  sort(): void {
    timsort.sort(this.data, (a: XYPoint, b: XYPoint) => {
      return a.x - b.x;
    });
  }
}
