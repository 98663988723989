import { MenuItem } from 'primeng/primeng';
import { Subject } from 'rxjs/Rx';

export class SpectrumAnalyzerDataParameters {

  spanRbwRatio: number; // ratio used to calculate rbw when span changes
  RbwVbwRatio: number; // ratio to calculate vbw when rbw changes
  private graphDbPerDiv = 5;

  // inputs/representation of these values
  graphRBW = 0;
  graphVBW = 0;

  // the axis mins/maxes are not required, but can be set manually
  // if they are set, they will trump the bounds of the dataset
  axisMinX: number;
  axisMinY: number;
  axisMaxX: number;
  axisMaxY: number;

  spanChanged: Subject<number> = new Subject<number>();
  centerFreqChanged: Subject<number> = new Subject<number>();

  // these are the RBW/VBW for the graph, and changes are emitted to the parent
  rbwValueChange: Subject<number> = new Subject<number>();
  vbwValueChange: Subject<number> = new Subject<number>();
  dbPerDivValueChange: Subject<number> = new Subject<number>();

  private dbDivHasBeenSet: boolean;

  set span(value: number) {
    this.spanChanged.next(value);
  }

  set centerFreq(value: number) {
    this.centerFreqChanged.next(value);
  }

  set rbw(value: number) {
    this.graphRBW = value;
    this.rbwValueChange.next(value);
  }

  set vbw(value: number) {
    this.graphVBW = value;
    this.vbwValueChange.next(value);
  }

  get dbPerDiv() {
    return this.graphDbPerDiv;
  }

  set dbPerDiv(value: number) {
    this.dbDivHasBeenSet = true;
    this.graphDbPerDiv = value;
    this.dbPerDivValueChange.next(value);
  }
  hasDbPerDivBeenSet() {
    return this.dbDivHasBeenSet;
  }

  removeDbPerDivSetting() {
    this.dbDivHasBeenSet = false;
  }
}
