import { XYPoint } from './index'
import { TrendMeasurement } from 'app/models/trend-measurement';
export class TrendDataSet {
    Iid: string;
    transponderId: string;
    carrierId: string;
    measEirpTrace: TrendMeasurement;
    measCFTrace: TrendMeasurement;
    measCN0Trace: TrendMeasurement;
    nomCN0Trace: TrendMeasurement;
    measN0Trace: TrendMeasurement;
    measCNTrace: TrendMeasurement;
    measEbnoTrace: TrendMeasurement;
    nomEbnoTrace: TrendMeasurement;
    nomCFTrace: TrendMeasurement;
    nomEirpTrace: TrendMeasurement;

    absMeasEirpTrace: TrendMeasurement;
    absMeasCFTrace: TrendMeasurement;
    absMeasEbnoTrace: TrendMeasurement;
    absNomEbnoTrace: TrendMeasurement;
    absNomCFTrace: TrendMeasurement;
    absNomEirpTrace: TrendMeasurement;
    absNomOccBWTrace: TrendMeasurement;
    absMeasOccBWTrace: TrendMeasurement;
    absMeasCN0Trace: TrendMeasurement;
    absNomCN0Trace: TrendMeasurement;

    eirpDeltaMaxTrace: TrendMeasurement;
    eirpDeltaMinTrace: TrendMeasurement;
    cfDeltaMinTrace: TrendMeasurement;
    cfDeltaMaxTrace: TrendMeasurement;
    occBWDeltaMinTrace: TrendMeasurement;
    assignedBWTrace: TrendMeasurement;
    occBWDeltaMaxTrace: TrendMeasurement;
    cN0DeltaMaxTrace: TrendMeasurement;
    cN0DeltaMinTrace: TrendMeasurement;
    ebnoDeltaMinTrace: TrendMeasurement;
    ebnoDeltaMaxTrace: TrendMeasurement;

    absEirpDeltaMaxTrace: TrendMeasurement;
    absEirpDeltaMinTrace: TrendMeasurement;
    absCFDeltaMinTrace: TrendMeasurement;
    absCFDeltaMaxTrace: TrendMeasurement;
    absOccBWDeltaMinTrace: TrendMeasurement;
    absOccBWDeltaMaxTrace: TrendMeasurement;
    absCN0DeltaMaxTrace: TrendMeasurement;
    absCN0DeltaMinTrace: TrendMeasurement;
    absEbnoDeltaMinTrace: TrendMeasurement;
    absEbnoDeltaMaxTrace: TrendMeasurement;

    measDataRateTrace: TrendMeasurement;
    measSymbolRateTrace: TrendMeasurement;
    nomOccBWTrace: TrendMeasurement;
    measOccBWTrace: TrendMeasurement;
    timestampStart: number;
    timestampStop: number;
}
