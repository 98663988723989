import { Carrier } from "app/models";
import { Frequency } from "app/models/frequency";
import { Beam } from "app/models/beam";
import { EntityDef } from "app/models/entity-def";

export class Transponder extends EntityDef {
  //Status
  active: boolean;
  status: string;
  //Identification
  primaryName: string;
  aliases: Array<string>;
  noradNumber: string;
  //Information
  carriers: Array<Carrier>;
  frequencies: Array<Frequency>;
  guardBands: Array<string>;
  beams: Array<Beam>;
  polarizations: Array<string>;
  //UI info
  satelliteName: string;
}
