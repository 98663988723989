import { Output, EventEmitter } from '@angular/core';
import { ScaleContinuousNumeric } from 'd3-ng2-service';
import { Subject } from 'rxjs/Rx';
import { D3 } from 'd3-ng2-service';
import { ChartRenderer } from './../../../chart/pf-chart/render/pf-chart-renderer';
import { WaterfallPlotAttributes } from './pf-waterfall-graph-attributes';
import { WaterfallSeries, WaterfallData, WaterfallXY, AxisGridDimensions } from '../../index';
import { GradientGenerator } from '../utilities/gradient-generator';

export class WaterfallRenderer {
  attributes = new WaterfallPlotAttributes();
  onRenderEnd: Subject<boolean> = new Subject<boolean>();
  forwardFillValues: boolean;
  private height: number;
  private width: number;
  private d3: any;
  private trackingLineElem;
  private showYAxisLabels: boolean;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  init(showYAxisLabels: boolean) {
    this.showYAxisLabels = showYAxisLabels;
  }
  appendSvg(svg: any, graphContainer: any) {
    svg = this.d3.select(graphContainer)
      .append('svg')
      .classed('pf-waterfall-svg', true)
      .attr('id', this.attributes.graphId + '-svg');

    if (this.attributes.outerGraphHeight > 0 && this.attributes.outerGraphWidth > 0) {
      svg.attr('viewBox', '0 0 ' + this.attributes.innerGraphWidth + ' ' + (this.attributes.outerGraphHeight - 5))
        .attr('width', this.attributes.outerGraphWidth)
        .attr('height', this.attributes.outerGraphHeight);
    } else {
      svg.attr('width', '100%')
        .attr('height', '100%');
    }
    return svg;
  }
  appendCanvas(canvas: any, chartContainerElem: any) {
    canvas = this.d3.select(chartContainerElem)
      .append('canvas')
      .attr('width', this.attributes.outerGraphWidth)
      .attr('height', this.attributes.outerGraphHeight)
      .attr('class', 'pf-waterfall-canvas');
    return canvas;
  }
  getCanvasBoundingRect() {
    return document.querySelector('canvas').getBoundingClientRect();
  }
  appendSvgElementsContainer(svg: any) {
    let widthOfGradientWidget = 50;
    const svgDataContainer = svg.append('g').attr('id', this.attributes.graphId)
      .style('position', 'absolute')
      .attr('height', this.attributes.innerGraphHeight)
      .attr('width', this.attributes.innerGraphWidth)
    if (this.showYAxisLabels) {
      svgDataContainer.attr('transform', `translate(${this.attributes.marginLeft}, ${this.attributes.marginTop})`);
    } else {
      svgDataContainer.attr('transform', `translate(-10, ${this.attributes.marginTop})`);
    }
    // add this rect for the ability to set a background color
    svgDataContainer.append('rect')
      .style('width', '100%')
      .style('height', '100%')
      .attr('class', 'pf-waterfall-background');
    if (this.attributes.graphBorderColor !== '') {
      const borderColorElem = svgDataContainer.append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('height', this.attributes.innerGraphHeight - 10)
        .attr('width', this.attributes.innerGraphWidth)
        .style('width', '100%')
        .style('height', 'calc(100% - 10px)')
        .style('stroke', this.attributes.graphBorderColor)
        .attr('id', this.attributes.graphId + '-border')
        .style('fill', 'none')
        .style('stroke-width', 2);
    }
    return svgDataContainer;
  }
  resize() {
    
    if (this.getParentComponent() != null) {
      this.setOuterGraphDimensions();
      let yAxisWidth = this.getYAxisWidth();      
      let widthOfGradientWidget = 100;
      this.setGraphHeightDimensions();
      this.setGraphWidthDimensions(this.getYAxisWidth(), widthOfGradientWidget);
      return this.attributes.outerGraphHeight;
    }
  }
  private getYAxis() {
    return document.getElementById(this.attributes.graphId + '-yaxis');
  }
  private getYAxisWidth() {
      let yAxisWidth;
      let yaxis = this.getYAxis();
    if (yaxis != null) {
      yAxisWidth = yaxis.getBoundingClientRect().width;
      //if the axis is large enough to warrant adding a margin, add some margin
      //usually only the case when the axis is greater than 50px wide
      if (yAxisWidth > 60) {
        this.attributes.marginLeft = yAxisWidth - 60;
      }
    } else {
      yAxisWidth = 105;
      }
    return yAxisWidth;
  }
  private getParentComponent() {
    let parentComponent;
    const currentComponent = document.getElementById(this.attributes.graphId + '-pf-waterfall-container');
    if (currentComponent != null) {
      parentComponent = currentComponent.parentElement.parentElement;
    }
    return parentComponent;
  }
  private setOuterGraphDimensions() {
    let parentComponent = this.getParentComponent();
    const style = window.getComputedStyle(parentComponent, null);
    const rect = parentComponent.getBoundingClientRect();
    this.attributes.outerGraphHeight = rect.height;
    this.attributes.outerGraphWidth = rect.width;
    // have to subtract the padding on the div to get the correct height and width
    this.attributes.outerGraphHeight -= parseFloat(style.paddingTop.replace('px', ''))
      + parseFloat(style.paddingBottom.replace('px', ''));
    this.attributes.outerGraphWidth -= parseFloat(style.paddingLeft.replace('px', ''))
      + parseFloat(style.paddingRight.replace('px', ''));
  }
  private setGraphWidthDimensions(yAxisWidth, widthOfGradientWidget) {
    if (this.showYAxisLabels) {
      this.attributes.innerGraphWidth = this.attributes.outerGraphWidth - yAxisWidth - widthOfGradientWidget;
    } else {
      this.attributes.innerGraphWidth = this.attributes.outerGraphWidth - 50 - widthOfGradientWidget;
    }
    if (this.attributes.innerGraphWidth < 0) {
      this.attributes.innerGraphWidth = 0;
      this.attributes.outerGraphWidth = 0;
    }
  }
  private setGraphHeightDimensions() {
    if (this.showYAxisLabels) {
      this.attributes.innerGraphHeight = this.attributes.outerGraphHeight - 50;
    } else {
      this.attributes.innerGraphHeight = this.attributes.outerGraphHeight - 50;
    }
    if (this.attributes.innerGraphHeight < 0) {
      this.attributes.innerGraphHeight = 0;
      this.attributes.outerGraphHeight = 0;
    }
  }
  addMouseEvents(svg: any, mouseMove: Function, mouseLeave: Function) {
    svg.on('mousemove', mouseMove).on('mouseleave', mouseLeave);
  }
  setCanvasLocation(canvas, dimensions: AxisGridDimensions) {
    canvas.style('left', dimensions.leftInPixels + 'px');
    canvas.style('top', dimensions.topInPixels + 'px');
    canvas.attr('height', (dimensions.bottomInPixels - dimensions.topInPixels) + 'px');
    canvas.attr('width', (dimensions.rightInPixels - dimensions.leftInPixels) + 'px');
  }
  clearCanvas(context: CanvasRenderingContext2D) {
    context.clearRect(0, 0, this.attributes.outerGraphWidth, this.attributes.outerGraphHeight);
  }
  render(context: CanvasRenderingContext2D, series: WaterfallSeries, forwardFillValues: boolean,
    domainScale: ScaleContinuousNumeric<number, number>, rangeScale: ScaleContinuousNumeric<number, number>,
    dataDomain: number[] = null, gradient = null): void {
    this.forwardFillValues = forwardFillValues;
    //context.clearRect(0, 0, this.attributes.outerGraphWidth, this.attributes.outerGraphHeight);
    if (!!series) {
      this.renderPoints(context, series, this.attributes.outerGraphHeight, domainScale, rangeScale, dataDomain, gradient);
    }
  }

  private renderPoints(context: CanvasRenderingContext2D, series: WaterfallSeries, height: number,
    domainScale: ScaleContinuousNumeric<number, number>, rangeScale: ScaleContinuousNumeric<number, number>,
    dataDomain: number[] = null, gradient = null): void {

    context.shadowBlur = 0;

    let dataRange;
    if (dataDomain != null) {
      dataRange = dataDomain;
    } else {
      dataRange = series.getDataRange();
    }

    if (series.data() != null) {
      if (this.forwardFillValues) {
        this.colorCanvasWithForwardFill(series, gradient, height, dataRange, domainScale, rangeScale, context);
      } else {
        this.colorCanvasNoForwardFill(series, gradient, height, dataRange, domainScale, rangeScale, context);
      }
    }
  }
  private colorCanvasWithForwardFill(series, gradient, height, dataRange, domainScale, rangeScale, context) {
    // determine the number of data sets per group of pixels
    // render the canvas gradually to show the user our progress
    // we group them to minimize the time required for each timeout
    // since each timeout is placed in a separate digest cycle
    const dataSetLength = series.length();
    const allDataSets = series.data();
    const numOfDataSetGroups = Math.round(height) < dataSetLength ? Math.round(height) : dataSetLength;
    const numOfTracesPerDataSetGroup = Math.round(dataSetLength / numOfDataSetGroups);
    const beginningTimeStamp = (allDataSets[0] as WaterfallData).timestamp;
    const endTimeStamp = (allDataSets[allDataSets.length - 1] as WaterfallData).timestamp;
    let dataSetsIdx = 0;

    let fillValues;
    if (gradient == null) {
      fillValues = this.getFillValuesFromData(series);
    } else {
      fillValues = gradient;
    }
    for (let i = 0; i < numOfDataSetGroups; i++) {
      for (let j = 0; j < numOfTracesPerDataSetGroup; j++) {
        if (dataSetsIdx < allDataSets.length && allDataSets[dataSetsIdx] != null) {
          this.fillCanvasForwardFill(allDataSets, dataSetsIdx, dataSetLength, dataRange, domainScale, rangeScale, endTimeStamp, fillValues, context);
        }
        dataSetsIdx++;
      }
    }
  }
  private fillCanvasForwardFill(allDataSets, dataSetsIdx, dataSetLength, dataRange, domainScale, rangeScale, endTimeStamp, fillValues, context) {
    let forwardFillVertPixelAmt = this.getForwardFillValue(rangeScale, allDataSets, dataSetsIdx);
    let thisContext = this;
    (function (dataSetsIdx, forwardFillVertPixelAmt, thisContext) {
      let timeout = setTimeout(() => {
        let points = allDataSets[dataSetsIdx].points();
        for (let i = 0; i < points.length; i++) {
          let currentXVal = domainScale(points[i].x);
          let currentYVal = rangeScale((allDataSets[dataSetsIdx] as WaterfallData).timestamp);
          let forwardFillHorizPixelAmt = i < points.length - 1 ? Math.ceil(domainScale(points[i + 1].x) - currentXVal) : Math.ceil(thisContext.attributes.innerGraphWidth - currentXVal);
          context.fillStyle = fillValues[thisContext.calculateColorIndexForData(dataRange, points[i].y, fillValues.length)];
          context.fillRect(currentXVal, currentYVal, forwardFillHorizPixelAmt, forwardFillVertPixelAmt);
        }

        if ((allDataSets[dataSetsIdx] as WaterfallData).timestamp >= endTimeStamp) {
          // emit the finished event
          thisContext.onRenderEnd.next(true);
        }
        clearTimeout(timeout);
      });
    })(dataSetsIdx, forwardFillVertPixelAmt, thisContext);
  }
  private getFillValuesFromData(series) {
    let fillValues = [];
    let dataRange = series.getDataRange();
    let difference = Math.abs(dataRange[1] - dataRange[0]);
    fillValues = GradientGenerator.generateGradient(this.attributes.gradient, difference);
    return fillValues;
  }
  private getForwardFillValue(rangeScale, allDataSets, dataSetsIdx) {
    //to forward fill values, we need to calculate the difference in pixels between this data set and the next
    //then fill the canvas with pixels for that range
    let yPixelsOfThisData = rangeScale((allDataSets[dataSetsIdx] as WaterfallData).timestamp);
    let forwardFillVertPixelAmt;
    let yPixelsOfPreviousData = dataSetsIdx > 0 ? rangeScale((allDataSets[dataSetsIdx - 1] as WaterfallData).timestamp) : this.attributes.innerGraphHeight - 1;
    forwardFillVertPixelAmt = Math.ceil(yPixelsOfPreviousData - yPixelsOfThisData);
    return forwardFillVertPixelAmt;
  }

  private colorCanvasNoForwardFill(series, gradient, height, dataRange, domainScale, rangeScale, context) {
    // determine the number of data sets per group of pixels
    // render the canvas gradually to show the user our progress
    // we group them to minimize the time required for each timeout
    // since each timeout is placed in a separate digest cycle
    const dataSetLength = series.length();
    const allDataSets = series.data();
    const numOfDataSetGroups = Math.round(height) < dataSetLength ? Math.round(height) : dataSetLength;
    const numOfTracesPerDataSetGroup = Math.round(dataSetLength / numOfDataSetGroups);
    const beginningTimeStamp = (allDataSets[0] as WaterfallData).timestamp;
    const endTimeStamp = (allDataSets[allDataSets.length - 1] as WaterfallData).timestamp;
    let fillValues;
    if (gradient == null) {
      fillValues = this.getFillValuesFromData(series);
    } else {
      fillValues = gradient;
    }
    let dataSetsIdx = 0;
    for (let i = 0; i < numOfDataSetGroups; i++) {
      for (let j = 0; j < numOfTracesPerDataSetGroup; j++) {
        this.fillCanvasNoForwardFill(allDataSets, dataSetsIdx, dataRange, dataSetLength, domainScale, rangeScale, endTimeStamp, fillValues, context);
      }
    }
  }
  private fillCanvasNoForwardFill(allDataSets, dataSetsIdx, dataRange, dataSetLength, domainScale, rangeScale, endTimeStamp, fillValues, context) {
    const chartData = allDataSets[dataSetsIdx++];
    if (chartData != null) {
      let timeout = setTimeout(() => {
        chartData.points().forEach(point => {
          context.fillStyle = fillValues[this.calculateColorIndexForData(dataRange, point.y, fillValues.length)];
          context.fillRect(domainScale(point.x), rangeScale((chartData as WaterfallData).timestamp), 1, 1);
        });
        if ((chartData as WaterfallData).timestamp >= endTimeStamp) {
          // emit the finished event
          this.onRenderEnd.next(true);
        }
        clearTimeout(timeout);
      });
    }
  }
  private calculateColorIndexForData(range: number[], yValue: number, fillValueLength: number): number {
    const rangeRange = Math.abs(range[1] - range[0]);
    const yValueDiffFromMin = Math.abs(yValue - range[0]);
    const percentageOfMinMax = Math.abs(yValueDiffFromMin / rangeRange);
    const indexOfGradientColorForData = Math.round(fillValueLength * percentageOfMinMax);
    return indexOfGradientColorForData;
  }
}
