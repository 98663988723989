export class User {
  account_id: string;
  name: string;
  firstName: string = '';
  lastName: string = '';
  password: string;
  email: string;
  isAdmin: boolean;
  parent: string;
  parents: string[];
  phoneNumber: number;
  profile_id: string;
  disabled: boolean;
  locked: boolean;
  data: any;
  details: any;
  roles = [];
  hasAdminRole() {
    if (this.roles != null) {
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i] == 'Administrator') {         
          return true;
        }
      };
      return false;
    }
  }
  constructor(user: User = null) {
    if (user != null) {
      this.account_id = user.account_id;
      this.name = user.name;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.password = user.password;
      this.email = user.email;
      this.parent = user.parent;
      this.phoneNumber = user.phoneNumber;
      this.profile_id = user.profile_id;
      this.disabled = user.disabled;
      this.locked = user.locked;
      this.roles = user.roles;
      this.isAdmin = this.hasAdminRole();
    }
  }
}
