import { Component } from '@angular/core';
import { User, UserMessage, ServiceResponse, Carrier } from 'app/models';
import { UserService } from 'app/services/user-service';
import { AppUtilities } from 'app/services/app-utilities';
import { EntityDef } from 'app/models/entity-def';
import { DataViewDemoService } from 'app/services/data-view-demo-service';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import { Transponder } from 'app/models/transponder';
import { Organization } from '../../models/organization';
import { EntityType } from '../../enums/entity-type';
import { EntityOperation } from '../../enums/entity-operation';
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  satellitesLoading: boolean;
  transpondersLoading: boolean;
  carriersLoading: boolean;
  groups = ['Administrators', 'Users'];
  companies = ['RT Logic', 'Kratos'];
  currentUser: User;
  currentUserDetails: User;
  newUserPassword: string;
  currentUserPassword: string;
  confirmUserPassword: string;
  passwordError: boolean;
  showChangePasswordInput: boolean;
  loading: boolean;
  userLoading: boolean;

  constructor(private utilityService: AppUtilities, private userService: UserService, private dataService: DataViewDemoService) {
    this.userLoading = true;
      this.userService.getCurrentUserInfo().subscribe((response: ServiceResponse) => {
      if (response.success) {
        this.currentUser = JSON.parse(response.data);
        this.currentUserDetails = this.currentUser.details;
        this.currentUserDetails.isAdmin = this.currentUser.roles.findIndex((role) => { return role == 'Administrator' }) > -1;
      } else {
        const msg = new UserMessage();
        msg.messageTitle = 'Request Failed';
        msg.messageDetail = 'Unable to retrieve user profile';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
      this.userLoading = false;
    });
  }

  prepareUserObjForSending() {
    if (this.showChangePasswordInput)
      this.currentUser.password = this.newUserPassword;
    let idx = this.currentUser.roles.findIndex((role) => {
      return role == 'Administrator';
    });
    if (this.currentUserDetails.isAdmin) {
      if (idx < 0) {
        this.currentUser.roles.push('Administrator');
      }
    } else {
      if (idx > -1) {
        this.currentUser.roles.splice(idx, 1);
      }
    }
    this.currentUser.parents = [];
    if (this.currentUserDetails.parent)
      this.currentUser.parents.push(this.currentUserDetails.parent);
    //have to do this because of the backend design...
    //details is where all this user info is actually stored
    this.currentUser.details = this.currentUserDetails;
  }
  save() {
    this.loading = true;
    
    this.prepareUserObjForSending();
    this.userService.saveUserInfo(this.currentUser).subscribe((response: ServiceResponse) => {
      this.loading = false;
      if (response.success) {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Success';
        msg.messageDetail = 'Changes were saved successfully';
        msg.messageType = 'success';
        this.utilityService.showNotification(msg);
      } else {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Failed';
        msg.messageDetail = 'Unable to save user information';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
    });
  }
  changePassword() {
    this.loading = true;
    this.userService.changeUserPassword(this.currentUser.name, this.currentUserPassword, this.newUserPassword).subscribe((response: ServiceResponse) => {
      this.loading = false;
      if (response.success) {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Success';
        msg.messageDetail = 'Changes were saved successfully';
        msg.messageType = 'success';
        this.utilityService.showNotification(msg);
        this.currentUserPassword = '';
        this.newUserPassword = '';
        this.confirmUserPassword = '';
        this.showChangePasswordInput = false;
      } else {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Failed';
        msg.messageDetail = 'Unable to save user information';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
    });
  }
  showChangePassword() {
    this.showChangePasswordInput = true;
  }
  checkMatch(newValue) {
    this.confirmUserPassword = newValue;
    if (this.newUserPassword != this.confirmUserPassword)
      this.passwordError = true;
    else
      this.passwordError = false;
  }
  private showSuccess(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'success';
    this.utilityService.showNotification(msg);
  }
}
