import { Axis, D3, Selection } from 'd3-ng2-service';

export class SpectrumAnalyzerAxes {
  yAxisTitle: string;
  showXAxesTickLabels: boolean;
  showYAxesTickLabels: boolean;
  showOnlyGraphWindow: boolean;
  displayAsNormalGraph: boolean;
  private marginLeft: number;
  private marginBottom: number;
  private xAxisFunc: any;
  private yAxisFunc: any;
  private xAxisTickFunc: any;
  private yAxisTickFunc: any;
  private yAxisId: string;
  private xAxisId: string;
  private xAxis: any;
  private yAxis: any;
  private yAxisTicks: number[];
  private xAxisTicks: number[];
  private xAxisTickCount: number;
  private d3: D3;
  private dbDivValue = 0;
  private ticks: number;
  private graphId: any;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  init(graphId: string, yAxisTitle: string, marginLeft: number, marginBottom: number, ticks: number, dbDiv: number) {
    this.yAxisId = graphId + '-yaxis';
    this.xAxisId = graphId + '-xaxis';
    this.yAxisTitle = yAxisTitle;
    this.marginLeft = marginLeft;
    this.marginBottom = marginBottom;
    this.graphId = graphId;
    this.dbDivValue = dbDiv;
    this.setTicks(ticks);
  }
  setTicks(ticks: number) {
    this.ticks = ticks;
    // we are going to coerce the x axis into having a line down the middle at the center freq
    // so we need the tick count for the x axis to be even
    if (ticks % 2 === 1) {
      ticks++;
    }
    this.xAxisTickCount = ticks;
  }
  // empty refers to whether the axes should be created but not populated with data-related attributes
  // used to render an empty graph before actual data is available
  create(svg: Selection<any, {}, null, undefined>,
    empty: boolean,
    height: number,
    width: number,
    domainScale: any,
    rangeScale: any,
    xAxisTickFunc: any = null,
    yAxisTickFunc: any = null,
    dbPerDiv: number = null,
    showGraticule: boolean = true) {
    // calculate the tick values for both axes
    this.xAxisTickFunc = xAxisTickFunc;
    this.yAxisTickFunc = yAxisTickFunc;
    const xAxisTicks = this.determineXAxisTicks(domainScale, this.xAxisTickCount);
    const yAxisTicks = this.determineYAxisTicks(rangeScale, this.ticks, dbPerDiv);

    if (!empty) {
      if (xAxisTickFunc != null) {
        this.xAxisFunc = this.d3.axisBottom(domainScale).tickValues(xAxisTicks).tickFormat(xAxisTickFunc);
      } else {
        this.xAxisFunc = this.d3.axisBottom(domainScale).tickValues(xAxisTicks);
      }
      if (yAxisTickFunc != null) {
        this.yAxisFunc = this.d3.axisLeft(rangeScale).tickValues(yAxisTicks).tickFormat(yAxisTickFunc);
      } else {
        this.yAxisFunc = this.d3.axisLeft(rangeScale).tickValues(yAxisTicks);
      }
    } else {
      this.xAxisFunc = this.d3.axisBottom(domainScale).tickValues(xAxisTicks).tickFormat(function (d) { return ''; });
      this.yAxisFunc = this.d3.axisLeft(rangeScale).tickValues(yAxisTicks).tickFormat(function (d) { return ''; });
    }

    // check to make sure the graticule is configured to be shown
    if (showGraticule) {
      this.xAxisFunc.tickSize(-height);
      this.yAxisFunc.tickSize(-width);
    }

    // remove the old axes if they exist
    svg.select('#' + this.yAxisId).remove();
    svg.select('#' + this.xAxisId).remove();

    this.yAxis = svg.insert('g')
      .attr('id', this.yAxisId)
      .attr('class', 'pf-specan-grid')
      .call(this.yAxisFunc);

    this.xAxis = svg.insert('g')
      .attr('class', 'pf-specan-grid')
      .attr('id', this.xAxisId)
      .attr('transform', 'translate(0,' + (height) + ')')
      .call(this.xAxisFunc);

    // we have our own axis border so remove the ones added with the axes
    svg.select('#' + this.yAxisId).select('path').attr('stroke', 'transparent');
    svg.select('#' + this.xAxisId).select('path').attr('stroke', 'transparent');

    if (!empty) {
      this.dbDivValue = +(yAxisTicks[1] - yAxisTicks[0]).toFixed(2);

      if (!this.showOnlyGraphWindow && this.showYAxesTickLabels) {
        //  text label for the y axis
        svg.append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0 - this.marginLeft)
          .attr('x', 0 - ((height - this.marginBottom) / 2))
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .attr('class', 'pf-specan-graph-label')
          .text(this.yAxisTitle);
      }
    }
    // remove the tick marks from the x axis
    this.removeAxisTicksIfNecessary();
    return this.calculateAxisGridDimensions(svg);
  }

  calculateAxisDimensions(svg) {
    const yAxisBounds = document.querySelector('#' + this.yAxisId).getBoundingClientRect();
    const xAxisBounds = document.querySelector('#' + this.xAxisId).getBoundingClientRect();
    const yAxisGridBounds = document.querySelector('#' + this.yAxisId).querySelector('path').getBoundingClientRect();
    const xAxisGridBounds = document.querySelector('#' + this.xAxisId).querySelector('path').getBoundingClientRect();
    const axisDimensions = {
      leftInPixels: yAxisBounds.left,
      rightInPixels: yAxisBounds.right,
      topInPixels: yAxisBounds.top,
      bottomInPixels: yAxisBounds.bottom,
      width: yAxisGridBounds.left - yAxisBounds.left,
      height: xAxisGridBounds.bottom - xAxisBounds.bottom
    };
    return axisDimensions;
  }
  getDbDiv() {
    return this.dbDivValue;
  }
  changeDbDivVal(dbDiv: number) {
    this.dbDivValue = dbDiv;
  }
  reset() {
    this.xAxis = null;
    this.yAxis = null;
    this.yAxisFunc = null;
    this.xAxisFunc = null;
  }

  // depending on the zoom, we might be dealing with two different scales
  zoom(transformedScaleX: any, transformedScaleY: any, dbPerDiv: number = null, domainScale: any = null, rangeScale: any = null): void {
    this.xAxisTicks = this.determineXAxisTicks(transformedScaleX, this.xAxisTickCount);
    this.yAxisTicks = this.determineYAxisTicks(transformedScaleY, this.ticks, dbPerDiv);
    this.dbDivValue = +(this.yAxisTicks[1] - this.yAxisTicks[0]).toFixed(2);
    const yScale = rangeScale != null ? rangeScale : transformedScaleY;
    const xScale = domainScale != null ? domainScale : transformedScaleX;
    if (this.yAxisTickFunc != null) {
      this.yAxis.call(this.yAxisFunc.scale(yScale).tickValues(this.yAxisTicks).tickFormat(this.yAxisTickFunc));
    } else {
      this.yAxis.call(this.yAxisFunc.scale(yScale).tickValues(this.yAxisTicks));
    }
    if (this.xAxisTickFunc != null) {
      this.xAxis.call(this.xAxisFunc.scale(xScale).tickValues(this.xAxisTicks).tickFormat(this.xAxisTickFunc));
    } else {
      this.xAxis.call(this.xAxisFunc.scale(xScale).tickValues(this.xAxisTicks));
    }
    // remove the tick marks from the x axis
    this.removeAxisTicksIfNecessary();
  }
  private removeAxisTicksIfNecessary() {
    if (!this.displayAsNormalGraph || !this.showXAxesTickLabels) {
      this.xAxis.selectAll('text').remove();
    }
    if (!this.showYAxesTickLabels || this.showOnlyGraphWindow) {
      this.yAxis.selectAll('text').remove();
    }
  }
  private determineXAxisTicks(scale, ticks) {
    const domain = scale.domain();

    const tickIncr = (domain[1] - domain[0]) / ticks;
    const axisTicks = [];

    for (let i = 0; i < ticks + 1; i++) {
      axisTicks.push(domain[0] + (tickIncr * i));
    }

    return axisTicks;
  }
  private determineYAxisTicks(scale, ticks, dbPerDiv) {
    const domain = scale.domain();
    if (dbPerDiv != null) {
      ticks = (domain[1] - domain[0]) / dbPerDiv;
    } else {
      dbPerDiv = (domain[1] - domain[0]) / ticks;
    }
    const axisTicks = [];

    for (let i = 0; i < ticks + 1; i++) {
      axisTicks.push(domain[0] + (dbPerDiv * i));
    }

    return axisTicks;
  }
  private calculateAxisGridDimensions(svg) {
    const xAxisBounds = document.querySelector('#' + this.xAxisId).querySelector('path').getBoundingClientRect();
    const gridDimensions = {
      leftInPixels: xAxisBounds.left,
      rightInPixels: xAxisBounds.right,
      topInPixels: xAxisBounds.top,
      bottomInPixels: xAxisBounds.bottom
    };
    return gridDimensions;
  }
}
