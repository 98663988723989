import {
  OnInit, Component, Input, EventEmitter, Output, Inject, InjectionToken, ViewChild,
  ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver, OnDestroy
} from '@angular/core';
import { DataTable } from 'primeng/primeng';
import { Subscription } from 'rxjs/Rx';
import { DataViewDemoService } from '../../../services/data-view-demo-service';
import { UserMessage } from 'app/models/user-message';
import { AppUtilities } from '../shared';
import { UserService } from '../../../services/user-service';
import { ConfirmationDialogComponent } from '../alert/confirmation-dialog.component';

export abstract class SatcomEditorBase implements OnInit, OnDestroy {
  totalRecords: number;
  @ViewChild('updatePanel', { read: ViewContainerRef }) updatePanel;
  @ViewChild('dataTable') dataTable: DataTable;
  entityType;
  entityOperation;
  loading: boolean = true;
  pageSize: number = 15;
  pageNumber: number = 0;
  avgRowHeight: number = 30.4;
  displayPageEnd = 0;
  displayPageStart = 0;
  inView: boolean;
  onLastPage: boolean;
  settingsKey: string;
  nameFilter: string;
  // set these defaults to calculate the size of the table
  filterBarHeight: number = 35;
  filter: string;
  headerBarHeight: number = 0;
  tableHeaderHeight: number = 45;
  footerBarHeight: number = 31;
  miscPaddingAndTabs: number = 75;
  extraComponentPadding: number = 0;
  tableContainerId: string = '';
  @ViewChild('alert') alert: ConfirmationDialogComponent;
  showInUseOnly: boolean;
  entityList: any[] = new Array<any>();
  selectedEntity: any;
  cols: any[];
  items: any[];
  identifierFieldName: string;
  editing: boolean;
  editorComponentRef: any;

  constructor(public dataService: DataViewDemoService, private utilityService: AppUtilities, private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver, public userService: UserService) {
    // in the constructor of extending classes, you must do the following:
    // initialize the type of the selected entities
    // this.selectedEntities = new Array<EntityType>();
    // initialize the filter type
    // this.currentQuery.filter = new EntityTypeFilter();
    // initialize the sort and initial sorting value
    // this.currentQuery.sort = new Sort();
    // this.currentQuery.sort.Add('EntityTypeIdentifier', false);
    // designate a table container id for the table to use
    // this.tableContainerId = 'EntityTypeTable';


    this.items = [
      { label: 'Edit', command: (event) => this.updateEntity(event, this.selectedEntity) },
      { separator: true },
      { label: 'Delete', command: (event) => this.deleteSelected() }
    ];

  }
  openEditPanel(className, existingItem = null) {
    this.editing = true;
    const factory = this.componentFactoryResolver.resolveComponentFactory(className);
    const componentRef = this.viewContainer.createComponent(factory) as any;
    componentRef.instance.open(existingItem).then((update) => {
      if (update) {
        this.query();
      }
      this.editing = false;
      componentRef.destroy();
    });
  }
  setEnumTypes(entityType, entityOp) {
    this.entityOperation = entityOp;
    this.entityType = entityType;
  }
  ngOnInit() {
    let sub = this.userService.checkUserAuthenticated().subscribe((response) => {
      if (response.success) {
        this.initialize();
      } else {
        let newSub = this.userService.userLoggedInObs.subscribe((result) => {
          this.initialize();
          newSub.unsubscribe();
        })
      }
    });

  }
  ngOnDestroy() {
    this.entityList = [];
  }
  updateRow(event) {
    this.updateEntity(event, event.data);
  }
  abstract windowResized();
  protected onPage(event) {
    this.pageNumber = event.first / this.pageSize;
    this.calculatePageMarkers(event.first);
  }
  query(data = null) {
    this.loading = true;
    this.dataService.performEntitiesOperation(this.entityType, this.entityOperation.Get, data).subscribe(
      (result) => {
        if (!!result && result.success) {
          this.entityList = JSON.parse(result.data);
        } else {
          this.loading = false;
          this.utilityService.showServiceError('Error Retrieving Entities', result);
        }
        this.totalRecords = this.entityList.length;

        this.loading = false;
        // determine if we're on the last page. If we are, we don't want to call ensureCorrectRowCount because there will naturally
        // be less rows than will fill the page
        this.setLastPageIndicator();
        // put this in a timeout to give a digest cycle to insert the actual rows into the HTML before checking their heights
        setTimeout(() => {
          if (!this.onLastPage) {
            this.ensureCorrectRowCount();
          }
          this.calculatePageMarkers();
        }, 1);
      },
      (err) => {
        this.loading = false;
        this.utilityService.showError('Error Retrieving Entities', err.message);

      }
    );
  }
  protected currentlyOnLastPage() {
    const totalPageCount = Math.floor(this.totalRecords / this.pageSize);
    return this.pageNumber === totalPageCount || this.entityList.length < this.pageSize;
  }
  abstract delete(entity);
  activate(entity) {
    let data = {} as any;
    data.id = entity.id;
    this.dataService.performEntityOperation(this.entityType, this.entityOperation.Activate, data).subscribe(
      (response) => {
        if (response.success) {
          this.utilityService.showSuccess('Entity Activated', 'Entity successfully activated.');

          this.query();
        } else {
          this.utilityService.showError('Activation Failed', 'Could not activate entity: ' + response.responseMsg);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.utilityService.showError('Activation Failed', 'Could not activate entity: ' + error.message);
      });
  }
  deactivate(entity) {
    let data = {} as any;
    data.id = entity.id;
    this.dataService.performEntityOperation(this.entityType, this.entityOperation.Deactivate, data).subscribe(
      (response) => {
        if (response.success) {
          this.utilityService.showSuccess('Entity Deactivated', 'Entity successfully deactivated.');


          this.query();
        } else {
          this.loading = false;
          this.utilityService.showError('Deactivation Failed', 'Could not deactivate entity: ' + response.responseMsg);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
          this.utilityService.showError('Deactivation Failed', 'Could not deactivate entity: ' + error.message);
      });
  }
  deleteBatch(entityList) {
    this.alert.open('Confirm Deletion',
      'Are you sure you want to delete the selected item(s)? This action cannot be undone.', 'OK', 'Cancel')
      .then(resolve => {
        const msg = new UserMessage();
        msg.messageTitle = 'Error Deleting Entities';
        msg.messageDetail = 'Batch deletion is not currently supported';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      });
  }

  deleteSelected() {
    this.alert.open('Confirm Deletion',
      'Are you sure you want to delete the selected entity? This action cannot be undone.', 'OK', 'Cancel')
      .then(resolve => {
        this.loading = true;
        if (this.editorComponentRef != null) {
          this.editorComponentRef.destroy();
          this.editorComponentRef = null;
        }
        this.delete(this.selectedEntity);
      },
        (cancel) => {
          this.loading = false;
          const msg = new UserMessage();
          msg.messageTitle = 'Delete Cancelled';
          msg.messageType = 'info';
          this.utilityService.showNotification(msg);

        });
  }

  tabOff() {
    this.inView = false;
  }

  setRightClickedRow(event) {
    this.selectedEntity = event.data;
  }

  resizeTable() {
    let combinedRowHeight = 0;
    let i = 0;
    let tableContainerHeight = document.getElementById(this.tableContainerId).clientHeight;
    if (tableContainerHeight <= 0) {
      // use the utility service to get the size of the page and calculate how big the table will be manually
      const pageSize = document.getElementsByClassName('pf-nav-content')[0].clientHeight;
      tableContainerHeight = pageSize - (this.footerBarHeight + this.headerBarHeight
        + this.tableHeaderHeight + this.miscPaddingAndTabs + this.filterBarHeight);
      combinedRowHeight += this.extraComponentPadding;
    } else {
      // otherwise we know how tall it is and we can calculate exactly how many rows will fit
      const rows = (document.getElementById(this.tableContainerId).querySelector('table') as any).rows;
      let filterBarHeight = 0;
      // account for the search header height
      const searchHeaders = document.getElementsByClassName('table-search-header');
      for (let j = 0; j < searchHeaders.length; j++) {
        if ((searchHeaders[j] as any).offsetHeight > filterBarHeight) {
          filterBarHeight = (searchHeaders[j] as any).offsetHeight;
        }
      }
      // add the search header height along with the height of the table header so we can know
      // how much remaining space we have for rows
      combinedRowHeight += rows[0].offsetHeight + filterBarHeight;

      // this is the 'no results' row: we can use it for an indicator of how tall rows will be
      if (rows[1] != null) {
        this.avgRowHeight = rows[1].offsetHeight;
      }

      // add up the row height until our row height and count have filled the available space in the table container
      while (combinedRowHeight < tableContainerHeight && i < 50) {
        i++;
        if (i < rows.length) {
          combinedRowHeight += rows[i].offsetHeight;
        } else {
          combinedRowHeight += this.avgRowHeight;
        }
      }
    }

    // in case the new height is more than the last height, we should see if we need to request more rows to fill the space
    while (combinedRowHeight < tableContainerHeight && i < 50) {
      combinedRowHeight += this.avgRowHeight;
      if (combinedRowHeight < tableContainerHeight) {
        i++;
      }
    }

    // decrement the counter if we exceeded the table container height to prevent half a row from showing
    // this is the case where we have more rows than we need
    while (combinedRowHeight > tableContainerHeight && i < 50) {
      combinedRowHeight -= this.avgRowHeight;
      i--;
    }

    // calculations so we can compare previous to current
    const oldPageNum = this.pageNumber;
    const oldPageSize = this.pageSize;
    const currentLocation = oldPageNum * oldPageSize;
    this.pageSize = i;
    this.pageNumber = i > 0 ? Math.floor(currentLocation / i) : 0;
    this.setCurrentPage(this.pageNumber, Math.floor(this.pageNumber * i), this.pageSize);
  }

  // used for the enter key press event on the Name filter box on the table header
  protected filterKeyPress(event) {
    // if the enter key was pressed while cursor is inside the Name filter box
    if (event.keyCode === 13) {
      this.filterChanged();
    }
  }
  protected setLastPageIndicator() {
    this.onLastPage = this.currentlyOnLastPage();
  }
  protected setCurrentPage(number: number, first: number, rows: number) {
    //  only works if reset is called first...?
    this.dataTable.reset();
    this.dataTable.page = number;
    this.dataTable.first = first;
    this.dataTable.rows = rows;
    this.dataTable.paginate();
    this.calculatePageMarkers();
  }

  private calculatePageMarkers(pageStart = null) {
    this.setLastPageIndicator();
    let pageEnd;
    if (pageStart == null) {
      if (this.onLastPage) {
        pageEnd = this.entityList.length;
        pageStart = this.entityList.length - this.entityList.length % this.pageSize + 1;
      } else {
        pageEnd = (this.pageNumber + 1) * this.pageSize;
        pageStart = pageEnd - this.pageSize;
      }
      if (pageStart <= 0) {
        if (this.entityList.length > 0) {
          pageStart = 1;
        } else {
          pageStart = 0;
        }
      }
    } else {
      if (this.onLastPage) {
        pageEnd = this.entityList.length;
      } else {
        pageEnd = pageStart + this.pageSize;
      }
      pageStart++;
    }
    this.displayPageEnd = pageEnd;
    this.displayPageStart = pageStart;
    if (this.totalRecords == 0)
      this.displayPageStart = 0;
  }

  protected abstract filterChanged();

  protected abstract initialize();

  // extending components need to implement this as it is called from the context menu
  protected abstract updateEntity(event, selectedEntity);
  protected ensureCorrectRowCount() {
    // its possible that the new rows contain content that results in the rows being rendered larger than the previous rows
    // and therefore overflowing the container. We will remove unnecessary rows and update the page size accordingly for the next query
    if (this.tableContainerId !== '') {
      const tableContainerHeight = document.getElementById(this.tableContainerId).clientHeight;
      if (tableContainerHeight > 0) {

        // need to account for the search header
        let combinedRowHeight = 0;
        let filterBarHeight = 0;
        const searchHeaders = document.getElementsByClassName('table-search-header');
        for (let i = 0; i < searchHeaders.length; i++) {
          if ((searchHeaders[i] as any).offsetHeight > filterBarHeight) {
            filterBarHeight = (searchHeaders[i] as any).offsetHeight;
          }
        }
        const rows = (document.getElementById(this.tableContainerId).querySelector('table') as any).rows;
        combinedRowHeight += rows[0].offsetHeight + filterBarHeight;
        let i = 0;
        // add up the row height until our row height and count have filled the available space in the table container
        while (combinedRowHeight < tableContainerHeight && i < 50) {
          i++;
          if (i < rows.length) {
            combinedRowHeight += rows[i].offsetHeight;
            this.avgRowHeight = (this.avgRowHeight * (i - 1) + rows[i].offsetHeight) / i;
          } else {
            combinedRowHeight += this.avgRowHeight;
          }
        }

        // decrement the counter if we exceeded the table container height to prevent half a row from showing
        // this is the case where we have more rows than we need
        while (combinedRowHeight > tableContainerHeight) {
          combinedRowHeight -= this.avgRowHeight;
          i--;
        }

        // this is the case where we have less rows than we need
        // possibly uncomment this if an additional query is not a bad thing. Keeping it commented out to minimize queries
        while (combinedRowHeight < tableContainerHeight && i < 50) {
          combinedRowHeight += this.avgRowHeight;
          i++;
        }
        this.pageSize = i;
        // this.query();


      }
    }
  }
}
