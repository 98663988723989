﻿export class ZoomEventInfo {
    zoomToExtent: boolean;
    transform: any;
    dataDomainX: any;
    dataDomainY: any;
    graphDomainY: any;
    setTransform(deltaX, deltaY, proportion) {
        this.transform.x = deltaX;
        this.transform.y = deltaY;
        this.transform.k = proportion;
    }
}