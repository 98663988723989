
export class Table {
  name: string;
  visible: boolean = false;
  arrayOfData: Array<any>;
  databaseColumns: Array<Column>;
  blankItem: any;
}


export class Column {
  name: string;
  field: string;
  constructor(name: string, field: string) {
    this.name = name;
    this.field = field;
  }
}
