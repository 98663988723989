import {
  OnInit, Component, Input, EventEmitter, Output, Inject, InjectionToken, ViewChild,
  ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver, AfterViewInit, OnDestroy
} from '@angular/core';
import { TransponderFormComponent } from './transponders-update.component';
import { AppUtilities } from '../shared';
import { SatcomEditorBase } from '../models/SATCOMEditorBase';
import { Satellite } from '../models/satellite';
import { UserService } from '../../../services/user-service';

import { EntityType } from '../shared/enums/entity-type';
import { EntityOperation } from '../shared/enums/entity-operation';
import { DataViewDemoService } from '../../../services/data-view-demo-service';
import { Dropdown } from 'primeng/primeng';

@Component({
  selector: 'transponders',
  templateUrl: './transponders.component.html',
  styleUrls: ['./transponders.component.css']
})

export class TransponderDatabaseComponent extends SatcomEditorBase {
  satellites = new Array<Satellite>();
  @ViewChild(Dropdown) dropdown: Dropdown;

  selectedSatellite: Satellite;
  @Input() entityType;
  @Input() entityOperation;
  @Input() updatePanelClassName;
  constructor(public dataService: DataViewDemoService, private utilities: AppUtilities, private vc: ViewContainerRef,
    private cfr: ComponentFactoryResolver, public userService: UserService) {
    super(dataService, utilities, vc, cfr, userService);
    this.tableContainerId = 'transpondersTable';

  }
  ngAfterViewInit() {
    super.setEnumTypes(this.entityType, this.entityOperation);
    this.headerBarHeight = 45;
  }
  changeSatellite(event = null) {
    this.queryWithCurrentSatellite();
  }
  protected initialize() {
    this.cols = this.utilities.getColumns(EntityType.Transponder);
    this.identifierFieldName = this.utilities.getIdentifier(EntityType.Transponder);
    this.getSatellites();
  }
  private getSatellites() {
    let data = { };
    (data as any).queryString = 'filter=active=true&orbit_class=GEO&expand=true';
    let sub2 = this.dataService.performEntitiesOperation(EntityType.Satellite, EntityOperation.Get, data).subscribe((response) => {
      if (response.success) {
        let satellites = JSON.parse(response.data);
        if (!this.checkSatelliteListsEqual(satellites)) {
          this.satellites = satellites;
          this.dropdown.options = this.satellites;
          this.selectedSatellite = this.satellites[0];
          this.changeSatellite();
        }
      } else {
        this.loading = false;
        this.utilities.showServiceError('Error Retrieving Satellites', response);
      }
      sub2.unsubscribe();
    },
      (err) => {
        this.loading = false;
        this.utilities.showError('Error Retrieving Satellites', err.message);

      });
  }
  private checkSatelliteListsEqual(satList) {
    if (satList.length != this.satellites.length)
      return false;
    for (let i = 0; i < this.satellites.length; i++) {
      if (this.satellites[i].satellite_id != satList[i].satellite_id)
        return false;
    }
    return true;
  }
  // implement inherited methods
  protected filterChanged() {
    if (this.nameFilter != null) {
      this.filter = this.nameFilter;
    }
    //enable if server side filtering is wanted
    //this.query();
  }

  windowResized() {
    if (this.inView) {
      this.resizeTable();
    }
  }
  delete(entity) {
    let data = {} as any;
    data.transponderId = entity.transponder_id;
    data.satelliteId = entity.satellite_id;
    this.dataService.performEntityOperation(this.entityType, this.entityOperation.Delete, data).subscribe(
      (response) => {
        if (response.success) {
          this.utilities.showSuccess('Transponder Deleted', 'Transponder successfully deleted.');
          this.editing = false;
          this.queryWithCurrentSatellite();

        } else {
          this.utilities.showError('Could not delete transponder', response.responseMsg);

        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.utilities.showError('Could not delete transponder', error);

      });
  }
  protected updateEntity(event, existingItem = null) {
    this.editing = true;
    if (this.editorComponentRef != null)
      this.editorComponentRef.destroy();
    const factory = this.cfr.resolveComponentFactory(TransponderFormComponent);
    this.editorComponentRef = this.updatePanel.createComponent(factory) as any;
    this.editorComponentRef.instance.updateGrid.subscribe(() => {
      this.queryWithCurrentSatellite();

    })
    this.editorComponentRef.instance.open(this.satellites, this.selectedSatellite, existingItem).then((update) => {
      if (update) {
        this.queryWithCurrentSatellite();

      }
      this.editing = false;
      this.editorComponentRef.destroy();
      this.editorComponentRef = null;
    });
  }
  queryWithCurrentSatellite() {
    let data = {} as any;
    if (this.selectedSatellite != null) {
      data.satelliteId = this.selectedSatellite.satellite_id;
    }
    this.query(data);
  }
  tabInView() {
    this.inView = true;
    this.getSatellites();
    this.resizeTable();
  }

  protected createTransponder() {
    this.editing = true;
    this.selectedEntity = null;
    if (this.editorComponentRef != null)
      this.editorComponentRef.destroy();
    const factory = this.cfr.resolveComponentFactory(TransponderFormComponent);
    this.editorComponentRef = this.updatePanel.createComponent(factory) as any;
    this.editorComponentRef.instance.updateGrid.subscribe(() => {
      this.queryWithCurrentSatellite();

    })
    this.editorComponentRef.instance.open(this.satellites, this.selectedSatellite).then((update) => {
      if (update) {
        this.queryWithCurrentSatellite();

      }
      this.editing = false;
      this.editorComponentRef.destroy();
      this.editorComponentRef = null;
    });
    this.setRightClickedRow(event);
  }
}
