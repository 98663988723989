import { Component, ComponentFactoryResolver, NgModule, OnDestroy, ViewContainerRef } from '@angular/core';
import { ConfirmationDialogComponent } from 'app/components/confirmation-dialog/confirmation-dialog.component';
import { AddUserComponent } from 'app/components/user-management/add-user/add-user.component';
import { EntityOperation } from 'app/enums/entity-operation';
import { EntityType } from 'app/enums/entity-type';
import { Carrier, ServiceResponse, User, UserMessage } from 'app/models';
import { EntityDef } from 'app/models/entity-def';
import { Transponder } from 'app/models/transponder';
import { AppUtilities } from 'app/services/app-utilities';
import { DataViewDemoService } from 'app/services/data-view-demo-service';
import { UserService } from 'app/services/user-service';

import { Organization } from '../../models/organization';

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnDestroy {
  satellitesLoading: boolean;
  transpondersLoading: boolean;
  carriersLoading: boolean;
  groups = ['Administrators', 'Users'];
  companies = ['RT Logic', 'Kratos'];
  currentUser: User;
  currentUserDetails: User;
  users: User[];
  oldUserPassword: string;
  newUserPassword: string;
  confirmUserPassword: string;
  passwordError: boolean;
  showChangePasswordInput: boolean;
  loading: boolean;
  satellites: EntityDef[];
  transponders: Transponder[];
  carriers: Carrier[];
  selectedSatellite: EntityDef;
  selectedTransponder: Transponder;
  selectedCarrier: Carrier;
  allEntities = new Array<EntityDef>();
  usersLoading: boolean;
  userLoading: boolean;
  organizations: Organization[];

  constructor(private utilityService: AppUtilities, private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver, private userService: UserService, private dataService: DataViewDemoService) {
    this.usersLoading = true;
    this.getAllUsers();
    let sub = this.userService.checkUserAuthenticated().subscribe((response) => {
      if (response.success) {
        //this.getSatellites();
        this.getOrganizations();
      } else {
        let newSub = this.userService.userLoggedInObs.subscribe(() => {
          //this.getSatellites();
        })
      }
    });
  }
  getAllUsers() {
    this.userService.getAllUsers().subscribe((response: ServiceResponse) => {
      this.usersLoading = false;
      if (response.success) {
        this.users = JSON.parse(response.data);
      } else {
        this.utilityService.showServiceError('Request Failed', response);
      }
    });
  }
  getOrganizations() {
    let sub = this.dataService.performEntitiesOperation(EntityType.Organization, EntityOperation.Get).subscribe((results) => {
      if (results.success) {
        this.organizations = JSON.parse(results.data);
      } else {
        this.utilityService.showError('Error Retrieving Organizations', results.responseMsg);
      }
      // sub.unsubscribe();
    }, (error) => {
      sub.unsubscribe();
      this.satellitesLoading = false;
      this.utilityService.showError('Error Retrieving Organizations', error.statusText);
    });
  }
  selectUser(event) {
    //if (this.satellites == null) {
    //  this.getSatellites();
    //}
    this.userLoading = true;
    this.confirmUserPassword = '';
    this.newUserPassword = '';
    this.showChangePasswordInput = false;
      this.currentUserDetails = null;
    this.userService.getUser(this.currentUser).subscribe((response: ServiceResponse) => {
      this.userLoading = false;
    if (response.success) {
      this.currentUser = JSON.parse(response.data);
      this.currentUserDetails = this.currentUser.details;
      this.currentUserDetails.isAdmin = this.currentUser.roles.findIndex((role) => { return role == 'Administrator' }) > -1;
    } else {
      this.currentUser = null;
        const msg = new UserMessage();
        msg.messageTitle = 'Request Failed';
        msg.messageDetail = 'Unable to retrieve user information';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
    });
  }
  openDetailSatellite() {

  }
  openDetailTransponder() {

  }
  openDetailCarrier() {

  }
  deleteUser(event) {
    let user = event.value;
    const factory = this.componentFactoryResolver.resolveComponentFactory(ConfirmationDialogComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    componentRef.instance.open('User Deletion Confirmation', 'Are you sure you want to delete this user? This action cannot be undone.').then((result: boolean) => {
      // result will be null if they cancelled
      if (result) {
        this.userService.deleteUser(user).subscribe((response: ServiceResponse) => {
          if (response.success) {
            const msg = new UserMessage();
            msg.messageTitle = 'Delete Success';
            msg.messageDetail = 'User was deleted successfully';
            msg.messageType = 'success';
            this.utilityService.showNotification(msg);
            this.currentUser = null;
            this.currentUserDetails = null;
            this.getAllUsers();
          } else {
            const msg = new UserMessage();
            msg.messageTitle = 'Request Failed';
            msg.messageDetail = 'Unable to delete user';
            msg.messageType = 'error';
            this.utilityService.showNotification(msg);
          }
        });

      }
      componentRef.destroy();
    });
  }
  toggleUserDisabled(disabled) {
    this.loading = true;
    this.userService.toggleUserDisabled(this.currentUser.account_id, disabled).subscribe((response: ServiceResponse) => {
      this.loading = false;
      if (response.success) {
        this.currentUser.disabled = disabled;
        const msg = new UserMessage();
        msg.messageTitle = 'Disable Success';
        if (disabled) {
          msg.messageDetail = 'User account was disabled successfully';
        } else {
          msg.messageDetail = 'User account was enabled successfully';
        }
        msg.messageType = 'success';
        this.utilityService.showNotification(msg);
        this.getAllUsers();
      } else {
        const msg = new UserMessage();
        msg.messageTitle = 'Disable Failed';
        msg.messageDetail = 'Unable to disable user account';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
    });
  }
  toggleUserLocked(locked) {
    this.loading = true;
    this.userService.toggleUserLocked(this.currentUser.account_id, locked).subscribe((response: ServiceResponse) => {
      this.loading = false;
      if (response.success) {
        this.currentUser.locked = locked;
        const msg = new UserMessage();
        msg.messageTitle = 'Lock Success';
        if (locked) {
          msg.messageDetail = 'User account was locked successfully';
        } else {
          msg.messageDetail = 'User account was unlocked successfully';
        }
        msg.messageType = 'success';
        this.utilityService.showNotification(msg);
        this.getAllUsers();
      } else {
        const msg = new UserMessage();
        msg.messageTitle = 'Lock Failed';
        msg.messageDetail = 'Unable to modify user account';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
    });
  }
  addUser() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(AddUserComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    componentRef.instance.open().then((result: boolean) => {
      // result will be null if they cancelled
      if (result) {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Success';
        msg.messageDetail = 'User was added successfully';
        msg.messageType = 'success';
        this.utilityService.showNotification(msg);
        this.getAllUsers();
      }
      componentRef.destroy();
    });
  }
  prepareUserObjForSending() {
    let idx = this.currentUser.roles.findIndex((role) => {
      return role == 'Administrator';
    });
    if (this.currentUserDetails.isAdmin) {
      if (idx < 0) {
        this.currentUser.roles.push('Administrator');
      }
    } else {
      if (idx > -1) {
        this.currentUser.roles.splice(idx, 1);
      }
    }
    this.currentUser.parents = [];
    if (this.currentUserDetails.parent)
      this.currentUser.parents.push(this.currentUserDetails.parent);
    //have to do this because of the backend design...
    //details is where all this user info is actually stored
    this.currentUser.details = this.currentUserDetails;
  }
  save() {
    this.loading = true;
    this.prepareUserObjForSending();
    this.userService.saveUserInfo(this.currentUser).subscribe((response: ServiceResponse) => {
      this.loading = false;
      if (response.success) {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Success';
        msg.messageDetail = 'Changes were saved successfully';
        msg.messageType = 'success';
        this.utilityService.showNotification(msg);
      } else {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Failed';
        msg.messageDetail = 'Unable to save user information';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
    });
  }
  changePassword() {
    this.loading = true;
    this.userService.changeUserPassword(this.currentUser.name, this.oldUserPassword, this.newUserPassword).subscribe((response: ServiceResponse) => {
      this.loading = false;
      if (response.success) {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Success';
        msg.messageDetail = 'Changes were saved successfully';
        msg.messageType = 'success';
        this.utilityService.showNotification(msg);
        this.oldUserPassword = '';
        this.newUserPassword = '';
        this.confirmUserPassword = '';
        this.showChangePasswordInput = false;
      } else {
        const msg = new UserMessage();
        msg.messageTitle = 'Save Failed';
        msg.messageDetail = 'Unable to save user information';
        msg.messageType = 'error';
        this.utilityService.showNotification(msg);
      }
    });
  }
  showChangePassword() {
    this.showChangePasswordInput = true;
  }
  checkMatch(newValue) {
    this.confirmUserPassword = newValue;
    if (this.newUserPassword != this.confirmUserPassword)
      this.passwordError = true;
    else
      this.passwordError = false;
  }
  toggleSelectAllTranspondersAndCarriers(event) {
    this.transpondersLoading = true;
    this.carriersLoading = true;
    setTimeout(() => {
      this.selectedSatellite.selected = event;
      if (this.selectedSatellite.selected) {
        this.getTransponders(this.selectedSatellite, true);
      } else {
        this.transponders.forEach((xpdr) => {
          if (xpdr.satelliteName == this.selectedSatellite.name) {
            xpdr.selected = false;
            this.carriers.forEach((car) => {
              if (car.transponderName == xpdr.name) {
                car.selected = false;
              }
            });
          }
        });
        this.carriers = [];

        this.transpondersLoading = false;
        this.carriersLoading = false;
      }
    })

  }
  toggleSelectAllCarriers(event) {
    this.carriersLoading = true;
    setTimeout(() => {
      this.selectedTransponder.selected = event;
      if (this.selectedTransponder.selected) {
        this.getCarriersForTransponder(this.selectedTransponder, true);
      } else {
        this.carriers.forEach((car) => {
          if (car.transponderName == this.selectedTransponder.name) {
            car.selected = false;
          }
        });
        this.carriersLoading = false;
      }
    });
  }
  getCarriersForTransponder(transponder, selectWhenDone, concat = false) {
    let data = {} as any;
    data.satelliteId = transponder.satelliteName;
    data.transponderId = transponder.identifier;
    let sub = this.dataService.performEntitiesOperation(EntityType.Carrier, EntityOperation.Get, data).subscribe((results) => {
      this.carriersLoading = false;
      if (results.success) {
        if (concat && this.carriers != null) {
          this.carriers = this.carriers.concat(JSON.parse(results.data));
        } else {
          this.carriers = JSON.parse(results.data);
        }
        this.carriers.forEach((car) => {
          car.transponderName = transponder.name;
          this.allEntities.push(car);
          if (selectWhenDone) {
            car.selected = true;
          }
        });
      } else {
        this.utilityService.showError('Error Retrieving Carriers', results.responseMsg);
      }
      sub.unsubscribe();
    }, (error) => {
      sub.unsubscribe();
      this.carriersLoading = false;
      this.utilityService.showError('Error Retrieving Carriers for Transponder', error.statusText);
    });
  }
  selectTransponder(event) {
    this.carriersLoading = true;
    this.getCarriersForTransponder(event.value, false);
  }
  getTransponders(satellite, selectWhenDone) {
    let data = {} as any;
    data.satelliteId = satellite.identifier;
    let sub = this.dataService.performEntitiesOperation(EntityType.Transponder, EntityOperation.Get, data).subscribe((results) => {
      this.transpondersLoading = false;
      if (results.success) {
        this.transponders = JSON.parse(results.data);
        this.transponders.forEach((xpdr) => {
          xpdr.satelliteName = satellite.name;
          let idx = this.allEntities.findIndex((ent) => {
            return ent.identifier == xpdr.identifier;
          });
          if (idx == -1) {
            this.allEntities.push(xpdr);
          }
          if (selectWhenDone) {
            xpdr.selected = true;
            this.getCarriersForTransponder(xpdr, true, true);
          }
        });
      } else {
        this.utilityService.showError('Error Retrieving Transponders', results.responseMsg);
      }
    }, (error) => {
      sub.unsubscribe();
      this.transpondersLoading = false;
      this.utilityService.showError('Error Retrieving Transponders for Satellite', error.statusText);
    });
  }
  selectSatellite(event) {
    this.transpondersLoading = true;
    this.getTransponders(event.value, false);
  }
  getSatellites() {
    this.satellitesLoading = true;
    let sub = this.dataService.performEntitiesOperation(EntityType.Satellite, EntityOperation.Get).subscribe((results) => {
      this.satellitesLoading = false;
      if (results.success) {
        this.satellites = JSON.parse(results.data);
      } else {
        this.utilityService.showError('Error Retrieving Satellites', results.responseMsg);
      }
      sub.unsubscribe();
    }, (error) => {
      sub.unsubscribe();
      this.satellitesLoading = false;
      this.utilityService.showError('Error Retrieving Satellites', error.statusText);
    });
  }
  
  private showSuccess(errorTitle: string, errorDetail: string) {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'success';
    this.utilityService.showNotification(msg);
  }
  ngOnDestroy() {
    this.users = [];
  }
}

