import {
  OnInit, Component, Input, EventEmitter, Output, Inject, InjectionToken, ViewChild,
  ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver, AfterViewInit, OnDestroy
} from '@angular/core';
import { AntennaFormComponent } from './antennas-update.component';
import { AppUtilities } from '../shared';
import { DataViewDemoService } from '../../../services/data-view-demo-service';
import { SatcomEditorBase } from '../models/SATCOMEditorBase';
import { Site } from '../models/site';
import { UserService } from '../../../services/user-service';

import { EntityType } from '../shared/enums/entity-type';
import { EntityOperation } from '../shared/enums/entity-operation';

@Component({
  selector: 'antennas',
  templateUrl: './antennas.component.html',
  styleUrls: ['./antennas.component.css']
})

export class AntennaDatabaseComponent extends SatcomEditorBase {
  selectedSite: Site = null;
  sites = new Array<Site>();
  bands;
  active;
  @Input() entityType;
  @Input() entityOperation;
  @Input() updatePanelClassName;
  constructor(private dataGen: DataViewDemoService, private utilities: AppUtilities, private vc: ViewContainerRef,
    private cfr: ComponentFactoryResolver, public userService: UserService) {
    super(dataGen, utilities, vc, cfr, userService);
    this.tableContainerId = 'antennasTable';

  }
  ngAfterViewInit() {
    super.setEnumTypes(this.entityType, this.entityOperation);
    this.headerBarHeight = 45;
  }
  queryWithSelectedSite(event = null) {
    let data = {} as any;
    data.siteId = this.selectedSite.site_id;
    this.query(data);
  }


  protected initialize() {
    this.cols = this.utilities.getColumns(EntityType.Antenna);
    this.identifierFieldName = this.utilities.getIdentifier(EntityType.Antenna);

    this.getSites();
  }
  private getSites() {
    let sub2 = this.dataService.performEntitiesOperation(EntityType.Site, EntityOperation.Get).subscribe((response) => {
      if (response.success) {
        let satellites = JSON.parse(response.data);
        if (!this.checkSiteListsEqual(satellites)) {
          this.sites = JSON.parse(response.data);
          this.selectedSite = this.sites[0];
          this.queryWithSelectedSite();
        }
      } else {
        this.loading = false;
        this.utilities.showServiceError('Error Retrieving Sites', response);
      }
      sub2.unsubscribe();
    },
      (err) => {
        this.loading = false;
        this.utilities.showError('Error Retrieving Sites', err.message);

      });
  }
  private checkSiteListsEqual(siteList) {
    if (siteList.length != this.sites.length)
      return false;
    for (let i = 0; i < this.sites.length; i++) {
      if (this.sites[i].site_id != siteList[i].site_id)
        return false;
    }
    return true;
  }
  // implement inherited methods
  protected filterChanged() {
    if (this.nameFilter != null) {
      this.filter = this.nameFilter;
    }
   //enable if server side filtering is wanted
    //this.query();
  }

  windowResized() {
    if (this.inView) {
      this.resizeTable();
    }
  }

  protected updateEntity(event, existingItem = null) {
    this.editing = true;
    if (this.editorComponentRef != null)
      this.editorComponentRef.destroy();
    const factory = this.cfr.resolveComponentFactory(AntennaFormComponent);
    this.editorComponentRef = this.updatePanel.createComponent(factory) as any;
    this.editorComponentRef.instance.open(this.sites, this.selectedSite, existingItem).then((update) => {
      if (update) {
        this.queryWithSelectedSite();
      }
      this.editing = false;
      this.editorComponentRef.destroy();
      this.editorComponentRef = null;
    });
  }

  tabInView() {
    this.inView = true;
    this.getSites();
    this.resizeTable();
  }
  delete(entity) {
    let data = {} as any;
    data.siteId = entity.site_id;
    data.antennaId = entity.antenna_id;
    this.dataService.performEntityOperation(this.entityType, this.entityOperation.Delete, data).subscribe(
      (response) => {
        if (response.success) {
          this.utilities.showSuccess('Antenna Deleted', 'Antenna successfully deleted.');
          this.editing = false;
          this.queryWithSelectedSite();
        } else {
          this.utilities.showError('Could not delete antenna', response.responseMsg);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.utilities.showError('Could not delete antenna', error);

      });
  }
  protected createAntenna() {
    this.editing = true;
    this.selectedEntity = null;
    if (this.editorComponentRef != null)
      this.editorComponentRef.destroy();
    const factory = this.cfr.resolveComponentFactory(AntennaFormComponent);
    this.editorComponentRef = this.updatePanel.createComponent(factory) as any;
    this.editorComponentRef.instance.open(this.sites, this.selectedSite).then((update) => {
      if (update) {
        this.queryWithSelectedSite();
      }
      this.editing = false;
      this.editorComponentRef.destroy();
      this.editorComponentRef = null;
    });
  }
}
