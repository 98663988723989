import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { UserMessage } from 'app/models/user-message';
import { User } from 'app/models/user';
import { ServiceResponse } from 'app/models/service-response';
import { EntityType } from 'app/enums/entity-type';
import { Column } from '../../models/table';

@Injectable()
export class AppUtilities {
  currentUser: User = new User();
  showNotificationSource = new Subject<UserMessage>();
  showNotificationObs = this.showNotificationSource.asObservable();

  // global notification center, which passes notifications to the app.component for display
  showNotification(message: UserMessage): void {
    this.showNotificationSource.next(message);
  }
  truncateString(str, length = 50) {
    if (str.length >= length)
      str = str.substr(0, length) + '...';
    return str;
  }
  createAliasString(values) {
    let str = '';    
    for (let i = 0; i < values.length; i++) {
      str += '   ' + values[i].name + '/' + values[i].entity + ',';
    }
    str = str.substr(0, str.length - 1);
    if (str.length >= 75)
      str = str.substr(0, 75) + '...';
    return str;
  }
  getColumns(entityType): any[] {
    if (entityType == EntityType.Site) {
      let databaseColumns = new Array<Column>();
      databaseColumns.push(new Column("Name", "name"));
      databaseColumns.push(new Column("Site Type", "site_types"));
      databaseColumns.push(new Column("Latitude", "latitude"));
      databaseColumns.push(new Column("Longitude", "longitude"));
      databaseColumns.push(new Column("Elevation", "altitude"));
      databaseColumns.push(new Column("Aliases", "aliases"));
      databaseColumns.push(new Column("Active", "active"));
      return databaseColumns;
    } else if (entityType == EntityType.Antenna) {
      let databaseColumns = new Array<Column>();
      databaseColumns.push(new Column("Name", "name"));
      databaseColumns.push(new Column("Antenna Type", "antenna_types"));
      databaseColumns.push(new Column("Size", "size"));
      databaseColumns.push(new Column("Active", "active"));
      databaseColumns.push(new Column("Fixed", "fixed"));
      databaseColumns.push(new Column("Latitude", "latitude"));
      databaseColumns.push(new Column("Longitude", "longitude"));
      databaseColumns.push(new Column("Elevation", "altitude"));
      databaseColumns.push(new Column("Receive", "receive"));
      databaseColumns.push(new Column("Transmit", "transmit"));
      return databaseColumns;
    } else if (entityType == EntityType.Satellite) {
      let databaseColumns = new Array<Column>();
      databaseColumns.push(new Column("Name", "name"));
      databaseColumns.push(new Column("NORAD", "norad_id"));
      databaseColumns.push(new Column("COSPAR", "cospar_id"));
      databaseColumns.push(new Column("Orbit Class", "orbit_class"));
      databaseColumns.push(new Column("Aliases", "aliases"));
      databaseColumns.push(new Column("Active", "active"));
      return databaseColumns;
    } else if (entityType == EntityType.Transponder) {
      let databaseColumns = new Array<Column>();
      databaseColumns.push(new Column("Name", "name"));
      databaseColumns.push(new Column("Uplink CF (MHz)", "uplinkcf"));
      databaseColumns.push(new Column("Downlink CF (MHz)", "downlinkcf"));
      databaseColumns.push(new Column("Bandwidth (MHz)", "bw"));
      databaseColumns.push(new Column("Aliases", "aliases"));
      databaseColumns.push(new Column("UG Band", "uguard_band"));
      databaseColumns.push(new Column("LG Band", "lguard_band"));
      databaseColumns.push(new Column("Translation", "translation"));
      databaseColumns.push(new Column("Active", "active"));
      return databaseColumns;
    } else if (entityType == EntityType.Carrier) {
      let databaseColumns = new Array<Column>();
      databaseColumns.push(new Column("Name", "name"));
      databaseColumns.push(new Column("Uplink CF", "uplinkcf"));
      databaseColumns.push(new Column("Downlink CF", "downlinkcf"));
      databaseColumns.push(new Column("Bandwidth", "bw"));
      databaseColumns.push(new Column("Aliases", "aliases"));
      databaseColumns.push(new Column("UG Band", "uguard_band"));
      databaseColumns.push(new Column("LG Band", "lguard_band"));
      databaseColumns.push(new Column("Active", "active"));
      return databaseColumns;
    } 
  }
  getIdentifier(entityType): string {
    if (entityType == EntityType.Site) {
      return 'site_id';
    } else if (entityType == EntityType.Antenna) {
      return 'antenna_id';
    } else if (entityType == EntityType.Satellite) {
      return 'satellite_id';
    } else if (entityType == EntityType.Transponder) {
      return 'transponder_id';
    }
  }
  isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  isChrome = navigator.userAgent.indexOf("Chrome");
  getDateString(d) {
    if (d != null) {
      let date = new Date(d);
      let mm = date.getMonth() + 1; // getMonth() is zero-based
      let dd = date.getDate();

      let string = [(mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,
      date.getFullYear()
      ].join('/');
      return string;
    } else
      return "";
  }
  convertToMhz(value) {
    if (value == 0) {
      return value;
    } else {
      value = Math.round(value / 1000);
      //need to do this so the string shows up with 3 decimal places
      return value / 1000;
    }
  }

  getElementTransform(elem) {
    let transform = '';
    let style = elem.attributes['style'];
    if (style != null) {
      let styles = style.value.split(';');
      for (let i = 0; i < styles.length; i++) {
        if (styles[i].includes('transform')) {
          transform = styles[i].split(':')[1].replace(' ', '');
        }
      }
      return transform;
    }
  }
  getFormattedDuration(ticks: number): string {
    var seconds = ticks / 1000;
    var hour = Math.floor(seconds / 3600);
    var minute = Math.floor((seconds / 60) % 60);
    var second = seconds % 60;

    return hour + ':' + minute + ':' + second;
  }

  showServiceError(errorTitle: string, response: ServiceResponse): void {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    if (response.statusCode == 500) {
      msg.messageDetail = 'Server Error: ' + response.responseMsg;
    } else if (response.statusCode == 401) {
      msg.messageDetail = 'You are not authorized to perform this action';
    } else {
      msg.messageDetail = response.responseMsg;
    }
    msg.messageType = 'error';
    this.showNotification(msg);
  }

  showError(errorTitle: string, errorDetail: string):void {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'error';
    this.showNotification(msg);
  }

  showSuccess(title: string, detail: string): void {
    const msg = new UserMessage();
    msg.messageTitle = title;
    msg.messageDetail = detail;
    msg.messageType = 'success';
    this.showNotification(msg);
  }

  showInfo(errorTitle: string, errorDetail: string): void {
    const msg = new UserMessage();
    msg.messageTitle = errorTitle;
    msg.messageDetail = errorDetail;
    msg.messageType = 'info';
    this.showNotification(msg);
  }
}
