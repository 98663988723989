export class DataViewConfiguration {
  showCarrierRegions: boolean;
  showModAnalysis: boolean;
  showTrendAndAnalysisGraphs: boolean;
  showBursts: boolean = true;
  minMaxForWaterfallGradient = [-105, -40];
  autoDetectGradientBounds: boolean = true;
  disableModWaterfall: boolean;
  playbackSpeed = 100;
}
