export enum EntityType {
  Satellite,
  SatelliteAliases,
  SatelliteTransponder,
  Transponder,
  Carrier,
  Antenna,
  Site,
  SiteAntenna,
  TLE,
  Lease,
  LeaseRevision,
  LeaseTransponder,
  LeaseRevisionTransponder,
  LeaseTransponderCarrier,
  Weather,
  SatelliteImage,
  SiteImage,
  SiteAntennaImage,
  AntennaImage,
  Organization,
  StateVector
}
