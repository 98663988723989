import { Axis, D3, Selection } from 'd3-ng2-service';

import { ChartAddon } from '../addons/pf-chart-addon';

export class ChartAxesCentered implements ChartAddon {
  d3: D3;
  height: number;
  width: number;
  domain: any;
  range: any;
  xAxis: Axis<{}>;
  yAxis: Axis<{}>;
  transform: any;

  constructor() { }

  init(d3: D3, height: number, width: number): void {
    this.d3 = d3;
    this.height = height;
    this.width = width;
  }

  create(svg: Selection<any, {}, null, undefined>, domainScale: any, rangeScale: any): void {
    this.xAxis = this.d3.axisBottom(domainScale);
    this.yAxis = this.d3.axisLeft(rangeScale);
    this.domain = svg.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0, ${this.height / 2})`)
      .call(this.xAxis);
    this.range = svg.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(${this.width / 2}, 0)`)
      .call(this.yAxis);
  }

  zoom(domainScale: any, rangeScale: any, transform: any): void {
    if (!!transform) {
      this.transform = transform;
      this.domain.call(this.xAxis.scale(transform.rescaleX(domainScale)));
      this.range.call(this.yAxis.scale(transform.rescaleY(rangeScale)));
    } else {
      this.domain.call(this.xAxis.scale(domainScale));
      this.range.call(this.yAxis.scale(rangeScale));
    }
  }

  onScaleOrResize(height: number, width: number, domainScale: any, rangeScale: any): void {
    this.height = height;
    this.width = width;
    this.domain.attr('transform', `translate(0, ${this.height / 2})`);
    this.range.attr('transform', `translate(${this.width / 2}, 0)`);
    this.zoom(domainScale, rangeScale, this.transform);
  }
}