import { SvgMarker } from "app/components/graphs/chart";

export class MarkerDataset {
  visible: boolean;
  markerName: string;
  datasetName: string;
  marker: SvgMarker;
  referenceMarker: SvgMarker;
  delta: number;
}
