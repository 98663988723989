import { Component, OnDestroy, OnInit, ViewChild, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { D3, D3Service, Selection } from 'd3-ng2-service';
import {
  ZoomEventInfo, SvgZoom, ChartUtility, SvgTooltip, SvgTooltipConfig, CanvasGraphElementModel,
  CanvasGraphElementRenderer
} from './../../chart/index';
import { WaterfallSeries } from './data-models/pf-waterfall-series';
import { ChartScaleLinear } from './../../chart/pf-chart/scale/pf-chart-scale-linear';
import { WaterfallRenderer } from './render/pf-waterfall-renderer';
import { WaterfallData } from './data-models/pf-waterfall-data';
import { WaterfallPlotConfiguration } from './configuration/pf-waterfall-config';
import { WaterfallDataParameters } from './configuration/pf-waterfall-data-params';
import { WaterfallGradientWidget } from './graph-elements/pf-waterfall-gradient-widget';
import { WaterfallPlotUnits } from './configuration/pf-waterfall-units';
import { VerticalWaterfallTrackingLine } from './graph-elements/pf-waterfall-tracking-line-vertical';
import { HorizontalWaterfallTrackingLine } from './graph-elements/pf-waterfall-tracking-line-horizontal';
import { WaterfallAxes } from './axes/pf-waterfall-axes';
import { AxisGridDimensions } from './axes/pf-waterfall-axis-grid-dimensions';
import { WaterfallXY } from './data-models/pf-waterfall-xy';
import { UUID } from 'angular2-uuid';
import { WaterfallPlotTemplateVariables } from './pf-waterfall-html-template-vars';

export class WaterfallPlot implements OnDestroy {
  dataModel: WaterfallSeries;
  config: WaterfallPlotConfiguration;
  dataParams: WaterfallDataParameters;
  graphElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();
  // the parent specan.component needs access to these
  graphContainer: ElementRef;
  units: WaterfallPlotUnits;
  // scrollZoomFunc: Function;
  // this should be a pointer to a function
  onSelectedDomainAndRangeChanged: Function;
  onBrushed: Function;
  onRenderBegin: Function;
  onRenderEnd: Function;
  mouseMoveFunc: Function;
  mouseLeaveFunc: Function;

  // this is our connection to the enclosing component. Rather than directly exposing elements
  // of the renderer and axes to the html template,
  // we will use this object to set the variables accordingly and use them in this component and in the html
  htmlTemplateVars: WaterfallPlotTemplateVariables;

  // these should be pointers to functions in the parent component
  onVerticalTrackingLineChange: Function;
  onHorizontalTrackingLineChange: Function;

  private renderer: WaterfallRenderer;
  private graphElementRenderer: CanvasGraphElementRenderer;
  private trackingLineV: VerticalWaterfallTrackingLine;
  private trackingLineH: HorizontalWaterfallTrackingLine;
  private gradient: WaterfallGradientWidget;
  private axes: WaterfallAxes;
  private tooltip: SvgTooltip;
  private zoom: SvgZoom;
  private d3: D3;
  // one is for the svg as a whole
  // the other is for adding elements so we can style them differently
  private svg: any;
  private svgElementsContainer: any;
  private canvas: any;
  private context: any;
  private scaleX: any;
  private scaleY: any;
  private currentX: number;
  private currentY: number;
  private transformedScaleX: any;
  private transformedScaleY: any;
  private usingMinMaxXY: boolean;
  private domainX: any;
  // holds the size of the grid so we can exactly overlay the canvas at the right position
  private gridDimensions: AxisGridDimensions = new AxisGridDimensions();
  private containerDimensions: AxisGridDimensions = new AxisGridDimensions();

  // this indicates whether the user is dragging a sample line. If so, we don't want to show tooltips etc
  private userInteractingWithGraph: boolean;
  private tooltipVisible = true;
  private renderEndSub: Subscription;

  constructor(d3: D3, private changeDetector: ChangeDetectorRef) {
    this.d3 = d3;
    this.renderer = new WaterfallRenderer(this.d3);
    this.graphElementRenderer = new CanvasGraphElementRenderer(this.d3);
    this.trackingLineV = new VerticalWaterfallTrackingLine(this.d3);
    this.trackingLineH = new HorizontalWaterfallTrackingLine(this.d3);
    this.axes = new WaterfallAxes(this.d3);
    this.zoom = new SvgZoom(this.d3);
    this.gradient = new WaterfallGradientWidget(this.d3);
    this.tooltip = new SvgTooltip(this.d3);
    this.renderEndSub = this.renderer.onRenderEnd.subscribe((boolean) => {
      this.enableControlsAfterRendering();
      this.addGraphElements();
      this.notifyRenderEnd();
    });
  }

  initialize() {
    // initializes renderer variables and properly sizes graph
    this.renderer.attributes.graphId = this.htmlTemplateVars.graphId;
    this.initializeRenderer();
    const newHeight = this.renderer.resize();
    this.correctRendererSize();
    this.renderer.attributes.gradient = this.config.gradientColors;
   
    this.zoom.initialize(null, this.htmlTemplateVars.graphId);
    this.initializeGraphElements();
  }
  setCurrentMouseCoords(pos: [number, number]) {
    this.currentX = pos[0];
    this.currentY = pos[1];
  }
  getScaledMouseCoords(posX: number, posY: number) {
    const x = +this.transformedScaleX.invert(posX).toFixed(this.config.roundXToDecimal);
    const y = +this.transformedScaleY.invert(posY).toFixed(2);
    return [x, y];
  }


  getWaterfallElementHeight(): number {
    return this.renderer.attributes.outerGraphHeight;
  }
  getWaterfallElementWidth(): number {
    return this.renderer.attributes.outerGraphWidth;
  }
  getGraphCanvasHeight(): number {
    return this.renderer.attributes.innerGraphHeight;
  }
  getGraphCanvasWidth(): number {
    return this.renderer.attributes.innerGraphWidth;
  }

  addTrackingLines() {
    this.trackingLineH.addElementToSvg(this.svgElementsContainer,
      (this.gridDimensions.bottomInPixels - this.gridDimensions.topInPixels),
      (this.gridDimensions.rightInPixels - this.gridDimensions.leftInPixels));
    this.trackingLineV.addElementToSvg(this.svgElementsContainer,
      (this.gridDimensions.bottomInPixels - this.gridDimensions.topInPixels),
      (this.gridDimensions.rightInPixels - this.gridDimensions.leftInPixels));
  }

  toggleZoom(zoomMode: boolean) {
    this.htmlTemplateVars.zoomMode = zoomMode;
    if (!this.htmlTemplateVars.zoomMode) {
      this.zoom.removeBrush(this.svgElementsContainer);
    } else {
      this.zoom.appendZoom(this.svgElementsContainer, this.onBrushed,
        this.renderer.attributes.innerGraphWidth, this.renderer.attributes.innerGraphHeight);
    }
  }

  getGradientLinePixelLocation() {
    return this.gradient.getTrackingLinePixelLocation();
  }

  getCurrentGradient() {
    return this.gradient.getCurrentGradient();
  }

  setHTrackingLineLocationByTimestamp(timestamp: number) {
    const screenLocationOfTimestamp = this.transformedScaleY(timestamp);
    this.trackingLineH.moveTrackingLineTo(screenLocationOfTimestamp);
  }

  setHTrackingLineLocationByPixel(pixel: number) {
    if (this.trackingLineH != null) {
      this.trackingLineH.moveTrackingLineTo(pixel);
    }
  }

  setVTrackingLineLocationByFrequency(freq: number) {
    if (this.trackingLineV != null) {
      const screenLocationOfFrequency = this.transformedScaleX(freq);
      this.trackingLineV.moveTrackingLineTo(screenLocationOfFrequency);
    }
  }

  setVTrackingLineLocationByPixel(pixel: number) {
    if (this.trackingLineV != null) {
      this.trackingLineV.moveTrackingLineTo(pixel);
    }
  }

  getHTrackingLineLocationInPixels(): number {
    if (this.trackingLineH != null) {
      return this.trackingLineH.getTrackingLinePixelLocation();
    }
  }

  getVTrackingLineLocationInPixels(): number {
    if (this.trackingLineV != null) {
      return this.trackingLineV.getTrackingLinePixelLocation();
    }
  }

  getHTrackingLineLocationAsTimestamp(): number {
    if (this.trackingLineH != null) {
      const pixelValue = this.trackingLineH.getTrackingLinePixelLocation();
      return this.scaleValueByDomainY(pixelValue);
    }
  }

  getVTrackingLineLocationAsFrequency(): number {
    if (this.trackingLineV != null) {
      const pixelValue = this.trackingLineV.getTrackingLinePixelLocation();
      const freqValue = this.scaleValueByDomainX(pixelValue);
      if (this.dataModel.length() > 0) {
        const point = this.dataModel.getPointNearestFrequency(freqValue);
        if (point != null) {
          return point.x;
        }
      }
    }
    return null;
  }

  findNextTimestampForwardFromData(currentTimestamp) {
    const data = this.dataModel.data();
    let dataIdx = 0;
    for (; dataIdx < data.length; dataIdx++) {
      if ((data[dataIdx] as WaterfallData).timestamp > currentTimestamp) {
        break;
      }
    }
    if (dataIdx < data.length) {
      return (data[dataIdx] as WaterfallData).timestamp;
    } else {
      return null;
    }
  }
  findNextTimestampBackwardFromData(currentTimestamp) {
    const data = this.dataModel.data();
    let dataIdx = data.length - 1;
    for (; dataIdx >= 0; dataIdx--) {
      if ((data[dataIdx] as WaterfallData).timestamp < currentTimestamp) {
        break;
      }
    }
    if (dataIdx >= 0) {
      return (data[dataIdx] as WaterfallData).timestamp;
    } else {
      return null;
    }
  }

  scaleValueByDomainY(value: number) {
    return this.transformedScaleY.invert(value);
  }

  scaleValueByDomainX(value: number) {
    return this.transformedScaleX.invert(value);
  }
  // disabling for now, doesn't seem like a good feature for a waterfall
  // scrollZoom(direction: number, eventInfo: ZoomEventInfo) {
  //    const d3 = this.d3;
  //    //let oldDomainX = d3.event.transform.rescaleX(this.scaleX).domain();
  //    //let oldDomainY = d3.event.transform.rescaleX(this.scaleY).domain();
  //    let oldDomainX = this.transformedScaleX.domain();
  //    let oldDomainY = this.transformedScaleY.domain();
  //    let newDomainX, newDomainY;
  //    let zoomPercentage = .05; //zoom 5% of extent
  //    if (this.htmlTemplateVars.zoomOnXAxis) {
  //        newDomainX = ChartUtility.zoomDomainByPercentage(direction, oldDomainX, zoomPercentage);
  //    }
  //    if (this.htmlTemplateVars.zoomOnYAxis) {
  //        newDomainY = ChartUtility.zoomDomainByPercentage(direction, oldDomainY, zoomPercentage);
  //    }
  //    this.setScaling(newDomainY, newDomainX);
  //    this.zoomToCurrentScale();
  // }

  brushed(selection: [[number, number], [number, number]]) {
    if (selection != null) {
      const xdomain = [selection[0][0], selection[1][0]];
      const ydomain = [selection[1][1], selection[0][1]];
      const selectedDomainX = xdomain.map(this.transformedScaleX.invert, this.transformedScaleX);
      const selectedDomainY = ydomain.map(this.transformedScaleY.invert, this.transformedScaleY);

      this.setScaling(selectedDomainY, selectedDomainX);
      this.removeZoomSelectionArea();
      this.usingMinMaxXY = false;
      this.zoomToCurrentScale();
      this.onSelectedDomainAndRangeChanged([this.transformedScaleX.domain(), this.transformedScaleY.domain()]);
    }

  }
  resetTrackingLineH() {
    if (this.config.playTracesTopToBottom) {
      this.setHTrackingLineLocationByPixel(0);
    } else {
      this.setHTrackingLineLocationByPixel(this.getGraphCanvasHeight());
    }
  }
  removeZoomSelectionArea() {
    this.zoom.removeZoomSelectionArea(this.svg);
  }
  getCurrentDomainX(): any[] {
    if (this.dataParams.axisMaxX != null && this.dataParams.axisMinX != null && this.usingMinMaxXY) {
      return [this.dataParams.axisMinX, this.dataParams.axisMaxX];
    }
    return this.transformedScaleX.domain();
  }
  getCurrentGraphDomainY(): any[] {
    if (this.dataParams.axisMinY != null && this.dataParams.axisMinY != null && this.usingMinMaxXY) {
      return [this.dataParams.axisMinY, this.dataParams.axisMaxY];
    }
    return this.transformedScaleY.domain();
  }

  zoomToExtent() {
    this.setScaling(this.calculateGraphDomainY(), this.calculateDomainX());
    this.zoomToCurrentScale();
  }

  zoomToEvent(eventInfo: ZoomEventInfo) {
    if (eventInfo.dataDomainX != null || eventInfo.graphDomainY != null) {
      this.usingMinMaxXY = false;
      const currentDomainX = this.domainX;
      if (eventInfo.graphDomainY == null) {
        eventInfo.graphDomainY = this.getCurrentGraphDomainY();
      }
      if (eventInfo.dataDomainX == null) {
        eventInfo.dataDomainX = this.getCurrentDomainX();
      }
      if (eventInfo.dataDomainY == null) {
        eventInfo.dataDomainY = this.getCurrentDataDomainY();
      }
      this.setScaling(eventInfo.graphDomainY, eventInfo.dataDomainX);
      this.zoomToCurrentScale();
      this.resetTrackingLineH();
    }
  }

  resize() {
    if (this.htmlTemplateVars.graphInitialized) {
      this.setRendererSize();
      this.htmlTemplateVars.waterfallContainerStyle = { 'height': (this.renderer.attributes.outerGraphHeight) + 'px' };
      this.setScaling(this.calculateGraphDomainY(), this.domainX);
      this.refreshGraph();
    } else {
      this.setRendererSize();
      this.htmlTemplateVars.waterfallContainerStyle = { 'height': (this.renderer.attributes.outerGraphHeight) + 'px' };
      this.removeSvg();

      this.axes.reset();
      this.gradient.reset();
      this.initializeGraphElements();
    }
  }

  private setRendererSize() {
    this.renderer.resize();
   // this.correctRendererSize();
  }
  private correctRendererSize() {
    const gradientWidgetDimensions = 55;
    this.renderer.attributes.innerGraphWidth = this.renderer.attributes.innerGraphWidth - gradientWidgetDimensions;
  }
 
  initializeGraphData() {
    this.domainX = this.calculateDomainX();
    if ((this.dataParams.axisMaxX != null && this.dataParams.axisMinX != null) ||
      (this.dataParams.axisMaxY != null && this.dataParams.axisMinY != null)) {
      this.usingMinMaxXY = true;
    }
    this.setScaling(this.calculateGraphDomainY());
    this.createAxesAndSetGridDimensions(false);
    this.htmlTemplateVars.graphInitialized = true;
    this.setRendererSize();
    this.gradient.toggleGrayscaleGradient(this.htmlTemplateVars.grayscale, this.htmlTemplateVars.grayscaleChoice);
    this.showGradientWidget();
  }


  // can be called if graph attributes have been changed from a parent component (i.e. tooltip visibility)
  refreshGraph() {
    this.removeSvg();
    this.axes.reset();
    this.gradient.reset();
    this.createCanvasAndSvg();
    this.initializeTrackingLines();
    this.addGradientWidget();
    this.addGraphElements();
    this.addTooltip();
    this.changeDetector.detectChanges();
    const createEmptyAxes = this.htmlTemplateVars.graphInitialized === false;
    this.createAxesAndSetGridDimensions(createEmptyAxes);
    this.alignCanvasWithSvgAxes();
    this.addTrackingLines();
    this.render();
  }

  render() {
    this.htmlTemplateVars.rendering = true;
    this.notifyRenderBegin();
    this.initializeGraphData();
    this.alignCanvasWithSvgAxes();
    this.renderData();
  }

  renderCanvasOnly() {
    this.notifyRenderBegin();
    this.setScaling(this.calculateGraphDomainY());
    this.renderData();
  }

  renderData(): void {
    this.disableControlsForRendering();
    let dataDomain;
    if (this.dataParams.maxPowerVal != null && this.dataParams.minPowerVal != null) {
      dataDomain = [this.dataParams.minPowerVal, this.dataParams.maxPowerVal];
    }
    const gradient = this.gradient.getCurrentGradient();
    this.renderer.render(this.context, this.dataModel, this.config.forwardFillValues,
      this.transformedScaleX, this.transformedScaleY, dataDomain, gradient);

  }
  clearCanvas() {
    this.renderer.clearCanvas(this.context);
  }
  disableControlsForRendering() {
    this.htmlTemplateVars.rendering = true;
    this.gradient.toggleGradientEnabled(false);
  }
  enableControlsAfterRendering() {
    this.htmlTemplateVars.rendering = false;
    this.gradient.toggleGradientEnabled(!this.config.disableGraphInteraction);
  }
  isMouseInsideGraph(): boolean {
    const currentWidth = this.gridDimensions.rightInPixels - this.gridDimensions.leftInPixels;
    const currentHeight = this.gridDimensions.bottomInPixels - this.gridDimensions.topInPixels;
    return (this.currentX > 0 && this.currentY > 0 && this.currentX <= currentWidth && this.currentY <= currentHeight);
  }
  ngOnDestroy(): void {
      this.removeSvg();
      this.renderEndSub.unsubscribe();
  }
  removeSvg() {
    this.d3.select(this.graphContainer.nativeElement).selectAll('svg').remove();
    this.d3.select(this.graphContainer.nativeElement).select('canvas').remove();
    this.svg = null;
    this.svgElementsContainer = null;
  }

  getScaledXDomain(domain: [number, number]) {
    return domain.map(this.transformedScaleX.invert, this.transformedScaleX);
  }
  getScaledYDomain(domain: [number, number]) {
    return domain.map(this.transformedScaleY.invert, this.transformedScaleY);
  }
  getCurrentDataDomainY(): any[] {
    return this.calculateDataDomainY();
  }

  setTooltipVisibility(visible: boolean) {
    this.tooltipVisible = visible;
    if (!visible) {
      this.hideTooltip();
    }
  }
  hideTooltip() {
    this.tooltip.hide();
  }
  setScaling(domainY, domainX = null) {
    if (domainX == null) {
      domainX = this.domainX;
    }
    if (domainY == null) {
      domainY = this.calculateGraphDomainY();
    }
    this.scaleY = this.d3.scaleLinear().domain(domainY).range([this.renderer.attributes.innerGraphHeight, 0]);
    this.scaleX = this.d3.scaleLinear().domain(domainX).range([0, this.renderer.attributes.innerGraphWidth]);
    this.transformedScaleY = this.d3.scaleLinear().domain(domainY).range([this.renderer.attributes.innerGraphHeight, 0]);
    this.transformedScaleX = this.d3.scaleLinear().domain(domainX).range([0, this.renderer.attributes.innerGraphWidth]);
  }
  getInnerGraphHeight(): number {
    return this.renderer.attributes.innerGraphHeight;
  }
  getInnerGraphWidth(): number {
    return this.renderer.attributes.innerGraphWidth;
  }
  getTimestampsForEachPixel(): number[] {
    const graphPixels = this.renderer.attributes.innerGraphHeight;
    const timestamps = new Array<number>();
    for (let i = 0; i < graphPixels; i++) {
      const timestampAtPixel = this.transformedScaleY.invert(i);
      timestamps.push(timestampAtPixel);
    }
    return timestamps;
  }
  gradientChanged = () => {
    this.renderData();
  }

  showTooltipAtCurrentMousePosition() {
    if (this.tooltipVisible) {
      const pos = new WaterfallXY();
      pos.x = this.currentX;
      pos.y = this.currentY;
      const scaledCoords = new WaterfallXY();
      scaledCoords.x = Math.round(this.transformedScaleX.invert(this.currentX));
      scaledCoords.y = Math.round(this.transformedScaleY.invert(this.currentY));
      const text = this.config.tooltipValueFunc(scaledCoords);
      this.showTooltipAtPosition(pos, text);
    }
  }
  private createCanvasAndSvg() {
    this.createCanvas();
    this.createSvg();
    this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;
  }
  private initializeGraphElements() {
    if (this.units.xAxisUnit != null && this.config.xAxisTitle.indexOf(' (' + this.units.xAxisUnit.defaultUnit.name + ')') < 0) {
      this.config.xAxisTitle += ' (' + this.units.xAxisUnit.defaultUnit.name + ')';
    }
    this.domainX = this.calculateDomainX();
    this.createCanvasAndSvg();
    this.initializeScales();
    this.initializeAxes();
    this.initializeTrackingLines();
    this.addGradientWidget();
    this.addTooltip();
    this.changeDetector.detectChanges();
    this.addTrackingLines();
  }
  private initializeRenderer() {
      this.renderer.init(this.config.showYAxisLabels);
  }
  private initializeScales() {
    this.scaleY = this.d3.scaleLinear().domain([0, this.renderer.attributes.innerGraphHeight])
      .range([this.renderer.attributes.innerGraphHeight, 0]);
    this.scaleX = this.d3.scaleLinear().domain([0, this.renderer.attributes.innerGraphWidth])
      .range([0, this.renderer.attributes.innerGraphWidth]);
    this.transformedScaleX = this.scaleX;
    this.transformedScaleY = this.scaleY;
  }
  private createSvg() {
    this.svg = this.renderer.appendSvg(this.svg, this.graphContainer.nativeElement);
    this.svgElementsContainer = this.renderer.appendSvgElementsContainer(this.svg);
    this.appendMouseEventsToSvg();
    if (this.htmlTemplateVars.zoomMode) {
      this.appendZoomToSvg();
    }
  }
  private appendZoomToSvg() {
    this.zoom.appendZoom(this.svgElementsContainer, this.onBrushed, this.renderer.attributes.innerGraphWidth,
       this.renderer.attributes.innerGraphHeight);
  }
  private appendMouseEventsToSvg() {
    this.renderer.addMouseEvents(this.svg, this.mouseMoveFunc, this.mouseLeaveFunc);
  }
  private createCanvas() {
    this.canvas = this.renderer.appendCanvas(this.canvas, this.graphContainer.nativeElement);

  }
  private addTooltip() {
    const tooltipConfig = new SvgTooltipConfig();
    tooltipConfig.tooltipTextHeight = 16;
    tooltipConfig.tooltipWidth = 80;
    tooltipConfig.graphHeight = this.getInnerGraphHeight();
    tooltipConfig.graphWidth = this.getInnerGraphWidth();
    tooltipConfig.fontSize = '12px';
    tooltipConfig.fontWeight = 'bold';
    tooltipConfig.bgClassName = 'pf-waterfall-tooltip-bg';
    tooltipConfig.textClassName = 'pf-waterfall-tooltip-text';
    tooltipConfig.circleClassName = 'pf-waterfall-focus-circle';
    this.tooltip.initialize(this.htmlTemplateVars.graphId + '-tooltip', tooltipConfig);
    this.tooltip.addTooltipElementToSvg(this.svgElementsContainer);
  }
  private initializeAxes() {
    this.axes.showXAxesTickLabels = this.config.showXAxisLabels;
    this.axes.showYAxesTickLabels = this.config.showYAxisLabels;

    this.axes.xAxisTitle = this.config.xAxisTitle;
    this.axes.yAxisTitle = this.config.yAxisTitle;
    this.axes.init(this.htmlTemplateVars.graphId, 10,
      this.config.xAxisTickFormatFunc, this.config.yAxisTickFormatFunc,
      this.config.yAxisTitle, this.config.xAxisTitle);
  }

  private zoomToCurrentScale() {
    this.axes.zoom(this.transformedScaleX, this.transformedScaleY);
    this.renderData();
  }
  private addGradientWidget() {
    this.gradient.appendElement(this.graphContainer.nativeElement, this.htmlTemplateVars.graphId,
      this.renderer.attributes.innerGraphHeight, this.calculateDataDomainY(), this.calculateDomainX(), this.gradientChanged);
    this.gradient.toggleGradientEnabled(false);
  }
  private addGraphElements() {
    if (this.graphElements != null) {
      for (let i = 0; i < this.graphElements.length; i++) {
        this.graphElementRenderer.addElementToCanvas(this.context, this.graphElements[i], this.transformedScaleX, this.transformedScaleY);
      }
    }
  }

  updateAxes() {
    this.axes.zoom(this.transformedScaleX, this.transformedScaleY);
  }

  private createAxesAndSetGridDimensions(empty: boolean) {
    const gradientWidgetDimensions = document.getElementById(this.renderer.attributes.graphId + '-gradient-svg').getBoundingClientRect();
    const containerDimensions = this.graphContainer.nativeElement.getBoundingClientRect();
    let innerGraphWidth = this.renderer.attributes.innerGraphWidth - containerDimensions.left - gradientWidgetDimensions.width;
    this.gridDimensions = this.axes.create(this.svgElementsContainer, empty, this.renderer.attributes.innerGraphHeight,
      innerGraphWidth, this.renderer.attributes.marginLeft, this.scaleX, this.scaleY, this.config.xAxisTickFormatFunc, this.config.yAxisTickFormatFunc, false);
    this.gridDimensions.bottomInPixels = this.gridDimensions.bottomInPixels - containerDimensions.top;
    this.gridDimensions.topInPixels = this.gridDimensions.topInPixels - containerDimensions.top;
    this.gridDimensions.leftInPixels = this.gridDimensions.leftInPixels - containerDimensions.left;
    this.gridDimensions.rightInPixels = this.gridDimensions.rightInPixels - containerDimensions.left;
    this.containerDimensions.bottomInPixels = containerDimensions.bottom;
    this.containerDimensions.topInPixels = containerDimensions.top;
    this.containerDimensions.leftInPixels = containerDimensions.left;
    this.containerDimensions.rightInPixels = containerDimensions.right;
  }
  private alignCanvasWithSvgAxes() {
    this.renderer.setCanvasLocation(this.canvas, this.gridDimensions);
  }
  private calculateDomainX() {
    if (this.usingMinMaxXY && this.dataParams.axisMaxX != null && this.dataParams.axisMinX != null) {
      return [this.dataParams.axisMinX, this.dataParams.axisMaxX];
    } else {
      if (this.dataModel.length() > 0) {
        const nextDomain = this.dataModel.getDomain();
        const min = nextDomain[0];
        const max = nextDomain[1];
        return [min, max];
      } else {
        return [-1, 1];
      }
    }
  }
  private calculateDataDomainY() {
    let min = 0;
    let max = 0;
    if (this.dataModel.length() > 0 && !(this.dataParams.maxPowerVal != null || this.dataParams.minPowerVal != null)) {
      const nextRange = this.dataModel.getDataRange();
      min = nextRange[0];
      max = nextRange[1];
    } else {
      if (this.dataParams.maxPowerVal != null) {
        max = this.dataParams.maxPowerVal;
      }
      if (this.dataParams.minPowerVal != null) {
        min = this.dataParams.minPowerVal;
      }
    }
    
    return [min, max];
  }
  private calculateGraphDomainY() {
    let min;
    let max;
    if (this.usingMinMaxXY && this.dataParams.axisMaxY != null && this.dataParams.axisMinY != null) {
      min = this.dataParams.axisMinY;
      max = this.dataParams.axisMaxY;
    } else {
      if (this.dataModel.length() > 0) {
        const nextRange = this.dataModel.getGraphRange();
        min = nextRange[0];
        max = nextRange[1];
      }
    }
    return [min, max];
  }
  private showTooltipAtPosition(position: WaterfallXY, text: string[]) {
    this.tooltip.showTooltipAtPosition(position, text);
  }
  private showGradientWidget() {
    this.gradient.reset();
    this.gradient.setGradient(this.config.gradientColors);
    this.addGradientWidget();
    this.gradient.initializeWithData(this.calculateDomainX(), this.calculateDataDomainY());
    this.gradient.toggleGradientEnabled(!this.config.disableGraphInteraction);

  }
  private initializeTrackingLines() {
    const canvasBoundingRect = this.renderer.getCanvasBoundingRect();
    this.trackingLineH.initialize(this.htmlTemplateVars.graphId + 'tracking-line-h', canvasBoundingRect.height,
      canvasBoundingRect.width, this.trackingLineMouseoverFunc, this.trackingLineMouseLeaveFunc,
      this.horizontalTrackingLineDragStart, this.horizontalTrackingLineDragging, this.horizontalTrackingLineDragEnd);
    this.trackingLineV.initialize(this.htmlTemplateVars.graphId + 'tracking-line-v', canvasBoundingRect.height,
      canvasBoundingRect.width, this.trackingLineMouseoverFunc, this.trackingLineMouseLeaveFunc,
      this.verticalTrackingLineDragStart, this.verticalTrackingLineDragging, this.verticalTrackingLineDragEnd);
  }
  private verticalTrackingLineDragStart = (d, i) => {
    if (!this.config.disableGraphInteraction) {
      this.userInteractingWithGraph = true;
      this.trackingLineV.dragStart();
    }
  }
  private horizontalTrackingLineDragStart = (d, i) => {
    if (!this.config.disableGraphInteraction) {
      this.userInteractingWithGraph = true;
      this.trackingLineH.dragStart();
    }
  }
  private trackingLineMouseoverFunc = (d, i) => {
    this.setTooltipVisibility(false);
  }
  private trackingLineMouseLeaveFunc = (d, i) => {
    //   have to check this variable to see if the mouse left the line because they are currently dragging it
    if (!this.userInteractingWithGraph) {
      this.setTooltipVisibility(true);
    }
  }
  private verticalTrackingLineMouseLeaveFunc = (d, i) => {
    //   have to check this variable to see if the mouse left the line because they are currently dragging it
    if (!this.userInteractingWithGraph) {
      this.setTooltipVisibility(true);
    }
  }
  private notifyRenderBegin() {
    const zoomEvent = new ZoomEventInfo();
    zoomEvent.dataDomainX = this.transformedScaleX.domain();
    zoomEvent.graphDomainY = this.calculateGraphDomainY();
    zoomEvent.dataDomainY = this.calculateDataDomainY();
    this.onRenderBegin(zoomEvent);
  }
  private notifyRenderEnd() {
    const zoomEvent = new ZoomEventInfo();
    zoomEvent.dataDomainX = this.transformedScaleX.domain();
    zoomEvent.graphDomainY = this.transformedScaleY.domain();
    zoomEvent.dataDomainY = this.calculateDataDomainY();
    this.onRenderEnd(zoomEvent);
  }
  private verticalTrackingLineDragEnd = (d, i) => {
    if (!this.config.disableGraphInteraction) {
      this.userInteractingWithGraph = false;
      const svg = document.getElementById(this.htmlTemplateVars.graphId);
      const newX = this.d3.mouse(svg)[0];
      const newFreqVal = this.scaleValueByX(newX);

      //  call the onVerticalTrackingLineChange function in case the parent component is listening and wants to know
      this.onVerticalTrackingLineChange(newFreqVal);
      this.trackingLineV.dragEnd();
    }
  }
  private horizontalTrackingLineDragEnd = (d, i) => {
    if (!this.config.disableGraphInteraction) {
      this.userInteractingWithGraph = false;
      const svg = document.getElementById(this.htmlTemplateVars.graphId);
      const newY = this.d3.mouse(svg)[1];
      const newTimestampVal = this.scaleValueByY(newY);

      //  call the onHorizontalTrackingLineChange function in case the parent component is listening and wants to know
      this.onHorizontalTrackingLineChange(newTimestampVal);
      this.trackingLineH.dragEnd();
    }
  }
  private verticalTrackingLineDragging = (d, i) => {
    if (!this.config.disableGraphInteraction) {
      //   set the freq line's x value to that of the current mouse position
      const svg = document.getElementById(this.htmlTemplateVars.graphId);
      const newX = this.d3.mouse(svg)[0];
      this.trackingLineV.dragging(newX);
    }
  }
  private horizontalTrackingLineDragging = (d, i) => {
    if (!this.config.disableGraphInteraction) {
      //   set the freq line's x value to that of the current mouse position
      const svg = document.getElementById(this.htmlTemplateVars.graphId);
      const newY = this.d3.mouse(svg)[1];
      this.trackingLineH.dragging(newY);
    }
  }
  private scaleValueByX(val) {
    return this.transformedScaleX.invert(val).toFixed(this.config.roundXToDecimal);
  }
  private scaleValueByY(val) {
    return this.transformedScaleY.invert(val);
  }
}
