import { SpectrumAnalyzerXY } from './pf-specan-xy';
// import { SpectrumAnalyzerMarker } from '../../index';
import { SpectrumAnalyzerData } from './pf-specan-data';
import { ChartSeries, XYPoint } from '../../../chart/index';
import { ChartData } from '../../../chart/index';
import { SpecanLineType } from './pf-specan-line-type';


export class SpectrumAnalyzerSeries extends ChartSeries {

  legendName: string;

  //  this indicates whether we have performed initial setup on this data model
  //  relative to the graph
  graphInfoInitialized: boolean;

  //  the color of the line when displayed on the graph
  graphColor: string;

  //  indicates the type of the line drawn
  lineType: SpecanLineType = SpecanLineType.solid;
  lineWidth: number = 1;
  // ID of the current path for rendering
  pathId: string;

  //  the count of how many previous point values to keep track of when averaging
  avgCount = 4;

  //  indicating whether the data should be shown in the graph
  visible = true;

  //  indicates whether this data model is selected in the UI for tooltip purposes
  selected = false;

  //  indicating whether this dataset should be continuously updating
  continuousUpdate = true;
  singleUpdate = false;
  needsUpdate = false;

  // gives enclosing components the ability to disable the toggling checkboxes and drive those externally instead
  disableMenuCheckbox: boolean;

  // this setting can save processing time if the min/max/avgs are not needed for this dataset
  calculateMinMaxAvg = true;
  // redeclare this inherited member as an instance of an array containing SpectrumAnalyzerData elements
  // so we can easily perform operations on the data
  protected _data = new Array<SpectrumAnalyzerData>();
  // these private variables indicate whether we are receiving power values to create our own data set
  // or whether the user is passing in pairs of points that have already been initialized
  private _usingPowerValues: boolean;
  private _usingXYPoints: boolean;
  private powerVals: number[] = new Array<number>();
  //  indicates the corresponding index within the inherited _data list to each trace
  private dataSetIndices = ['main', 'max', 'min', 'avg'];
  private stepFreq: number;
  private startFreq: number;

  // keep track of current min/maxes
  private currentMaxY: number;
  private currentMinY: number;

  // indicates whether we have initialized mins/maxes/avgs for the scenario when user provides x/y points
  private XYDataInitialized;

  constructor(name: string) {
    super(name);
    this.legendName = name;
    // initialize the data sets
    const mainDataSet = new SpectrumAnalyzerData();
    this._data.push(mainDataSet);
    const minDataSet = new SpectrumAnalyzerData();
    minDataSet.visible = false;
    this._data.push(minDataSet);
    const maxDataSet = new SpectrumAnalyzerData();
    maxDataSet.visible = false;
    this._data.push(maxDataSet);
    const avgDataSet = new SpectrumAnalyzerData();
    avgDataSet.visible = false;
    this._data.push(avgDataSet);
  }

  //  this is the param that contains the y values of the data
  initializePowerValues(powerVals: number[], startFreq: number, stepFreq: number): void {
    if (this.powerVals.length > 0) {
      for (let i = 0; i < powerVals.length; i++) {
        // if the new data set is longer than our previous one, add new elements to make them equal
        if (this.powerVals.length <= i) {
          this.powerVals.unshift(powerVals[i]);
        } else {
          this.powerVals[i] = powerVals[i];
        }
      }
      // if the new data set is shorter than our previous, remove elements so they will be the same size
      if (powerVals.length < this.powerVals.length) {
        const lengthDiff = this.powerVals.length - powerVals.length;
        this.powerVals.splice(powerVals.length - 1, lengthDiff);
      }
    } else {
      this.powerVals = powerVals.slice();
    }
    this.startFreq = +startFreq;
    this.stepFreq = +stepFreq;
    this._usingPowerValues = true;
    this._usingXYPoints = false;
    this.graphInfoInitialized = false;
  }

  //  this is the param that contains the y values of the data
  initializeXYPoints(points: XYPoint[]): void {
    if (this._data[0].length() > 0) {
      for (let i = 0; i < points.length; i++) {
        // if the new data set is longer than our previous one, add new elements to make them equal
        if (this._data[0].length() <= i) {
          this._data[0].push(points[i]);
        } else {
          this._data[0].pointAtIndex(i).x = points[i].x;
          this._data[0].pointAtIndex(i).y = points[i].y;
        }
      }
      // if the new data set is shorter than our previous, remove elements so they will be the same size
      if (points.length < this._data[0].length()) {
        const lengthDiff = this._data[0].length() - points.length;
        this._data[0].splice(points.length, lengthDiff);
      }
    } else {
      for (let i = 0; i < points.length; i++) {
        this._data[0].push(points[i]);
      }
    }

    this._usingPowerValues = false;
    this.graphInfoInitialized = false;
    this._usingXYPoints = true;
  }

  // a function the enclosing component can use to determine how data is being passed to this data set
  dataSetUsesPowerVals(): boolean {
    return this._usingPowerValues;
  }

  // a function the enclosing component can use to determine how data is being passed to this data set
  dataSetUsesXYPoints(): boolean {
    return this._usingXYPoints;
  }

  // returns the frequency start and stop for the current data set
  // we can shortcut getting the domain if this dataset is using start/step freq without iterating through the dataset
  getDomain(): [number, number] {
    if (this._usingPowerValues) {
      const min = this.startFreq;
      const max = this.startFreq + (this.stepFreq * this.powerVals.length);
      return [min, max];
    } else {
      return this._data[0].getDomain();
    }
  }

  setClearWriteVisibility(visible: boolean): void {
    this.getDataSetByName('main').visible = visible;
  }

  setMaxVisibility(visible: boolean): void {
    this.getDataSetByName('max').visible = visible;
  }

  setMinVisibility(visible: boolean): void {
    this.getDataSetByName('min').visible = visible;
  }

  setAvgVisibility(visible: boolean): void {
    this.getDataSetByName('avg').visible = visible;
  }

  hasVisiblePathElements(): boolean {
    if (!this.visible) {
      return false;
    } else {
      return this.getDataSetByName('main').visible;
    }
  }

  getDataSetByName(name: string): SpectrumAnalyzerData {
    const idx = this.dataSetIndices.findIndex((dataName) => {
      return dataName === name;
    });
    return this.dataSetAt(idx);
  }

  getPointAtYValue(value: number) {
    const data = this._data[0].points();
    for (let i = 0; i < this._data[0].length(); i++) {
      if (data[i].y === value) {
        return data[i];
      }
    }
    return null;
  }

  getPointAtXValue(value: number, dataSetName = 'main') {
    let dataset = this.getDataSetByName(dataSetName);
    if (dataset.length() > 0) {
      const data = dataset.points();
      const dataLength = dataset.length();
      let idx = 0;
      while (idx < dataLength && data[idx].x < value) {
        idx++;
      }
      if (idx < dataLength) {
        // return the closest of the two points
        const diffOne = data[idx].x - value;
        let diffTwo;
        if (idx + 1 < dataLength) {
          diffTwo = data[idx + 1].x - value;
        } else {
          diffTwo = data[idx].x - value;
        }
        if (diffOne > diffTwo) {
          return data[idx + 1];
        } else {
          return data[idx];
        }
      }
    }
    return null;
  }

  dataSetAt(index: number): SpectrumAnalyzerData {
    if (index >= 0) {
      return this._data[index];
    } else {
      return null;
    }
  }

  getRange(): [number, number] {
    return this._data[0].getRange();
  }

  resetDataModel(): void {
    this.powerVals = [];
    this._data[0].clear();
    this._data[1].clear();
    this._data[2].clear();
    this._data[3].clear();
  }

  //  the data model needs to be constructed by taking the input data (x coordinates) and creating y components
  //  for them using the starting frequency and successively adding the step value to construct the graph
  //  we also need to determine the all-time max and min value for each point along the way
  createDataModel(): void {
    let currentFreq = this.startFreq;
    //  create pointers to these locations in our data set
    //  they should always exist for specan data sets
    //  initialize if null
    const dataMaxes = this._data[1];
    const dataMins = this._data[2];
    const dataAvgs = this._data[3];
    const dataPoints = this._data[0];
    //  separate out the two iteration cases
    //  combining processes here to save processing time
    if (this._usingPowerValues) {
      // if the new data set is shorter than our previous, remove elements so they will be the same size
      if (this.powerVals.length < dataPoints.length()) {
        const lengthDiff = dataPoints.length() - this.powerVals.length;
        dataPoints.splice(this.powerVals.length - 1, lengthDiff);
        dataAvgs.splice(this.powerVals.length - 1, lengthDiff);
        dataMins.splice(this.powerVals.length - 1, lengthDiff);
        dataMaxes.splice(this.powerVals.length - 1, lengthDiff);
      }
      for (let i = 0; i < this.powerVals.length; i++) {
        if (this.powerVals[i] != null) {
          const xy = new SpectrumAnalyzerXY();
          xy.y = this.powerVals[i];
          xy.x = (currentFreq += this.stepFreq);

          //  if the data has not been initialized, set it initially to the first round of data
          if (dataPoints.pointAtIndex(i) != null) {
            if (this.calculateMinMaxAvg) {
              if (dataMins.pointAtIndex(i).y > xy.y) {
                dataMins.pointAtIndex(i).y = xy.y;
              }
              dataMins.pointAtIndex(i).x = xy.x;
              if (dataMaxes.pointAtIndex(i).y < xy.y) {
                dataMaxes.pointAtIndex(i).y = xy.y;
              }
              dataMaxes.pointAtIndex(i).x = xy.x;
              //  keep track of avg for last {{avgCount}} iterations
              dataAvgs.pointAtIndex(i).y = (xy.y + (this.avgCount * dataAvgs.pointAtIndex(i).y)) / (this.avgCount + 1);
              dataAvgs.pointAtIndex(i).x = xy.x;
            }
            //  only want to modify the point here, not add new points
            dataPoints.pointAtIndex(i).y = xy.y;
            dataPoints.pointAtIndex(i).x = xy.x;
          } else {
            //  otherwise these collections are empty and we should push empty points
            if (this.calculateMinMaxAvg) {
              const newMinPoint = new SpectrumAnalyzerXY(xy);
              dataMins.push(newMinPoint);
              const newMaxPoint = new SpectrumAnalyzerXY(xy);
              dataMaxes.push(newMaxPoint);
              const newAvgPoint = new SpectrumAnalyzerXY(xy);
              dataAvgs.push(newAvgPoint);
            }
            dataPoints.push(xy);
          }
        }
      }

      //  assuming here that if data is not supplied, then dataPoints has been supplied by the user and we will use that instead
      //  TODO test this
      //  this is the implementation for supporting users supplying y AND x points
      //  coming later after the big refactor
    } else if (this._usingXYPoints) {
      for (let i = 0; i < this._data[0].length(); i++) {
        if (this._data[0].pointAtIndex(i) != null) {
          //  if the data has not been initialized, set it initially to the first round of data
          if (this.XYDataInitialized) {
            if (dataMins.pointAtIndex(i) != null) {
              if (dataMins.pointAtIndex(i).y > dataPoints.pointAtIndex(i).y) {
                dataMins.pointAtIndex(i).y = dataPoints.pointAtIndex(i).y;
              }
            } else {
              dataMins.push(dataPoints.pointAtIndex(i));
            }
            dataMins.pointAtIndex(i).x = dataPoints.pointAtIndex(i).x;
            if (dataMaxes.pointAtIndex(i) != null) {
              if (dataMaxes.pointAtIndex(i).y < dataPoints.pointAtIndex(i).y) {
                dataMaxes.pointAtIndex(i).y = dataPoints.pointAtIndex(i).y;
              }
            } else {
              dataMaxes.push(dataPoints.pointAtIndex(i));
            }
            dataMaxes.pointAtIndex(i).x = dataPoints.pointAtIndex(i).x;
            //  keep track of avg for last 5 iterations
            if (dataAvgs.pointAtIndex(i) != null) {
              dataAvgs.pointAtIndex(i).y = (dataPoints.pointAtIndex(i).y +
                (this.avgCount * dataAvgs.pointAtIndex(i).y)) / (this.avgCount + 1);
            } else {
              dataAvgs.push(dataPoints.pointAtIndex(i));
            }
          } else {
            //  otherwise these collections are empty and we should push empty points
            const newMinPoint = new SpectrumAnalyzerXY(this._data[0].pointAtIndex(i));
            dataMins.push(newMinPoint);
            const newMaxPoint = new SpectrumAnalyzerXY(this._data[0].pointAtIndex(i));
            dataMaxes.push(newMaxPoint);
            const newAvgPoint = new SpectrumAnalyzerXY(this._data[0].pointAtIndex(i));
            dataAvgs.push(newAvgPoint);
          }
        }
        this.XYDataInitialized = true;
      }
    }
  }
}
