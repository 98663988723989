import { Axis, D3, Selection } from 'd3-ng2-service';
import { AxisGridDimensions } from './pf-waterfall-axis-grid-dimensions';

export class WaterfallAxes {
  yAxisTitle: string;
  xAxisTitle: string;
  marginLeft: number;
  showYAxesTickLabels = true;
  showXAxesTickLabels: boolean;
  showOnlyGraphWindow: boolean;
  gridDimensions = new AxisGridDimensions();
  private xAxisFunc: any;
  private yAxisFunc: any;
  private yAxisId: string;
  private xAxisId: string;
  private xAxis: any;
  private yAxis: any;
  private yAxisTicks: number[];
  private xAxisTicks: number[];
  private xAxisTickCount: number;
  private yAxisTickCount: number;
  private xAxisTickFunc: any;
  private yAxisTickFunc: any;
  private d3: D3;
  private dbDivValue = 0;
  private ticks: number;
  private graphId: string;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  init(graphId: string, ticks: number, xAxisTickFunc = null,
     yAxisTickFunc = null, yAxisTitle: string = null, xAxisTitle: string = null) {
    this.yAxisId = graphId + '-yaxis';
    this.xAxisId = graphId + '-xaxis';
    this.yAxisTitle = yAxisTitle;
    this.xAxisTitle = xAxisTitle;
    this.graphId = graphId;
    this.xAxisTickFunc = xAxisTickFunc;
    this.yAxisTickFunc = yAxisTickFunc;
    this.setXTicks(ticks);
    this.yAxisTickCount = ticks;
  }
  setXTicks(ticks: number) {
    this.ticks = ticks;
    // we are going to coerce the x axis into having a line down the middle at the center freq
    // so we need the tick count for the x axis to be even
    if (ticks % 2 === 1) {
      ticks++;
    }
    this.xAxisTickCount = ticks;
  }
  getXAxisElem() {
    return this.xAxis;
  }
  getYAxisElem() {
    return this.xAxis;
  }
  // empty refers to whether the axes should be created but not populated with data-related attributes
  // used to render an empty graph before actual data is available
  create(svg: Selection<any, {}, null, undefined>, empty: boolean, height: number, width: number, marginLeft: number,
    domainScale: any, rangeScale: any, xAxisTickFunc: any = null,
    yAxisTickFunc: any = null, showGraticule: boolean = true) {
    this.xAxisTickFunc = xAxisTickFunc;
    this.yAxisTickFunc = yAxisTickFunc;
    this.marginLeft = marginLeft;
    this.setAxisTickValues(height, width);
    
    this.setAxisFunctions(domainScale, rangeScale, empty);

    // check to make sure the graticule is configured to be shown
    if (showGraticule) {
      this.xAxisFunc.tickSize(-height);
      this.yAxisFunc.tickSize(-width);
    }

    // remove the old axes if they exist
    this.removeOldAxes(svg);
    this.addAxes(svg, height, width);

    if (!empty) {
      this.determineDBPerDiv(rangeScale);

      if (!this.showOnlyGraphWindow) {
        this.addYAxisLabel(svg, height);
        this.addXAxisLabel(svg, height, width);
      }
    }
    // remove the tick marks from the x axis
    this.dealWithAxisTickLabels();
    this.calculateAxisGridDimensions(svg);
    return this.gridDimensions;
  }
  private determineDBPerDiv(rangeScale) {
    const yAxisTicks = this.determineAxisTicks(rangeScale, this.yAxisTickCount);
    this.dbDivValue = +(yAxisTicks[1] - yAxisTicks[0]).toFixed(2);
  }
  private setAxisTickValues(height, width) {
    // calculate the tick values for both axes
    if (width < 750) {
      this.setXTicks(5);
    } else {
      this.setXTicks(10);
    }

    if (height < 250) {
      this.yAxisTickCount = 5;
    } else {
      this.yAxisTickCount = 10;
    }
  }
  private setAxisFunctions(domainScale, rangeScale, empty) {
    const xAxisTicks = this.determineAxisTicks(domainScale, this.xAxisTickCount);
    const yAxisTicks = this.determineAxisTicks(rangeScale, this.yAxisTickCount);
    if (!empty) {
      if (this.xAxisTickFunc != null) {
        this.xAxisFunc = this.d3.axisBottom(domainScale).tickValues(xAxisTicks).tickFormat(this.xAxisTickFunc);
      } else {
        this.xAxisFunc = this.d3.axisBottom(domainScale).tickValues(xAxisTicks);
      }
      if (this.yAxisTickFunc != null) {
        this.yAxisFunc = this.d3.axisLeft(rangeScale).tickValues(yAxisTicks).tickFormat(this.yAxisTickFunc);
      } else {
        this.yAxisFunc = this.d3.axisLeft(rangeScale).tickValues(yAxisTicks);
      }
    } else {
      this.xAxisFunc = this.d3.axisBottom(domainScale).ticks(this.ticks).tickFormat(function (d) { return ''; });
      this.yAxisFunc = this.d3.axisLeft(rangeScale).ticks(this.ticks).tickFormat(function (d) { return ''; });
    }
}
  private addAxes(svg, height, width) {
    this.yAxis = svg.insert('g')
      .attr('id', this.yAxisId)
      .attr('class', 'pf-graph-grid')
      .call(this.yAxisFunc);

    this.xAxis = svg.insert('g')
      .attr('class', 'pf-graph-grid')
      .attr('id', this.xAxisId)
      .attr('height', height)
      .attr('width', width)
      .attr('transform', 'translate(0,' + (height) + ')')
      .call(this.xAxisFunc);
  }
  private removeOldAxes(svg) {
    svg.select('#' + this.yAxisId).remove();
    svg.select('#' + this.xAxisId).remove();
    svg.select('#' + this.yAxisId + '-label').remove();
    svg.select('#' + this.xAxisId + '-label').remove();
  }
  private addXAxisLabel(svg, height, width) {
    if (this.xAxisTitle != null && this.showXAxesTickLabels) {
      //  text label for the x axis
      svg.append('text')
        .attr('y', height + 20)
        .attr('x', (width / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('id', this.xAxisId + '-label')
        .attr('class', 'pf-graph-label')
        .text(this.xAxisTitle);
    }
  }
  private addYAxisLabel(svg, height) {
    if (this.yAxisTitle != null && this.showYAxesTickLabels) {
      let heightOfFont = document.getElementById(this.yAxisId).querySelector('.tick').getBoundingClientRect().height;
      let locationOfYAxisLabel = document.getElementById(this.yAxisId).getBoundingClientRect().width + heightOfFont + 5; //give a buffer of 5
      //  text label for the y axis
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - locationOfYAxisLabel)
        .attr('x', 0 - (height / 2))
        .attr('dy', '1em')
        .attr('id', this.yAxisId + '-label')
        .style('text-anchor', 'middle')
        .attr('class', 'pf-graph-label')
        .text(this.yAxisTitle);
    }
  }
  getDbDiv() {
    return this.dbDivValue;
  }
  reset() {
    this.xAxis = null;
    this.yAxis = null;
    this.yAxisFunc = null;
    this.xAxisFunc = null;
    this.gridDimensions = new AxisGridDimensions();
  }

  // depending on the zoom, we might be dealing with two different scales
  zoom(transformedScaleX: any, transformedScaleY: any, domainScale: any = null, rangeScale: any = null): void {
    this.xAxisTicks = this.determineAxisTicks(transformedScaleX, this.xAxisTickCount);
    this.yAxisTicks = this.determineAxisTicks(transformedScaleY, this.ticks);
    this.dbDivValue = +(this.yAxisTicks[1] - this.yAxisTicks[0]).toFixed(2);

    this.callYAxis(rangeScale, transformedScaleY);
    this.callXAxis(domainScale, transformedScaleX);
       
    this.dealWithAxisTickLabels();
  }
  private dealWithAxisTickLabels() {
    if (!this.showYAxesTickLabels || this.showOnlyGraphWindow) {
      this.yAxis.selectAll('text').remove();
    }
    if (!this.showXAxesTickLabels) {
      this.xAxis.selectAll('text').remove();
    }
  }
  private callXAxis(domainScale, transformedScaleX) {
    let xFuncCall;
    if (domainScale != null) {
      xFuncCall = this.xAxisFunc.scale(domainScale).tickValues(this.xAxisTicks);
    } else {
      xFuncCall = this.xAxisFunc.scale(transformedScaleX).tickValues(this.xAxisTicks);
    }
    if (this.xAxisTickFunc != null) {
      xFuncCall.tickFormat(this.xAxisTickFunc);
    }
    this.xAxis.transition()
      .duration(0)
      .call(xFuncCall);
  }
  private callYAxis(rangeScale, transformedScaleY) {
    let yFuncCall;
    if (rangeScale != null) {
      yFuncCall = this.yAxisFunc.scale(rangeScale).tickValues(this.yAxisTicks);
    } else {
      yFuncCall = this.yAxisFunc.scale(transformedScaleY).tickValues(this.yAxisTicks);
    }
    if (this.yAxisTickFunc != null) {
      yFuncCall.tickFormat(this.yAxisTickFunc);
    }
    this.yAxis.transition()
      .duration(0)
      .call(yFuncCall);
  }
  private determineAxisTicks(scale, ticks) {
    const domain = scale.domain();

    const tickIncr = (domain[1] - domain[0]) / ticks;
    const axisTicks = [];

    for (let i = 0; i < ticks + 1; i++) {
      axisTicks.push(domain[0] + (tickIncr * i));
    }

    return axisTicks;
  }
  private calculateAxisGridDimensions(svg) {
    const yAxisBounds = document.querySelector('#' + this.yAxisId).querySelector('path').getBoundingClientRect();
    const xAxisBounds = document.querySelector('#' + this.xAxisId).querySelector('path').getBoundingClientRect();
    this.gridDimensions.leftInPixels = yAxisBounds.right;
    this.gridDimensions.rightInPixels = xAxisBounds.right;
    this.gridDimensions.topInPixels = yAxisBounds.top;
    this.gridDimensions.bottomInPixels = xAxisBounds.top;
  }
}
