import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']

})
export class ConfirmationDialogComponent {
  cancelText: string;
  okText: string;
  headerText: string;
  bodyText: string;
  visible = false;
  @ViewChild('okButton') public okButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;

  open(header: string, body: string, ok = 'OK', cancel = 'Cancel'): Promise<boolean> {
        this.visible = true;
    this.headerText = header;
    this.bodyText = body;
    this.okText = ok;
    this.cancelText = cancel;
    return new Promise<boolean>((resolve, reject) => {
      this.cancelButton.nativeElement.onclick = ((e: any) => {
        e.preventDefault();
        this.visible = false;
        resolve(false);
      });
      this.okButton.nativeElement.onclick = ((e: any) => {
        e.preventDefault();
        this.visible = false;
        resolve(true);
      });
    });
  }
}
