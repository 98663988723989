import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TooltipModule } from 'primeng/primeng';
import { UnitInputComponent } from './pf-unit-input/pf-unit-input.component';

export { Unit } from './pf-unit/unit';
export { UnitOptions } from './pf-unit/unit-options';
export { UnitConversion } from './pf-unit/unit-conversion';
export { UnitValidator } from './pf-unit/unit-validator';
export { UnitInputComponent } from './pf-unit-input/pf-unit-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
  ],
  declarations: [
    UnitInputComponent,
  ],
  exports: [
    UnitInputComponent,
  ]
})
export class PFUnitModule {}
