import { Axis, D3, Selection } from 'd3-ng2-service';
import { XYPoint } from '../../../chart/index';
import { CanvasGraphElementModel } from './pf-canvas-graph-element-model';

export class CanvasGraphElementRenderer {
    private d3: D3;

    constructor(d3: D3) {
        this.d3 = d3;
    }
    addElementToCanvas(context, model: CanvasGraphElementModel, scaleX, scaleY) {
        context.beginPath();
        context.strokeStyle = model.lineColor;
        context.lineWidth = model.lineWidth;
        model.xyPoints.forEach(point => {
            context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
        });
        if (model.connectStartAndEndPoints) {
            context.closePath();
        }
        if (model.fillInShapeWithBackground) {
            context.fillStyle = model.backgroundColor;
            context.fill();
        }
        context.stroke();
    }
}
