export class WaterfallPlotAttributes {
    gradient: string[];
    span: number;
    graphBorderColor: string;

    marginLeft = 0;
    marginBottom = 30;
    marginTop = 5;

    // outer refers to the whole graph including axes
    // inner refers to the inside group that needs a different size, excluding the axes, to get the correct scale
    innerGraphWidth: number;
    innerGraphHeight: number;
    outerGraphWidth: number;
    outerGraphHeight: number;
    graphId: string;

    constructor() {
        this.gradient = ['#5900ff', '#0026ff', '#00fff2', '#00ff2a', '#fffa00', '#ff9900', '#ff0000'];
    }
}
