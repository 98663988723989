import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { EntityOperation } from 'app/enums/entity-operation';
import { EntityType } from 'app/enums/entity-type';
import { Carrier } from 'app/models';
import { AnalysisParams } from 'app/models/analysis-params';
import { EntityDef } from 'app/models/entity-def';

import { AppUtilities } from '../../services/app-utilities';
import { DataViewDemoService } from '../../services/data-view-demo-service';

@Component({
  selector: 'analysis-selection-bar',
  templateUrl: './analysis-selection-bar.component.html',
  styleUrls: ['./analysis-selection-bar.component.css']
})

export class AnalysisSelectionBarComponent implements OnDestroy, AfterViewInit {
  @Output() StartAnalysis = new EventEmitter<AnalysisParams>();
  @Output() StopAnalysis = new EventEmitter<null>();
  @Output() ConfigPressed = new EventEmitter<null>();
  @Input() showConfig = false;
  analysisBLabel = 'Start Analysis';

  selectedStartDate: Date;
  selectedEndDate = new Date(Date.now());

  availableSatellites = new Array<EntityDef>();
  availableTransponders = new Array<EntityDef>();
  availableCarriers = new Array<Carrier>();

  chosenSatellite: EntityDef;
  chosenTransponder: EntityDef;
  chosenCarrier: EntityDef = new EntityDef();

  carriersLoading: boolean;
  analysisRunning = false;
  loadingSelectionData = false;
  transponderLoading = false;
  satelliteLoading = false;
  loadLastTraces = true;
  liveUpdate: boolean;
  calendarVisible = true;

  startMinDate = new Date(2000, 0, 1, 0, 0, 0, 0);
  endMinDate = new Date(2000, 0, 1, 0, 0, 0, 0);
  maxDate = new Date(Date.now());

  defaultTimeEnd = new Date(2000, 0, 1, 23, 59, 59, 0);
  defaultTimeStart = new Date(2000, 0, 1, 0, 0, 0, 0);

  timestart = new Date(2000, 0, 1, 0, 0, 0, 0);
  timeend = new Date(2000, 0, 1, 23, 59, 59, 0);

  traceCountLimit = 10000;
  lastHoursOfTraces = 24;
  dataModelNeedsUpdate = false;

  constructor(private traceDataService: DataViewDemoService, private utilityService: AppUtilities) {
  }

  ngAfterViewInit() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      this.traceCountLimit = 1000;
    }
  }

  toggleLiveUpdate(checked: boolean) {
    this.liveUpdate = checked;
    if (this.liveUpdate) {
      this.traceCountLimit = 1;
    } else {
      this.traceCountLimit = 1000;
    }
    this.dataModelNeedsUpdate = true;
  }

  satelliteChanged() {
    this.transponderLoading = true;
    this.chosenTransponder = null;
    this.chosenCarrier = null;
    this.getTranspondersForSatellite();
    this.dataModelNeedsUpdate = false;
  }

  private getTranspondersForSatellite() {
    const data = {} as any;
    data.satelliteId = this.chosenSatellite.identifier;
    const sub = this.traceDataService.performEntitiesOperation(EntityType.Transponder, EntityOperation.GetList, data)
      .subscribe((results) => {
        if (results.success) {
          this.availableTransponders = JSON.parse(results.data);
          if (this.chosenTransponder !== null) {
            const idx = this.availableTransponders.findIndex((xpdr) => {
              return xpdr.identifier == this.chosenTransponder.identifier;
            });
            if (idx > -1) {
              this.chosenTransponder = this.availableTransponders[idx];
            }
            this.getCarriersForTransponder();
          }
        } else {
          this.utilityService.showError('Error Retrieving Transponders for Satellite', results.responseMsg);
        }
        this.transponderLoading = false;
      }, (error) => {
        this.transponderLoading = false;
        this.utilityService.showError('Error Retrieving Transponders for Satellite', error.message);
      });
  }
  refreshSatellites() {
    this.getSatellites();
  }
  private setSelectedURLTransponder(transponderId) {
    let xpdr = new EntityDef();
    xpdr.name = transponderId;
    xpdr.identifier = transponderId;
    this.chosenTransponder = xpdr;
    let data = {} as any;
    data.satelliteId = this.chosenSatellite.identifier;
    let sub = this.traceDataService.performEntitiesOperation(EntityType.Transponder, EntityOperation.GetList, data).subscribe((results) => {
      if (results.success) {
        this.availableTransponders = JSON.parse(results.data);
        if (this.chosenTransponder != null) {
          let idx = this.availableTransponders.findIndex((xpdr) => {
            return xpdr.identifier.includes(this.chosenTransponder.identifier);
          });
          if (idx > -1)
            this.chosenTransponder = this.availableTransponders[idx];
          this.startAnalysis();
        }
      } else {
        this.utilityService.showError('Error Retrieving Transponders for Satellite', results.responseMsg);
      }
      this.transponderLoading = false;
    }, (error) => {
      this.transponderLoading = false;
      this.utilityService.showError('Error Retrieving Transponders for Satellite', error.message);
    });
  }
  private setSelectedURLCarrier(carrierId) {
    if (carrierId != null) {
      let car = new EntityDef();
      car.name = carrierId;
      car.identifier = carrierId;
      this.chosenCarrier = car;
      this.availableCarriers = [this.chosenCarrier as Carrier];
    }
  }
  private setSelectedURLSatelliteById(satId) {
    let sat = new EntityDef();
    sat.name = satId;
    sat.identifier = satId;
    this.chosenSatellite = sat;
    let sub = this.traceDataService.performEntitiesOperation(EntityType.Satellite, EntityOperation.GetList).subscribe((results) => {
      if (results.success) {
        this.setSatellitesFromResponse(results.data);
      } else {
        this.utilityService.showError('Error Retrieving Satellites', results.responseMsg);
      }
      sub.unsubscribe();
      this.satelliteLoading = false;
    }, (error) => {
      sub.unsubscribe();
      this.satelliteLoading = false;
      this.utilityService.showError('Error Retrieving Satellites', error.message);
    });
  }

  private setSelectedURLTimestamp(timestamp) {
    if (timestamp != null) {
      this.selectedEndDate = new Date(+timestamp);
      this.selectedStartDate = new Date(+timestamp);
    } else {
      this.loadLastTraces = true;
    }
  }
  configPressed() {
    this.ConfigPressed.next();
  }
  transponderChanged() {
    this.chosenCarrier = null;
    this.getCarriersForTransponder();
  }
  startDateSelected() {
    this.endMinDate = new Date(this.selectedStartDate.getTime());
    if (this.selectedEndDate < this.selectedStartDate)
      this.selectedEndDate = new Date(this.selectedStartDate.getTime());
  }
  private getCarriersForTransponder() {
    this.carriersLoading = true;
    let data = {} as any;
    data.satelliteId = this.chosenSatellite.identifier;
    data.transponderId = this.chosenTransponder.identifier;
    let sub = this.traceDataService.performEntitiesOperation(EntityType.Carrier, EntityOperation.GetList, data).subscribe((results) => {
      if (results.success) {
        this.setCarriersFromResponse(results.data);
      } else {
        this.utilityService.showError('Error Retrieving Carriers for Transponder', results.responseMsg);
      }
      this.carriersLoading = false;
      // sub.unsubscribe();
    }, (error) => {
      sub.unsubscribe();
      this.carriersLoading = false;
      this.utilityService.showError('Error Retrieving Carriers for Transponder', error.message);
    });
  }
  private setCarriersFromResponse(carriers) {
    this.availableCarriers = JSON.parse(carriers);
    if (this.chosenCarrier != null) {
      let idx = this.availableCarriers.findIndex((car) => {
        return car.identifier == this.chosenCarrier.identifier;
      });
      if (idx > -1) {
        this.chosenCarrier = this.availableCarriers[idx];
      }
    }
  }

  getSatellites() {
    this.satelliteLoading = true;
    const sub = this.traceDataService.performEntitiesOperation(EntityType.Satellite, EntityOperation.GetList).subscribe(results => {
      if (results.success) {
        this.setSatellitesFromResponse(results.data);
      } else {
        this.utilityService.showError('Error Retrieving Satellites', results.responseMsg);
      }
      if (sub) {
        sub.unsubscribe();
      }
      this.satelliteLoading = false;
    }, (error) => {
      if (sub) {
        sub.unsubscribe();
      }
      this.satelliteLoading = false;
      this.utilityService.showError('Error Retrieving Satellites', error.message);
    });

  }

  private setSatellitesFromResponse(satellites) {
    this.availableSatellites = JSON.parse(satellites);
    if (this.chosenSatellite != null) {
      const idx = this.availableSatellites.findIndex((sat) => {
        return sat.identifier === this.chosenSatellite.identifier;
      });
      if (idx > -1) {
        this.chosenSatellite = this.availableSatellites[idx];
      }
      this.getTranspondersForSatellite();
    }
  }
  private loadLastHours() {
    this.selectedEndDate = new Date(Date.now());
    this.selectedStartDate = new Date(Date.now());
    this.selectedStartDate.setHours(this.selectedEndDate.getHours() - this.lastHoursOfTraces, this.selectedEndDate.getMinutes(), this.selectedEndDate.getSeconds(), 0);
  }
  startAnalysisWithParams(params: AnalysisParams) {
    this.setSelectedURLSatelliteById(params.satelliteId);
    this.setSelectedURLTransponder(params.transponderId);
    this.setSelectedURLCarrier(params.carrierId);
    this.setSelectedURLTimestamp(params.stopInMs);

  }
  startAnalysis() {
    this.loadingSelectionData = true;
    this.analysisRunning = true;
    if (this.loadLastTraces) {
      this.loadLastHours();
    }
    if (this.userHasSelectedParams()) {
      this.constructAnalysisParamsAndEmit();
    } else {
      this.utilityService.showError('Invalid selection data', 'Please provide valid selections before beginning analysis');
      this.stopAnalysis();
    }
  }
  private userHasSelectedParams() {
    return ((this.selectedStartDate != null && this.selectedEndDate != null) || this.loadLastTraces) && this.chosenSatellite != null && this.chosenTransponder != null;
  }
  private constructAnalysisParamsAndEmit() {
    let interval = this.getStartStopTime();
    if (!this.loadLastTraces)
      interval = this.setHoursOnInterval(interval);
    let analysisSettings = new AnalysisParams();
    analysisSettings.dataModelNeedsUpdate = this.dataModelNeedsUpdate;
    analysisSettings.satelliteId = this.chosenSatellite.identifier;
    analysisSettings.transponderId = this.chosenTransponder.identifier;
    analysisSettings.carrierId = this.chosenCarrier != null ? this.chosenCarrier.identifier : null;
    analysisSettings.limit = this.traceCountLimit;
    analysisSettings.liveUpdate = this.liveUpdate;
    analysisSettings.loadRecent = this.loadLastTraces;
    analysisSettings.startInMs = interval[0];
    analysisSettings.stopInMs = interval[1];
    this.StartAnalysis.next(analysisSettings);
  }
  private setHoursOnInterval(interval: number[]): number[] {
    let start = interval[0];
    let stop = interval[1];
    let startDate;
    if (start != 0) {
      startDate = new Date(start);
      startDate.setHours(startDate.getHours() - (new Date(start)).getTimezoneOffset() / 60);
    }
    let startTimeAdjusted = start != 0 ? startDate.getTime() : 0;
    let stopDate = new Date(stop);
    stopDate.setHours(stopDate.getHours() - (new Date(stop)).getTimezoneOffset() / 60);
    let stopTimeAdjusted = stopDate.getTime();
    return [startTimeAdjusted, stopTimeAdjusted];
  }
  finishedLoading() {
    this.loadingSelectionData = false;
  }
  stopAnalysis() {
    this.analysisBLabel = 'Start Analysis';
    this.analysisRunning = false;
    this.toggleLiveUpdate(false);
    this.loadingSelectionData = false;
    this.dataModelNeedsUpdate = true;
    this.StopAnalysis.next();
  }

  clearSelections() {
    this.chosenCarrier = null;
    this.chosenSatellite = null;
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.chosenTransponder = null;
    this.timestart = new Date(this.defaultTimeStart.getTime());
    this.timeend = new Date(this.defaultTimeEnd.getTime());
  }

  toggleAnalysis() {
    if (this.analysisRunning) {
      this.analysisBLabel = 'Start Analysis';
      this.stopAnalysis();

    } else {
      this.startAnalysis();
      this.analysisBLabel = 'Stop Analysis';
    }
  }

  private getStartStopTime(): number[] {
    if (this.selectedEndDate != null) {
      let startInMs = 0;
      let stopInMs = 0;
      if (this.selectedStartDate != null) {
        //set start time to chosen date and set hours/mins based on chosen time
        this.makeSureTimesAreNotNull();
        if (!this.loadLastTraces)
          this.setDateHoursBySelection();

        startInMs = this.getStartInMsFromDate();
        stopInMs = this.getStopInMsFromDate();
      } else {
        startInMs = 0;
        stopInMs = Date.now();
      }

      return [startInMs, stopInMs];
    } else {
      return [0, 0];
    }
  }
  private getStopInMsFromDate() {
    let selectedEndDate = new Date(this.selectedEndDate.getTime());
    return Date.UTC(selectedEndDate.getUTCFullYear(), selectedEndDate.getUTCMonth(),
      selectedEndDate.getUTCDate(), selectedEndDate.getUTCHours(), selectedEndDate.getUTCMinutes(), selectedEndDate.getUTCSeconds());
  }
  private getStartInMsFromDate() {
    let selectedStartDate = new Date(this.selectedStartDate.getTime());
    let chosenDate = Date.UTC(selectedStartDate.getUTCFullYear(), selectedStartDate.getUTCMonth(),
      selectedStartDate.getUTCDate(), selectedStartDate.getUTCHours(), selectedStartDate.getUTCMinutes(), selectedStartDate.getUTCSeconds());
    chosenDate = Date.UTC(selectedStartDate.getUTCFullYear(), selectedStartDate.getUTCMonth(),
      selectedStartDate.getUTCDate(), selectedStartDate.getUTCHours(), selectedStartDate.getUTCMinutes(), selectedStartDate.getUTCSeconds());
    return chosenDate;
  }
  private setDateHoursBySelection() {
    this.selectedStartDate.setHours(this.timestart.getHours(), this.timestart.getMinutes(), this.timestart.getSeconds(), 0);
    this.selectedEndDate.setHours(this.timeend.getHours(), this.timeend.getMinutes(), this.timeend.getSeconds(), 0);
  }
  private makeSureTimesAreNotNull() {
    if (this.timestart == null)
      this.timestart = new Date(2000, 0, 1, 0, 0, 0, 0);
    if (this.timeend == null)
      this.timeend = new Date(2000, 0, 1, 23, 59, 59, 0);
  }
  ngOnDestroy() {
    this.availableSatellites = [];
    this.availableTransponders = [];
    this.availableCarriers = [];
  }
}
