import { D3 } from 'd3-ng2-service';

export class SvgLegend {
  private d3: D3;
  private id = 'legend';
  private legendElem: any;
  private colorsElem: any;
  private keysElem: any;
  private colors: string[] = new Array<string>();
  private keys: string[] = new Array<string>();
  private top: number;
  private left: number;

  constructor(d3: D3) {
    this.d3 = d3;
  }

  initialize(id: string, top: number, left: number) {
    this.id = id;
    this.top = top;
    this.left = left;
  }

  addElementToSvg(svg: any, top: number, left: number) {
    this.top = top;
    this.left = left;
    if (svg.select('#' + this.id).empty()) {
      this.legendElem = svg.append('g').attr('id', this.id)
        .attr('class', 'plot-legend');
    } else {
      this.legendElem = svg.select('#' + this.id);
    }
    if (this.keys.length > 0) {
      this.addKeysToSvg(svg);
    }
    if (this.colors.length > 0) {
      this.addColorsToSvg(svg);
    }
  }

  addItem(name: string, color: string) {
    this.keys.push(name);
    this.colors.push(color);
  }

  update(svg) {
    this.reset();
    this.addElementToSvg(svg, this.top, this.left);
  }

  itemAdded(name: string) {
    return this.keys.findIndex(key => key === name) !== -1;
  }

  show() {
    if (this.legendElem != null) {
      this.legendElem.attr('display', null);
    }
  }

  hide() {
    if (this.legendElem != null) {
      this.legendElem.attr('display', 'none');
    }
  }

  reset() {
    if (this.colorsElem != null) {
      this.colorsElem.remove();
    }
    if (this.keysElem != null) {
      this.keysElem.remove();
    }
    if (this.legendElem != null) {
      this.legendElem.remove();
    }
    this.colorsElem = null;
    this.keysElem = null;
    this.keys = [];
    this.colors = [];
  }

  private addColorsToSvg(svg) {
    const legendColors = this.d3.scaleOrdinal().range(this.colors);
    this.colorsElem = this.legendElem.append('rect')
      .attr('x', this.left)
      .attr('y', this.top - 9.5)
      .attr('width', 19)
      .attr('height', 19)
      .attr('fill', legendColors);
  }

  private addKeysToSvg(svg) {
    this.legendElem = this.legendElem.selectAll('g')
      .data(this.keys)
      .enter().append('g')
      .attr('transform', function (d, i) { return 'translate(0,' + i * 20 + ')'; });

    this.keysElem = this.legendElem.append('text')
      .attr('x', this.left - 25)
      .attr('y', this.top)
      .attr('class', 'plot-legend-text')
      .attr('dy', '0.32em')
      .text(function (d) { return d; });
  }
}
