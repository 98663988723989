import { D3 } from 'd3-ng2-service';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs/Rx';
import {
    CarrierRegion
} from '../pf-specan/data-models/pf-specan-carrier-region';
import { SpectrumAnalyzerSeries } from '../pf-specan/data-models/pf-specan-series';
import { SpectrumAnalyzerThumbnailConfiguration } from './configuration/pf-specan-thumbnail-config';
import { SpectrumAnalyzerDataParameters } from '../pf-specan/configuration/pf-specan-data-params';
import { SpectrumAnalyzerThumbnailRenderer } from './render/pf-specan-thumbnail-renderer';
import { SpectrumAnalyzerThumbnailAxes } from './axes/pf-specan-thumbnail-axes';
import { SpectrumAnalyzerXY } from '../pf-specan/data-models/pf-specan-xy';
import { SpectrumAnalyzerCarrierRegionElement } from '../pf-specan/graph-elements/pf-specan-carrier-region-element';
import { SpectrumAnalyzerTemplateVariables } from '../pf-specan/pf-specan-html-template-vars';
import { SpectrumAnalyzerUtility } from '../pf-specan/utilities/pf-specan-utility';
import { ChartScale, XYPoint, SvgTooltip, SvgLegend, SvgFocusTracking, SvgZoom, SvgTooltipConfig } from '../../chart/index';
import { Unit, UnitOptions, UnitInputComponent, UnitValidator, UnitConversion } from '../../unit/index';

export class SpectrumAnalyzerThumbnail {
  config: SpectrumAnalyzerThumbnailConfiguration;
  dataParams: SpectrumAnalyzerDataParameters;
  dataModels: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();
  carrierRegions: CarrierRegion[] = new Array<CarrierRegion>();

  // this is our connection to the enclosing component. Rather than directly exposing elements
  // of the renderer and axes to the html template,
  // we will use this object to set the variables accordingly and use them in this component and in the html
  htmlTemplateVars: SpectrumAnalyzerTemplateVariables;

  // the parent specan.component needs access to these
  graphContainer: any;
  
  gridDimensions: any;

  // private graph elements
  private axes: SpectrumAnalyzerThumbnailAxes;
  private renderer: SpectrumAnalyzerThumbnailRenderer;

  private context: any;
  private canvas: any;

  //  this variable tells us whether we have already initialized the data that needs to be captured on the first render
  private graphDataInitialized: boolean;
  // this variable is determined by whether min/max x/y coords were given to the graph
  // we want to use these for rendering until the user performs an action that changes the min/max y of the graph
  private usingMinMaxXY: boolean;

  // hold the current graph scales and domains
  private scaleY: any;
  private scaleX: any;
  private transformedScaleY: any;
  private transformedScaleX: any;
  private domainX: any;
  private maxY: number;
  private minY: number;
  private maxX: number;
  private minX: number;

  private borderColorSub: Subscription;

  //  these should only be used as a last resort. Colors should be provided as properties on each dataset object
  private extraColors = ['#731b36', '#f4bf42', '#41b8f4', '#6bd6ad', '#00E5EE', '#00868B', '#97FFFF',
    '#7FFFD4', '#FF4040', '#800000', '#C67171'];

  constructor(private d3: D3) {
    this.renderer = new SpectrumAnalyzerThumbnailRenderer(this.d3);
    this.axes = new SpectrumAnalyzerThumbnailAxes(this.d3);
  }

  initialize() {
    
    this.initRenderer();
    this.initAxisVars();

    this.borderColorSub = this.config.borderColorChanged.subscribe((value) => {
      //this.renderer.changeBorderColor(this.svgElementsBehindCanvasContainer, value);
    });

    // set initial scales
    this.scaleY = this.d3.scaleLinear().domain([0, this.renderer.attributes.outerGraphHeight])
      .range([this.renderer.attributes.innerGraphHeight, 0]);
    this.scaleX = this.d3.scaleLinear().domain([0, this.renderer.attributes.outerGraphWidth])
      .range([0, this.renderer.attributes.innerGraphWidth]);
    this.appendCanvas();
    this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;

    let timeout = setTimeout(() => {
      this.createAxes();
      this.htmlTemplateVars.specanContainerStyle = { 'height': this.renderer.attributes.outerGraphHeight + 'px' };
      clearTimeout(timeout);
    });

  }

  destroy() {
    if (this.borderColorSub != null) {
      this.borderColorSub.unsubscribe();
    }
  }

  private calculateAutoscaleScalesAndSet() {
    let newDomainY = this.calculateDomainY();
    if (this.dataParams.hasDbPerDivBeenSet()) {
      if (this.transformedScaleY != null) {
        newDomainY = this.transformedScaleY.domain();
      }
      newDomainY = this.determineNewDomainForDbDiv(newDomainY, this.config.numberOfYTicks);
    } 
    this.setScaling(newDomainY, this.calculateDomainX());
  }
  
  autoscaleGraph() {
    if (this.graphDataInitialized) {
      this.calculateAutoscaleScalesAndSet();
      this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
    }
  }

  resize() {
    this.renderer.resize();
    this.htmlTemplateVars.specanContainerStyle = { 'height': (this.renderer.attributes.outerGraphHeight) + 'px' };
    this.calculateAutoscaleScalesAndSet();
    this.refreshGraph();
  }

  // can be called if graph attributes have been changed from a parent component (i.e. tooltip visibility)
  refreshGraph() {
    this.removeSvg();
    this.appendCanvas();
    this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;
    this.initGraphScalingAndElements();
    this.resetCanvas();
    this.createAxes();
    this.renderGraphData();
    this.addCarrierRegions();
  }

  render(): void {
    this.calculateAutoscaleScalesAndSet();
    this.createDataModels();
    this.resetCanvas();
    this.createAxes();
    this.renderGraphData();
  }

  removeSvg() {
    if (this.graphContainer != null) {
      this.d3.select(this.graphContainer.nativeElement).selectAll('svg').remove();
    }
  }
  zoomToExtent() {
    this.usingMinMaxXY = false;
    this.setScaling(this.calculateDomainY(), this.domainX);
    this.zoomToNewScale(this.scaleX, this.scaleY);
  }
  zoomToDomain(xDomain, yDomain) {
    if (xDomain == null) {
      xDomain = this.domainX;
    }
    if (yDomain == null) {
      yDomain = this.calculateDomainY();
    }
    this.setScaling(yDomain, xDomain);
    this.zoomToNewScale(this.transformedScaleX, this.transformedScaleY);
  }

  getOuterGraphHeight(): number {
    return this.renderer.attributes.outerGraphHeight;
  }
  getOuterGraphWidth(): number {
    return this.renderer.attributes.outerGraphWidth;
  }
  getInnerGraphHeight(): number {
    return this.renderer.attributes.innerGraphHeight;
  }
  getInnerGraphWidth(): number {
    return this.renderer.attributes.innerGraphWidth;
  }
  
  getCurrentDomainX() {
    return this.transformedScaleX.domain();
  }
  getCurrentDomainY() {
    return this.transformedScaleY.domain();
  }
  zoomToCoordinates(xCoords, yCoords) {
    let newXDomain, newYDomain;
    if (xCoords != null) {
      newXDomain = xCoords.map(this.transformedScaleX.invert, this.transformedScaleX);
    }
    if (yCoords != null) {
      newYDomain = yCoords.map(this.transformedScaleY.invert, this.transformedScaleY);
    }
    this.zoomToDomain(newXDomain, newYDomain);
  }

  setScaling(domainY, domainX = null) {
    if (this.htmlTemplateVars.graphInitialized) {
      if (domainX == null) {
        domainX = this.domainX;
      }
      if (domainY == null) {
        domainY = this.calculateDomainY();
      }
    } else {
      domainY = this.scaleY.domain();
      domainX = this.scaleX.domain();
    }
    domainY = this.adjustDomainByPercentage(this.config.yPercentageBuffer, domainY);
    this.scaleY = this.d3.scaleLinear().domain(domainY).range([this.renderer.attributes.outerGraphHeight, 0]);
    this.scaleX = this.d3.scaleLinear().domain(domainX).range([0, this.renderer.attributes.outerGraphWidth]);
    this.transformedScaleY = this.d3.scaleLinear().domain(domainY).range([this.renderer.attributes.innerGraphHeight, 0]);
    this.transformedScaleX = this.d3.scaleLinear().domain(domainX).range([0, this.renderer.attributes.innerGraphWidth]);
  }
  private adjustDomainByPercentage(pct, domain) {
    const margin = pct * Math.abs(domain[1] - domain[0]);
    domain[0] -= margin;
    domain[1] += margin;
    return domain;
  }
  //  the data model needs to be constructed by taking the input data (x coordinates) and creating y components
  //  for them using the starting frequency and successively adding the step value to construct the graph
  private createDataModels() {
    for (let i = 0; i < this.dataModels.length; i++) {
      //  pass in 'true' for first data set, otherwise false
      //  we only need to keep track of maxes and mins for the main data set, which is the first one in the list
      if ((this.dataModels[i].continuousUpdate || this.dataModels[i].needsUpdate) && this.dataModels[i].visible) {
        this.dataModels[i].createDataModel();
      }
      this.dataModels[i].needsUpdate = false;
      
    }
  }
  private zoomToNewScale(xScale, yScale) {
    this.resetCanvas();
    this.renderGraphData();
    this.createAxes();
    this.addCarrierRegions();
  }
  private renderGraphData() {
    //  if we have data models, display the data
    if (this.dataModels.length > 0 && this.dataModels[0].dataSetAt(0).length() > 0) {

      //  initialize graph UI elements
      if (!this.htmlTemplateVars.graphInitialized) {
        this.initGraphScalingAndElements();
      }

      //  initialize data-related graph elements
      if (!this.graphDataInitialized) {
        this.initializeGraphData();
      }

      this.addCarrierRegions();
      this.addLineElements();
    } 
  }
  private determineNewDomainForDbDiv(domain, ticks) {
    const domainRange = Math.abs(domain[1] - domain[0]);
    const newDomainRange = ticks * this.dataParams.dbPerDiv;
    const difference = newDomainRange - domainRange;
    const diffToAdjustDomainBy = difference / 2;
    domain[0] -= diffToAdjustDomainBy;
    domain[1] += diffToAdjustDomainBy;
    return domain;
  }
  private initGraphScalingAndElements() {
    this.renderer.clearCanvas(this.context);
    this.transformedScaleX = this.scaleX;
    this.transformedScaleY = this.scaleY;
    this.htmlTemplateVars.graphInitialized = true;
  }
  private addCarrierRegions() {
    for (let i = 0; i < this.carrierRegions.length; i++) {
      const cr = this.carrierRegions[i];
      const currentFreqWindow = this.transformedScaleX.domain();
      if ((cr.startFreq > currentFreqWindow[0] && cr.startFreq < currentFreqWindow[1]) ||
        (cr.stopFreq < currentFreqWindow[1] && cr.stopFreq > currentFreqWindow[0])) {
      
        let crStartX = this.transformedScaleX(cr.startFreq);
        let crStopX = this.transformedScaleX(cr.stopFreq);
        if (crStartX < 0) {
          crStartX = 0;
        }
        if (crStopX > this.renderer.attributes.innerGraphWidth) {
          crStopX = this.renderer.attributes.innerGraphWidth;
        }
        this.context.beginPath();
        this.context.strokeStyle = cr.color;
        this.context.moveTo(crStartX, 0);
        this.context.lineTo(crStartX, this.renderer.attributes.innerGraphHeight);
        this.context.lineTo(crStopX, this.renderer.attributes.innerGraphHeight);
        this.context.lineTo(crStopX, 0);
        this.context.closePath();
        this.context.fillStyle = cr.color;
        this.context.fill();
      }
    }
  }

  private initializeGraphData() {
    this.domainX = this.calculateDomainX();
    if (this.dataParams.axisMaxX != null && this.dataParams.axisMaxY != null &&
      this.dataParams.axisMinX != null && this.dataParams.axisMinY != null) {
      this.usingMinMaxXY = true;
    }
    this.maxY = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).y;
    this.minY = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).y;
    this.maxX = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).x;
    this.minX = this.dataModels[0].getDataSetByName('main').pointAtIndex(0).x;
    if (this.domainX[0] != null && this.domainX[1] != null) {
      //  call this initially to apply the span given by the user
      this.setScaling(this.calculateDomainY(), this.domainX);
      this.createAxes();
      this.addCarrierRegions();
      this.graphDataInitialized = true;
    }
  }

  private createAxes() {
    this.axes.create(this.context, this.scaleX, this.scaleY, this.dataParams.dbPerDiv, this.config.graticuleColor, this.config.borderColor);
   // this.renderer.addGraphBorder(this.svgElementsBehindCanvasContainer);
  }
  private calculateDomainY() {
    if (this.dataModels.length > 0 && this.dataModels[0].dataSetAt(0).length() > 0) {
      if (this.usingMinMaxXY && this.dataParams.axisMaxY != null && this.dataParams.axisMinY != null) {
        return [this.dataParams.axisMinY, this.dataParams.axisMaxY];
      } else {
        //  set initial values to first data model
        const firstY = this.dataModels[0].getRange();
        let min = firstY[0];
        let max = firstY[1];

        //  iterate through each data model, call the method to set its min/max, compare to global mix/max
        for (let i = 1; i < this.dataModels.length; i++) {
          const nextY = this.dataModels[i].getRange();
          if (nextY[0] != null && nextY[0] < min) {
            min = nextY[0];
          }
          if (nextY[1] != null && nextY[1] > max) {
            max = nextY[1];
          }
        }
        this.setDomainY(min, max);
        return [min, max];
      }
    } else {
      return this.scaleY.domain();
    }
  }
  private calculateDomainX() {
    if (this.usingMinMaxXY && this.dataParams.axisMaxX != null && this.dataParams.axisMinX != null) {
      return [this.dataParams.axisMinX, this.dataParams.axisMaxX];
    } else if (this.dataModels.length > 0 && this.dataModels[0].dataSetAt(0).length() > 0) {
      let min: number;
      let max: number;
      const minMax = this.dataModels[0].getDomain();
      min = minMax[0];
      max = minMax[1];

      //  keep track of these in case we run out of data at any point
      if (this.maxX < max || this.maxX == null) {
        this.maxX = max;
      }
      if (this.minX > min || this.minX == null) {
        this.minX = min;
      }
      //  if these two are equal, we've run out of data
      if (min === max) {
        return [this.minX, this.maxX];
      }
      return [min, max];
    } else {
      return this.scaleX.domain();
    }
  }

  private setDomainY(max: number, min: number) {
    //  reset global values
    if (this.minY > min) {
      this.minY = min;
    }
    if (this.maxY < max) {
      this.maxY = max;
    }
  }

  private translateSvgToCanvasCoordinateX(value) {
    const svgRect = this.graphContainer.nativeElement.querySelector('svg').getBoundingClientRect();
    value -= svgRect.left;
    return value;
  }

  private initializeDataSet(dataSet, i) {
    let color = '';
    let name = '';
    if (dataSet.legendName != null) {
      name = dataSet.legendName;
    }
    if (dataSet.graphColor != null) {
      color = dataSet.graphColor;
    } else {
      dataSet.graphColor = this.extraColors[0];
      this.extraColors.splice(0, 1);
      color = dataSet.graphColor;
    }
    if (dataSet.pathId == null) {
      dataSet.pathId = this.htmlTemplateVars.graphId + '-line-' + dataSet.legendName.replace(/[^A-Z0-9]/ig, '') + UUID.UUID();
    }
    dataSet.graphInfoInitialized = true;
  }

  private getLengthOfLargestDataSet() {
    let largest = 0;
    for (let i = 0; i < this.dataModels.length; i++) {
      if (this.dataModels[i].length() > largest) {
        largest = this.dataModels[i].length();
      }
    }
    return largest;
  }
  // initialization of the renderer. Should only be done once
  private initRenderer() {
    
    if (this.config.borderColor != null) {
      this.renderer.attributes.graphBorderColor = this.config.borderColor;
    }
    this.renderer.attributes.graphId = this.htmlTemplateVars.graphId;
    // initializes renderer variables and properly sizes graph
    this.renderer.resize();

  }

  // initialization of the axes. Should only be done once
  private initAxisVars() {
    // initialize axes
    this.axes.init(this.htmlTemplateVars.graphId, this.config.numberOfXTicks, this.config.numberOfYTicks);
  }

  private resetCanvas() {
    this.renderer.clearCanvas(this.context);
  }

  private addLineElements() {
  
    //  update all lines
    for (let i = 0; i < this.dataModels.length; i++) {
      if (!this.dataModels[i].graphInfoInitialized) {
        this.initializeDataSet(this.dataModels[i], i);
      }
      //  pass in the data points and the id for the path
      this.renderer.appendLineToCanvas(this.context, this.dataModels[i], this.transformedScaleX, this.transformedScaleY);
    }
    
  }
  private appendCanvas() {
    this.d3.select(this.graphContainer.nativeElement).select('canvas').remove();
    this.canvas = this.renderer.appendCanvas(this.canvas, this.graphContainer.nativeElement);
    this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;

  }
}
