﻿import { Axis, D3, Selection } from 'd3-ng2-service';
import { UUID } from 'angular2-uuid';
import { CarrierRegion } from '../../index';

export class SpectrumAnalyzerCarrierRegionElement {
    private d3: D3;
    private id = 'carrierRegion' + UUID.UUID();
    private regionElem: any;
    private x0: number;
    private width: number;
    private height: number;
    private name: string;
    private color: string;
    private textHeight = 20;

    constructor(d3: D3) {
        this.d3 = d3;
    }
    initialize(id: string, x0: number, x1: number, height: number, name: string, color: string) {
        this.id = id;
        this.x0 = x0;
        this.width = Math.abs(x1 - x0);
        this.height = height;
        this.name = name;
        this.color = color;
    }
    addElementToSvg(svg: any) {
        this.regionElem = svg.append('g').attr('class', 'pf-specan-carrier-region');
        this.regionElem.append('rect')
            .style('opacity', 0.2)
            .style('fill', this.color)
            .attr('x', this.x0)
            .attr('width', this.width)
            .attr('y', 0)
            .attr('height', this.height - this.textHeight);
        this.regionElem.append('text').attr('y', this.height - this.textHeight)
            .attr('x', this.x0 + (this.width / 2 - (this.name.length / 2)))
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .attr('id', this.id + '-label')
            .attr('class', 'pf-graph-label')
            .text(this.name);
    }

    hide() {
        if (this.regionElem != null) {
            this.regionElem.attr('display', 'none');
        }
    }
    reset() {
        this.regionElem = null;
    }
}