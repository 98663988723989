import { XYPoint } from '../../../chart/index';
import { EventEmitter, Output } from '@angular/core';
// this class handles the visual settings of the watefall, but nothing data related. That is handled by the pf-waterfall-data-params class
export class WaterfallPlotConfiguration {
  disableGraphInteraction: boolean;
  showTooltip = true;
  showYAxisLabels = true;
  showXAxisLabels = true;
  forwardFillValues = true;
  playTracesTopToBottom = false;
  showMouseCoordinates = true;
  allowZoom = true;
  roundXToDecimal = 6;
  showLoadingOverlay = false;
  xAxisTitle = 'Frequency';
  yAxisTitle = 'Timestamp (UTC) =>';
  gradientColors = ['#5900ff', '#0026ff', '#00fff2', '#00ff2a', '#fffa00', '#ff9900', '#ff0000'];
  serverSideDataZooming = true;
  showOnlyGraphWindow = false;
  xAxisTickFormatFunc: Function;
  @Output() graphVisibilityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _graphVisible = true;
  yAxisTickFormatFunc: Function = function (d: number) {
    return new Date(d).toLocaleString();
  };


  tooltipValueFunc: Function = (position: XYPoint): string[] => {
    const values = new Array<string>();
    values.push(this.xAxisTickFormatFunc(position.x));
    values.push(this.yAxisTickFormatFunc(position.y));
    return values;
  }

  set graphVisible(value: boolean) {
    this._graphVisible = value;
    this.graphVisibilityChanged.next(value);
  }
  get graphVisible() {
    return this._graphVisible;
  }

}
