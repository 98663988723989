import { Subject } from 'rxjs/Rx';

import { XYPoint } from '../../../chart';

// this class handles the visual settings of the specan, but nothing data related. That is handled by the pf-specan-data-params class
export class SpectrumAnalyzerConfiguration {
  graphVisibilityChanged: Subject<boolean> = new Subject<boolean>();
  borderColorChanged: Subject<string> = new Subject<string>();
  numberOfTicksChanged: Subject<number> = new Subject<number>();
  allowZoom = true;
  showFocusTracking = false;
  showTooltip = true;
  showGraticule = true;
  showSpan = true;
  showDbPerDiv = true;
  showConfigButton = false;
  showAutoscale = true;
  showLoadingOverlay = false;
  showMouseCoordinates = true;
  showLegend = false;
  floatingTooltip = false;
  showSampleFreq = true;
  showXAxesTickLabels = true;
  showYAxesTickLabels = true;
  showRBWandVBW = true;
  // if this is set to true, no markings will be shown. Only the graph will be shown.
  showOnlyGraphWindow = false;
  // if this is set to true, the specan becomes a normal graph with x/y axes and other helpful widgets
  displayAsNormalGraph = false;
  // option to determine whether the menu is shown that contains toggling controls for each data set
  showDataMenus = true;
  // this property is used with the legend to prefix datasets with no name
  legendPrefix: string;
  graphTitle: string;
  yAxisTitle: string;
  xAxisTickFormatFunc: Function;
  yAxisTickFormatFunc: Function;
  roundXToDecimal = 6;
  private _graphVisible = true;
  private _numberOfTicks = 10;
  private _borderColor = '#000';

  set graphVisible(value: boolean) {
    this._graphVisible = value;
    this.graphVisibilityChanged.next(value);
  }
  get graphVisible() {
    return this._graphVisible;
  }

  set borderColor(value: string) {
    this._borderColor = value;
    this.borderColorChanged.next(value);
  }

  get borderColor() {
    return this._borderColor;
  }

  set numberOfTicks(value: number) {
    this._numberOfTicks = value;
    this.numberOfTicksChanged.next(value);
  }
  get numberOfTicks(): number {
    if (this._numberOfTicks != null) {
      return this._numberOfTicks;
    } else {
      return 10;
    }
  }
  tooltipValueFunc: Function = (position: XYPoint): string[] => {
    const values = new Array<string>();
    let positionVal = position.y;
    if (this.yAxisTickFormatFunc != null) {
      positionVal = this.yAxisTickFormatFunc(position.y);
    } else {
      positionVal = positionVal.toFixed(this.roundXToDecimal) as any;
    }
    values.push(positionVal as any);
    return values;
  }
}
