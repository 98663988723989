﻿import { Axis, D3, Selection } from 'd3-ng2-service';

export class SvgZoom {
  zoomFunc: any;
  private d3: D3;
  private brush: any;
  private brushId: string;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  initialize(scrollZoomFunc, graphId) {
      if (scrollZoomFunc != null) {
          this.zoomFunc = this.d3.zoom().on('zoom', scrollZoomFunc);
      }
    this.brushId = graphId + '-brush';
  }
  appendZoom(svg, brushFunc, width, height) {
    this.brush = this.d3.brush().extent([[0, 0], [width, height]]).on('end', brushFunc);
    svg.append('g')
      .attr('class', 'brush')
      .attr('id', this.brushId)
        .call(this.brush);
    if (this.zoomFunc != null) {
        svg.call(this.zoomFunc);
    }
  }
  resetTranslate(svg) {
    this.zoomFunc.scaleTo(svg, 1);
    this.zoomFunc.translateBy(svg, 0, 0);
  }
  brushExistsOnSvg(svg) {
    if (this.brush != null && !this.brush.empty()) {
      return true;
    } else {
      return false;
    }
  }
  removeBrush(svg) {
    svg.select('#' + this.brushId).remove();
  }
  removeZoomSelectionArea(svg) {
      if (this.brush != null) {
          svg.select('#' + this.brushId).call(this.brush.move, null);
      }
  }
}