import {
  OnInit, Component, Input, ElementRef, ViewChild, Inject, InjectionToken, ViewContainerRef,
  ChangeDetectorRef, ComponentFactoryResolver
} from '@angular/core';
import { AppUtilities } from '../../services/app-utilities';
import { UserMessage, User } from '../../models/index';
import { UserService } from 'app/services/user-service';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
  user: User = new User();
  submit: any;
  loading: boolean;
  @Input() visible: boolean;
  @ViewChild('okButton') public okButton: ElementRef;
  @ViewChild('cancelButton') public cancelButton: ElementRef;
  elementRef: ElementRef;

  constructor(private userService: UserService, private utilityService: AppUtilities, private ref: ElementRef) {
    this.elementRef = ref;
  }

  open(): Promise<boolean> {
    this.visible = true;
    //focus on the first input on the dialog when it opens for convenience
    this.elementRef.nativeElement.querySelector('input').focus();
    return new Promise<boolean>(resolve => {
      if (this.cancelButton != null) {

        this.cancelButton.nativeElement.onclick = ((e: any) => {
          e.preventDefault();
          this.visible = false;
          resolve(null);
        });
      }
      if (this.okButton != null) {
        this.okButton.nativeElement.onclick = ((e: any) => {
          this.loading = true;
          e.preventDefault();
          this.userService.login(this.user.name, this.user.password).subscribe((results) => {
            this.loading = false;
            if (results.success) {
              this.visible = false;
              this.ok(resolve);
            } else {
              let msg = new UserMessage();
              msg.messageTitle = 'Login Failed';
              msg.messageType = 'error';
              msg.messageDetail = results.responseMsg;
              this.utilityService.showNotification(msg);
            }
          });

        });
      }

      // this is an event set up that fires when the user presses the enter button
      this.submit = ((e: any) => {
        if (e.keyCode == 13) {
          this.loading = true;
          this.userService.login(this.user.name, this.user.password).subscribe((results) => {
            this.loading = false;
            if (results.success) {
              this.ok(resolve);
            } else {
              let msg = new UserMessage();
              msg.messageTitle = 'Login Failed';
              msg.messageType = 'error';
              msg.messageDetail = results.responseMsg;
              this.utilityService.showNotification(msg);
            }
          });
        }
      });
    });
  }

  ok(resolve: (Object) => any) {
    this.loading = false;
    this.utilityService.currentUser.name = this.user.name;
    resolve(true);
  }
}
