import { Output, EventEmitter } from '@angular/core';
import { ScaleContinuousNumeric } from 'd3-ng2-service';
import { D3 } from 'd3-ng2-service';
import { WaterfallSeries } from '../data-models/pf-waterfall-series';
import { WaterfallData } from '../data-models/pf-waterfall-data';
import { WaterfallXY } from '../data-models/pf-waterfall-xy';
import { WaterfallAxes } from '../axes/pf-waterfall-axes';
import { AxisGridDimensions } from '../axes/pf-waterfall-axis-grid-dimensions';
import { GradientGenerator } from '../utilities/gradient-generator';

export class WaterfallGradientWidget {
  // for the parent component to attach to
  gradientChanged: Function;

  private height: number;
  private axes: WaterfallAxes;
  private width = 10;
  private d3: any;
  private gradientElem;
  private gradientLineElem;
  private id;
  private marginLeft = 20;
  private context: any;
  private svg: any;
  private canvas: any;
  private parentGradient = [];
  private yScale;
  private xScale;
  private gradientColors = ['#5900ff', '#0026ff', '#00fff2', '#00ff2a', '#fffa00', '#ff9900', '#ff0000'];
  private gradientGrayscaleColors = ['#ffffff', '#848484', '#000000'];
  private grayscale = false;
  private grayscaleDirection: string;
  private gradientColorArray = [];
  private fillValues = [];
  private points;
  private disabled = false;

  constructor(d3: D3) {
    this.d3 = d3;
    this.axes = new WaterfallAxes(this.d3);
  }
  appendElement(chartContainerElem: any, graphId, height, xDomain, yDomain, gradientChangedFunc = null) {
    this.gradientChanged = gradientChangedFunc;
    this.height = height;
    this.id = graphId + '-gradient';
    this.gradientElem = this.d3.select(chartContainerElem).append('div').attr('width', '100%').style('position', 'relative')
      .attr('id', this.id + '-container')
      .attr('height', '100%')
      .attr('class', 'pf-waterfall-gradient-widget-container');
    this.canvas = this.gradientElem.append('canvas').attr('class', 'pf-waterfall-gradient-widget-canvas')
      .attr('width', this.width + 'px')
      .attr('height', height);
    this.svg = this.gradientElem.append('svg').attr('class', 'pf-waterfall-gradient-widget-svg')
      .attr('id', this.id + '-svg')
      .style('width', (this.width * 5) + 'px').attr('height', (height + 10) + 'px');
    this.yScale = this.d3.scaleLinear().domain(yDomain).range([this.height, 0]);
    this.xScale = this.d3.scaleLinear().domain(xDomain).range([this.width, 0]);
    this.addDraggableLine(this.id + '-gradient-line-1', (this.height / 2));
    this.initializeAxes();
  }
  moveTrackingLineTo(newY) {
    if (newY > this.height) {
      newY = this.height;
    }
    if (newY < 0) {
      newY = 0;
    }
    this.gradientLineElem.select('circle').attr('transform', 'translate(' + (this.marginLeft * 2 + 4) + ',' + newY + ')');
    this.gradientLineElem.attr('display', null);
    this.gradientLineElem.selectAll('line').attr('y1', newY)
      .attr('y2', newY)
      .attr('x1', this.marginLeft)
      .attr('x2', this.marginLeft + this.width);
  }
  getTrackingLinePixelLocation(): number {
    return this.gradientLineElem.select('line').attr('y1');
  }
  toggleGradientEnabled(enabled: boolean) {
    this.disabled = !enabled;
  }
  toggleGrayscaleGradient(grayscale: boolean, direction: string = null) {
    this.grayscale = grayscale;
    this.grayscaleDirection = direction;
    if (direction === 'ASC') {
      this.gradientGrayscaleColors = this.gradientGrayscaleColors.reverse();
    } else {
      this.gradientGrayscaleColors = this.gradientGrayscaleColors.sort();
    }
  }

  getCurrentGradient() {
    return this.fillValues.slice();
  }
  reset() {
    const gradientContainer = document.querySelector('#' + this.id + '-container');
    gradientContainer.parentNode.removeChild(gradientContainer);
    this.gradientElem = null;
    this.svg = null;
    this.canvas = null;
  }
  setGradient(gradient) {
    this.gradientColors = gradient;
  }
  initializeWithData(xDomain, yDomain) {
    this.yScale = this.d3.scaleLinear().domain(yDomain).range([this.height, 0]);
    this.xScale = this.d3.scaleLinear().domain(xDomain).range([this.width, 0]);
    this.axes.create(this.svg, false, this.height, this.width, this.marginLeft,  this.xScale, this.yScale, false);
    // at first these two arrays are equal because the gradient line indicator will be placed right in the middle to start
    // once the user drags and changes the gradient, these two will no longer be equal
    // gradientColorArray is our reference and will not change
    this.fillValues = GradientGenerator.generateGradient(this.gradientColors, this.height);
    if (this.grayscale) {
      this.gradientColorArray = GradientGenerator.generateGradient(this.gradientGrayscaleColors, this.height);
    } else {
      this.gradientColorArray = GradientGenerator.generateGradient(this.gradientColors, this.height);
    }
    this.initializePoints();
    this.calculateNewGradientForDragLineLocation(this.height / 2);
    this.render();
  }
  private addDraggableLine(id, height) {
    // need two line elements to allow a larger surface area for the user to grab and drag, but apply different CSS
    // so that the thick line does not obscure data until dragged
    this.gradientLineElem = this.svg.append('g');
    this.gradientLineElem.append('line')
      .attr('x1', this.marginLeft + 10)
      .attr('y1', height)
      .attr('x2', this.width + this.marginLeft + 10)
      .attr('y2', height)
      .attr('class', 'pf-waterfall-gradient-line-midline')
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
    this.gradientLineElem.append('line')
      .attr('x1', this.marginLeft + 10)
      .attr('y1', height)
      .attr('x2', this.width + this.marginLeft + 10)
      .attr('y2', height)
      .attr('class', 'pf-waterfall-gradient-line')
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));

    this.gradientLineElem.append('circle')
      .attr('r', 4)
      .attr('class', 'pf-waterfall-gradient-circle')
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
    this.gradientLineElem.select('circle').attr('transform', 'translate(' + (this.marginLeft * 2 + 4) + ',' + height + ')')
    .attr('display', null);
  }
  private dragStart() {
    if (!this.disabled) {
      this.gradientLineElem.select('line').attr('class', 'pf-waterfall-gradient-line-drag');
    }
  }
  private initializeAxes() {
    this.axes.init(this.id,  5);
    this.axes.showYAxesTickLabels = true;
    this.axes.showXAxesTickLabels = false;
    this.axes.create(this.svg, true, this.height, this.width,this.marginLeft, this.xScale, this.yScale, false);
    const yAxis = this.axes.getYAxisElem();
    this.svg.select('#' + yAxis.node().getAttribute('id'))
      .style('left', (this.marginLeft * 3) + 'px');
    const xAxis = this.axes.getXAxisElem();
    this.svg.select('#' + xAxis.node().getAttribute('id')).remove();
  }
  private dragEnd() {
    if (!this.disabled) {
      this.gradientLineElem.select('line').attr('class', 'pf-waterfall-gradient-line-midline');
      const lineY = this.getTrackingLinePixelLocation();
      this.calculateNewGradientForDragLineLocation(lineY);
      this.render();
      if (this.gradientChanged != null) {
        this.gradientChanged();
      }
    }
  }
  private dragging(newY: number) {
    if (newY > this.height) {
      newY = this.height;
    }
    if (newY < 0) {
      newY = 0;
    }
    if (!this.disabled) {
      this.gradientLineElem.select('circle').attr('transform', 'translate(' + (this.marginLeft * 2 + 4) + ',' + newY + ')');
      this.gradientLineElem.selectAll('line').attr('y1', newY)
        .attr('x1', this.marginLeft + 10)
        .attr('y2', newY)
        .attr('x2', this.marginLeft + this.width + 10);

      setTimeout(() => {
        this.calculateNewGradientForDragLineLocation(newY);
        this.render();
      });
    }
  }
  private dragStartFunc = (d, i) => {
    this.dragStart();
  }
  private dragEndFunc = (d, i) => {
    this.dragEnd();
  }
  private draggingFunc = (d, i) => {
    //   set the freq line's x value to that of the current mouse position
    const svg = document.getElementById(this.id + '-svg');
    const newY = this.d3.mouse(svg)[1];
    this.dragging(newY);
  }

  private initializePoints() {
    this.points = [];
    for (let i = 0; i < this.height; i++) {
      for (let j = 0; j < this.width; j++) {
        const newXY = new WaterfallXY();
        newXY.x = j;
        newXY.y = i;
        this.points.push(newXY);
      }
    }
  }

  private render(): void {
    this.context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;
    this.context.clearRect(0, 0, this.width, this.height);

    this.points.forEach(point => {
      this.context.fillStyle = this.fillValues[this.calculateColorIndexForData(this.yScale.domain(),
         this.height, this.yScale.invert(point.y))];
      this.context.fillRect(point.x - 1, point.y - 1, 2, 2);
    });
  }

  private calculateNewGradientForDragLineLocation(lineY) {
    if (this.grayscale) {
      this.calculateGradient(lineY, this.gradientGrayscaleColors);

    } else {
      this.calculateGradient(lineY, this.gradientColors);
    }
  }
  private calculateGradient(lineY, gradientColors) {
    this.fillValues = [];
    this.parentGradient = [];
    const percentageAbove = ((lineY) / this.height);
    const percentageBelow = ((this.height - lineY) / this.height);
    const countBelow = Math.round(percentageAbove * this.height);
    const countAbove = Math.round(percentageBelow * this.height);
    this.fillValues = this.fillValues.concat(GradientGenerator.generateGradient(gradientColors.slice(0,
       Math.ceil(gradientColors.length / 2)), countAbove));
    this.fillValues = this.fillValues.concat(GradientGenerator.generateGradient(gradientColors.slice(Math.floor(gradientColors.length
       / 2), gradientColors.length), countBelow));
    const parentCountBelow = Math.round(percentageAbove * (this.height));
       const parentCountAbove = Math.round(percentageBelow * (this.height));
    this.parentGradient = this.parentGradient.concat(GradientGenerator.generateGradient(gradientColors.slice(0,
       Math.ceil(gradientColors.length / 2)), parentCountBelow));
    this.parentGradient = this.parentGradient.concat(GradientGenerator.generateGradient(gradientColors
      .slice(Math.floor(gradientColors.length / 2), gradientColors.length), parentCountAbove));
  }
  private calculateColorIndexForData(range: number[], dataSetLength: number, yValue: number): number {
    const rangeRange = Math.abs(range[1] - range[0]);
    const yValueDiffFromMin = Math.abs(yValue - range[0]);
    const percentageOfMinMax = Math.abs(yValueDiffFromMin / rangeRange);
    const indexOfGradientColorForData = Math.round(dataSetLength * percentageOfMinMax);
    return indexOfGradientColorForData;
  }
}
