import { D3 } from 'd3-ng2-service';

import { ChartMargins } from '../addons/pf-chart-margins';
import { ChartScale } from './pf-chart-scale';

export class ChartScaleLinear implements ChartScale {
  private d3: D3;
  private height: number;
  private width: number;
  private domain: [number, number];
  private range: [number, number];

  constructor(private defaultDomain: [number, number], private defaultRange: [number, number], private margins?: ChartMargins) { }

  init(d3: D3, height: number, width: number) {
    this.d3 = d3;
    this.height = height;
    this.width = width;
  }

  setDomainMinMax(domain: [number, number]): void {
    this.domain = domain;
  }

  setRangeMinMax(range: [number, number]): void {
    this.range = range;
  }

  getDomainMinMax(): [number, number] {
    return this.domain ? this.domain : this.defaultDomain;
  }

  getRangeMinMax(): [number, number] {
    return this.range ? this.range : this.defaultRange;
  }

  getDomain(): any {
    const domain = !!this.domain ? this.domain : this.defaultDomain;
    return this.d3.scaleLinear().
      domain(domain).
      range([0, this.margins ? this.width - this.margins.left - this.margins.right : this.width]);
  }

  getRange(): any {
    const range = !!this.range ? this.range : this.defaultRange;
    return this.d3.scaleLinear()
      .domain(range)
      .range([this.margins ? this.height - this.margins.top - this.margins.bottom : this.height, 0]);
  }

  setViewSize(height: number, width: number): void {
    this.height = height;
    this.width = width;
  }
}