﻿import { ChartData } from './pf-chart-data';

export class ChartSeries {
  protected _data: ChartData[] = [];

  constructor(protected name: string) { }

  push(data: ChartData): void {
    this._data.push(data);
  }

  data(): ChartData[] {
    return this._data;
  }

  length(): number {
    return this._data.length;
  }

  splice(start: number, deleteCount?: number, ...items): ChartData[] {
    return this._data.splice(start, deleteCount, ...items);
  }

  setName(name: string): void {
    this.name = name;
  }

  getName(): string {
    return this.name;
  }
}