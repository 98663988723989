﻿import { UUID } from 'angular2-uuid';
export class SpectrumAnalyzerTemplateVariables {
  graphSpan: number;
  graphStartFreq: number;
  graphStopFreq: number;
  graphCenterFreq: number;
  graphDbPerDiv: number;
  // needed for responsive specan
  specanContainerStyle = { 'height': '90%' };
  graphInitialized: boolean;
  currentSampleFreq: number;
  // indicates whether zooming should be on only y axis, only x, or both
  zoomOnYAxis = true;
  zoomOnXAxis = true;
  zoomMode: boolean;
  graphId = 's' + UUID.UUID();

}
