import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { D3Service } from 'd3-ng2-service';
import { ButtonModule, CheckboxModule, ContextMenuModule, OverlayPanelModule, RadioButtonModule, TooltipModule } from 'primeng/primeng';
import { SpectrumAnalyzerSeries } from './pf-specan/data-models/pf-specan-series';

import { PFChartModule } from '../chart/index';
import { PFUnitModule } from '../unit/index';
import { SpectrumAnalyzerComponent } from './pf-specan/pf-specan.component';
import { SpectrumAnalyzerThumbnailComponent } from './pf-specan-thumbnail/pf-specan-thumbnail.component';

export { SpectrumAnalyzerAxes } from './pf-specan/axes/pf-specan-axes';
export { SpectrumAnalyzerSeries } from './pf-specan/data-models/pf-specan-series';
export { SpectrumAnalyzerData } from './pf-specan/data-models/pf-specan-data';
export { SpectrumAnalyzerConfiguration } from './pf-specan/configuration/pf-specan-config';
export { SpectrumAnalyzerUnits } from './pf-specan/configuration/pf-specan-units';
export { SpectrumAnalyzerRenderer } from './pf-specan/render/pf-specan-renderer';
export { SpectrumAnalyzerDataParameters } from './pf-specan/configuration/pf-specan-data-params';
export { SpectrumAnalyzerXY } from './pf-specan/data-models/pf-specan-xy';
export { SpecanLineType } from './pf-specan/data-models/pf-specan-line-type';
export { SpectrumAnalyzerFrequencyLine } from './pf-specan/graph-elements/pf-specan-frequency-line';
export { SpectrumAnalyzerCarrierRegionElement } from './pf-specan/graph-elements/pf-specan-carrier-region-element';
export { CarrierRegion } from './pf-specan/data-models/pf-specan-carrier-region';
export { SpectrumAnalyzerComponent } from './pf-specan/pf-specan.component';
export { SpectrumAnalyzerThumbnailComponent } from './pf-specan-thumbnail/pf-specan-thumbnail.component';
export { SpectrumAnalyzerThumbnailConfiguration } from './pf-specan-thumbnail/configuration/pf-specan-thumbnail-config';
export { SpectrumAnalyzerThumbnailRenderer } from './pf-specan-thumbnail/render/pf-specan-thumbnail-renderer';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ContextMenuModule,
    CheckboxModule,
    ButtonModule,
    RadioButtonModule,
    OverlayPanelModule,
    TooltipModule,
    PFChartModule,
    PFUnitModule,
  ],
  providers: [
    D3Service,
  ],
  declarations: [
    SpectrumAnalyzerComponent, SpectrumAnalyzerThumbnailComponent
  ],
  exports: [
    SpectrumAnalyzerComponent, SpectrumAnalyzerThumbnailComponent
  ],
})
export class PFSpecanModule {}
