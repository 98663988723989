﻿import { UUID } from 'angular2-uuid';
export class WaterfallPlotTemplateVariables {
  // needed for responsive specan
  waterfallContainerStyle = { 'height': '90%' };
  // indicates whether zooming should be on only y axis, only x, or both
  zoomOnYAxis = true;
  zoomOnXAxis = true;
  zoomMode: boolean;
  graphInitialized: boolean;
  graphId = 'w' + UUID.UUID();
  playbackSpeedInMs = 500;
  rendering = false;
  grayscale = false;
  grayscaleChoice = 'DESC';
}
