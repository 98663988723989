import { EntityAlias } from "./entity-alias";


export class Site {
  constructor(
    public site_id?: string,
    public name?: string,
    public aliases?: EntityAlias[],
    public site_types?: string[],
    public longitude?: number,
    public latitude?: number,
    public altitude?: number,
    public active?: boolean,
  ) {
    this.aliases = [];
    this.site_types = [];
  }
  static copy(existingItem) {
    let newObj = JSON.parse(JSON.stringify(existingItem));
    if (newObj.aliases == null) {
      newObj.aliases = [];
    }
    if (newObj.site_types == null) {
      newObj.site_types = [];
    }
    return newObj;
  }
}
