import { XYPoint } from '../../../chart/index';
export class WaterfallXY implements XYPoint {
  x: number;
  y: number;
  powerVal: number;
  constructor(fromPoint: XYPoint = null, x: number = null, y: number = null, powerVal: number = null) {
    if (fromPoint != null) {
      this.x = fromPoint.x;
      this.y = fromPoint.y;
      this.powerVal = (fromPoint as any).powerVal;
    } else if (x != null && y != null) {
      this.x = x;
      this.y = y;
      this.powerVal = powerVal;
    }
  }

}
