import {JwtHelper } from 'angular2-jwt';
export class AuthResponse {
  account_id: string;
  profile_id: string;
  token: AuthToken;
}

export class AuthToken {
  static helper: JwtHelper = new JwtHelper();
  expires_in: number;
  retrieved_at: number;
  access_token: string;
  refresh_token: string;
  static isTokenAboutToExpire(token: AuthToken) {
    let exp = this.getTokenExpiration(token);
    let twoMins = 120000;
    let expiresInLessThanTwoMins = exp.getTime() - this.getUtcDate(new Date(Date.now())) < twoMins;
    return expiresInLessThanTwoMins;
  }
  private static getUtcDate(date) {
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  static getTokenExpiration(token: AuthToken) {
    return this.helper.getTokenExpirationDate(token.access_token);
  }
  static isTokenExpired(token: AuthToken) {
    return this.helper.isTokenExpired(token.access_token);
  }
  static getTokenInformation(token: AuthToken) {
    let tokenSplit = token.access_token.split('.');
    let payload = JSON.parse(atob(tokenSplit[1]));
    return payload;
  }
  static getAccountId(token: AuthToken) {
    let payload = this.getTokenInformation(token);
    for (let i = 0; i < payload.principals.length; i++) {
      if (payload.principals[i].account_id != null) {
        return payload.principals[i].account_id;
      }
    }
  }
  static getProfileId(token: AuthToken) {
    let payload = this.getTokenInformation(token);
    for (let i = 0; i < payload.principals.length; i++) {
      if (payload.principals[i].profile_id != null) {
        return payload.principals[i].profile_id;
      }
    }
  }
}
