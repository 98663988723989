import { Component, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CanvasGraphElementModel, MarkerShape } from 'app/components/graphs/chart';
import { SpectrumAnalyzerComponent, SpectrumAnalyzerConfiguration, SpectrumAnalyzerDataParameters, SpectrumAnalyzerSeries, SpectrumAnalyzerUnits } from 'app/components/graphs/specan';
import { UnitConversion, UnitOptions } from 'app/components/graphs/unit';
import { WaterfallXY } from 'app/components/graphs/waterfall';
import { AnalysisParams } from 'app/models/analysis-params';
import { UserService } from 'app/services/user-service';
import { Observable, Subscription } from 'rxjs/Rx';
import { DataViewConfiguration, Trace } from '../../models/index';
import { AppUtilities } from '../../services/app-utilities';
import { DataViewDemoService } from '../../services/data-view-demo-service';
import { AnalysisSelectionBarComponent } from '../analysis-selection-bar/analysis-selection-bar.component';
import { MarkerDataset } from './marker-dataset';
@Component({
  selector: 'specan-view',
  templateUrl: './specan-view.component.html',
  styleUrls: ['./specan-view.component.css']
})

export class SpecanViewComponent implements OnDestroy {
  currentAnalysisParams = new AnalysisParams();
  contextMenuItems: any[];
  disablePlay: boolean;
  UnitOptions = UnitOptions;
  Math = Math;
  disablePlayReverse: boolean;
  disableStepForward: boolean;
  disableStepBack: boolean;
  configuration: DataViewConfiguration = new DataViewConfiguration();
  @ViewChild('specan') specan: SpectrumAnalyzerComponent;
  @ViewChild(AnalysisSelectionBarComponent) analysisSelectionBar: AnalysisSelectionBarComponent;
  interval: any;
  liveUpdateIntervalCount = 0;
  markers = new Array<MarkerDataset>();
  selectedMarker: string;
  liveUpdateInterval: any;
  showConfigPanel = false;
  currentTraceIdx = 0;
  specanDataSeries: SpectrumAnalyzerSeries = new SpectrumAnalyzerSeries('SA Trace');
  specanUnits: SpectrumAnalyzerUnits = new SpectrumAnalyzerUnits();
  specanDataModelList: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();

  specanConfig: SpectrumAnalyzerConfiguration = new SpectrumAnalyzerConfiguration();
  specanDataParams: SpectrumAnalyzerDataParameters = new SpectrumAnalyzerDataParameters();

  atLeastOneAnalysisHasRun: boolean;

  saSpecanData: Trace[] = new Array<Trace>();

  playingTraces: boolean;
  playingReverseTraces: boolean;
  playbackSpeed = 100;
  showingZoom: boolean;

  traceSub: Subscription;
  startAnalysisOnLogin: boolean;
  specanGraphElements: CanvasGraphElementModel[] = new Array<CanvasGraphElementModel>();
  specanContainerWidth = 'calc(100%)';

  mainTraceColor;
  minTraceColor;
  maxTraceColor;
  avgTraceColor;
  minTraceVisible = false;
  maxTraceVisible = false;
  avgTraceVisible = false;
  mainTraceVisible = true;

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint() {
    this.specanContainerWidth = '7.5in';
    this.specanDataSeries.graphColor = 'black';
    setTimeout(() => {
      this.specan.refreshGraph();
      this.specan.resizeGraph();
    })
  }

  @HostListener('window:afterprint', ['$event'])
  onAfterPrint() {
    this.specanContainerWidth = 'calc(100%)';
    this.specanDataSeries.graphColor = '#b9f5eb';
    setTimeout(() => {
      this.specan.refreshGraph();
      this.specan.resizeGraph();
    })
  }

  constructor(private traceDataService: DataViewDemoService, private route: ActivatedRoute,
    private utilityService: AppUtilities, private userService: UserService) {
    this.setDefaultTraceColors();
    this.configureSpecan();
    this.saMinIcon = 'pi pi-fw';
    this.saMaxIcon = 'pi pi-fw';
    this.saAvgIcon = 'pi pi-fw';
    this.refreshCMIcons();
    let timeout = setTimeout(() => {
      this.specanDataModelList.push(this.specanDataSeries);
      clearTimeout(timeout);
    });
  }

  private retrievePastConfigFromLastSession() {
    this.userService.getCurrentUserInfo().subscribe((response) => {
      if (response.success) {
        let user = JSON.parse(response.data);
        let details = user.details;
        if (details.specanConfig != null) {
          this.minTraceColor = (details.specanConfig as any).minTraceColor;
          this.maxTraceColor = (details.specanConfig as any).maxTraceColor;
          this.avgTraceColor = (details.specanConfig as any).avgTraceColor;
          this.mainTraceColor = (details.specanConfig as any).mainTraceColor;
          this.saveColors();
        }
      }
    });
  }
  saveUserConfigToSession() {
    let specanConfig = {};
    (specanConfig as any).minTraceColor = this.minTraceColor;
    (specanConfig as any).maxTraceColor = this.maxTraceColor;
    (specanConfig as any).avgTraceColor = this.avgTraceColor;
    (specanConfig as any).mainTraceColor = this.mainTraceColor;
    this.userService.saveValueToUserSession('specanConfig', specanConfig).subscribe((response) => {
      if (response.success) {

      } else {

      }
    });
  }
  yAxisTickFormatFunc = function (d: number) {
    return new Date(d).toLocaleString();
  };

  ngOnInit() {
    this.route.queryParamMap
      .subscribe((paramMap: any) => {
        let satId = paramMap.params['satelliteId'];
        let transponderId = paramMap.params['transponderId'];
        let carrierId = paramMap.params['carrierId'];
        let loadLast250 = paramMap.params['loadLast250'];
        let timestamp = paramMap.params['timestamp'];
        if (satId != null && transponderId != null) {
          this.currentAnalysisParams.satelliteId = satId;
          this.currentAnalysisParams.transponderId = transponderId;
          this.currentAnalysisParams.carrierId = carrierId;
          this.currentAnalysisParams.stopInMs = timestamp;
          this.currentAnalysisParams.startInMs = timestamp;
          if (loadLast250 != null && loadLast250 == 'false') {
            this.currentAnalysisParams.loadRecent = false;
          } else
            this.currentAnalysisParams.loadRecent = true;

          this.startAnalysisOnLogin = true;
        }
      });
  }

  pollForLiveUpdates() {
    if (this.liveUpdateIntervalCount == 0) {
      this.liveUpdateIntervalCount++;
      this.specanDataSeries.avgCount = 1;
      this.getSpecanDataForChosenDates(this.currentAnalysisParams);
      this.liveUpdateInterval = setInterval(() => {
        this.currentAnalysisParams.stopInMs = Date.now();
        this.getSpecanDataForChosenDates(this.currentAnalysisParams);
      }, 60000);
    } else {
      this.stopPolling();
    }
  }
  stopPolling() {
    if (this.liveUpdateInterval != null) {
      this.liveUpdateIntervalCount = 0;
      clearInterval(this.liveUpdateInterval);
    }
  }
  toggleLiveUpdate(liveUpdate) {
    if (liveUpdate) {
      this.pollForLiveUpdates();
    } else {
      this.stopPolling();
    }
  }

  saveColors() {
    this.setSpecanColors();
    if(this.specan != null)
      this.specan.refreshGraph();
  }
  setSpecanColors() {
    this.specanDataSeries.graphColor = this.mainTraceColor;
    this.specanDataSeries.getDataSetByName('min').color = this.minTraceColor;
    this.specanDataSeries.getDataSetByName('max').color = this.maxTraceColor;
    this.specanDataSeries.getDataSetByName('avg').color = this.avgTraceColor;
  }

  toggleConfigMenu(show = null) {
    if (show != null)
      this.showConfigPanel = show;
    else
      this.showConfigPanel = !this.showConfigPanel;
    setTimeout(() => {
      this.specan.resizeGraph();
    })
  }

  startAnalysis(params) {
    this.resetBeforeAnalysis();
    this.currentAnalysisParams = params;
    let timeout = setTimeout(() => {
      this.refreshCMIcons();
      if (this.currentAnalysisParams.liveUpdate) {
        this.pollForLiveUpdates();
      } else {
        this.getSpecanDataForChosenDates(this.currentAnalysisParams);
      }

    }, 75);
  }
  resetBeforeAnalysis() {
    this.resetPlayback();
    this.resetTraceVisibility();
    this.atLeastOneAnalysisHasRun = true;
    this.currentTraceIdx = 0;
    this.resetMarkers();
  }
  resetMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].datasetName = '';
      this.markers[i].delta = 0;
      this.markers[i].marker = null;
      this.markers[i].referenceMarker = null;
      this.markers[i].visible = false;
    }
  }

  getSpecanDataForChosenDates(params: AnalysisParams) {
    this.initializeLoadingSpinners();
    this.getDataForDates(params.satelliteId, params.transponderId, params.carrierId, params.startInMs, params.stopInMs, 0, 0, Math.round(this.specan.getCurrentGraphWidth()), params.limit);
  }
  private setDefaultTraceColors() {
    this.mainTraceColor = '#b9f5eb';
    this.minTraceColor = '#9df791';
    this.maxTraceColor = '#f48bda';
    this.avgTraceColor = '#f9e77f';
  }
  restoreDefaultColors() {
    this.setDefaultTraceColors();
    this.specanDataSeries.graphColor = this.mainTraceColor;
    this.specanDataSeries.getDataSetByName('min').color = this.minTraceColor;
    this.specanDataSeries.getDataSetByName('max').color = this.maxTraceColor;
    this.specanDataSeries.getDataSetByName('avg').color = this.avgTraceColor;
    this.specan.refreshGraph();
  }

  toggleMinVisibility(checked) {
    this.minTraceVisible = checked;
    if (checked) {
      this.specanDataSeries.setMinVisibility(true);
    } else {
      this.specanDataSeries.setMinVisibility(false);
    }
    this.specan.refreshGraph();
    this.refreshCMIcons();
  }

  toggleMaxVisibility(checked) {
    this.maxTraceVisible = checked;
    if (checked) {
      this.specanDataSeries.setMaxVisibility(true);
    } else {
      this.specanDataSeries.setMaxVisibility(false);
    }
    this.specan.refreshGraph();
    this.refreshCMIcons();
  }

  toggleAvgVisibility(checked) {
    this.avgTraceVisible = checked;
    if (checked) {
      this.specanDataSeries.setAvgVisibility(true);
    } else {
      this.specanDataSeries.setAvgVisibility(false);
    }
    this.specan.refreshGraph();
    this.refreshCMIcons();
  }
  //recalculateMinMaxAvg() {
  //  if (this.saSpecanData.length > 0) {
  //    let maxTrace = this.specanDataSeries.getDataSetByName('max');
  //    let minTrace = this.specanDataSeries.getDataSetByName('min');
  //    let avgTrace = this.specanDataSeries.getDataSetByName('avg');
  //    for (let i = 0; i < this.saSpecanData[this.currentTraceIdx].powerValues.length; i++) {
  //      maxTrace.pointAtIndex(i).y = this.saSpecanData[this.currentTraceIdx].powerValues[i];
  //      minTrace.pointAtIndex(i).y = this.saSpecanData[this.currentTraceIdx].powerValues[i];
  //      avgTrace.pointAtIndex(i).y = this.saSpecanData[this.currentTraceIdx].powerValues[i];
  //    }
  //    for (let i = this.currentTraceIdx; i < this.saSpecanData.length; i++) {
  //      for (let j = 0; j < maxTrace.length(); j++) {
  //        let pointMax = maxTrace.pointAtIndex(j);
  //        let pointMin = minTrace.pointAtIndex(j);
  //        let pointAvg = avgTrace.pointAtIndex(j);
  //        if (this.saSpecanData[i].powerValues[j] > pointMax.y)
  //          pointMax.y = this.saSpecanData[i].powerValues[j];
  //        else if (pointMin.y > this.saSpecanData[i].powerValues[j])
  //          pointMin.y = this.saSpecanData[i].powerValues[j];
  //        pointAvg.y += this.saSpecanData[i].powerValues[j];
  //      }
  //    }
  //    let traceCountIncludedInAvg = this.saSpecanData.length - this.currentTraceIdx + 1; //add one for the first value it was initialized to
  //    for (let i = 0; i < this.saSpecanData[this.currentTraceIdx].powerValues.length; i++) {
  //      avgTrace.pointAtIndex(i).y /= traceCountIncludedInAvg;
  //    }
  //  }
  //}

  resetTraceVisibility() {
    this.specanDataSeries.setMinVisibility(false);
    this.specanDataSeries.setMaxVisibility(false);
    this.specanDataSeries.setAvgVisibility(false);
    this.avgTraceVisible = false;
    this.minTraceVisible = false;
    this.maxTraceVisible = false;
  }

  getNextTrace(interval) {
    let idx = 0;
    return Observable.interval(interval).flatMap(() => {
      if (idx > 350) {
        idx = 0;
      }
      return this.traceDataService.getLiveTraces(this.currentAnalysisParams.satelliteId, this.currentAnalysisParams.transponderId, this.currentAnalysisParams.carrierId, idx++);
    });
  }

  isPlaying() {
    return this.playingTraces;
  }
  togglePlayback(forward = true) {
    if (this.playingTraces || this.playingReverseTraces) {
      this.stopPlayback();
    } else {
      this.startPlayback(forward);
    }
  }
  startPlayback(forward) {

    this.interval = setInterval(() => {
      if (forward) {
        this.playNextForwardTrace();
      }
      else {
        this.playNextReverseTrace();
      }
      this.setDisabledPropsForPlayButtons();
    }, this.playbackSpeed);
  }
  playNextReverseTrace() {
    this.playingReverseTraces = true;
    this.currentTraceIdx--;
    if (this.currentTraceIdx > 0) {
      // move line
      this.showSpecanDataAtIndex(this.currentTraceIdx);
    } else {
      this.currentTraceIdx++;
      this.playingReverseTraces = false;
      clearInterval(this.interval);
    }
  }
  playNextForwardTrace() {
    this.playingTraces = true;
    this.currentTraceIdx++;
    if (this.currentTraceIdx < this.saSpecanData.length) {
      // move line
      this.showSpecanDataAtIndex(this.currentTraceIdx);
    } else {
      this.currentTraceIdx--;
      this.playingTraces = false;
      clearInterval(this.interval);
    }
  }
  stopPlayback() {
    this.playingTraces = false;
    this.playingReverseTraces = false;
    clearInterval(this.interval);
  }
  stepForward() {
    this.stopPlayback();
    if (this.currentTraceIdx < this.saSpecanData.length) {
      this.currentTraceIdx++;
      this.showSpecanDataAtIndex(this.currentTraceIdx);
    }
    this.setDisabledPropsForPlayButtons();
  }
  stepBackward() {
    this.stopPlayback();
    if (this.currentTraceIdx > 0) {
      this.currentTraceIdx--;
      this.showSpecanDataAtIndex(this.currentTraceIdx);
    }
    this.setDisabledPropsForPlayButtons();
  }
  playFromEnd() {
    this.currentTraceIdx = 0;
    this.showSpecanDataAtIndex(0);
  }
  resetPlayback() {
    this.stopPlayback();
    this.playFromEnd();
  }
  playFromBeginning() {
    this.currentTraceIdx = this.saSpecanData.length - 1;
    this.showSpecanDataAtIndex(this.saSpecanData.length - 1);
  }
  showSpecanDataBySlider = (event) => {
    if (event.value != null) {
      if (event.value > -1 && event.value < this.saSpecanData.length) {
        this.currentTraceIdx = event.value;
        this.showSpecanDataAtIndex(event.value);
      } else if (event.value > this.saSpecanData.length) {

      }
    }
  }
  showSpecanDataAtIndex(index) {
    if (this.saSpecanData.length > index) {
      let currentData = this.saSpecanData[this.currentTraceIdx];
      this.specanDataSeries.initializePowerValues(currentData.powerValues, currentData.startFreq, currentData.stepFreq);
      this.specanConfig.graphTitle = this.getDateString(currentData.timestamp);
      this.specan.renderGraph();
      this.calculateMarkerDeltas();
    }
    //this.recalculateMinMaxAvg();
    this.setDisabledPropsForPlayButtons();
  }
  private getDateString(timestamp) {
    let string = new Date(timestamp).toUTCString();
    return 'Captured: ' + string.replace(/GMT-.*$/g, 'UTC');
  }
  playbackSpeedChanged() {
    if (this.playingTraces) {
      this.stopPlayback();
      this.startPlayback(true);
    } else if (this.playingReverseTraces) {
      this.stopPlayback();
      this.startPlayback(false);
    }
  }

  setDisabledPropsForPlayButtons() {
    if (this.currentTraceIdx == 0) {
      this.disableStepBack = true;
      this.disablePlayReverse = true;
    }
    else {
      this.disableStepBack = false;
      this.disablePlayReverse = false;
    }
    if (this.currentTraceIdx >= this.saSpecanData.length - 1) {
      this.disableStepForward = true;
      this.disablePlay = true;
    }
    else {
      this.disableStepForward = false;
      this.disablePlay = false;
    }
  }
  //need to reimplement once carrier regions are needed
  //findCarrierForFreq(freq: number): Carrier {
  //  let carrier;
  //  for (let i = 0; i < this.availableCarriers.length; i++) {
  //    if (freq < this.availableCarriers[i].stopFreq && freq > this.availableCarriers[i].startFreq) {
  //      carrier = this.availableCarriers[i];
  //      break;
  //    }
  //  }
  //  return carrier;
  //}

  getDataForDates(satellite, transponder, carrier, start, stop, startFreq, endFreq, traceLength, traceCount) {
    let timeout2 = setTimeout(() => {
      this.saSpecanData = [];
      this.getSASpecanData(satellite, transponder, carrier, start, stop, startFreq, endFreq, traceLength, traceCount);
      //this.getSawtooth(satellite, transponder, carrier, start, stop);
      clearTimeout(timeout2);
    });
  }

  toggleSpecanZoom(show = null) {
    if (show != null) {
      this.showingZoom = show;
    } else {
      this.showingZoom = !this.showingZoom;
    }
    if (this.showingZoom) {
      this.specan.showZoomMenu();
      this.specanConfig.allowZoom = true;
    } else {
      this.specan.hideZoomMenu();
      this.specanConfig.allowZoom = false;
    }
  }

  getSawtooth(satellite, transponder, carrier, start, stop) {
    let traceSub = this.traceDataService.getSawtooth(satellite, transponder, carrier, start, stop).subscribe((result) => {
      if (result.success) {
        this.specanGraphElements = new Array<CanvasGraphElementModel>();
        let sawtoothData = result.data;
        if (sawtoothData != null) {
          const specanElement = new CanvasGraphElementModel();
          specanElement.connectStartAndEndPoints = false;
          specanElement.fillInShapeWithBackground = false;
          specanElement.lineWidth = 10;
          specanElement.lineColor = 'white';
          specanElement.xyPoints = new Array<WaterfallXY>();
          for (let i = 0; i < sawtoothData.length; i++) {
            specanElement.xyPoints.push(new WaterfallXY(sawtoothData[i]));
          }
          this.specanGraphElements.push(specanElement);
        }
      } else {
        this.utilityService.showError('Error Retrieving Sawtooth Data', result.responseMsg);
      }
      traceSub.unsubscribe();
    },
      (err) => {
        this.specanConfig.showLoadingOverlay = false;
        traceSub.unsubscribe();
        this.utilityService.showError('Error Retrieving Sawtooth Data', err.message);
      });
  }
  getSASpecanData(satellite, transponder, carrier, start, stop, startFreq, endFreq, traceLength, traceCount) {
    
    let traceSub = this.traceDataService.getTraces(satellite, transponder, carrier, start, stop, startFreq, endFreq, traceLength, traceCount).subscribe((result) => {
      if (!this.currentAnalysisParams.liveUpdate) {
        this.analysisSelectionBar.stopAnalysis();
        this.specanDataSeries.avgCount = traceCount;
      }
      else {
        this.analysisSelectionBar.finishedLoading();
        this.specanDataSeries.avgCount++;
      }
      this.specanConfig.showLoadingOverlay = false;
      this.toggleSpecanZoom(true);
      if (result.success) {
        this.loadSpecanDataFromResponse(result);
      } else {
        this.utilityService.showError('Error Retrieving SA Specan Data', result.responseMsg);
        if (!this.currentAnalysisParams.liveUpdate) {
          this.analysisSelectionBar.stopAnalysis();
        }
      }
      traceSub.unsubscribe();
    },
      (err) => {
        this.specanConfig.showLoadingOverlay = false;
        if (!this.currentAnalysisParams.liveUpdate) {
          this.analysisSelectionBar.stopAnalysis();
        }
          traceSub.unsubscribe();
        this.utilityService.showError('Error Retrieving SA Specan Data', err.message);
      });
  }
 
  loadSpecanDataFromResponse(result) {
    this.saSpecanData = null;
    this.saSpecanData = new Array<Trace>();
    if (result.data != null) {
      for (let i = 0; i < result.data.length; i++) {
        this.saSpecanData.push(result.data[i]);
      }
      this.currentTraceIdx = this.saSpecanData.length - 1;
      if (this.saSpecanData[this.currentTraceIdx] != null) {
        if (this.currentAnalysisParams.dataModelNeedsUpdate) {
          this.specanDataSeries.resetDataModel();
          this.currentAnalysisParams.dataModelNeedsUpdate = false;
        }
        if (!this.atLeastOneAnalysisHasRun) {
          this.specan.renderGraph();
        }
        this.specan.refreshGraph();
        this.specan.setDbPerDiv(5);
        this.showSpecanDataAtIndex(this.currentTraceIdx);
        this.atLeastOneAnalysisHasRun = true;
      } else {
        this.utilityService.showError('No Data Found', 'No specan data was found for the specified combination');
        if(!this.currentAnalysisParams.liveUpdate)
          this.specanDataSeries.resetDataModel();
        this.specan.renderGraph();
      }
    } else {
      this.utilityService.showError('No Data Found', 'No specan data was found for the specified combination');
        if(!this.currentAnalysisParams.liveUpdate)
          this.specanDataSeries.resetDataModel();
      this.specan.renderGraph();
    }
  }
  stopAnalysis() {
    if (this.currentAnalysisParams.liveUpdate)
      this.stopPolling();
  }
  resetMinMaxAvg() {
    this.specanDataSeries.resetDataModel();
    this.showSpecanDataAtIndex(this.currentTraceIdx);
    this.specan.refreshGraph();
  }
  showSpecanDataAtTimestamp(timestamp: number) {
    let specanData;
    if (this.currentAnalysisParams.liveUpdate) {
      specanData = this.getSpecanTraceForTimestampDesc(this.saSpecanData, timestamp);
    } else {
      specanData = this.getSpecanTraceForTimestampAsc(this.saSpecanData, timestamp);
    }
    if (specanData != null) {
      this.specanDataSeries.initializePowerValues(specanData.powerValues, specanData.startFreq, specanData.stepFreq);
      this.specanConfig.graphTitle = this.getDateString(timestamp);
      this.specan.renderGraph();
    }

  }

  getSpecanTraceForTimestampDesc(data: Trace[], timestamp: number) {
    let traceTsIsGreaterThanGivenTs = true;
    let traceIdx = 0;
    for (; traceIdx < data.length; traceIdx++) {
      // as soon as this becomes false, we've found our trace
      if (!traceTsIsGreaterThanGivenTs)
        break;
      if (data[traceIdx].timestamp <= timestamp)
        traceTsIsGreaterThanGivenTs = false;
    }
    if (traceIdx < data.length) {
      let oneTraceBefore = data[traceIdx + 1];
      let diffTimeOneBefore = Math.abs(oneTraceBefore.timestamp - timestamp);
      let diffSelected = Math.abs(data[traceIdx].timestamp - timestamp);
      if (diffSelected < diffTimeOneBefore) {
        return data[traceIdx];
      } else {
        return data[traceIdx + 1];
      }

    }
    else if (traceIdx == 0) {
      return data[traceIdx];
    }
    else
      return null;
  }
  getSpecanTraceForTimestampAsc(data: Trace[], timestamp: number) {
    let traceTsIsLessThanGivenTs = true;
    let traceIdx = 0;
    for (; traceIdx < data.length; traceIdx++) {
      // as soon as this becomes false, we've found our trace
      if (!traceTsIsLessThanGivenTs)
        break;
      if (data[traceIdx].timestamp >= timestamp)
        traceTsIsLessThanGivenTs = false;
    }
    if (traceIdx < data.length) {
      let oneTraceBefore = data[traceIdx - 1];
      let diffTimeOneBefore = Math.abs(oneTraceBefore.timestamp - timestamp);
      let diffSelected = Math.abs(data[traceIdx].timestamp - timestamp);
      if (diffSelected < diffTimeOneBefore) {
        return data[traceIdx];
      } else {
        return data[traceIdx - 1];
      }
    }
    else if (traceIdx == 0) {
      return data[traceIdx];
    }
    else
      return null;
  }

  ngOnDestroy() {
    if (this.traceSub != null) {
      this.traceSub.unsubscribe();
    }
    clearInterval(this.interval);
    clearInterval(this.liveUpdateInterval);
    this.saSpecanData = [];
  }

  markerMoved(marker, xUnitInput) {
    xUnitInput.readOnly = true;
    marker.markerMoved.next();
  }
  private configureSpecan() {
    this.specanConfig.borderColor = 'white';
    this.specanConfig.numberOfTicks = 10;
    this.specanConfig.showLegend = true;
    this.specanConfig.showRBWandVBW = false;
    this.specanConfig.showDataMenus = false;
    this.specanUnits.xAxisUnit = new UnitConversion(UnitOptions.Hz, UnitOptions.GHz, [UnitOptions.MHz, UnitOptions.GHz]);
    this.specanUnits.spanUnit = new UnitConversion(UnitOptions.Hz, UnitOptions.MHz, [UnitOptions.MHz, UnitOptions.kHz]);
    this.specanConfig.graphTitle = 'No Data to Display';
    this.specanConfig.showSampleFreq = false;
    this.specanConfig.showConfigButton = false;
    this.specanDataSeries.graphColor = '#b9f5eb';
    this.specanConfig.showLegend = true;
    this.specanDataSeries.avgCount = 0;
    for (let i = 1; i <= 4; i++) {
      let newMarkerDataset = new MarkerDataset();
      newMarkerDataset.markerName = 'M ' + i;
      this.markers.push(newMarkerDataset);
    }
    this.setSpecanColors();

  }

  getTimeDurationFromTicks(ticks): string {
    let days = Math.floor(ticks / (1000 * 60 * 60 * 24));
    ticks -= days * (1000 * 60 * 60 * 24);

    let hours = Math.floor(ticks / (1000 * 60 * 60));
    ticks -= hours * (1000 * 60 * 60);

    let mins = Math.floor(ticks / (1000 * 60));
    ticks -= mins * (1000 * 60);

    let seconds = Math.floor(ticks / (1000));
    ticks -= seconds * (1000);

    let resultString = '';
    if (days > 0) {
      resultString += days + " days ";
    }
    if (hours > 0) {
      resultString += hours + ' hours ';
    }
    resultString += mins + ' mins ';
    return resultString;
  }
  ngAfterViewInit() {
    this.setSpecanTooltip();
    this.retrievePastConfigFromLastSession();
    let sub = this.userService.checkUserAuthenticated().subscribe((response) => {
      if (response.success) {
        if (this.startAnalysisOnLogin) {
          this.analysisSelectionBar.startAnalysisWithParams(this.currentAnalysisParams);
        } else {
          setTimeout(() => {
            this.analysisSelectionBar.getSatellites();
          });
        }
      } else {
        let newSub = this.userService.userLoggedInObs.subscribe(() => {
          if (this.startAnalysisOnLogin) {
            this.analysisSelectionBar.startAnalysisWithParams(this.currentAnalysisParams);
          } else {
            setTimeout(() => {
              this.analysisSelectionBar.getSatellites();
            });
          }
        })
      }
    });
  }
  private setSpecanTooltip() {
    this.specanConfig.tooltipValueFunc = (position: WaterfallXY): string[] => {
      let values = new Array<string>();
      values.push(position.y.toFixed(this.specanConfig.roundXToDecimal) + ' dB');
      values.push((position.x * this.specan.units.xAxisUnit.defaultUnit.multiplier).toFixed(this.specanConfig.roundXToDecimal) + ' ' + this.specan.units.xAxisUnit.defaultUnit.name);
      //let carrier = this.findCarrierForFreq(position.x);
      //if (carrier != null) {
      //  values.push('Carrier: ' + carrier.primaryName);
      //} else {
      //  values.push('No Carrier');
      //}
      return values;
    };
  }
  initializeLoadingSpinners() {
    this.specanConfig.showLoadingOverlay = true;
  }

  saMinIcon: string;
  saMaxIcon: string;
  saAvgIcon: string;
  displaySaIcon: string = 'pi pi-check';
  modMinIcon: string;
  modMaxIcon: string;
  modAvgIcon: string;
  displayModIcon: string = 'pi pi-check';
  showSaMin: boolean;
  showSaAvg: boolean;
  showSaMax: boolean;
  showModMin: boolean;
  showModMax: boolean;
  showModAvg: boolean;
  refreshCMIcons() {
    this.contextMenuItems = [];
    let markerItems = [];
    for (let i = 0; i < 4; i++) {
      let markerName = 'M ' + (i + 1);
      let refMarkerName = 'R ' + (i + 1);
      markerItems.push({
        label: 'Marker ' + (i + 1),
        items: [
          {
            label: 'Main',
            icon: this.markers[i].visible && this.markers[i].datasetName == 'main' ? 'pi pi-check' : '',
            command: () => {
              this.toggleMarkerOnTrace('main', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          // { separator: true },
          {
            label: 'Min Hold',
            disabled: !this.minTraceVisible,
            icon: this.markers[i].visible && this.markers[i].datasetName == 'min' ? 'pi pi-check' : '',
            command: () => {
              this.toggleMarkerOnTrace('min', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          {
            label: 'Max Hold',
            disabled: !this.maxTraceVisible,
            icon: this.markers[i].visible && this.markers[i].datasetName == 'max' ? 'pi pi-check' : '',
            command: () => {
              this.toggleMarkerOnTrace('max', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          {
            label: 'Average',
            disabled: !this.avgTraceVisible,
            icon: this.markers[i].visible && this.markers[i].datasetName == 'avg' ? 'pi pi-check' : '',
            command: () => {
              this.toggleMarkerOnTrace('avg', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          { separator: true }
        ]
      });
    }
    let refMarkerItems = [];
    for (let i = 0; i < 4; i++) {
      let markerName = 'M ' + (i + 1);
      let refMarkerName = 'R ' + (i + 1);
      let items = null;
      if (this.markers[i].visible) {
        items = [
          {
            label: 'Main',
            disabled: this.markers[i].datasetName != 'main',
            icon: this.markers[i].visible && this.markers[i].datasetName == 'main' && this.markers[i].referenceMarker != null ? 'pi pi-check' : '',
            command: () => {
              this.toggleRefMarkerOnTrace('main', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          {
            label: 'Min Hold',
            disabled: !this.minTraceVisible || this.markers[i].datasetName != 'min',
            icon: this.markers[i].visible && this.markers[i].datasetName == 'min' && this.markers[i].referenceMarker != null ? 'pi pi-check' : '',
            command: () => {
              this.toggleRefMarkerOnTrace('min', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          {
            label: 'Max Hold',
            disabled: !this.maxTraceVisible || this.markers[i].datasetName != 'max',
            icon: this.markers[i].visible && this.markers[i].datasetName == 'max' && this.markers[i].referenceMarker != null ? 'pi pi-check' : '',
            command: () => {
              this.toggleRefMarkerOnTrace('max', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          {
            label: 'Average',
            disabled: !this.avgTraceVisible || this.markers[i].datasetName != 'avg',
            icon: this.markers[i].visible && this.markers[i].datasetName == 'avg' && this.markers[i].referenceMarker != null ? 'pi pi-check' : '',
            command: () => {
              this.toggleRefMarkerOnTrace('avg', markerName, refMarkerName);
              this.refreshCMIcons();
            }
          },
          { separator: true }
        ];
      }
      refMarkerItems.push({
        label: 'Reference Marker ' + (i + 1),
        disabled: !this.markers[i].visible,
        items: items
      });
    }
    markerItems = markerItems.concat(refMarkerItems);
    markerItems.push({
      label: 'Remove All',
      command: () => {
        this.removeAllMarkers();
        this.refreshCMIcons();
      }
    })
    this.contextMenuItems.push({
      label: 'Markers',
      items: markerItems
    });
  }
  private removeAllMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].marker != null) {
        this.specan.removeMarker(this.markers[i].marker.name);
        if (this.markers[i].referenceMarker != null) {
          this.specan.removeMarker(this.markers[i].referenceMarker.name);
          this.markers[i].referenceMarker = null;
        }
        this.markers[i].visible = false;
        this.markers[i].marker = null;
      }
    }
  }
  private toggleMarkerOnTrace(dataSetName, markerName, refMarkerName) {
    let idx = this.markers.findIndex(markerDataSet => { return markerDataSet.markerName == markerName; });
    if (this.markers[idx].marker != null) {
      this.specan.removeMarker(markerName);
      if (this.markers[idx].referenceMarker != null) {
        this.specan.removeMarker(refMarkerName);
        this.markers[idx].referenceMarker = null;
      }
      this.markers[idx].visible = false;
      if (this.markers[idx].datasetName != dataSetName)
        this.addMarkerToTrace(dataSetName, markerName);
      else {
        this.markers[idx].marker = null;
        this.markers[idx].referenceMarker = null;
      }
    } else {
      this.addMarkerToTrace(dataSetName, markerName);
    }
  }
  private toggleRefMarkerOnTrace(dataSetName, markerName, refMarkerName) {
    let idx = this.markers.findIndex(markerDataSet => { return markerDataSet.markerName == markerName; });
    if (this.markers[idx].referenceMarker != null) {
      this.specan.removeMarker(refMarkerName);
      if (this.markers[idx].referenceMarker.pathDataSetName != dataSetName) {
        this.addRefMarkerToTrace(dataSetName, markerName, refMarkerName);
      } else {
        this.markers[idx].referenceMarker = null;
      }
    } else {
      this.addRefMarkerToTrace(dataSetName, markerName, refMarkerName);
    }
  }
  private addMarkerToTrace(dataSetName, markerName) {
    let idx = this.markers.findIndex(markerDataSet => { return markerDataSet.markerName == markerName; });
    if (idx != -1) {
      this.specan.removeMarker(markerName);
    }
    let newMarker = this.specan.addMarkerToGraph(dataSetName, markerName, [markerName], MarkerShape.circle);
    this.markers[idx].marker = newMarker as any;
    this.markers[idx].datasetName = dataSetName;
    this.markers[idx].visible = true;
    setTimeout(() => {
    this.toggleConfigMenu(true);
    })
  }
  private addRefMarkerToTrace(dataSetName, markerName, refMarkerName) {
    let idx = this.markers.findIndex(markerDataSet => { return markerDataSet.referenceMarker != null && markerDataSet.referenceMarker.name == refMarkerName; });
    if (idx != -1) {
      this.specan.removeMarker(refMarkerName);
    }
    let newMarker = this.specan.addMarkerToGraph(dataSetName, refMarkerName, [refMarkerName], MarkerShape.circle);
    idx = this.markers.findIndex(markerDataSet => { return markerDataSet.markerName == markerName; });
    this.markers[idx].referenceMarker = newMarker as any;
    setTimeout(() => {
      this.toggleConfigMenu(true);
    })
  }
  private calculateMarkerDeltas() {
    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].referenceMarker != null) {
        this.markers[i].delta = this.markers[i].marker.y - this.markers[i].referenceMarker.y;
      }
    }
  }
}
