export class ChartMargins {

  constructor(public top?: number, public right?: number, public bottom?: number, public left?: number) {
    if (this.top === undefined) {
      this.top = 0;
    }
    if (this.left === undefined) {
      this.left = 0;
    }
    if (this.right === undefined) {
      this.right = 0;
    }
    if (this.bottom === undefined) {
      this.bottom = 0;
    }
  }

  chartWidth(width: number): number {
    return width - this.left - this.right;
  }

  chartHeight(height: number): number {
    return height - this.top - this.bottom;
  }
}