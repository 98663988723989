﻿export class ChartUtility {
  static calculateNewDomain(span: number, domain: [number, number]): [number, number] {
    // change the x domain to reflect the specified span
    // get the previous span and divide by two
    const prevSpan = (domain[1] - domain[0]) / 2;
    const spanDelta = (span / 2) - prevSpan;

    // apply the new changes
    domain[0] = domain[0] - spanDelta;
    domain[1] = domain[1] + spanDelta;
    return domain;
  }
  static zoomDomainByPercentage(direction: number, domain: [number, number], percentage: number): [number, number] {
    const move = ((domain[1] - <number>domain[0]) * percentage) * direction;
    const newDomain = [domain[0] + move, domain[1] - move] as [number, number];
    return newDomain;
  }
  static moveDomain(direction: number, domain: [number, number], distance: number): [number, number] {
    const move = ((domain[1] - <number>domain[0]) / distance) * direction;
    domain[0] = domain[0] + move;
    domain[1] = domain[1] + move;
    return domain;
  }
  static translateSvgToDomCoordinate(svgDomain: [number, number], domDomain: [number, number], svgPointToTranslate: number) {
    const svgRatio = svgPointToTranslate / (svgDomain[1] - svgDomain[0]);
    return (domDomain[1] - domDomain[0]) * svgRatio;
  }
}