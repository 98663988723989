import { EntityDef } from "app/models/entity-def";

export class Carrier extends EntityDef {
  primaryName: string;
  noradNumber: string
  centralDB: string;
  monitoringDB: string;
  satIDDB: string;
  nominalCenterFreq: number;
  measuredCenterFreq: number;
  measuredBandwidth: number;
  startFreq: number;
  stopFreq: number;
  //UI
  transponderName: string; 
  satelliteName: string; 
}
