export class SpectrumAnalyzerThumbnailAttributes {
  graphBorderColor = '#000';
  ticks: number;
  margin = 5;
  // outer refers to the whole graph including axes
  // inner refers to the inside group that needs a different size, excluding the axes, to get the correct scale
  innerGraphWidth: number;
  innerGraphHeight: number;
  outerGraphWidth: number;
  outerGraphHeight: number;
  graphId: string;
}
