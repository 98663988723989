﻿import { Axis, D3, Selection } from 'd3-ng2-service';
import { ZoomEventInfo } from '../../index';
export class CanvasZoom {
    zoomFunc: any;
    private d3: D3;
    private brush: any;
    private brushId: string;

    constructor(d3: D3) {
        this.d3 = d3;
    }
    initialize(scrollZoomFunc, extent: [number, number], graphId) {
        this.zoomFunc = this.d3.zoom().scaleExtent(extent).on('zoom', scrollZoomFunc);
        this.brushId = graphId + '-canvas-brush';
    }
    appendZoom(canvas, width, height) {
        canvas.call(this.zoomFunc);
    }
    resetTranslate(context, height, width) {
        context.clearRect(0, 0, width, height);
        const identityTransform = this.d3.zoomIdentity;
        context.translate(identityTransform.x, identityTransform.y);
    }
    zoom(context: CanvasRenderingContext2D, eventInfo: ZoomEventInfo, width, height) {
        context.save();
        context.clearRect(0, 0, width, height);
        context.scale(eventInfo.transform.k, eventInfo.transform.k);
    }
}