import {
  Component, ElementRef, HostListener, EventEmitter, Output, Input, OnDestroy,
  AfterViewInit, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { D3, D3Service, Selection, ZoomBehavior } from 'd3-ng2-service';
import { Observable, Subscription } from 'rxjs/Rx';

import {
  CarrierRegion
} from '../pf-specan/data-models/pf-specan-carrier-region';
import { SpectrumAnalyzerSeries } from '../pf-specan/data-models/pf-specan-series';
import { SpectrumAnalyzerThumbnailConfiguration } from './configuration/pf-specan-thumbnail-config';
import { SpectrumAnalyzerDataParameters } from '../pf-specan/configuration/pf-specan-data-params';
import { SpectrumAnalyzerUnits } from '../pf-specan/configuration/pf-specan-units';
import { SpectrumAnalyzerTemplateVariables } from '../pf-specan/pf-specan-html-template-vars';
import { SpectrumAnalyzerThumbnail } from './pf-specan-thumbnail';
import { MenuItem } from 'primeng/primeng';
import { ChartScale, SvgZoom, ZoomEventInfo } from '../../chart/index';


@Component({
  selector: 'pf-spectrum-analyzer-thumbnail',
  templateUrl: './pf-specan-thumbnail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpectrumAnalyzerThumbnailComponent implements AfterViewInit, OnDestroy {

  @Input() config: SpectrumAnalyzerThumbnailConfiguration = new SpectrumAnalyzerThumbnailConfiguration();
  @Input() dataParams: SpectrumAnalyzerDataParameters = new SpectrumAnalyzerDataParameters();
  // by default this is initialized to the most commonly used units. Does not have to be provided but can be overridden
  @Input() units: SpectrumAnalyzerUnits = new SpectrumAnalyzerUnits();
  @Input() carrierRegions: CarrierRegion[] = new Array<CarrierRegion>();
  @Input() dataModels: SpectrumAnalyzerSeries[] = new Array<SpectrumAnalyzerSeries>();

  // this is the connection between the private specan object and the html template for this component.
  // rather than directly exposing elements of the renderer and axes to the html template,
  // we will use this object to set the variables accordingly and use them in this component and in the html
  htmlTemplateVars: SpectrumAnalyzerTemplateVariables = new SpectrumAnalyzerTemplateVariables();

  private elementRef: ElementRef;
  private d3: D3;
  private d3Service: D3Service = new D3Service();
  private specan: SpectrumAnalyzerThumbnail;

  @ViewChild('graphContainer') private graphContainer: ElementRef;
  
  private specanInitialized: boolean;

  private visibilitySub: Subscription;

  constructor(d3Service: D3Service, private ref: ElementRef, private changeDetector: ChangeDetectorRef) {
    this.d3 = d3Service.getD3();
    window.onresize = () => {
      if (this.config.graphVisible) {
        this.specan.resize();
      }
    };

    this.specan = new SpectrumAnalyzerThumbnail(this.d3);
    this.elementRef = ref;
  }
  getCurrentGraphHeight() {
    return this.specan.getInnerGraphHeight();
  }

  getCurrentGraphWidth() {
    return this.specan.getInnerGraphWidth();
  }
  detectChanges() {
    if(this.changeDetector != null)
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    this.initializeSpecan();
    this.visibilitySub = this.config.graphVisibilityChanged.subscribe((visible) => {
      if (!this.specanInitialized && visible) {
        // timeout is necessary to give html elements enough time to appear before attempting to access them
        this.specan.initialize();
        this.specanInitialized = true;
      }
    });
    // check to make sure an array type was passed in
    if ((this.dataModels == null)) {
      throw new Error('Attribute \'dataModels\' is required');
    }
    if (!(this.dataModels instanceof Array)) {
      throw new Error('Attribute \'dataModels\' must be of type SpectrumAnalyzerSeries[]');
    }
    if (!(this.units instanceof SpectrumAnalyzerUnits)) {
      throw new Error('Attribute \'units\' must be of type SpectrumAnalyzerUnits');
    }
  }

  resizeGraph() {
    if (this.config.graphVisible) {
      this.specan.resize();
    }
  }

  // can be called if graph attributes have been changed from a parent component (i.e. tooltip visibility)
  refreshGraph() {
    if (this.config.graphVisible) {
      this.syncModelsWithSpecan();
      this.specan.refreshGraph();
    }
  }

  renderGraph() {
    if (this.config.graphVisible) {
      if (!this.specanInitialized) {
        this.specan.initialize();
        this.specanInitialized = true;
      }
      this.syncModelsWithSpecan();
      this.specan.render();
    }
  }

  autoscaleGraph() {
    if (this.config.graphVisible) {
      this.specan.autoscaleGraph();
    }
  }
  getCurrentDomainX() {
    if (this.config.graphVisible) {
      return this.specan.getCurrentDomainX();
    }
  }
  getCurrentDomainY() {
    if (this.config.graphVisible) {
      return this.specan.getCurrentDomainY();
    }
  }
 
  setDomainX(domainX: [number, number]) {
    if (this.config.graphVisible) {
      this.specan.zoomToDomain(domainX, null);
    }
  }
  setDomainY(domainY: [number, number]) {
    if (this.config.graphVisible) {
      this.specan.zoomToDomain(null, domainY);
    }
  }
  setDomainXAndY(domainX: [number, number], domainY: [number, number]) {
    if (this.config.graphVisible) {
      this.specan.setScaling(domainY, domainX);
      this.specan.zoomToDomain(domainX, domainY);
    }
  }

  toggleModelVisible(model: SpectrumAnalyzerSeries) {
    if (!model.visible) {
      model.selected = false;
      model.getDataSetByName('avg').visible = false;
      model.getDataSetByName('min').visible = false;
      model.getDataSetByName('max').visible = false;
      model.getDataSetByName('main').visible = false;

    } else {
      // show main by default when path's visibility is toggled back on
      model.getDataSetByName('main').visible = true;
    }
    this.renderGraph();
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:pf-resize', ['$event'])
  onResize(event: Event): void {
    if (this.config.graphVisible) {
      this.specan.resize();
    }
  }

  ngOnDestroy(): void {
    this.changeDetector.detach();
    if (this.config.graphVisible) {
      this.specan.removeSvg();
    }
    if(this.visibilitySub != null)
      this.visibilitySub.unsubscribe();
    this.specan.destroy();
  }

  private initializeSpecan() {
    this.specan.htmlTemplateVars = this.htmlTemplateVars;
    this.specan.dataModels = this.dataModels;
    this.specan.dataParams = this.dataParams;
    this.specan.carrierRegions = this.carrierRegions;
    this.specan.config = this.config;
    this.specan.graphContainer = this.graphContainer;
    if (this.config.graphVisible) {
      this.specan.initialize();
      this.specanInitialized = true;
    }
  }

  private syncModelsWithSpecan() {
    this.specan.htmlTemplateVars = this.htmlTemplateVars;
    this.specan.dataModels = this.dataModels;
    this.specan.dataParams = this.dataParams;
    this.specan.carrierRegions = this.carrierRegions;
    this.specan.config = this.config;
    this.specan.graphContainer = this.graphContainer;
  }
}
