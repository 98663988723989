import { Trace } from "app/models";

export class TraceRequestResponse {
  satelliteName: string;
  transponderName: string;
  carrierName: string;
  mostRecentTimestamp: number;
  traceLength: number;
  traceCount: number = 1;
  traces: Trace[];
}
