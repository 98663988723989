export class GradientGenerator {
  static generateGradient(colors: string[], stepCount: number): string[] {
    let gradientColors = [];
    const colorPairs = colors.length - 1;
    let stepsPerColorPair = Math.round(stepCount / colorPairs);
    if(stepsPerColorPair < 1)
      stepsPerColorPair = 1;
    for (let i = 1; i < colors.length; i++) {
      gradientColors = gradientColors.concat(this.generateGradientBetweenTwoColors(colors[i], colors[i - 1], stepsPerColorPair));
    }
    return gradientColors;
  }
  private static hex(c) {
    const s = '0123456789abcdef';
    let i = parseInt(c, 10);
    if (i === 0 || isNaN(c)) {
      return '00';
    }
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - i % 16) / 16) + s.charAt(i % 16);
  }

  /* Convert an RGB triplet to a hex string */
  private static convertToHex(rgb) {
    return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
  }

  /* Remove '#' in color hex string */
  private static trim(s) { return (s.charAt(0) === '#') ? s.substring(1, 7) : s; }

  /* Convert a hex string to an RGB triplet */
  private static convertToRGB(hex) {
    const color = [];
    color[0] = parseInt((this.trim(hex)).substring(0, 2), 16);
    color[1] = parseInt((this.trim(hex)).substring(2, 4), 16);
    color[2] = parseInt((this.trim(hex)).substring(4, 6), 16);
    return color;
  }

  private static generateGradientBetweenTwoColors(colorStart, colorEnd, colorCount) {

    // The beginning of your gradient
    const start = this.convertToRGB(colorStart);

    // The end of your gradient
    const end = this.convertToRGB(colorEnd);

    // The number of colors to compute
    const len = colorCount;

    // Alpha blending amount
    let alpha = 0.0;

    const colorList = [];

    for (let i = 0; i < len; i++) {
      const c = [];
      alpha += (1.0 / len);

      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];

      colorList.push('#' + this.convertToHex(c));
    }
    return colorList;
  }
}
