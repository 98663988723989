﻿import { ChartData } from '../../../chart/index';
import { WaterfallXY } from './pf-waterfall-xy';
export class WaterfallData extends ChartData {
    visible = true;
    timestamp: number;
    graphDataId: string;
    //  this is the param that contains the y values of the data
    initializePowerValues(powerVals: number[], startFreq: number, stepFreq: number): void {
        for (let i = 0; i < powerVals.length; i++) {
            const newXY = new WaterfallXY();
            newXY.x = startFreq + (stepFreq * i);
            newXY.y = powerVals[i];
            this.push(newXY);
        }
    }
}