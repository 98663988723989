import { ElementRef } from '@angular/core';
import { ScaleContinuousNumeric } from 'd3-ng2-service';
import { D3, D3Service } from 'd3-ng2-service';
import { SpectrumAnalyzerSeries } from '../../index';
import { ChartData } from '../../../chart/index';
import { SpectrumAnalyzerThumbnailAttributes } from './pf-specan-thumbnail-attributes';

export class SpectrumAnalyzerThumbnailRenderer {
  d3: D3;
  attributes: SpectrumAnalyzerThumbnailAttributes = new SpectrumAnalyzerThumbnailAttributes();
  svg: any;
  svgDataContainer: any;
  private borderColorElem: any;
  constructor(d3: D3) {
    this.d3 = d3;
  }

  appendCanvas(canvas: any, chartContainerElem: any) {
    canvas = this.d3.select(chartContainerElem)
      .append('canvas')
      .attr('width', this.attributes.outerGraphWidth)
      .attr('height', this.attributes.outerGraphHeight)
      .attr('class', 'pf-specan-canvas');
    return canvas;
  }
 
  changeBorderColor(svg, color) {
    this.attributes.graphBorderColor = color;
    if (this.borderColorElem != null) {
      this.borderColorElem.style('stroke', this.attributes.graphBorderColor);
    } else {
      this.addBorder(svg, color);
    }
    return svg;
  }

  clearCanvas(context) {
    context.clearRect(0, 0, this.attributes.outerGraphWidth, this.attributes.outerGraphHeight);
  }

  appendLineToCanvas(context, dataPath: SpectrumAnalyzerSeries, scaleX, scaleY) {
    const mainDataSet = dataPath.getDataSetByName('main');
    if (dataPath.visible && mainDataSet.visible) {
      context.beginPath();
      context.strokeStyle = dataPath.graphColor;
      mainDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
    const avgDataSet = dataPath.getDataSetByName('avg');
    if (avgDataSet.visible && dataPath.visible) {
      context.beginPath();
      context.strokeStyle = avgDataSet.color;
      avgDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
    const minDataSet = dataPath.getDataSetByName('min');
    if (minDataSet.visible && dataPath.visible) {
      context.beginPath();
      context.strokeStyle = minDataSet.color;
      minDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
    const maxDataSet = dataPath.getDataSetByName('max');
    if (maxDataSet.visible && dataPath.visible) {
      context.beginPath();
      context.strokeStyle = maxDataSet.color;
      maxDataSet.points().forEach(point => {
        context.lineTo(scaleX(point.x) - 1, scaleY(point.y) - 1, 2);
      });
      context.stroke();
    }
  }
 
  resize(): number {
    let parentComponent;
    const currentComponent = document.getElementById(this.attributes.graphId + '-pf-specan-container');
    if (currentComponent != null) {
      parentComponent = currentComponent.parentElement.parentElement;
    }
    if (parentComponent != null) {
      const style = window.getComputedStyle(parentComponent, null);
      const rect = parentComponent.getBoundingClientRect();
      this.attributes.outerGraphHeight = rect.height;
      this.attributes.outerGraphWidth = rect.width;
      // have to subtract the padding on the div to get the correct height and width
      this.attributes.outerGraphHeight -= parseFloat(style.paddingTop.replace('px', ''))
        + parseFloat(style.paddingBottom.replace('px', ''));
      this.attributes.outerGraphWidth -= parseFloat(style.paddingLeft.replace('px', ''))
        + parseFloat(style.paddingRight.replace('px', ''));

      this.attributes.innerGraphHeight = this.attributes.outerGraphHeight - this.attributes.margin;
      this.attributes.innerGraphWidth = this.attributes.outerGraphWidth - this.attributes.margin;
      if (this.attributes.innerGraphHeight < 0) {
        this.attributes.innerGraphHeight = 0;
        this.attributes.outerGraphHeight = 0;
      }
      if (this.attributes.innerGraphWidth < 0) {
        this.attributes.innerGraphWidth = 0;
        this.attributes.outerGraphWidth = 0;
      }
      if (this.attributes.innerGraphHeight < 175) {
        this.attributes.ticks = 5;
      }
      return this.attributes.innerGraphHeight;
    }
  }

  addGraphBorder(svg: any) {
      if (this.attributes.graphBorderColor !== '') {
          this.addBorder(svg, this.attributes.graphBorderColor);
      }
  }
 
  private addBorder(svg, color) {
    this.borderColorElem = svg.append('rect')
      .attr('x', 1)
      .attr('y', 1)
      .attr('height', this.attributes.innerGraphHeight - 2)
      .attr('width', this.attributes.innerGraphWidth - 2)
      .style('stroke', color)
      .attr('id', this.attributes.graphId + '-border')
      .style('fill', 'none')
      .style('stroke-width', 3);
  }


}
