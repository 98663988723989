import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { ActivationPopupComponent } from "./popups/activation/activation-popup.component";

export abstract class FormComponent {
  @Output() emitClose = new EventEmitter<string>();

  @Input('selectedItem') selectedItem: any;
  @Input('activationPopup') activationPopup: ActivationPopupComponent;

  add = false;
  edit = false;

  activateMethod;
  deactivateMethod;

  secondForm = false;
  entityType;
  entityOperation;

  constructor(private dataGenerator: any, type:number, operation) {
    this.entityType = type;
    this.entityOperation = operation;
  }
  save() {
    if (this.add && this.verifyAddItems()) {
      this.addAsset(this.dataGenerator.performEntityOperation(this.entityOperation.Create, this.entityType, JSON.stringify(this.selectedItem)));
    }
    else if (this.edit && this.verifyEditItems()) {
      if (this.selectedItem.generalInfo != null)
        this.selectedItem.generalInfo = JSON.stringify(this.selectedItem.generalInfo);
      this.editAsset(this.dataGenerator.performEntityOperation(this.entityOperation.Update, this.entityType, JSON.stringify(this.selectedItem)));
      //not sure why this is necessary twice?
      if(this.selectedItem.generalInfo != null)
        this.selectedItem.generalInfo = JSON.parse(this.selectedItem.generalInfo);
    }
  }
  abstract verifyAddItems();
  abstract verifyEditItems();
  activate() {
    this.activateAsset(this.dataGenerator.performEntityOperation(this.entityOperation.Activate, this.entityType, this.selectedItem.id));
  }

  deactivate() {
    this.deactivateAsset(this.dataGenerator.performEntityOperation(this.entityOperation.Deactivate, this.entityType, this.selectedItem.id));
  }
  addAsset(addMethod) {
    let sub = addMethod.subscribe((result) => {
      if (result.success) {
        this.dataGenerator.showError("Item Added to DB", "");
        this.add = false;
        this.edit = true;
      } else {
        this.dataGenerator.showError("Item Not Added to DB", result.responseMsg);
      }
      sub.unsubscribe();
    });
  }

  cancel() {
    this.add = false;
    this.edit = false;
  }

  editAsset(editMethod) {
    let sub = editMethod.subscribe((result) => {
      if (result.success) {
        this.dataGenerator.showError("Item Updated in DB", "");
      } else {
        this.dataGenerator.showError("Item Not Updated in DB", result.responseMsg);
      }
      sub.unsubscribe();
    });
  }

  activateAsset(activateMethod) {
    let sub = activateMethod.subscribe((result) => {
      if (result.success) {
        this.dataGenerator.showError("Item Activated", "");
      } else {
        this.dataGenerator.showError("Item Not Activated", result.responseMsg);
      }
      sub.unsubscribe();
    });
  }
  delete() {
    this.selectedItem.entities = [];
    let data = {} as any;
    data.id = this.selectedItem.id;
    let sub = this.dataGenerator.performEntityOperation(this.entityType, this.entityOperation.Delete, data).subscribe((result) => {
      if (result.success) {
        this.dataGenerator.showError("Item Deleted from DB", "");
        this.edit = false;
        this.add = true;
      } else {
        this.dataGenerator.showError("Item Not Deleted from DB", result.responseMsg);
      }
      sub.unsubscribe();
    });
  }

  deactivateAsset(deactivateMethod) {
    let sub = deactivateMethod.subscribe((result) => {
      if (result.success) {
        this.dataGenerator.showError("Item Deactivated", "");
      } else {
        this.dataGenerator.showError("Item Not Deactivated", result.responseMsg);
      }
      sub.unsubscribe();
    });
  }

  checkActivation(checkMethod, type: string, method) {
    let sub = checkMethod.subscribe((results) => {
      if (results.success && results.data.length > 0) {
        this.openPopup(results.data, type);
      } else if (results.success) {
        sub.unsubscribe();
        if (type == "ACTIVATE") {
          this.activateAsset(method);
        } else {
          this.deactivateAsset(method);
        }
      } else {
        this.dataGenerator.showError("Item Not " + type.toLowerCase + "d", results.responseMsg);
      }
    });
  }

  openPopup(results, text) {
    this.activationPopup.linkNames = results;
    this.activationPopup.selectedItem = this.selectedItem;
    this.activationPopup.type = text;
    this.activationPopup.visible = true;
    this.activationPopup.activateMethod = this.activateMethod;
    this.activationPopup.deactivateMethod = this.deactivateMethod;
  }

  newGenInfo() {
    setTimeout(() => {
      if (!this.selectedItem.generalalInfo) {
        this.selectedItem.generalInfo = new Object();
      }
      this.selectedItem.generalInfo.new = "value";
      this.selectedItem.generalInfo = Object.assign({}, this.selectedItem.generalInfo);
    }, 500)
  }

  newOtherName() {
    setTimeout(() => {
      if (!this.selectedItem.otherNames) {
        this.selectedItem.otherNames = new Object();
      }
      this.selectedItem.otherNames.new = "value";
      this.selectedItem.otherNames = Object.assign({}, this.selectedItem.otherNames);
    }, 500)
  }

  removeGenInfo(key) {
    setTimeout(() => {
      delete this.selectedItem.generalInfo[key];
      this.selectedItem.generalInfo = Object.assign({}, this.selectedItem.generalInfo);
    }, 500)
  }

  removeOtherName(key) {
    setTimeout(() => {
      delete this.selectedItem.otherNames[key];
      this.selectedItem.otherNames = Object.assign({}, this.selectedItem.otherNames);
    }, 500)
  }
}
