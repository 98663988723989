export class MonitorGroup {
  monitor_group_id: number;
  profile_id: string;
  name: string;
  settings = new MonitorGroupSettings();
  items: MonitorGroupItem[] = new Array<MonitorGroupItem>();
}
export class MonitorGroupSettings {
  showAlarms: boolean = true;
  showSawtooth: boolean = true;
  symmetricalView: boolean = true;
}
export class MonitorGroupItem {
  satellite_id: string;
  transponder_id: string;
  carrier_id: string;
}
