﻿import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs/Rx';
import { DataViewConfiguration } from '../models/index';

@Injectable()
export class ConfigurationService {
    ConfigurationChangedSource = new Subject<DataViewConfiguration>();
    ConfigurationChanged = this.ConfigurationChangedSource.asObservable();
}

