import { Axis, D3, Selection } from 'd3-ng2-service';
import { XYPoint } from '../../../chart/index';
import { SvgTooltipConfig } from './pf-svg-tooltip-config';

export class SvgTooltip {

  config: SvgTooltipConfig;
  x: number;
  y: number;
  private d3: D3;
  private id = 'tooltip';
  private tooltipElem: any;

  constructor(d3: D3) {
    this.d3 = d3;
  }
  initialize(id: string, config: SvgTooltipConfig) {
    this.id = id;
    this.config = config;
  }
  addTooltipElementToSvg(svg: any) {
    this.tooltipElem = svg.append('g').attr('id', this.id)
      .attr('display', 'none');

    this.tooltipElem.append('rect')
      .attr('width', this.config.tooltipWidth)
      .attr('x', 5)
      .attr('height', this.config.tooltipTextHeight)
      .attr('class', this.config.bgClassName);

    this.tooltipElem.append('text')
      .attr('x', 35)
      .attr('dy', '1.2em')
      .style('text-anchor', 'middle')
      .attr('class', this.config.textClassName)
      .attr('font-size', this.config.fontSize)
      .attr('font-weight', this.config.fontWeight);

    this.tooltipElem.append('circle')
      .attr('id', this.id + 'circle')
      .attr('r', 4)
      .attr('class', this.config.circleClassName);
  }
  showTooltipAtPosition(position: XYPoint, text: string[]) {
    if (text.length > 0) {
      this.tooltipElem.select('text').selectAll('tspan').remove();
      let longestTextLength = 0;
      for (let i = 0; i < text.length; i++) {
        const textMarginLeft = (text[i].toString().length / 2) * 6;
        const extraMargin = 10;
        this.tooltipElem.select('text').append('tspan')
          .attr('x', textMarginLeft + extraMargin).attr('dy', '1.2em')
          .text(text[i].toString());
        if (longestTextLength < text[i].toString().length) {
          longestTextLength = text[i].toString().length;
        }
      }
      const tooltipWidth = longestTextLength * 7;
      const tooltipHeight = this.config.tooltipTextHeight * text.length;
      this.tooltipElem.select('rect')
        .attr('height', tooltipHeight)
        .attr('width', tooltipWidth);

      // if the tooltip would go off the graph, attach it to the other side
      let translateX = position.x;
      let translateY = position.y;
      let circleLocationX = 3;
      let circleLocationY = 1;
      if (position.x + tooltipWidth > this.config.graphWidth) {
        translateX -= tooltipWidth;
        circleLocationX += tooltipWidth;
      }
      if (position.y + tooltipHeight > this.config.graphHeight) {
        translateY -= tooltipHeight;
        circleLocationY += tooltipHeight;
      }
      this.tooltipElem.attr('transform', 'translate(' + translateX + ',' + translateY + ')').attr('display', null);
      this.tooltipElem.select('#' + this.id + 'circle')
        .attr('cx', circleLocationX)
        .attr('cy', circleLocationY);
      this.x = position.x;
      this.y = position.y;
    } else {
      this.hide();
    }
  }
  hide() {
    if (this.tooltipElem != null) {
      this.tooltipElem.attr('display', 'none');
    }
  }
  reset() {
    this.tooltipElem = null;
  }
}
