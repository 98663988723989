﻿export class Burst {
    carrierName: string;
    label: string;
    centerFreq: number;
    bandwidth: number;
    startFreq: number;
    stopFreq: number;
    burstStartDate: number;
    burstEndDate: number;
    burstDuration: number;
}