import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { D3, D3Service, Selection, ZoomBehavior } from 'd3-ng2-service';

import { ChartAddon } from './addons/pf-chart-addon';
import { ChartMargins } from './addons/pf-chart-margins';
import { ChartSeries } from './data/pf-chart-series';
import { Chart } from './pf-chart';
import { ChartRenderer } from './render/pf-chart-renderer';
import { ChartScale } from './scale/pf-chart-scale';
import { ChartZoomRange } from './zoom/pf-chart-zoom-range';

@Component({
  selector: 'pf-chart',
  templateUrl: './pf-chart.component.html',
  styleUrls: ['./pf-chart.component.css']
})
export class ChartComponent implements OnInit, OnDestroy {
  @Input() renderer: ChartRenderer;
  @Input() scale: ChartScale;
  @Input() addons: ChartAddon[];
  @Input() zoomRange: ChartZoomRange;
  @Input() margins: ChartMargins;

  protected d3: D3;
  protected svg: Selection<any, {}, null, undefined>;
  protected canvas: Selection<any, {}, null, undefined>;
  protected chart: Chart;
  protected zoom: ZoomBehavior<Element, {}>;
  protected width: number;
  protected height: number;

  @ViewChild('chartContainer') private chartContainer: ElementRef;

  constructor(d3Service: D3Service) {
    this.d3 = d3Service.getD3();
  }

  getD3(): D3 {
    return this.d3;
  }

  getSvg(): Selection<any, {}, null, undefined> {
    return this.svg;
  }

  getCanvas(): Selection<any, {}, null, undefined> {
    return this.canvas;
  }

  getChartContainer(): ElementRef {
    return this.chartContainer;
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:pf-resize', ['$event'])
  onResize(event: Event): void {
    setTimeout(() => {
      this.height = this.chartContainer.nativeElement.offsetHeight as number;
      this.width = this.chartContainer.nativeElement.offsetWidth as number;

      this.scale.setViewSize(this.height, this.width);
      this.canvas.attr('width', this.canvasWidth()).attr('height', this.canvasHeight());
      this.updateScale();
    });
  }

  ngOnInit() {
    this.createChart();
  }

  ngOnDestroy(): void {
    this.d3.select(this.chartContainer.nativeElement).select('svg').remove();
    this.d3.select(this.chartContainer.nativeElement).select('canvas').remove();
  }

  render(data: ChartSeries[]): void {
    if (!!this.chart) {
      this.chart.render(data);
    }
  }

  resetZoom(): void {
    this.zoom.scaleTo(this.canvas as any, 1);
  }

  resetPan(): void {
    this.zoom.translateTo(this.canvas as any, this.canvasWidth() / 2, this.canvasHeight() / 2);
  }

  scaleFromData(data: ChartSeries[]): void {
    let minDomain = undefined, maxDomain = undefined, minRange = undefined, maxRange = undefined;
    data.forEach(series => {
      series.data().forEach(chartData => {
        const domain = chartData.getDomain();
        const range = chartData.getRange();
        if (undefined === minDomain || domain[0] < minDomain) {
          minDomain = domain[0];
        }
        if (undefined === maxDomain || domain[1] > maxDomain) {
          maxDomain = domain[1];
        }
        if (undefined === minRange || range[0] < minRange) {
          minRange = range[0];
        }
        if (undefined === maxRange || range[1] > maxRange) {
          maxRange = range[1];
        }
      });
    });
    const domainRange = maxDomain - minDomain;
    const rangeRange = maxRange - minRange;
    const domainPadding = .02 * domainRange;
    const rangePadding = .02 * rangeRange;
    this.scale.setDomainMinMax([minDomain - domainPadding, maxDomain + domainPadding]);
    this.scale.setRangeMinMax([minRange - rangePadding, maxRange + rangePadding]);
    this.updateScale();
  }

  updateScale(): void {
    const domainScale = this.scale.getDomain();
    const rangeScale = this.scale.getRange();
    if (this.addons) {
      for (const addon of this.addons) {
        if (addon.onScaleOrResize) {
          addon.onScaleOrResize(this.height, this.width, domainScale, rangeScale);
        }
      }
    }
    this.chart.onScaleOrResize(this.height, this.width, domainScale, rangeScale);
  }

  private createChart() {
    this.height = this.chartContainer.nativeElement.offsetHeight as number;
    this.width = this.chartContainer.nativeElement.offsetWidth as number;

    this.createSvg();
    this.createCanvas();

    this.scale.init(this.d3, this.height, this.width);

    const context = this.canvas.node().getContext('2d') as CanvasRenderingContext2D;
    this.chart = new Chart(this.d3, context, this.height, this.width, this.renderer);

    const domainScale = this.scale.getDomain();
    const rangeScale = this.scale.getRange();
    this.chart.setScales(domainScale, rangeScale);

    if (this.addons) {
      for (const addon of this.addons) {
        addon.init(this.d3, this.height, this.width);
        addon.create(this.svg, domainScale, rangeScale);
      }
    }
  }

  private createSvg() {
    this.svg = this.d3.select(this.chartContainer.nativeElement)
      .append('svg')
      .attr('class', 'plot')
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('width', '100%')
      .style('height', '100%');
  }

  private createCanvas() {
    this.canvas = this.d3.select(this.chartContainer.nativeElement)
      .append('canvas')
      .attr('width', this.canvasWidth())
      .attr('height', this.canvasHeight())
      .attr('style', `position: absolute; top: ${this.canvasTop()}px; left: ${this.canvasLeft()}px`);

    this.zoom = this.d3.zoom();

    if (!!this.zoomRange) {
      this.zoom = this.zoom.scaleExtent([this.zoomRange.min, this.zoomRange.max]);
      this.zoom = this.zoom.on('zoom', () => {
        this.chart.zoom(this.d3.event.transform);
        if (this.addons) {
          for (const addon of this.addons) {
            if (addon.zoom) {
              addon.zoom(this.scale.getDomain(), this.scale.getRange(), this.d3.event.transform);
            }
          }
        }
      });
    }

    this.canvas = this.canvas.call(this.zoom);
  }

  private canvasWidth(): number {
    return this.margins ? this.margins.chartWidth(this.width) : this.width;
  }

  private canvasHeight(): number {
    return this.margins ? this.margins.chartHeight(this.height) : this.height;
  }

  private canvasTop(): number {
    return this.margins ? this.margins.top : 0;
  }

  private canvasLeft(): number {
    return this.margins ? this.margins.left : 0;
  }
}
