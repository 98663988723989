﻿import { Axis, D3, Selection } from 'd3-ng2-service';
import { XYPoint } from '../../../chart/index';
import { WaterfallTrackingLine } from './pf-waterfall-tracking-line';

export class VerticalWaterfallTrackingLine extends WaterfallTrackingLine {

  addElementToSvg(svg: any, currentHeight: number, currentWidth: number) {
    this.height = currentHeight;
    this.width = currentWidth;
    if (!svg.select('#' + this.id).empty()) {
      svg.select('#' + this.id).remove();
    }
    this.lineElem = svg
      .append('g').attr('id', this.id);

    // need two line elements to allow a larger surface area for the user to grab and drag, but apply different CSS
    // so that the thick line does not obscure data until dragged
    // place at halfway through the canvas minus 7 to account for the axis ticks on the left
    this.lineElem.append('line')
      .attr('x1', (this.width / 2) - 7)
      .attr('y1', 0)
      .attr('x2', (this.width / 2) - 7)
      .attr('y2', this.height)
      .attr('class', 'pf-waterfall-tracking-line-midline pf-waterfall-tracking-line-midline-v')
      .on('mouseover', this.mouseOverFunc)
      .on('mouseleave', this.mouseLeaveFunc)
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
    // place at halfway through the canvas minus 7 to account for the axis ticks on the left
    this.lineElem.append('line')
      .attr('x1', (this.width / 2) - 7)
      .attr('y1', 0)
      .attr('x2', (this.width / 2) - 7)
      .attr('y2', this.height)
      .attr('class', 'pf-waterfall-tracking-line-v')
      .on('mouseover', this.mouseOverFunc)
      .on('mouseleave', this.mouseLeaveFunc)
      .call(this.d3.drag()
        .on('start', this.dragStartFunc)
        .on('drag', this.draggingFunc)
        .on('end', this.dragEndFunc));
  }
  dragStart() {
    this.lineElem.select('.pf-waterfall-tracking-line-v').attr('class', 'pf-waterfall-tracking-line-v pf-waterfall-tracking-line-drag');
  }
  dragEnd() {
    this.lineElem.select('.pf-waterfall-tracking-line-drag').attr('class', 'pf-waterfall-tracking-line-v');
  }
  dragging(newX: number) {
    if (newX >= this.width) {
      newX = this.width - 3;
    } else if (newX <= 0) {
      newX = 3;
    }
    this.lineElem.selectAll('line').attr('x1', newX)
      .attr('y1', 0)
      .attr('x2', newX)
      .attr('y2', this.height);
  }
  moveTrackingLineTo(newX) {
    this.lineElem.attr('display', null);
    this.lineElem.selectAll('line').attr('x1', newX)
      .attr('y1', 0)
      .attr('x2', newX)
      .attr('y2', this.height);
  }
  getTrackingLinePixelLocation(): number {
    return this.lineElem.select('line').attr('x1');
  }
}