import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ContextMenuModule } from 'primeng/primeng';

import { ChartComponent } from './pf-chart/pf-chart.component';

export { ChartAddon } from './pf-chart/addons/pf-chart-addon';
export { ChartMargins } from './pf-chart/addons/pf-chart-margins';
export { ChartLabels } from './pf-chart/addons/pf-chart-labels';
export { ChartTicks } from './pf-chart/addons/pf-chart-ticks';
export { ChartMinSpacingTicks } from './pf-chart/addons/pf-chart-min-spacing-ticks';
export { ChartGrid } from './pf-chart/axes/pf-chart-grid';
export { ChartGuide } from './pf-chart/axes/pf-chart-guide';
export { ChartAxesLabels } from './pf-chart/axes/pf-chart-axes-labels';
export { ChartAxesBottomLeft } from './pf-chart/axes/pf-chart-axes-bottom-left';
export { ChartAxesCentered } from './pf-chart/axes/pf-chart-axes-centered';
export { ChartData } from './pf-chart/data/pf-chart-data';
export { ChartSeries } from './pf-chart/data/pf-chart-series';
export { XYPoint } from './pf-chart/data/pf-xy-point';
export { ChartComponent } from './pf-chart/pf-chart.component';
export { Chart } from './pf-chart/pf-chart';
export { ChartRenderer } from './pf-chart/render/pf-chart-renderer';
export { SvgRenderer } from './pf-chart/render/pf-svg-renderer';
export { SvgTooltip } from './pf-chart/tooltip/pf-svg-tooltip';
export { SvgTooltipConfig } from './pf-chart/tooltip/pf-svg-tooltip-config';
export { SvgFocusTracking } from './pf-chart/focus-tracking/pf-svg-focus-tracking';
export { SvgLegend } from './pf-chart/legend/pf-svg-legend';
export { SvgZoom } from './pf-chart/zoom/pf-svg-zoom';
export { SvgMarker } from './pf-chart/marker/pf-svg-marker';
export { MarkerShape } from './pf-chart/marker/pf-svg-marker-shape';
export { ChartScale } from './pf-chart/scale/pf-chart-scale';
export { ChartScaleLinear } from './pf-chart/scale/pf-chart-scale-linear';
export { ChartQuadbinTransform } from './pf-chart/transforms/pf-chart-quadbin-transform';
export { ChartZoomRange } from './pf-chart/zoom/pf-chart-zoom-range';
export { ZoomEventInfo } from './pf-chart/zoom/pf-zoom-event-info';
export { CanvasZoom } from './pf-chart/zoom/pf-canvas-zoom';
export { ChartUtility } from './pf-chart/utilities/pf-chart-utility';
export { CanvasGraphElementModel } from './pf-chart/graph-elements/pf-canvas-graph-element-model';
export { CanvasGraphElementRenderer } from './pf-chart/graph-elements/pf-canvas-graph-element-renderer';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    ContextMenuModule,
  ],
  declarations: [
    ChartComponent,
  ],
  exports: [
    ChartComponent,
  ],
})
export class PFChartModule {}
