import { D3, Selection } from 'd3-ng2-service';

import { ChartAddon } from '../addons/pf-chart-addon';
import { ChartMargins } from '../addons/pf-chart-margins';
import { ChartTicks } from '../addons/pf-chart-ticks';
import { AxisSelection } from './pf-chart-axis-selection';

export class ChartGrid implements ChartAddon {
  svg: Selection<any, {}, null, undefined>;
  d3: D3;
  height: number;
  width: number;

  private xGrid: AxisSelection;
  private yGrid: AxisSelection;
  private transform: any;

  private readonly elementClass = 'grid';

  constructor(private margins: ChartMargins, private ticks?: ChartTicks) { }

  init(d3: D3, height: number, width: number): void {
    this.d3 = d3;
    this.height = height;
    this.width = width;
  }

  create(svg: Selection<any, {}, null, undefined>, domainScale: any, rangeScale: any): void {
    this.svg = svg;

    if (this.xGrid) {
      this.xGrid.remove();
    }
    if (this.yGrid) {
      this.yGrid.remove();
    }

    this.xGrid = this.createX(domainScale);
    this.yGrid = this.createY(rangeScale);
  }

  zoom(domainScale: any, rangeScale: any, transform: any): void {
    this.transform = transform;
    this.xGrid.domainZoom(domainScale, transform);
    this.yGrid.rangeZoom(rangeScale, transform);
  }

  onScaleOrResize(height: number, width: number, domainScale: any, rangeScale: any): void {
    this.height = height;
    this.width = width;

    this.create(this.svg, domainScale, rangeScale);
    this.zoom(domainScale, rangeScale, this.transform);
  }

  private createX(domainScale: any): AxisSelection {
    const ticks = this.ticks ? this.ticks.numXTicks(this.margins.chartWidth(this.width)) : undefined;
    let axis = this.d3.axisTop(domainScale)
      .tickSize(-this.margins.chartHeight(this.height))
      .tickFormat(() => { return ''; });

    if (ticks) {
      axis = axis.ticks(ticks);
    }

    const selection = this.svg.append('g')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`)
      .call(axis);

    return new AxisSelection(axis, selection);
  }

  private createY(rangeScale: any): AxisSelection {
    const ticks = this.ticks ? this.ticks.numYTicks(this.margins.chartHeight(this.height)) : undefined;
    let axis = this.d3.axisRight(rangeScale)
      .tickSize(-this.margins.chartWidth(this.width))
      .tickFormat(() => { return ''; });

    if (ticks) {
      axis = axis.ticks(ticks);
    }

    const selection = this.svg.append('g')
      .attr('class', this.elementClass)
      .attr('transform', `translate(${this.width - this.margins.right}, ${this.margins.top})`)
      .call(axis);

    return new AxisSelection(axis, selection);
  }
}